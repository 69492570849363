<ng-container>
    <button mat-icon-button
            class="menu-button"
            *ngIf="canCodify"
            (click)="openMenu($event)"
            [matMenuTriggerFor]="codifyMenu">
        <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #codifyMenu="matMenu"
              [class]="'codify-stay-menu'">
        <ng-template matMenuContent>
            <button mat-menu-item
                    (click)="codifyDataSetElement()">
                <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.CODIFY_STAY' | translate}}</span>
            </button>

            <button mat-menu-item
                    (click)="groupingDataSetElement()">
                <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.GROUPING_STAY' | translate}}</span>
            </button>
        </ng-template>
    </mat-menu>
</ng-container>

<div class="spinner"
     *ngIf="isLoading">
    <mat-progress-spinner
        [diameter]="15"
        mode="indeterminate">
    </mat-progress-spinner>
</div>

