<div class="layout-container">
    <ct-toolbar></ct-toolbar>

    <div class="menu-container"
         *ngIf="menuItemGroups">
        <mat-sidenav-container autosize
                               class="full-height">
            <mat-sidenav mode="side"
                         opened="true">
                <mat-nav-list class="full-height">
                    <div class="items-container">
                        <div class="menu-items">
                            <ng-container *ngFor="let itemGroup of orderMenuItemGroups()">
                                <div class="position-relative"
                                     *ngIf="itemGroup.canDisplay">
                                    <mat-list-item class="item"
                                                   ctTooltip
                                                   [templateComponent]="subMenuComponent"
                                                   [config]="getSubMenuComponentConfig(itemGroup)"
                                                   [overrideRightCssProperty]="true"
                                                   [right]="'-5'"
                                                   [ngClass]="{'active': hasActiveItem(itemGroup), 'active-collapse': hasActiveItem(itemGroup) && !isExpanded}">
                                        <mat-icon class="{{itemGroup.color}}"
                                                  *ngIf="itemGroup.icon"
                                                  [ngClass]="{'collapse-icon': !isExpanded}">{{itemGroup.icon}}</mat-icon>
                                        <span class="name"
                                              matBadgeColor="primary"
                                              matBadgeSize="medium"
                                              matBadgeOverlap="false"
                                              *ngIf="isExpanded"
                                              [matBadgeHidden]="!canDisplayItemGroupBadge(itemGroup)"
                                              [matBadge]="getItemGroupBadgeValue(itemGroup)">{{itemGroup.name}}</span>
                                        <mat-divider></mat-divider>
                                    </mat-list-item>
                                </div>
                            </ng-container>
                        </div>

                        <div class="collapse-sidenav">
                            <mat-divider></mat-divider>
                            <mat-list-item class="item"
                                           (click)="collapse()">
                                <ng-container *ngIf="isExpanded; else iconsMenuCollapsed">
                                    <mat-icon class="collapse-icon">chevron_left</mat-icon>
                                    <mat-icon class="collapse-icon second-chevron">chevron_left</mat-icon>
                                    <span>{{'STUFF.COLLAPSE' | translate}}</span>
                                </ng-container>
                            </mat-list-item>

                            <ng-template #iconsMenuCollapsed>
                                <div [matTooltip]="'STUFF.EXPEND' | translate">
                                    <mat-icon class="collapse-icon">chevron_right</mat-icon>
                                    <mat-icon class="collapse-icon second-chevron">chevron_right</mat-icon>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </mat-nav-list>
            </mat-sidenav>

            <mat-sidenav-content (scroll)="onSideNavScroll($event)">
                <mat-progress-bar class="loading-bar"
                                  mode="indeterminate"
                                  [ngClass]="{'visibility-hidden': !isLoading}"></mat-progress-bar>
                <ui-view></ui-view>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
