import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataSetElementApiService {
    private _BASE_URL = 'data-set-element';

    constructor(private _http: HttpService) {}

    get(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}`, {params: urlRequestParams});
    }

    isReady(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/is-ready`, {params: urlRequestParams});
    }

    loadDiagnostics(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/excluded-diagnoses`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    loadPredictiveDiagnostics(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/diagnostic-predictive`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    loadFilterSearchRules(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/filter-search-rule`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
    editFilterSearchStatus(dataSetElementId: number, filterSearchId: number, status: number) {
        return this._http
            .patch(
                `${this._BASE_URL}/${dataSetElementId}/filter-search-rule/${filterSearchId}/validity-status`, {validityStatus: status})
            .pipe(map(res => res.data));
    }
    codify(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/codify`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    linkCodifyRule(dataSetElementId: number) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/link-codify-rule-health-stay`)
            .pipe(map(res => res.data));
    }

    loadGrouping(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/health-grouping`, {params: urlRequestParams});
    }

    calculateGrouping(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/group-health-stay`, {params: urlRequestParams});
    }

    calculateGroupingDiagnosticPredictions(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/group-health-diagnostic-predictions`, {params: urlRequestParams});
    }

    calculateGroupingActPredictions(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/group-health-act-predictions`, {params: urlRequestParams});
    }

    loadDataSetElementLogs(urlRequestParams ?: any) {
        return this._http
            .get(`${this._BASE_URL}/log`, {params: urlRequestParams});
    }

    loadCodificationLogs(urlRequestParams ?: any) {
        return this._http
            .get(`${this._BASE_URL}/log-codification`, {params: urlRequestParams});
    }

    loadCodificationHistory(dataSetElementId: number, urlRequestParams ?: any): Observable<any> {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/history/codify-history`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    updateDataSetElementDates(dataSetElementId: number, body: any, urlRequestParams?: any) {
        return this._http
            .patch(`${this._BASE_URL}/${dataSetElementId}/period`, body, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }


    deleteDataSetElementPrediction(diagnosticPredictionId: number) {
        return this._http
            .delete(`${this._BASE_URL}/diagnostic-prediction/${diagnosticPredictionId}`)
            .pipe(map(res => res.data));
    }
}
