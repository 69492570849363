import {Injectable} from '@angular/core';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root'
})
export class JsPdfService {
    constructor() {}

    async addCanvasImageToPage(pdfFile: jsPDF,
                               selector: string,
                               y: number,
                               height: number,
                               widthRatio = 0.9): Promise<void> {
        if (pdfFile && selector) {
            try {
                const canvas = await html2canvas(document.querySelector(selector));
                if (canvas) {
                    const imgData  = canvas.toDataURL('image/jpeg', 1);
                    const pdfFileWidth: number = pdfFile.internal.pageSize.getWidth();
                    // If we don't want the image to be full width
                    let width: number = pdfFileWidth * widthRatio;
                    // If we have a canvas smaller than the page
                    // then we don't upsize it to (otherwise the image would be blurry)
                    if (canvas.width < pdfFileWidth) {
                        width = canvas.width;
                    }
                    if (canvas.height < height) {
                        height = canvas.height;
                        // If it's a page with only the image and the canvas is smaller than the image
                        // we center vertically
                        if (y === 1) {
                            y = (pdfFile.internal.pageSize.getHeight() - height) / 2;
                        }
                    }
                    // Center horizontally
                    const x = (pdfFileWidth - width) / 2;
                    pdfFile.addImage(imgData, x, y, width, height);
                }
            } catch (e) {
                console.error(e);
                throw e;
            }
        }
    }
}
