<ngx-datatable
    *ngIf="highlight && highlight.structuredData"
    class="material"
    [rows]="highlight.structuredData.rows"
    [columns]="highlight.structuredData.columns"
    [columnMode]="columnModeForce"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [rowClass]="getRowClass"
    [scrollbarH]="true"
    [reorderable]="true">
</ngx-datatable>
