import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ResetPasswordService} from '../../../core/authentication/reset-password.service';
import {StateService} from '@uirouter/angular';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {LayoutService} from '../../layout/layout.service';
import {SnackBarService} from '../../../core/services/snack-bar.service';

@Component({
    selector: 'ct-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
    isActive = false;
    isLoading = true;
    resetPasswordForm: FormGroup;
    error = false;
    type: number;
    passwordValidation = false;
    token: string;

    validationErrors = {
        oldPassword: [
            {
                type: 'required',
                message: this._translateService.instant('ERROR.REQUIRED_FIELD')
            }
        ],
        password: [
            {
                type: 'required',
                message: this._translateService.instant('ERROR.REQUIRED_FIELD')
            }
        ],
        passwordConfirmation: [
            {
                type: 'required',
                message: this._translateService.instant('ERROR.REQUIRED_FIELD')
            }
        ]
    };

    constructor(public $state: StateService,
                public formBuilder: FormBuilder,
                private _translateService: TranslateService,
                private _resetPasswordService: ResetPasswordService,
                private _layoutService: LayoutService,
                private _hotkeysService: HotkeysService,
                private _snackbarService: SnackBarService
    ) {
        this.token = this.$state.params.token;
    }

    get formControls() {
        return this.resetPasswordForm.controls;
    }

    async ngOnInit() {
        this._layoutService.startLoading();
        this.isActive = false;
        this._hotkeysService.add(new Hotkey('enter', () => {
            this.submit();
            return false;
        }));
        await this._checkResetPassword();
        this._initForm();
        this.isActive = true;
        this.isLoading = false;
        this._layoutService.stopLoading();
    }

    public async submit() {
        this._layoutService.startLoading();
        try {
            this.error = false;
            let data: any = {
                password: this.resetPasswordForm.get('password').value,
                passwordConfirmation: this.resetPasswordForm.get('passwordConfirmation').value,
            };
            if (this.type === 3) {
                data = {
                    oldPassword: this.resetPasswordForm.get('oldPassword').value,
                    ...data
                };
            }

            const res: any = await this._resetPasswordService.resetPassword(
                this.token,
                this.type,
                data
            );

            if (true === res.status) {
                this._goToLogin();
                this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            }
            this._layoutService.stopLoading();
        } catch (e) {
            this._layoutService.stopLoading();
            this.error = true;
            throw e;
        }
    }

    private _initForm() {
        let formFields: any = {
            password: [null, [Validators.required]],
            passwordConfirmation: [null, [Validators.required]],
        };
        if (this.type === 3) {
            formFields = {
                oldPassword: [null, [Validators.required]],
                ...formFields,
            };
        }
        this.resetPasswordForm = this.formBuilder.group(formFields);
    }

    private async _checkResetPassword() {
        try {
            const res: any = await this._resetPasswordService
                .checkToken(this.token);

            this.type = res.type;
        } catch (e) {
            if (403 === e.status) {
                this._goToLogin();
            }
        }
    }

    private _goToLogin() {
        sessionStorage.removeItem('stateRedirection');

        this.$state.go('authentication.login');
    }

    isFormValid() {
        return this.resetPasswordForm.valid && this.passwordValidation;
    }

    isPasswordValid($event) {
        this.passwordValidation = $event;
    }
}
