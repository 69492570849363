import {Component, Inject, OnInit} from '@angular/core';
import {DataSetElement} from '../../../../shared/data-set-element.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash-es';

@Component({
    selector: 'ct-stay-detail-edit-act-execution-date',
    templateUrl: './stay-detail-edit-act-execution-date-dialog.component.html',
    styleUrls: ['./stay-detail-edit-act-execution-date-dialog.component.scss']
})
export class StayDetailEditActExecutionDateDialogComponent implements OnInit {
    dataSetElement: DataSetElement;
    act: any;
    selectedDateTime: any;
    title: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<StayDetailEditActExecutionDateDialogComponent>) { }

    ngOnInit() {
        this.title = 'DATA_SET.CODIFICATION_ELEMENT.ACT.EDIT_DATE';
        this.act = _.cloneDeep(this.data.linkAct);
    }

    validate() {
        this.act.execution_date = this.selectedDateTime;
        this.dialogRef.close({new: this.act, old: this.data.linkAct});
    }
}
