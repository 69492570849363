import {Component, Input, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../configuration/configuration.service';

@Component({
    selector: 'ct-stay-detail-severity',
    templateUrl: './stay-detail-severity.component.html',
    styleUrls: ['./stay-detail-severity.component.scss']
})
export class StayDetailSeverityComponent implements OnInit {
    private _authorizedLabels = ['1', '2', '3', '4'];
    private _threshold: number;

    @Input() predictiveDiagnostics: any[];
    authorizedPredictiveDiagnostics: any[] = [];

    constructor(private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this._initThreshold();
        this._initAuthorizedPredictiveDiagnostics();
    }

    private _initThreshold() {
        this._threshold = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.severity.threshold');
    }

    private _initAuthorizedPredictiveDiagnostics() {
        if (this.predictiveDiagnostics &&
            this._threshold !== null &&
            typeof this._threshold !== 'undefined') {
            this.authorizedPredictiveDiagnostics = this.predictiveDiagnostics
                .filter(el => el.codificationLabelId === 7 &&
                    this._authorizedLabels.includes(el.diagnostic.slug) &&
                    el.score >= this._threshold)
                .sort((a, b) => a.diagnostic.slug > b.diagnostic.slug ? 1 : a.diagnostic.slug < b.diagnostic.slug ? -1 : 0);
        }
    }
}
