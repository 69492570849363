import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuGroupSubMenuComponent} from './menu-group-sub-menu.component';
import {TooltipDirectiveModule} from '../../../../shared/directives/tooltip/tooltip.directive.module';
import {MatListModule} from '@angular/material/list';
import {UIRouterModule} from '@uirouter/angular';
import {MatBadgeModule} from '@angular/material/badge';

@NgModule({
    declarations: [
        MenuGroupSubMenuComponent
    ],
    imports: [
        CommonModule,
        TooltipDirectiveModule,
        MatListModule,
        UIRouterModule,
        MatBadgeModule
    ],
    exports: [
        MenuGroupSubMenuComponent
    ],
})
export class MenuGroupSubMenuModule {}
