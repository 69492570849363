import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionGenderComponent} from './search-engine-condition-gender.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionGenderService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('HEALTH.PATIENT.GENDER'),
            'gender',
            SearchEngineConditionGenderComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {gender: ''};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.gender = this.apiParams.args.value;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {value: this.params.gender};
    }

    isValidApiParams(args: any): boolean {
        return !!args?.value;
    }
}
