<mat-form-field class="max-width-110">
    <input matInput
           [placeholder]="placeholder | translate"
           [matDatepicker]="date"
           [(ngModel)]="condition.service.params[paramKey]">
    <mat-datepicker-toggle matSuffix
                           [for]="date"></mat-datepicker-toggle>
    <mat-datepicker #date
                    startView="year"></mat-datepicker>
</mat-form-field>
