// With default adapter there is a shift between day and month with datepicker
import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    parse(value: string): Date | null {
        if (value) {
            let searchString = null;
            if (value.includes('/')) {
                searchString = '/';
            } else if (value.includes('-')) {
                searchString = '-';
            }

            if (searchString) {
                const it = value.split(searchString);
                return new Date(+it[2], +it[1] - 1, +it[0], 12);
            } else {
                return new Date(Date.parse(value));
            }
        } else {
            return null;
        }
    }

    format(date: Date, displayFormat: Object): string {
        return ('0' + date.getDate()).slice(-2) + '/' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    }
}
