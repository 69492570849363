<ng-container *ngIf="canDisplayImpreciseStayDateIndicator()">
    <div class="align-center">
        <ct-warning-indicator [dataSetElement]="dataSetElement"
                              [tooltipText]="'TOOLTIP.IMPRECISE_STAY_DATE' | translate"
                              [classes]="['margin-left-10']"></ct-warning-indicator>

        <button mat-mini-fab
                color="primary"
                class="mini-fab-edition-button"
                [matTooltip]="'TOOLTIP.EDIT_RUM_DATES' | translate"
                (click)="openEditionDialog($event)">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</ng-container>
