import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StructuredDataApiService {
    private _BASE_URL = 'structured-data';

    constructor(private _http: HttpService) { }

    searchValue(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/search-value`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
