import {Component, Input, OnInit} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';

@Component({
    selector: 'ct-search-engine-condition-codify',
    templateUrl: './search-engine-condition-codify.component.html',
    styleUrls: ['./search-engine-condition-codify.component.scss']
})
export class SearchEngineConditionCodifyComponent implements OnInit {
    @Input() condition: any;

    constructor(public colorService: ColorService,
                private _broadcastService: BroadcastService) { }

    ngOnInit() {
        this.condition.service = {
            params: {
                codify: true
            }
        };
    }

    updateListAccordingToCodifyStatus() {
        this._broadcastService.send('filterSearchList::search', {page: 1});
    }
}
