<mat-dialog-content>
    <div class="subtract-button-container">
        <div class="display-inline-block font-weight-bold cursor-pointer tag tag-white-on-blue active tag-raised"
             *ngFor="let nbDays of daysToSubtract"
             (click)="selectRange(nbDays)">
            {{'TIME.ABBREVIATION_DAY' | translate}} - {{nbDays}}
        </div>

        <div class="display-inline-block font-weight-bold cursor-pointer tag tag-white-on-blue active tag-raised"
             (click)="selectRange(1, true)">
            {{'TIME.ABBREVIATION_MONTH' | translate}} - 1
        </div>
    </div>

    <div class="display-inline-block font-weight-bold cursor-pointer tag tag-white-on-blue active tag-raised"
         (click)="selectRange(2, true)">
        {{'TIME.ABBREVIATION_MONTH' | translate}} - 2
    </div>

    <form class="margin-top-20"
          (ngSubmit)="selectRange(dateSubtractValue)" >
        <div class="display-inline-block font-weight-bold tag tag-white-on-blue active tag-raised field-label">{{'TIME.ABBREVIATION_DAY' | translate}} - </div>

        <mat-form-field class="width-100"
                        [floatLabel]="'never'">
            <input matInput
                   type="number"
                   name="value"
                   step="1"
                   min="1"
                   [(ngModel)]="dateSubtractValue"
                   [placeholder]="'STUFF.VALUE' | translate">
        </mat-form-field>

        <button mat-mini-fab
                color="primary"
                type="submit"
                class="validation-button no-box-shadow"
                [matTooltip]="'BUTTON.VALIDATE' | translate"
                [disabled]="!dateSubtractValue">
            <mat-icon>check</mat-icon>
        </button>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close
            type="button"
            class="color-blue-on-white">{{'BUTTON.CANCEL' | translate | uppercase}}</button>
</mat-dialog-actions>
