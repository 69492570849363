import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticDiagnosticTextApiService {
    private _BASE_URL = 'diagnostic';

    constructor(private _http: HttpService) { }

    getAll(diagnosticId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${diagnosticId}/diagnostic-text`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
