import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/core';
import {ThemePalette} from '@angular/material/core';

@Injectable({
    providedIn:  'root'
})
export class ColorService {
    constructor(public $state: StateService) {}

    getColorAccordingToCurrentState(returnClass: boolean = false): ThemePalette {
        if (this.$state && this.$state.current) {
            // @ts-ignore
            const color: string = this.$state.current.color;
            if (returnClass) {
                return color ? color as ThemePalette : 'primary';
            }
            return color === 'indigo' ? 'accent' : 'primary';
        }
        return returnClass ? 'primary' : 'primary';
    }
}
