import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailInfoHeaderComponent} from './stay-detail-info-header.component';
import {StatusLightModule} from '../../../../../shared/components/status-light/status-light.module';
import {AiCodedIndicatorModule} from '../../../../../shared/components/ai-coded-indicator/ai-coded-indicator.module';

@NgModule({
    declarations: [
        StayDetailInfoHeaderComponent
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule.forChild(),
        AiCodedIndicatorModule,
        StatusLightModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    exports: [
        StayDetailInfoHeaderComponent
    ],
})
export class StayDetailInfoHeaderModule {}
