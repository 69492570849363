import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailInfoComponent} from './stay-detail-info.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {FieldDisplayModule} from '../../../../shared/components/field-display/field-display.module';
import {StatusLightModule} from '../../../../shared/components/status-light/status-light.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailInfoHeaderModule} from './header/stay-detail-info-header.module';
import {WarningIndicatorModule} from '../../../../shared/components/warning-indicator/warning-indicator.module';
import {StayDetailPatientIppEditDialogModule} from '../shared/stay-detail-patient-ipp-edit-dialog/stay-detail-patient-ipp-edit-dialog.module';
import {GhmTagModule} from '../../../../shared/components/ghm-tag/ghm-tag.module';
import {StayDetailInfoAdditionalModule} from './additional/stay-detail-info-additional.module';

@NgModule({
    declarations: [
        StayDetailInfoComponent
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule.forChild(),
        StayDetailInfoHeaderModule,
        StayDetailPatientIppEditDialogModule,
        StayDetailInfoAdditionalModule,
        StatusLightModule,
        GhmTagModule,
        WarningIndicatorModule,
        FieldDisplayModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    exports: [
        StayDetailInfoComponent,
        StayDetailInfoAdditionalModule,
        StayDetailInfoHeaderModule
    ],
})
export class StayDetailInfoModule {}
