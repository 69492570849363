import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InnerHitsService {
    constructor() {}

    /**
     * To be able to create Angular Element ct-medication
     * @param highlight
     * @private
     */
    private _replaceMedicationTag(highlight: any) {
        if (highlight &&
            highlight.content) {
            highlight.content.forEach((line, index, array) => {
                if (line.includes('<medication')) {
                    // To update reference
                    array[index] = line.replace(/medication/g, 'ct-medication');
                }
            });
        }
    }

    /**
     * Group by documentType
     * @param highlight
     * @param innerHits
     * @private
     */
    groupDocumentContent(highlight: any[], innerHits: any) {
        const highlightByDocType = [];
        if (innerHits &&
            innerHits.documentContent &&
            innerHits.documentContent.length > 0) {
            innerHits.documentContent.forEach(docData => {
                if (docData.data) {
                    const obj = highlightByDocType
                        .find(element => element.documentTypeId === docData.data.documentTypeId);
                    if (obj) {
                        // Replace medication tag by ct-medication
                        if (docData.highlight) {
                            this._replaceMedicationTag(docData.highlight);
                        }
                        obj.healthDocumentId.push(docData.data.healthDocumentId);
                        obj.documentContent.push(docData);
                    } else {
                        const objToPush = {
                            documentTypeId: docData.data.documentTypeId,
                            documentTypeSlug: docData.data.documentTypeSlug,
                            documentTypeName: docData.data.documentTypeName,
                            documentTypeEssential: docData.data.documentTypeEssential,
                            stayId: docData.data.stayId,
                            healthDocumentId: [docData.data.healthDocumentId],
                            documentContent: []
                        };
                        // Replace medication tag by ct-medication
                        if (docData.highlight) {
                            this._replaceMedicationTag(docData.highlight);
                        }

                        objToPush.documentContent.push(docData);
                        highlightByDocType.push(objToPush);
                    }
                }
            });
            [].push.apply(highlight, highlightByDocType);
        }
    }

    /**
     * Construct columns and rows for structured data table
     * and group by documentType
     * @param highlight
     * @param innerHits
     * @private
     */
    groupStructuredData(highlight: any[], innerHits: any) {
        const highlightByDocType = [];
        // Construct columns and rows for table
        if (innerHits &&
            innerHits.structuredData &&
            innerHits.structuredData.length > 0) {
            innerHits.structuredData.forEach(el => {
                if (el.data) {
                    const columns = [];
                    const rows = [];
                    const obj = highlightByDocType
                        .find(element => element.documentTypeId === el.data.documentTypeId);
                    const fields = el.data.fields;

                    if (obj) {
                        let row = {};
                        for (const columnSlug in fields) {
                            if (fields.hasOwnProperty(columnSlug)) {
                                const tmpLine = {};
                                tmpLine[columnSlug] = fields[columnSlug].value;
                                row = {...row, ...tmpLine};
                            }
                        }
                        rows.push(row);
                        obj.structuredData.rows = obj.structuredData.rows.concat(rows);
                        if (obj.healthDocumentId &&
                            !obj.healthDocumentId.includes(el.data.healthDocumentId)) {
                            obj.healthDocumentId.push(el.data.healthDocumentId);
                        }
                    } else {
                        let row = {};
                        for (const columnSlug in fields) {
                            if (fields.hasOwnProperty(columnSlug)) {
                                columns.push({
                                    ...fields[columnSlug],
                                    prop: columnSlug
                                });

                                const tmpLine = {};
                                tmpLine[columnSlug] = fields[columnSlug].value;
                                row = {...row, ...tmpLine};
                            }
                        }
                        rows.push(row);
                        const objToPush = {
                            documentTypeId: el.data.documentTypeId,
                            documentTypeSlug: el.data.slug,
                            documentTypeName: el.data.name,
                            documentTypeEssential: el.data.documentTypeEssential,
                            stayId: el.data.stayId,
                            healthDocumentId: [el.data.healthDocumentId],
                            structuredData: {}
                        };
                        objToPush.structuredData = {columns, rows};
                        highlightByDocType.push(objToPush);
                    }
                }
            });
            [].push.apply(highlight, highlightByDocType);
        }
    }
}
