import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {AppLogService} from '../app-log/app-log.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    constructor(private _injector: Injector) {
        super();
    }

    handleError(error) {
        if (error) {
            const logService: AppLogService = this._injector.get(AppLogService);
            const message = error.message ? error.message : error.toString();
            if (error.status) {
                error = new Error(message);
            }
            const errorMsg = `Error message:\n${message}.\nStack trace: ${error.stack}`;
            logService.logError(errorMsg);
            throw error;
        }
    }
}
