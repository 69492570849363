<mat-card class="no-padding full-height">
    <mat-card-content class="full-height">
        <div class="row full-height">
            <div class="col no-padding align-self-center">

                <ng-container>
                    <ng-container *ngFor="let label of codificationLabels">
                        <button mat-icon-button
                                class="hover-background-color"
                                (click)="openExecuteDateDisplayDialog(predictiveAct.act)"
                                [matTooltip]="getMatTooltip(label)"
                        >
                            {{label}}
                        </button>
                    </ng-container>

                    <button mat-icon-button
                            [matTooltip]="'TOOLTIP.TEXT_INFO' | translate"
                            (click)="showJustification()"
                    >
                        <mat-icon>search</mat-icon>
                    </button>

                </ng-container>
            </div>
        </div>
    </mat-card-content>
</mat-card>
