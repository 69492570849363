import {NgModule} from '@angular/core';
import {ConfirmDialogModule} from '../shared/components/confirm-dialog/confirm-dialog.module';
import {FileDisplayDialogModule} from '../shared/components/file-display/dialog/file-display-dialog.module';
import {DiagnosticNoteDisplayDialogModule} from '../modules/diagnostic/note/display-dialog/diagnostic-note-display-dialog.module';
import {DiagnosticNoteEditDialogModule} from '../modules/diagnostic/note/edit-dialog/diagnostic-note-edit-dialog.module';
import {FilterSearchEditDialogModule} from '../modules/filter-search/shared/edit-dialog/filter-search-edit-dialog.module';
import {StayDetailDialogModule} from '../shared/components/stay-detail-dialog/stay-detail-dialog.module';
import {FilterSearchSubscribeDialogModule} from '../modules/filter-search/shared/subscribe-dialog/filter-search-subscribe-dialog.module';
import {DocumentTypeDocumentListDisplayDialogModule} from '../shared/components/document-type-document-list-display/dialog/document-type-document-list-display-dialog.module';


@NgModule({
    imports: [
        ConfirmDialogModule,
        FileDisplayDialogModule,
        DocumentTypeDocumentListDisplayDialogModule,
        FilterSearchEditDialogModule,
        FilterSearchSubscribeDialogModule,
        DiagnosticNoteDisplayDialogModule,
        DiagnosticNoteEditDialogModule,
        StayDetailDialogModule
    ]
})
export class EntryComponentsModule {}
