<mat-form-field class="max-width-110">
    <input matInput
           [placeholder]="'STUFF.AFTER_THE' | translate"
           [matDatepicker]="startDate"
           [max]="condition.service.params.endDate"
           [(ngModel)]="condition.service.params.startDate"
           (dateChange)="dateChange($event, 'start')">
    <mat-datepicker-toggle matSuffix
                           [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate
                    startView="year"></mat-datepicker>
</mat-form-field>

<mat-form-field class="max-width-110 margin-left-16">
    <input matInput
           [placeholder]="'STUFF.BEFORE_THE' | translate"
           [matDatepicker]="endDate"
           [min]="condition.service.params.startDate"
           [(ngModel)]="condition.service.params.endDate"
           (dateChange)="dateChange($event, 'end')">
    <mat-datepicker-toggle matSuffix
                           [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate
                    startView="year"></mat-datepicker>
</mat-form-field>
