import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'ct-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
    @Input() obj: any;
    @Input() key: string;
    @Input() text: string;
    @Input() icon: string;
    @Input() color: string;
    @Input() displayCTLogo: boolean;

    @Output() tagUpdated: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    changeValue() {
        if (this.obj &&
            this.key) {
            this.obj[this.key] = !this.obj[this.key];
            this.tagUpdated.emit(this.obj[this.key]);
        }
    }
}
