<div>
    <div
        *ngIf="isLoading"
        class="align-center-center margin-top-20"
    >
        <mat-progress-spinner
            [diameter]="100"
            mode="indeterminate">
        </mat-progress-spinner>
    </div>

    <ng-container *ngIf="!isLoading">
        <ct-stay-detail-severity
            *ngIf="canDisplayStaySeverity"
            [predictiveDiagnostics]="dataSetElement.dataSetElementDiagnosticPredictive"
            class="display-inline-block"
        ></ct-stay-detail-severity>

        <ct-tab-list
            [activeTabId]="activeTabId"
            (activeTabIdChange)="updateActiveTabId($event)"
        >
            <ng-container *ngFor="let tab of orderById(tabs)">
                <ct-tab-list-item
                    *ngIf="tab.display"
                    [label]="tab.name"
                    [id]="tab.id"
                >
                    <ct-stay-detail-act-prediction-list
                        *ngIf="[51].includes(tab.id); else diagnosticBlock"
                        [predictiveActs]="predictiveActs"
                    ></ct-stay-detail-act-prediction-list>
                    <ng-template #diagnosticBlock>
                        <ct-stay-detail-diagnostic-prediction-list
                            [dataSetElement]="dataSetElement"
                            [codificationLabels]="codificationLabels"
                            [codificationLabelIds]="tab.codificationLabelIds"
                            [diagnostics]="diagnostics"
                            [predictiveDiagnostics]="predictiveDiagnostics"
                            [orderBy]="tab.orderBy"
                            [canDisplayPredictionListMenu]="!isNearAutomationQualityControl"
                            [isNearAutomationQualityControl]="isNearAutomationQualityControl"
                            [displayOnlySimplifiedScore]="displayOnlySimplifiedScore"
                            [canDisplayTooltip]="canDisplayTooltip"
                            [isTypeRehabilitation]="isTypeRehabilitation"
                            [predictionListMenuStyle]="{top: canDisplayStaySeverity ? '88px' : '69px', right: '28px'}"
                            (firstPredictiveDiagnosticLoaded)="firstPredictiveDiagnosticLoaded.emit($event)"
                        ></ct-stay-detail-diagnostic-prediction-list>
                    </ng-template>
                </ct-tab-list-item>
            </ng-container>
        </ct-tab-list>
    </ng-container>
</div>
