import {Injectable} from '@angular/core';
import {MedicalUnitApiService} from '../../../core/api-services/medical-unit/medical-unit.api.service';

@Injectable({
    providedIn: 'root'
})
export class MedicalUnitService {
    constructor(private _medicalUnitApiService: MedicalUnitApiService) {}

    async loadAllMedicalUnits(params?: any) {
        try {
            const requestParams = {
                ...params,
                perPage: 1000,
                sort: 'code'
            };
            return await this._medicalUnitApiService
                .getAll(requestParams)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
}
