import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import * as _ from 'lodash-es';

@Component({
    selector: 'ct-file-display',
    templateUrl: './file-display.component.html',
    styleUrls: ['./file-display.component.scss']
})
export class FileDisplayComponent {
    private _src: string;
    private _filename: string;
    private _extension: string;
    private _AVAILABLE_EXTENSIONS = ['pdf', 'jpg', 'png', 'jpeg'];

    @Input() maxContainerHeight: string;
    @Input() containerHeight: string;
    @Input() objectHeight: string;

    @Input() set data(newData: any) {
        if (newData &&
            newData.src !== decodeURIComponent(this._src)) {
            const data = _.cloneDeep(newData);
            this._src = data.src;
            this._filename = data.filename;
            // Encode file name
            const srcTmp = this._src.split('/');
            srcTmp[srcTmp.length - 1] = encodeURIComponent(srcTmp[srcTmp.length - 1]);
            this._src = srcTmp.join('/');
            this._extension = this._filename.split('.').pop().toLowerCase();
        }
    }

    get src() {
        return this._src;
    }

    get url() {
        return `${environment.apiUrl}/${this._src}`;
    }

    constructor() { }

    isImage() {
        return this._extension ? ['jpg', 'png', 'jpeg'].includes(this._extension) : false;
    }

    isPDFFile() {
        return this._extension ? this._extension === 'pdf' : false;
    }
}
