import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordApiService {

    constructor(private _http: HttpService) {}

    call(body: any) {
        return this._http
            .post(`forgot-password`, body);
    }
}
