import {Component, Input, OnInit} from '@angular/core';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ct-search-engine-condition-stay-length',
    templateUrl: './search-engine-condition-stay-length.component.html',
    styleUrls: ['./search-engine-condition-stay-length.component.scss']
})
export class SearchEngineConditionStayLengthComponent implements OnInit {
    @Input() condition: any;

    stayLengths: any[];

    constructor(private _broadcastService: BroadcastService,
                private _translateService: TranslateService) { }

    ngOnInit() {
        this.stayLengths = [
            {
                value: 'all',
                text: 'STUFF.ALL'
            },
            {
                value: '0',
                text: `0 ${this._translateService.instant('STUFF.NIGHT_LOWERCASE')}`
            },
            {
                value: 'short',
                text: `1 - 2 ${this._translateService.instant('STUFF.NIGHTS_LOWERCASE')}`
            },
            {
                value: 'long',
                text: `3 ${this._translateService.instant('STUFF.NIGHTS_LOWERCASE')} ${this._translateService.instant('STUFF.AND_MORE')}`
            }
        ];
    }

    changeStatus(length: string) {
        this.condition.service.params.stayLength = length;
        this._broadcastService.send('searchEngineConditionStayLength::changeLength');
    }
}
