import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-ghm-gme',
    templateUrl: './search-engine-condition-ghm-gme.component.html'
})
export class SearchEngineConditionGhmGmeComponent implements OnInit {
    condition: any;
    useCase: string;
    placeHolder: string;
    paramKey: string;

    constructor() { }

    ngOnInit() {
        this.placeHolder = this.useCase === 'ghm' ? 'ACRONYM.GHM' : 'ACRONYM.GME';
        this.paramKey = this.useCase;
    }

}
