import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailImpreciseDiagnosticComponent} from './stay-detail-imprecise-diagnostic.component';
import {StayDetailDiagnosticPredictionElementModule} from '../diagnostic-prediction/element/stay-detail-diagnostic-prediction-element.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        StayDetailImpreciseDiagnosticComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        NgbTooltipModule,
        StayDetailDiagnosticPredictionElementModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    exports: [
        StayDetailImpreciseDiagnosticComponent
    ]
})
export class StayDetailImpreciseDiagnosticModule {}
