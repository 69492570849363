import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safeUrl'})
export class SafeUrlPipe implements PipeTransform  {
    constructor(private _sanitizer: DomSanitizer) {}

    transform(value: string, isPDF: boolean) {
        if (isPDF) {
            return this._sanitizer.bypassSecurityTrustResourceUrl(value);
        } else {
            return this._sanitizer.bypassSecurityTrustUrl(value);
        }
    }
}
