import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionDateValueComponent} from '../../shared/search-engine-condition-date-value/search-engine-condition-date-value.component';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionSurgeryDateService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'surgeryDate';
    placeholder = 'DATA_SET.CODIFICATION.SEARCH.SURGERY_DATE';

    constructor(private _translateService: TranslateService) {
        super(['surgeryDate'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.SURGERY_DATE'),
            'surgeryDate',
            SearchEngineConditionDateValueComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {surgeryDate: null};
        this.uriParams = {surgeryDate: null};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.surgeryDate) {
            this.uriParams.surgeryDate = `${DateHelperService.toMysql(this.params.surgeryDate)};=`;
        }
    }

    convertToParams(): void {
        if (this.uriParams.surgeryDate) {
            const split = this.uriParams.surgeryDate.split(';');
            this.params.surgeryDate = new Date(DateHelperService.mysqlToInput(split[0]));
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.surgeryDate = new Date(DateHelperService.mysqlToInput(this.apiParams.args.minValue));
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {minValue: DateHelperService.toMysql(this.params.surgeryDate), operator: '='};
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields['surgeryDate']) &&
            !this.isEmpty(fields['surgeryDate']));
    }

    isValidApiParams(args: any): boolean {
        return !!args?.minValue;
    }
}
