import {Injectable} from '@angular/core';
import {DataSetElementHealthDocumentApiService} from '../../../../core/api-services/data-set-element/health-document/data-set-element-health-document.api.service';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import {DataSetElement} from '../../shared/data-set-element.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {DocumentTypeService} from '../../../document-type/document-type.service';

@Injectable({
    providedIn: 'root'
})
export class StayDetailDocumentService {
    INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS: {crh: number, cro: number, anapath: number} = {crh: null, cro: null, anapath: null};

    constructor(private _dataSetElementHealthDocumentApiService: DataSetElementHealthDocumentApiService,
                private _documentTypeService: DocumentTypeService,
                private _configurationService: ConfigurationService) {
        this._initInferredDocumentTypesThresholds();
    }

    private _initInferredDocumentTypesThresholds(): void {
        const thresholds = this._documentTypeService.getInferredDocumentTypesThresholds();
        this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS.crh = thresholds?.crh;
        this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS.cro = thresholds?.cro;
        this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS.anapath = thresholds?.anapath;
    }

    async loadAllDocuments(dataSetElementId: number): Promise<any> {
        try {
            const params = {fields: 'documentType,parent,inferredDocumentType'};
            return await this._dataSetElementHealthDocumentApiService
                .getAll(dataSetElementId, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    private _updateDocumentsInferredDocumentTypeProperty(documents: any[]): void {
        documents?.forEach(doc => {
            // If inferredDocType confidence is < to config threshold then
            // we set inferredDocumentType to null for it to be grouped in undefined
            if (doc.inferredDocumentType &&
                doc.inferredDocumentType.confidence < this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS[doc.inferredDocumentType.name]) {
                doc.inferredDocumentType = null;
            }
        });
    }

    groupDocumentsByInferredDocumentType(documents: any[]): any[] {
        if (documents) {
            this._updateDocumentsInferredDocumentTypeProperty(documents);
            return _.groupBy(documents, 'inferredDocumentType.name');
        }
        return [];
    }

    filterPatientStays(patientStays: any[], currentDataSetElement: DataSetElement): any[] {
        if (patientStays &&
            currentDataSetElement) {
            const currentDataSetElementStartDateLess6Months = moment(currentDataSetElement.dataSetContent.startDate).subtract(6, 'months');
            const currentDataSetElementEndDateMore6Months = moment(currentDataSetElement.dataSetContent.endDate).add(6, 'months');
            return patientStays
                .map(data => data && data.data ? data.data : {})
                .filter(dataSetElement => {
                    if (dataSetElement) {
                        const dataSetElementStartDate = dataSetElement.dataSetContent.startDate;
                        const dataSetElementStayId = dataSetElement.dataSetContent.stayId || dataSetElement.dataSetContent.stayIdOriginal;
                        const currentDataSetElementStayId = currentDataSetElement.dataSetContent.stayId || currentDataSetElement.dataSetContent.stayIdOriginal;
                        return dataSetElementStayId !== currentDataSetElementStayId &&
                            (moment(dataSetElementStartDate).isBetween(currentDataSetElementStartDateLess6Months, moment(currentDataSetElement.dataSetContent.startDate), undefined, '[]') ||
                                moment(dataSetElementStartDate).isBetween(moment(currentDataSetElement.dataSetContent.endDate), currentDataSetElementEndDateMore6Months, undefined, '[]'));
                    }
                });
        }
        return [];
    }
}
