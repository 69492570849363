<div class="row">
    <div class="col no-padding">
        <div class="row">
            <div class="col no-padding">
                <div #header
                     class="align-center-space-between">
                    <div class="display-inline-block">
                        <ct-stay-detail-collapse-button [leftSideDisplayState]="leftSideDisplayState"
                                                        (buttonClicked)="toggleLeftSideCollapse.emit(true)"></ct-stay-detail-collapse-button>

                        <ng-container *ngIf="!canDisplayStayRisk">
                            <button class="icon-button-shadow"
                                    mat-icon-button
                                    [disabled]="isLoading"
                                    [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.OPEN_ALL' | translate"
                                    (click)="openCloseDocuments(true, 'all')">
                                <i class="fas fa-folder-open"></i>
                            </button>

                            <button class="icon-button-shadow"
                                    mat-icon-button
                                    [disabled]="isLoading"
                                    [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.CLOSE_ALL' | translate"
                                    (click)="openCloseDocuments(false, 'all')">
                                <i class="fas fa-folder"></i>
                            </button>
                        </ng-container>

                        <button class="icon-button-shadow"
                                mat-icon-button
                                [disabled]="isLoading || canDisplayJustificationSearchInput"
                                [matTooltip]="'TOOLTIP.KEY_WORDS_SEARCH' | translate"
                                (click)="switchCanDisplayJustificationSearchInput()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="">
                        <button class="icon-button-shadow"
                                mat-icon-button
                                *ctFeatureToggle="'codificationHistory'"
                                [matTooltip]="(commentDisplayState === 'none' ? 'TOOLTIP.DISPLAY_COMMENT' : 'TOOLTIP.EXIT_COMMENT') | translate"
                                (click)="toggleCommentCollapse.emit(true)">
                            <mat-icon>comment</mat-icon>
                        </button>
                        <button class="icon-button-shadow"
                                mat-icon-button
                                *ctFeatureToggle="'codificationHistory'"
                                [matTooltip]="(codificationHistoryDisplayState === 'none' ? 'TOOLTIP.DISPLAY_CODIFICATION_HISTORY' : 'TOOLTIP.EXIT_CODIFICATION_HISTORY') | translate"
                                (click)="toggleCodificationHistoryCollapse.emit(true)">
                            <mat-icon>history</mat-icon>
                        </button>
                    </div>

                </div>
            </div>
        </div>

        <ng-container *ngIf="!isLoading">
            <div class="row margin-top-20">
                <div class="col no-padding">
                    <ct-stay-detail-justification
                        [dataSetElement]="dataSetElement">
                    </ct-stay-detail-justification>
                </div>
            </div>

            <ng-container *ngIf="hasDocuments('stay')">
                <!--Documents attached to stay-->
                <h3 class="h3 documents-title">{{'STUFF.DOCUMENTS_STAY' | translate}}</h3>
                <button class="icon-button-shadow"
                        mat-icon-button
                        [disabled]="isLoading"
                        [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.OPEN_STAY_DOCS' | translate"
                        (click)="openCloseDocuments(true, 'stay')">
                    <i class="fas fa-folder-open"></i>
                </button>

                <button class="icon-button-shadow"
                        mat-icon-button
                        [disabled]="isLoading"
                        [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.CLOSE_STAY_DOCS' | translate"
                        (click)="openCloseDocuments(false, 'stay')">
                    <i class="fas fa-folder"></i>
                </button>


                <div class="row margin-top-20"
                     *ngIf="stayDocNb > 0">
                    <div class="col no-padding">
                        <ng-container *ngFor="let inferredDocType of inferredDocumentTypes">
                            <div #cardList
                                 *ngFor="let document of stayDocumentsGroupedByInferredDocType[inferredDocType]"
                                 [id]="'document' + document.id">
                                <ng-container *ngIf="document.subDocumentId !== false">
                                    <mat-card class="document-card no-padding"
                                              [ngClass]="{'active-card-box-shadow': document.isOpened, 'line-pre': isPre}">
                                        <mat-card-content>
                                            <ct-stay-detail-document-element
                                                [dataSetElement]="dataSetElement"
                                                [document]="document"></ct-stay-detail-document-element>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="align-center-center margin-top-20"
                     *ngIf="!isLoading && stayDocNb === 0">
                    <div class="no-data text-center">
                        {{'ERROR.NO_STAY_DOCUMENT' | translate}}
                    </div>
                </div>
            </ng-container>

            <!--Documents attached to patient-->
            <ng-container *ngIf="hasDocuments('patient')">
                <h3 class="h3 documents-title"
                    [matTooltip]="'TOOLTIP.DOCUMENTS_PATIENT' | translate">{{'STUFF.DOCUMENTS_PATIENT' | translate}}</h3>
                <button class="icon-button-shadow"
                        mat-icon-button
                        [disabled]="isLoading"
                        [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.OPEN_PATIENT_DOCS' | translate"
                        (click)="openCloseDocuments(true, 'patient')">
                    <i class="fas fa-folder-open"></i>
                </button>
                <button class="icon-button-shadow"
                        mat-icon-button
                        [disabled]="isLoading"
                        [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.CLOSE_PATIENT_DOCS' | translate"
                        (click)="openCloseDocuments(false, 'patient')">
                    <i class="fas fa-folder"></i>
                </button>
                <div class="row margin-top-20"
                     *ngIf="patientDocNb > 0">
                    <div class="col no-padding">
                        <ng-container *ngFor="let inferredDocType of inferredDocumentTypes">
                            <div #cardList
                                 *ngFor="let document of patientDocumentsGroupedByInferredDocType[inferredDocType]"
                                 [id]="'document' + document.id">
                                <ng-container *ngIf="document.documentType.display && document.subDocumentId !== false">
                                    <mat-card class="document-card no-padding"
                                              [ngClass]="{'active-card-box-shadow': document.isOpened, 'line-pre': isPre}">
                                        <mat-card-content>
                                            <ct-stay-detail-document-element
                                                [patientStays]="patientStays"
                                                [dataSetElement]="dataSetElement"
                                                [document]="document"></ct-stay-detail-document-element>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="align-center-center margin-top-20"
             *ngIf="!isLoading && docNb === 0">
            <div class="no-data text-center">
                {{'ERROR.NO_DOCUMENT' | translate}}
            </div>
        </div>

        <div class="align-center-center margin-top-20"
             *ngIf="isLoading">
            <mat-progress-spinner
                mode="indeterminate"
                [diameter]="100"></mat-progress-spinner>
        </div>
    </div>
</div>
