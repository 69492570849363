<div class="align-center full-height">
    <mat-radio-group class="margin-right-16"
                     [(ngModel)]="status">
        <mat-radio-button color="primary"
                          [value]="'012'">{{'STAY.CODED' | translate}}</mat-radio-button>

        <mat-radio-button color="primary"
                          [value]="'-1'"
                          (change)="changeStatus()">{{'STAY.NOT_CODED' | translate}}</mat-radio-button>
    </mat-radio-group>


    <mat-form-field *ngIf="status === '012'">
        <mat-select multiple
                    [placeholder]="'DATA_SET.CODIFICATION.SEARCH.CODIFICATION_TYPE' | translate"
                    [(ngModel)]="condition.service.params.aiCodedStatus">
            <mat-option *ngFor="let type of codificationTypes"
                        [value]="type.value">
                {{type.text | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
