<div class="row" class="">
    <div class="col padding-16 container-comment">
        <div class="add-comment">
            <mat-form-field>
                <mat-label>{{ 'DATA_SET.COMMENT.ACTION.NEW' | translate }}</mat-label>
                <textarea matInput [placeholder]="'DATA_SET.COMMENT.ACTION.NEW' | translate" [formControl]="message" required></textarea>
                <mat-error *ngIf="message.invalid">{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
            <button
                (click)="sendMessage()"
                [disabled]="message.invalid"
                mat-flat-button
                [matTooltip]="'DATA_SET.COMMENT.ACTION.NEW' | translate"
                color="primary">
                {{ 'DATA_SET.COMMENT.ACTION.SEND' | translate }} &nbsp;
                <mat-icon>send</mat-icon>
            </button>
        </div>
        <div class="all-comments">
            <div *ngIf="!comments?.length"
                 class="text-disabled text-center">
                {{'DATA_SET.COMMENT.NO_COMMENT' | translate}}
            </div>
            <div *ngFor="let comment of comments; let index = index" class="comment-block">
                <div class="info">
                    <p *ngIf="comment.user" class="user">
                        {{ comment.user.firstName }}
                    </p>
                    <p class="date">
                        {{ getDisplayDate(comment.createdAt) }}
                    </p>
                </div>
                <p [class]="{'scratched-comment' : !comment.status}">
                    {{ comment.message }}
                </p>
                <div *ngIf="comment.status"
                     class="action">
                    <button class="icon-button-shadow"
                            (click)="deleteMessage(index)"
                            [matTooltip]="'DATA_SET.COMMENT.ACTION.DELETE' | translate"
                            mat-icon-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


