import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DependenciesComponent} from './dependencies.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        DependenciesComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
    ],
    exports: [
        DependenciesComponent
    ],
})
export class DependenciesModule {}
