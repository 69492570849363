import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {ColorService} from '../../../../core/services/color.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {SearchEngineConditionCustomDateDialogComponent} from '../../condition/date/custom-dialog/search-engine-condition-custom-date-dialog.component';

@Component({
  selector: 'ct-search-engine-condition-date-comparison',
  templateUrl: './search-engine-condition-date-comparison.component.html',
  styleUrls: ['./search-engine-condition-date-comparison.component.scss']
})
export class SearchEngineConditionDateComparisonComponent implements OnInit {
    @Input() condition: any;
    @Input() canDisplayButtons = true;

    clientType: string;

    constructor(public $state: StateService,
                public colorService: ColorService,
                private _matDialog: MatDialog,
                private _dateHelperService: DateHelperService,
                private _broadcastService: BroadcastService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this.clientType = this._configurationService.getConfigurationContent('front', 'clientType') || 'public';
        // If we don't have already dates as params we set them to today for a private client
        // and currentMonth for a public one
        if (!this.condition.service.params.startDate &&
            !this.condition.service.params.endDate) {
            if (this.clientType === 'private') {
                this._setServiceParams(DateHelperService.getDateRangeByType('today'));
            } else if (this.clientType === 'public') {
                this._setServiceParams(DateHelperService.getDateRangeByType('currentMonth'));
            }
        }
    }

    private _setServiceParams(dateRange: any) {
        if (dateRange) {
            this.condition.service.params.startDate = dateRange.startDate;
            this.condition.service.params.endDate = dateRange.endDate;
        }
    }

    onDateChange(event: any, period: string) {
        if (event.value) {
            if (period === 'start') {
                this.condition.service.params.startDate = new Date(event.value);
            } else if (period === 'end') {
                this.condition.service.params.endDate = new Date(event.value);
            }
        }
    }

    onDateSubtractValueChange(amount: number, unit: string = 'day') {
        this.condition.service.params.customSubtractAmount = amount;
        this.condition.service.params.customSubtractUnit = unit;
        this._setServiceParams(this._dateHelperService.getDateRangeBySubtract(amount, unit));
    }

    search(type?: string) {
        if (type) {
            this._setServiceParams(DateHelperService.getDateRangeByType(type));
        }
        switch (this.condition.service.useCase) {
            case 'codification':
                this._broadcastService.send('stayList::search');
                this._broadcastService.send('patientDetail::search');
                break;
            case 'patient':
                this._broadcastService.send('patientList::search');
                break;
            case 'filterSearch':
                this._broadcastService.send('searchEngineConditionDate::filterSearch');
                break;
            case 'qualityControl':
                this._broadcastService.send('searchEngineConditionDate::qualityControl');
                break;
        }
    }

    canDisplayLamdaButton() {
        return this._translationHelperService.isFeatureAvailable('lamda') &&
            this.$state.current.name === 'quality-control';
    }

    getDialogButtonTranslation(): string {
        const serviceAmount = this.condition.service.params.customSubtractAmount;
        const serviceUnit = this.condition.service.params.customSubtractUnit;
        if (serviceAmount === 1) {
            const key = serviceUnit === 'day' ? 'TIME.YESTERDAY' : 'TIME.LAST_MONTH';
            return this._translateService.instant(key);
        } else {
            const key = serviceUnit === 'day' ? 'TIME.LAST_X_DAYS' : 'TIME.LAST_X_MONTHS';
            const amount = !serviceAmount ? 'X' : serviceAmount;
            return this._translateService.instant(key, {amount});
        }
    }

    onCustomDateButtonClick() {
        const serviceAmount = this.condition.service.params.customSubtractAmount;
        if (serviceAmount) {
            this.onDateSubtractValueChange(serviceAmount, this.condition.service.params.customSubtractUnit);
            this.search();
        } else {
            this.openDialog();
        }
    }

    openDialog() {
        const dialogRef: MatDialogRef<SearchEngineConditionCustomDateDialogComponent> =
            this._matDialog.open(SearchEngineConditionCustomDateDialogComponent, {
                autoFocus: true,
                panelClass: 'search-engine-condition-custom-date-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    const unit = res.isMonth ? 'month' : 'day';
                    this.condition.service.params.customSubtractAmount = res.amount;
                    this.condition.service.params.customSubtractUnit = unit;
                    this.onDateSubtractValueChange(res.amount, unit);
                    this.search();
                }
            });
    }
}
