import {StayDetailComponent} from './stay-detail.component';

const urlParams = '?fromFilterSearchList' +
    '&fromQualityControl' +
    '&fromQualityControlImpreciseDiagnostic' +
    '&fromQualityControlNearAutomation' +
    '&isNearAutomationDone' +
    '&fromPatientList' +
    '&fromPatientDetail' +
    '&fromStayDetail' +
    '&fromExternalStayList' +
    '&fromRehabilitationStayList' +
    '&forFilterSearch' +
    '&AIValuation' +
    '&parentId' +
    '&isFirstSSRWeek' +
    '&patientStayType' +
    '&access' +
    '&temporaryFilterSearchId' +
    '&temporaryFilterSearchPatientId';

export const stayDetailState = [
    {
        parent: 'layout',
        name: 'stay-detail',
        url: '/data-set/:dataSetId/' +
            'codification/:codificationId/' +
            'element/:dataSetElementId/v2' +
            urlParams,
        reloadOnSearch: false,
        component: StayDetailComponent
    },
    {
        parent: 'layout',
        name: 'stay-detail-old',
        url: '/data-set/:dataSetId/' +
            'codification/:codificationId/' +
            'element/:dataSetElementId' +
            urlParams,
        reloadOnSearch: false,
        component: StayDetailComponent
    }
];
