<div *ngFor="let param of condition.service.params.structuredData; index as parentIndex; trackBy: trackByFn">
    <div class="margin-left--10">
        <div class="row">
            <div class="col-auto no-padding">
                <button mat-icon-button
                        type="button"
                        matTooltip="{{'BUTTON.DELETE' | translate}}"
                        (click)="removeStructuredQueryCondition(parentIndex)">
                    <mat-icon>remove_circle</mat-icon>
                </button>

                <button mat-icon-button
                        type="button"
                        matTooltip="{{'BUTTON.ADD' | translate}}"
                        (click)="addStructuredQueryCondition()">
                    <mat-icon>add_circle</mat-icon>
                </button>

                <mat-form-field class="width-120 padding-right-20">
                    <mat-select placeholder="{{'DATA_SET.CODIFICATION.SEARCH.DOCUMENT_TYPE' | translate}}"
                                name="qStructuredDocumentTypeId[{{parentIndex}}]"
                                [panelClass]="['mat-' + colorService.getColorAccordingToCurrentState()]"
                                [(ngModel)]="param.qStructuredDocumentTypeId"
                                [disabled]="documentTypes.length === 0"
                                (selectionChange)="documentTypeChange(param.qStructuredDocumentTypeId, parentIndex)">
                        <mat-option *ngFor="let documentType of documentTypes"
                                    [value]="documentType.id">
                            {{documentType.name ? documentType.name : documentType.slug}}
                        </mat-option>
                    </mat-select>
                    <mat-progress-bar *ngIf="isLoading"
                                      mode="indeterminate"
                                      class="autocomplete-progress-bar"></mat-progress-bar>
                </mat-form-field>
            </div>

            <div class="col-auto no-padding"
                 *ngIf="!isLoading">
                <div class="row"
                     *ngFor="let qStructuredDocumentTypeColumn of param.qStructuredDocumentTypeColumn; index as index; trackBy: trackByFn">
                    <div class="col no-padding">
                        <button mat-icon-button
                                type="button"
                                matTooltip="{{'BUTTON.DELETE' | translate}}"
                                (click)="removeStructuredField(parentIndex, index, false)">
                            <mat-icon>remove_circle</mat-icon>
                        </button>

                        <button mat-icon-button
                                type="button"
                                matTooltip="{{'BUTTON.ADD' | translate}}"
                                (click)="addStructuredField(parentIndex)">
                            <mat-icon>add_circle</mat-icon>
                        </button>

                        <mat-form-field class="max-width-110 padding-right-20">
                            <mat-select placeholder="{{ 'STUFF.FIELD' | translate }}"
                                        name="qDocumentTypeColumn[{{ index }}][{{ param.qStructuredDocumentTypeId }}]"
                                        [panelClass]="['mat-' + colorService.getColorAccordingToCurrentState()]"
                                        [(ngModel)]="qStructuredDocumentTypeColumn.columnId"
                                        [disabled]="param.columns.length === 0"
                                        (selectionChange)="structuredFieldChange(qStructuredDocumentTypeColumn.columnId, index, parentIndex)">
                                <mat-option *ngFor="let column of param.columns" [value]="column.id">
                                    {{ column.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field
                            *ngIf="getSelectedColumn(qStructuredDocumentTypeColumn.columnId, parentIndex)?.dataType?.length > 1"
                            class="max-width-110 padding-right-20">
                            <mat-select placeholder="{{ 'STUFF.TYPE' | translate }}"
                                        name="qDocumentTypeDataType[{{ index }}][{{ param.qStructuredDocumentTypeId }}]"
                                        [value]="getDataType(qStructuredDocumentTypeColumn.dataType)"
                                        (selectionChange)="dataTypeFieldChange(qStructuredDocumentTypeColumn.columnId, index, parentIndex, $event.value)">
                                <mat-option *ngFor="let type of getSelectedColumn(qStructuredDocumentTypeColumn.columnId, parentIndex)?.dataType" [value]="type">
                                    {{ type }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!--Template according to column type-->
                        <ng-container [ngSwitch]="qStructuredDocumentTypeColumn.columnType">
                            <ng-container *ngSwitchCase="'string'">
                                <mat-form-field class="width-250">
                                    <input matInput
                                           required
                                           type="text"
                                           [(ngModel)]="autoCompleteInputs[parentIndex][index].value"
                                           (ngModelChange)="launchSearch(autoCompleteInputs[parentIndex][index], qStructuredDocumentTypeColumn)"
                                           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.TERM' | translate"
                                           [matAutocomplete]="auto">

                                    <mat-progress-bar mode="indeterminate"
                                                      class="autocomplete-progress-bar"
                                                      *ngIf="autoCompleteInputs[parentIndex][index].isLoading"></mat-progress-bar>

                                    <mat-autocomplete #auto="matAutocomplete"
                                                      (optionSelected)="selectOption($event, qStructuredDocumentTypeColumn)">
                                        <mat-option *ngFor="let text of searchedTexts$ | async"
                                                    [value]="text.value">{{text.value}}</mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngSwitchCase="'numeric'">
                                <ct-select-operator [label]="'DATA_SET.CODIFICATION.SEARCH.CONDITION'"
                                                    [(data)]="qStructuredDocumentTypeColumn.operator"></ct-select-operator>

                                <!--Min value-->
                                <mat-form-field>
                                    <input matInput
                                           required
                                           type="number"
                                           min="1"
                                           [(ngModel)]="qStructuredDocumentTypeColumn.min"
                                           [placeholder]="'STUFF.VALUE' | translate">
                                </mat-form-field>

                                <!--Max value-->
                                <mat-form-field *ngIf="qStructuredDocumentTypeColumn.operator === '><' || qStructuredDocumentTypeColumn.operator === '>=<'"
                                                class="margin-left-10">
                                    <input matInput
                                           required
                                           type="number"
                                           [min]="qStructuredDocumentTypeColumn.min"
                                           [(ngModel)]="qStructuredDocumentTypeColumn.max"
                                           [placeholder]="'STUFF.VALUE' | translate">
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngSwitchCase="'date'">
                                <mat-form-field class="max-width-110">
                                    <input matInput
                                           required
                                           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.AFTER' | translate"
                                           [matDatepicker]="startDate"
                                           [max]="qStructuredDocumentTypeColumn.endDate"
                                           [(ngModel)]="qStructuredDocumentTypeColumn.startDate"
                                           (dateChange)="dateChange(parentIndex, index, $event, 'start')">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate
                                                    startView="year"></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="max-width-110 margin-left-16">
                                    <input matInput
                                           required
                                           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.BEFORE' | translate"
                                           [matDatepicker]="endDate"
                                           [min]="qStructuredDocumentTypeColumn.startDate"
                                           [(ngModel)]="qStructuredDocumentTypeColumn.endDate"
                                           (dateChange)="dateChange(parentIndex, index, $event, 'end')">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate
                                                    startView="year"></mat-datepicker>
                                </mat-form-field>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
