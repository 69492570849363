import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionGhsGmtComponent} from './search-engine-condition-ghs-gmt.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';

@Injectable()
export class SearchEngineConditionGmtService extends SearchEngineCondition {
    useCase = 'gmt';

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('ACRONYM.GMT'),
            'gmt',
            SearchEngineConditionGhsGmtComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {gmt: null};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.gmt = this.apiParams.args.value;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.gmt) {
            this.apiParams.args = {value: this.params.gmt};
        }
    }

    isValidApiParams(args: any): boolean {
        return !!args?.value;
    }
}
