<div>
    <span class="font-weight-700">{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.EXPECTED_SEVERITY_LEVEL' | translate}} :</span>&nbsp;
    <div class="display-inline-block"
         *ngIf="authorizedPredictiveDiagnostics.length">
        <span *ngIf="authorizedPredictiveDiagnostics[0]">{{authorizedPredictiveDiagnostics[0].diagnostic.slug}}</span>
        <span *ngIf="authorizedPredictiveDiagnostics[0] && authorizedPredictiveDiagnostics[1]"> {{'STUFF.OR_LOWERCASE' | translate}} </span>
        <span *ngIf="authorizedPredictiveDiagnostics[1]">{{authorizedPredictiveDiagnostics[1].diagnostic.slug}}</span>
    </div>
    <span class="no-data"
          *ngIf="!authorizedPredictiveDiagnostics.length">{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.NO_SEVERITY' | translate}}</span>
</div>
