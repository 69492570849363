import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipComponent} from './tooltip.component';

@NgModule({
    declarations: [
        TooltipComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatCardModule
    ],
    exports: [
        TooltipComponent
    ]
})
export class TooltipModule {}
