import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ct-simplified-score',
    templateUrl: './simplified-score.component.html',
    styleUrls: ['./simplified-score.component.scss']
})
export class SimplifiedScoreComponent implements OnInit {
    @Input() value: number;
    @Input() tooltip: string;

    constructor() { }

    ngOnInit() {
    }

    canDisplaySimplifiedScore(useCase: string = 'all') {
        let scores = [10, 20, 30, 40];
        if (useCase === 'gold') {
            scores = [40];
        } else if (useCase === 'silver') {
            scores = [10, 20, 30];
        }
        return scores.includes(this.value);
    }

    getCircle(start: number, value: number) {
        if (start <= value) {
            return 'lens';
        } else {
            return 'panorama_fish_eye';
        }
    }
}
