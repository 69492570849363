<div class="row margin-top-10">
    <div class=" no-padding">
        <div class="act-text-field ct-text-field font-weight-700"
             [ngClass]="{'margin-top-no-act': !isEditionMode && !hasActs(), 'margin-top-no-act-edition': isEditionMode && !hasActs()}">
            <span>{{fieldTranslationKey | translate}} :</span>
        </div>
    </div>

    <div class="col no-padding">
        <div class="position-relative"
             *ngIf="isEditionMode">
            <mat-form-field class="full-width padding-right-20"
                            [appearance]="'standard'">
                <mat-chip-list #actList>
                    <ng-container [ngTemplateOutlet]="chipList"></ng-container>
                </mat-chip-list>

                <ct-stay-detail-codification-act-add-autocomplete [dataSetElement]="dataSetElement"
                                                                  [canDisplayNonePlaceholder]="!hasActs()"
                                                                  [matChipList]="actList"
                                                                  [autoCompletePanelWidth]="autoCompletePanelWidth"
                                                                  [actType]="actType"
                                                                  (actToAdd)="handleActAction('addition', $event)"></ct-stay-detail-codification-act-add-autocomplete>
            </mat-form-field>

            <div class="button-margin"
                 *ngIf="validateButton">
                <ng-container [ngTemplateOutlet]="validationButton"></ng-container>
            </div>
        </div>

        <ng-container *ngIf="!isEditionMode">
            <div class="padding-top-15"
                 *ngIf="!hasActs()">
                <div ctCustomHtmlAttribute
                     [htmlAttributeName]="'cypress-id'"
                     [htmlAttributeValue]="actType + '-act-0-0'"
                     class="no-data">{{'STUFF.NONE_MALE' | translate}}</div>

                <ng-container *ngIf="!disableEdition"
                             [ngTemplateOutlet]="editionButton"></ng-container>
            </div>

            <div class="display-container"
                 *ngIf="hasActs()">
                <mat-chip-list>
                    <ng-container [ngTemplateOutlet]="chipList"></ng-container>
                </mat-chip-list>

                <ng-container *ngIf="!disableEdition"
                             [ngTemplateOutlet]="editionButton"></ng-container>
            </div>
        </ng-container>

        <ng-template #editionButton>
            <button mat-mini-fab
                    color="primary"
                    class="edition-button"
                    [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.ACT.ADD_ACTS' | translate"
                    (click)="isEditionMode = true">
                <mat-icon>edit</mat-icon>
            </button>
        </ng-template>

        <ng-template #validationButton>
            <button mat-mini-fab
                    color="primary"
                    class="validation-button no-box-shadow"
                    [matTooltip]="'BUTTON.VALIDATE' | translate"
                    (click)="validate(true)">
                <mat-icon>check</mat-icon>
            </button>
        </ng-template>

        <ng-template #chipList>
            <ng-container *ngIf="isClassingActsActivated && dataSetElement.dataSetContent.linkAct && dataSetElement.dataSetContent.linkAct[actType] && dataSetElement.dataSetContent.linkAct[actType].classing">
                <mat-chip class="classing-act"
                          *ngFor="let linkAct of dataSetElement.dataSetContent.linkAct[actType].classing; index as index"
                          [ngClass]="{'duplicate-act' : hasSimilarAct(linkAct, true) && !isBasicAct}"
                          [ngbTooltip]="getTooltip(linkAct, true)"
                          tooltipClass="ngb-tooltip"
                          placement="right"
                          [removable]="isEditionMode"
                          [value]="linkAct"
                          (removed)="handleActAction('deletion', linkAct, 'classing')">
                    <div class="chip-text">
                        <span ctCustomHtmlAttribute
                              class="slug-text"
                              [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="actType + '-act-0-' + index">{{linkAct.slug}}
                        </span>
                        <ct-field-display
                            *ngIf="!isBasicAct"
                            class="display-block"
                                          [value]="dateHelperService.mysqlDateTimeToFrench(linkAct.execution_date)"></ct-field-display>
                    </div>
                    <button mat-icon-button
                            *ngIf="!isBasicAct"
                            class="menu-button"
                            (click)="openExecuteDateDisplayDialog(linkAct, true)">
                    <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-icon matChipRemove
                              *ngIf="isEditionMode">cancel</mat-icon>
                </mat-chip>
            </ng-container>

            <ng-container *ngIf="dataSetElement.dataSetContent.linkAct && dataSetElement.dataSetContent.linkAct[actType] && dataSetElement.dataSetContent.linkAct[actType].other">
                <mat-chip *ngFor="let linkAct of dataSetElement.dataSetContent.linkAct[actType].other; index as index"
                          [ngbTooltip]="getTooltip(linkAct, false)"
                          tooltipClass="ngb-tooltip"
                          placement="right"
                          [removable]="isEditionMode"
                          [value]="linkAct"
                          [ngClass]="{'duplicate-act' : hasSimilarAct(linkAct, false) && !isBasicAct}"
                          (removed)="handleActAction('deletion', linkAct, 'other')">
                    <div class="chip-text">
                        <span ctCustomHtmlAttribute
                              [htmlAttributeName]="'cypress-id'"
                              class="slug-text"
                              [htmlAttributeValue]="actType + '-act-1-' + index">{{linkAct.slug}}
                        </span>
                        <ct-field-display class="display-block"
                                          *ngIf="!isBasicAct"
                                          [value]="dateHelperService.mysqlDateTimeToFrench(linkAct.execution_date)"></ct-field-display>
                    </div>
                    <button mat-icon-button
                            *ngIf="!isBasicAct"
                            class="menu-button"
                            name="edit-act"
                            (click)="openExecuteDateDisplayDialog(linkAct, true)">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-icon matChipRemove *ngIf="isEditionMode">cancel</mat-icon>
                </mat-chip>
            </ng-container>
        </ng-template>
    </div>
</div>
