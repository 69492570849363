<div class="row">
    <div class="col no-padding">
        <ct-diagnostic-search [(param)]="condition.service.params.diagnosticMissingSlug"></ct-diagnostic-search>
    </div>
</div>

<div class="row"
     *ngIf="condition.service.validateWithCheckbox">
    <div class="col no-padding">
        <mat-checkbox class="margin-left-10 margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="revaluation.DA"
                      (change)="updateDiagnosticMissingCodificationLabelParam('DA')">
            <span>{{'STAY.REVALUATION' | translate}}</span>
        </mat-checkbox>
    </div>
</div>
