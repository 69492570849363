import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SimplifiedScoreComponent} from './simplified-score.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        SimplifiedScoreComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        SimplifiedScoreComponent
    ]
})
export class SimplifiedScoreModule {}
