import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {WarningIndicatorComponent} from './warning-indicator.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        WarningIndicatorComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatTooltipModule,
        MatIconModule
    ],
    exports: [
        WarningIndicatorComponent
    ]
})
export class WarningIndicatorModule {}
