import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';
import {ConfigurationService} from '../../../configuration/configuration.service';

@Injectable()
export class SearchEngineConditionStayIdService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService) {
        super(['stayId'],
            _translateService.instant('STAY.NUMBER'),
            'stayId',
            null,
            false);
        this.init();
    }
    private isSpaceInStayIdSearch = true;

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {stayId: null};
        this.isSpaceInStayIdSearch = this._configurationService.getConfigurationContent('front', 'advancedSearch.isSpaceInStayIdSearch');
        this.uriParams = {stayId: null};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.stayId = this.apiParams.args.values.length ? this.apiParams.args.values[0] : null;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.stayId) {
            const separatorRegex = this.isSpaceInStayIdSearch ? /[,; |_]/ : /[,;|_]/;
            this.apiParams.args = { values: this.params.stayId.split(separatorRegex) };
        }
    }
}
