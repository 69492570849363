<div class="margin-bottom-10">
    <!-- DP/DR-->
    <div
        *ngIf="!isTypeRehabilitation"
        class="row margin-bottom-10">
        <div class="col no-padding">
            <!--DP-->
            <div
                [ngClass]="{'width-176': diagnosticsToDisplay[codificationLabels.DP] && diagnosticsToDisplay[codificationLabels.DP].length}"
                class="diagnostic-container margin-right-16"
            >
                <div
                    [ngClass]="{'margin-bottom': !diagnosticsToDisplay[codificationLabels.DP] || !diagnosticsToDisplay[codificationLabels.DP].length}"
                    class="diagnostic-label-container ct-text-field font-weight-700"
                >
                    {{ 'ACRONYM.DP' | translate }}
                    :
                </div>
                <mat-form-field
                    [appearance]="'standard'"
                    [cdkDropListConnectedTo]="[getUniqueIdFromCodificationLabel(CodificationLabelEnum.DR), getUniqueIdFromCodificationLabel(CodificationLabelEnum.DA)]"
                    [cdkDropListData]="diagnosticsToDisplay[codificationLabels.DP]"
                    [cdkDropListDisabled]="disableDragAndDrop"
                    [id]="getUniqueIdFromCodificationLabel(CodificationLabelEnum.DP)"
                    cdkDropList
                    class="max-width-140"
                >
                    <mat-chip-list
                        #DPList
                        class="display-inline-block"
                    >
                        <mat-chip
                            (cdkDragDropped)="onDrop($event, diagnostic)"
                            (click)="showJustification(diagnostic)"
                            *ngFor="let diagnostic of diagnosticsToDisplay[codificationLabels.DP]; index as index"
                            [ngClass]="{'cursor-pointer': disableDragAndDrop, 'border-color': isSelected(diagnostic)}"
                            cdkDrag
                            class="custom-chip dp"
                        >
                            <mat-icon
                                (click)="openDiagnosticNoteDisplayDialog(diagnostic)"
                                *ngIf="hasDiagnosticNotes(diagnostic)"
                                [ngClass]="[getDiagnosticLabelColor(diagnostic)]"
                                class="diagnostic-note-icon text-color"
                            >
                                assignments
                            </mat-icon>
                            <span [htmlAttributeName]="'cypress-id'"
                                  [htmlAttributeValue]="'dp-' + index"
                                  [ngbTooltip]="getDiagnosticTooltip(diagnostic, false)"
                                  ctCustomHtmlAttribute
                                  placement="top"
                                  tooltipClass="ngb-tooltip"
                            >
                                {{ diagnostic.diagnostic.slug }}
                            </span>
                            <mat-icon
                                (click)="removeDiagnostic($event, diagnostic)"
                                matChipRemove
                            >
                                cancel
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>

                    <input hidden matInput>

                    <ct-stay-detail-codification-diagnostic-add-autocomplete
                        (addDiagnostic)="addDiagnostic($event)"
                        *ngIf="!diagnosticsToDisplay[codificationLabels.DP] || !diagnosticsToDisplay[codificationLabels.DP].length"
                        [codificationLabelId]="codificationLabels.DP"
                        [codificationLabelSlug]="CodificationLabelEnum.DP"
                        [cypressIdValue]="'dp-0'"
                        [dataSetElement]="dataSetElement"
                        [diagnosticsToDisplay]="diagnosticsToDisplay[codificationLabels.DP]"
                        [matChipList]="DPList"
                        [predictiveDiagnostics]="predictiveDiagnostics"
                    ></ct-stay-detail-codification-diagnostic-add-autocomplete>
                </mat-form-field>
            </div>

            <!--DR-->
            <div
                *ngIf="canDisplayDR"
                class="diagnostic-container"
            >
                <div
                    [ngClass]="{'margin-bottom': !diagnosticsToDisplay[codificationLabels.DR] || !diagnosticsToDisplay[codificationLabels.DR].length}"
                    class="diagnostic-label-container ct-text-field font-weight-700"
                >
                    {{ 'ACRONYM.DR' | translate }}:
                </div>
                <mat-form-field
                    [appearance]="'standard'"
                    [cdkDropListConnectedTo]="[getUniqueIdFromCodificationLabel(CodificationLabelEnum.DP), getUniqueIdFromCodificationLabel(CodificationLabelEnum.DA)]"
                    [cdkDropListData]="diagnosticsToDisplay[codificationLabels.DR]"
                    [cdkDropListDisabled]="disableDragAndDrop"
                    [id]="getUniqueIdFromCodificationLabel(CodificationLabelEnum.DR)"
                    cdkDropList
                    class="max-width-140"
                >
                    <mat-chip-list #DRList class="display-inline-block">
                        <mat-chip
                            *ngFor="let diagnostic of diagnosticsToDisplay[codificationLabels.DR]; index as index"
                            (cdkDragDropped)="onDrop($event, diagnostic)"
                            (click)="showJustification(diagnostic)"
                            [ngClass]="{'cursor-pointer': disableDragAndDrop, 'border-color': isSelected(diagnostic)}"
                            cdkDrag
                            class="custom-chip dr"
                        >
                            <mat-icon
                                (click)="openDiagnosticNoteDisplayDialog(diagnostic)"
                                *ngIf="hasDiagnosticNotes(diagnostic)"
                                [ngClass]="[getDiagnosticLabelColor(diagnostic)]"
                                class="diagnostic-note-icon text-color"
                            >
                                assignments
                            </mat-icon>
                            <span
                                [htmlAttributeName]="'cypress-id'"
                                [htmlAttributeValue]="'dr-' + index"
                                [ngbTooltip]="getDiagnosticTooltip(diagnostic, false)"
                                ctCustomHtmlAttribute
                                placement="top"
                                tooltipClass="ngb-tooltip"
                            >
                                {{ diagnostic.diagnostic.slug }}
                            </span>
                            <mat-icon
                                (click)="removeDiagnostic($event, diagnostic)"
                                matChipRemove
                            >
                                cancel
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>

                    <input hidden matInput>

                    <ct-stay-detail-codification-diagnostic-add-autocomplete
                        (addDiagnostic)="addDiagnostic($event)"
                        *ngIf="!diagnosticsToDisplay[codificationLabels.DR] || !diagnosticsToDisplay[codificationLabels.DR].length"
                        [codificationLabelSlug]="CodificationLabelEnum.DR"
                        [codificationLabelId]="codificationLabels.DR"
                        [cypressIdValue]="'dr-0'"
                        [dataSetElement]="dataSetElement"
                        [diagnosticsToDisplay]="diagnosticsToDisplay[codificationLabels.DR]"
                        [matChipList]="DRList"
                        [predictiveDiagnostics]="predictiveDiagnostics"
                    ></ct-stay-detail-codification-diagnostic-add-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>

    <!--/MP/AE-->
    <div *ngIf="isTypeRehabilitation"
         class="row margin-bottom-10">
        <div class="col no-padding">
            <!--MP-->
            <div class="diagnostic-container margin-right-30">
                <div
                    [ngClass]="{'margin-bottom': !diagnosticsToDisplay[codificationLabels.MP] || !diagnosticsToDisplay[codificationLabels.MP].length}"
                    class="diagnostic-label-container ct-text-field font-weight-700"
                >
                    {{ 'ACRONYM.MP' | translate }}:
                </div>

                <mat-form-field
                    [appearance]="'standard'"
                    [cdkDropListConnectedTo]="[getUniqueIdFromCodificationLabel(CodificationLabelEnum.AE), getUniqueIdFromCodificationLabel(CodificationLabelEnum.DA)]"
                    [cdkDropListData]="diagnosticsToDisplay[codificationLabels.MP]"
                    [cdkDropListDisabled]="disableDragAndDrop"
                    [id]="getUniqueIdFromCodificationLabel(CodificationLabelEnum.MP)"
                    cdkDropList
                    class="max-width-110"
                >
                    <mat-chip-list #MPList class="display-inline-block">
                        <mat-chip
                            (cdkDragDropped)="onDrop($event, diagnostic)"
                            (click)="showJustification(diagnostic)"
                            *ngFor="let diagnostic of diagnosticsToDisplay[codificationLabels.MP]; index as index"
                            [ngClass]="{'cursor-pointer': disableDragAndDrop, 'border-color': isSelected(diagnostic)}"
                            cdkDrag
                            class="custom-chip mp"
                        >
                            <mat-icon
                                (click)="openDiagnosticNoteDisplayDialog(diagnostic)"
                                *ngIf="hasDiagnosticNotes(diagnostic)"
                                [ngClass]="[getDiagnosticLabelColor(diagnostic)]"
                                class="diagnostic-note-icon text-color"
                            >
                                assignments
                            </mat-icon>
                            <span
                                [htmlAttributeName]="'cypress-id'"
                                [htmlAttributeValue]="'mp-' + index"
                                [ngbTooltip]="getDiagnosticTooltip(diagnostic, false)"
                                ctCustomHtmlAttribute
                                placement="top"
                                tooltipClass="ngb-tooltip"
                            >
                                {{ diagnostic.diagnostic.slug }}
                            </span>

                            <mat-icon
                                (click)="removeDiagnostic($event, diagnostic)"
                                *ngIf="!limitCodificationUpdateToDA"
                                matChipRemove
                            >
                                cancel
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>

                    <input hidden matInput>

                    <ct-stay-detail-codification-diagnostic-add-autocomplete
                        (addDiagnostic)="addDiagnostic($event)"
                        *ngIf="!diagnosticsToDisplay[codificationLabels.MP] || !diagnosticsToDisplay[codificationLabels.MP].length"
                        [codificationLabelSlug]="CodificationLabelEnum.MP"
                        [codificationLabelId]="codificationLabels.MP"
                        [cypressIdValue]="'mp-0'"
                        [dataSetElement]="dataSetElement"
                        [diagnosticsToDisplay]="diagnosticsToDisplay[codificationLabels.MP]"
                        [isDisabled]="limitCodificationUpdateToDA"
                        [matChipList]="MPList"
                        [predictiveDiagnostics]="predictiveDiagnostics"
                    ></ct-stay-detail-codification-diagnostic-add-autocomplete>
                </mat-form-field>
            </div>

            <!--AE-->
            <div class="diagnostic-container">
                <div
                    [ngClass]="{'margin-bottom': !diagnosticsToDisplay[codificationLabels.AE] || !diagnosticsToDisplay[codificationLabels.AE].length}"
                    class="diagnostic-label-container ct-text-field font-weight-700"
                >
                    {{ 'ACRONYM.AE' | translate }}:
                </div>
                <mat-form-field
                    [appearance]="'standard'"
                    [cdkDropListConnectedTo]="[getUniqueIdFromCodificationLabel(CodificationLabelEnum.MP), getUniqueIdFromCodificationLabel(CodificationLabelEnum.DA)]"
                    [cdkDropListData]="diagnosticsToDisplay[codificationLabels.AE]"
                    [cdkDropListDisabled]="disableDragAndDrop"
                    [id]="getUniqueIdFromCodificationLabel(CodificationLabelEnum.AE)"
                    cdkDropList
                    class="max-width-110"
                >
                    <mat-chip-list #AEList class="display-inline-block">
                        <mat-chip
                            (cdkDragDropped)="onDrop($event, diagnostic)"
                            (click)="showJustification(diagnostic)"
                            *ngFor="let diagnostic of diagnosticsToDisplay[codificationLabels.AE]; index as index"
                            [ngClass]="{'cursor-pointer': disableDragAndDrop, 'border-color': isSelected(diagnostic)}"
                            cdkDrag
                            class="custom-chip ae"
                        >
                            <mat-icon
                                (click)="openDiagnosticNoteDisplayDialog(diagnostic)"
                                *ngIf="hasDiagnosticNotes(diagnostic)"
                                [ngClass]="[getDiagnosticLabelColor(diagnostic)]"
                                class="diagnostic-note-icon text-color"
                            >
                                assignments
                            </mat-icon>
                            <span
                                [htmlAttributeName]="'cypress-id'"
                                [htmlAttributeValue]="'ae-' + index"
                                [ngbTooltip]="getDiagnosticTooltip(diagnostic, false)"
                                ctCustomHtmlAttribute
                                placement="top"
                                tooltipClass="ngb-tooltip"
                            >
                                {{ diagnostic.diagnostic.slug }}
                            </span>

                            <mat-icon
                                (click)="removeDiagnostic($event, diagnostic)"
                                *ngIf="!limitCodificationUpdateToDA"
                                matChipRemove
                            >
                                cancel
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>

                    <input hidden matInput>

                    <ct-stay-detail-codification-diagnostic-add-autocomplete
                        (addDiagnostic)="addDiagnostic($event)"
                        *ngIf="!diagnosticsToDisplay[codificationLabels.AE] || !diagnosticsToDisplay[codificationLabels.AE].length"
                        [codificationLabelSlug]="CodificationLabelEnum.AE"
                        [codificationLabelId]="codificationLabels.AE"
                        [cypressIdValue]="'ae-0'"
                        [dataSetElement]="dataSetElement"
                        [diagnosticsToDisplay]="diagnosticsToDisplay[codificationLabels.AE]"
                        [isDisabled]="limitCodificationUpdateToDA"
                        [matChipList]="AEList"
                        [predictiveDiagnostics]="predictiveDiagnostics"
                    ></ct-stay-detail-codification-diagnostic-add-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>

    <mat-divider class="da-separator"></mat-divider>

    <!-- DA -->
    <div class="row margin-top-10">
        <div
            [ngClass]="{'margin-top-no-diagnostic': !diagnosticsToDisplay[codificationLabels.DA] || !diagnosticsToDisplay[codificationLabels.DA].length}"
            class="col-auto da-diagnostic-label-container no-padding"
        >
            <span class="ct-text-field font-weight-700">{{ 'ACRONYM.DA' | translate }} :</span>
        </div>
        <div class="col align-self-center no-padding">
            <mat-form-field
                [appearance]="'standard'"
                [cdkDropListConnectedTo]="[getUniqueIdFromCodificationLabel(CodificationLabelEnum.DP), getUniqueIdFromCodificationLabel(CodificationLabelEnum.DR), getUniqueIdFromCodificationLabel(CodificationLabelEnum.AE), getUniqueIdFromCodificationLabel(CodificationLabelEnum.MP)]"
                [cdkDropListData]="diagnosticsToDisplay[codificationLabels.DA]"
                [cdkDropListDisabled]="disableDragAndDrop"
                [id]="getUniqueIdFromCodificationLabel(CodificationLabelEnum.DA)"
                cdkDropList
                class="full-width padding-right-20"
            >
                <mat-chip-list
                    #DAList
                    class="display-inline-block">
                    <mat-chip
                        (cdkDragDropped)="onDrop($event, diagnostic)"
                        (click)="showJustification(diagnostic)"
                        *ngFor="let diagnostic of diagnosticsToDisplay[codificationLabels.DA]; index as index"
                        [ngClass]="[getDiagnosticLabelColor(diagnostic), getDiagnosticLabelColor(diagnostic, '100')]"
                        cdkDrag
                        class="custom-chip background-color {{isSelected(diagnostic) ? 'border-color' : ''}} {{disableDragAndDrop ? 'cursor-pointer' : ''}}"
                    >
                        <mat-icon
                            (click)="openDiagnosticNoteDisplayDialog(diagnostic)"
                            *ngIf="hasDiagnosticNotes(diagnostic)"
                            [ngClass]="[getDiagnosticLabelColor(diagnostic)]"
                            class="diagnostic-note-icon text-color"
                        >
                            assignments
                        </mat-icon>
                        <span [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="'da-' + index"
                              [ngbTooltip]="getDiagnosticTooltip(diagnostic, true)"
                              ctCustomHtmlAttribute
                              placement="top"
                              tooltipClass="ngb-tooltip"
                        >
                            {{ diagnostic.diagnostic.slug }}
                        </span>
                        <sup *ngIf="diagnostic.exclude">
                            <mat-icon
                                [matTooltip]="'TOOLTIP.EXCLUDED_CODE' | translate"
                                [ngClass]="[getDiagnosticLabelColor(diagnostic)]"
                                class="excluded-icon text-color"
                            >
                                info
                            </mat-icon>
                        </sup>
                        <mat-icon
                            (click)="removeDiagnostic($event, diagnostic)"
                            matChipRemove
                        >
                            cancel
                        </mat-icon>
                    </mat-chip>
                </mat-chip-list>

                <ct-stay-detail-codification-diagnostic-add-autocomplete
                    (addDiagnostic)="addDiagnostic($event)"
                    [codificationLabelSlug]="CodificationLabelEnum.DA"
                    [codificationLabelId]="codificationLabels.DA"
                    [cypressIdValue]="'da-0'"
                    [dataSetElement]="dataSetElement"
                    [diagnosticsToDisplay]="diagnosticsToDisplay[codificationLabels.DA]"
                    [matChipList]="DAList"
                    [predictiveDiagnostics]="predictiveDiagnostics"
                ></ct-stay-detail-codification-diagnostic-add-autocomplete>
            </mat-form-field>
        </div>
    </div>
</div>
