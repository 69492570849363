import {Injectable} from '@angular/core';
import {HttpService} from '../../../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DataSetElementCodificationDiagnosticApiService {
    private _BASE_URL = 'data-set-element';

    constructor(private _http: HttpService) {}

    addDiagnostic(dataSetElementId: number, codificationId: any, data: any, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/${dataSetElementId}/codification/${codificationId}/diagnostic`, data, {params: urlRequestParams})
            .pipe(map(  (res) => {
                if (res.data) {
                    return res.data;
                } else if (res.error) {
                    return res.error;
                }
                return null;
            }));
    }

    removeDiagnostic(dataSetElementId: number, codificationId: any, diagnosticId: number, body: any, urlRequestParams?: any) {
        return this._http
            .delete(`${this._BASE_URL}/${dataSetElementId}/codification/${codificationId}/diagnostic/${diagnosticId}`, body, {params: urlRequestParams});
    }
}
