import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionActSlugComponent} from './search-engine-condition-act-slug.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionActSlugCSARRService extends SearchEngineCondition {
    useCase: string;

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.INCLUDING_ACTS_CSARR'),
            'actSlugCSARR',
            SearchEngineConditionActSlugComponent,
            true);
        this.useCase = this._translateService.currentLang === 'fr' ? 'csarr' : 'unk';
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            actSlug: [],
            actType: this.useCase,
            actSlugOperator: 'OR'
        };
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.actSlug = this.apiParams.args.slugs;
        this.params.actSlugOperator = this.apiParams.args.operator;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.type = 'actPresence'; // Override because there are 2 services (CCAM and CSARR)
        this.apiParams.args = {
            slugs: [...this.params.actSlug],
            type: this.params.actType,
            operator: this.params.actSlugOperator
        };
    }

    isValidApiParams(args: any): boolean {
        return !!(args?.slugs?.length && args?.operator && args?.type === this.useCase);
    }
}
