import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-act-slug',
    templateUrl: './search-engine-condition-act-slug.component.html',
    styleUrls: ['./search-engine-condition-act-slug.component.scss']
})
export class SearchEngineConditionActSlugComponent implements OnInit {
    condition: any;
    useCase: string;

    constructor() { }

    ngOnInit() {
    }
}
