import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class GroupingFunctionApiService {
    private _BASE_URL = 'grouping-function';

    constructor(private _http: HttpService) {}

    simulate(body: any, urlRequestParams?: any) {
        const params = {
            ...urlRequestParams,
            hideError: 'true'
        };
        return this._http
            .post(`${this._BASE_URL}/simulate`, body, {params})
            .pipe(map(res => res.data));
    }
}
