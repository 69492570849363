import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionDocumentTypeComponent} from './search-engine-condition-document-type.component';
import {DocumentTypeService} from '../../../document-type/document-type.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDocumentTypeService extends SearchEngineCondition {
    INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS: {crh: number, cro: number, anapath: number} = {crh: null, cro: null, anapath: null};

    constructor(private _translateService: TranslateService,
                private _documentTypeService: DocumentTypeService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.DOCUMENT_TYPE'),
            'documentTypePresence',
            SearchEngineConditionDocumentTypeComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            documentTypePresentValues: [],
            documentTypeMissingValues: [],
            documentTypePresentOperator: 'AND',
            documentTypeMissingOperator: 'NAND'
        };
        this._initInferredDocumentTypesThresholds();
        this.setApiParams(apiParams);
    }

    private _initInferredDocumentTypesThresholds(): void {
        const thresholds = this._documentTypeService.getInferredDocumentTypesThresholds();
        this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS.crh = thresholds?.crh;
        this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS.cro = thresholds?.cro;
        this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS.anapath = thresholds?.anapath;
    }

    convertToParamsFromApiParams(): void {
        if (this.apiParams.args.operator === 'AND') {
            this.params.documentTypePresentValues = this.apiParams.args.values;
        }
        if (this.apiParams.args.operator === 'NAND') {
            this.params.documentTypeMissingValues = this.apiParams.args.values;
        }
    }

    private _getValues(key: string): { documentTypeId: number, source: string, threshold?: number }[] {
        return this.params[`${key}Values`]?.map(value => {
            const objToReturn: { documentTypeId, source, threshold? } = {
                documentTypeId: value.documentTypeId,
                source: value.source
            };
            if (value.source === 'inferred') {
                objToReturn.threshold = this.INFERRED_DOCUMENT_TYPES_DISPLAY_THRESHOLDS[value.documentTypeSlug];
            }
            return objToReturn;
        }) || [];
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        // 2 objects because not the same operator
        this.apiParams.args = {
            values: [
                {
                    type: this.slug,
                    args: {
                        values: [...this._getValues('documentTypePresent')],
                        operator: this.params['documentTypePresentOperator']
                    }
                },
                {
                    type: this.slug,
                    args: {
                        values: [...this._getValues('documentTypeMissing')],
                        operator: this.params['documentTypeMissingOperator']
                    }
                }
            ]
        };
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
