<div class="add-diagnostic-dialog-content">
    <h1 mat-dialog-title>{{'DATA_SET.CODIFICATION_ELEMENT.DIAGNOSTIC.ADD_DIALOG.TITLE' | translate}}</h1>

    <button mat-raised-button
            color="primary"
            class="add-button"
            (click)="openAddActDialog()">{{'DATA_SET.CODIFICATION_ELEMENT.ACT.ADD_ACTS' | translate | uppercase}}</button>

    <mat-dialog-content>
        <ct-stay-detail-codification-diagnostic class="display-block margin-bottom-20"
                                              [dataSetElement]="dataSetElement"
                                              [predictiveDiagnostics]="predictiveDiagnostics"
                                              [diagnostics]="dataSetElementDiagnostics"
                                              [disableDragAndDrop]="true"
                                                [canTriggerCodificationUpdate]="false"></ct-stay-detail-codification-diagnostic>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button
                mat-dialog-close
                type="button"
                class="color-blue-on-white">{{'BUTTON.CANCEL' | translate | uppercase}}
        </button>
    </mat-dialog-actions>
</div>
