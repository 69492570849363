import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {VersionDisplayDialogComponent} from './version-display-dialog.component';
import {HttpService} from '../../core/http/http.service';
import packageInfo from 'package.json';

@Injectable({
    providedIn: 'root'
})
export class VersionDisplayDialogService {
    constructor(private _matDialog: MatDialog,
                private _httpService: HttpService) {}

    private _JSON_FILE_URL = './tags.json';

    async loadTagsFile() {
        try {
            // timestamp these requests to invalidate cache
            const tags = await this._httpService.getFile(this._JSON_FILE_URL + '?t=' + new Date().getTime())
                .toPromise();
            tags.services = tags.services ? tags.services.filter((service) => !!service.version) : [];
            return tags;
        } catch (e) {
            console.error(e);
            return {version: packageInfo.version, services : [] };
        }
    }

    openDialog() {
        this._matDialog.open(VersionDisplayDialogComponent, {
            data: {
                service: this
            },
            autoFocus: false,
            panelClass: 'version-display-dialog'
        });
    }
}
