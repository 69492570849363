<div>
    <mat-checkbox class="margin-left-10"
                  color="primary"
                  *ngFor="let col of columns"
                  [(ngModel)]="colsToActivate[col.slug]"
                  [checked]="colsToActivate[col.slug]"
                  (change)="updateColumnsToDisplay(true)">
        <span>{{col.name}}</span>
    </mat-checkbox>
</div>

<ngx-datatable
    #table
    class="material"
    *ngIf="columnsToDisplay.length > 0"
    [rows]="rowsToDisplay"
    [limit]="limit"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [rowClass]="getRowClass"
    [headerHeight]="40"
    [footerHeight]="35"
    [reorderable]="true"
    [scrollbarH]="true"
    [messages]="tableMessages">
    <ngx-datatable-column
            *ngFor="let col of columnsToDisplay"
            [prop]="col.prop"
            [name]="col.name">
    </ngx-datatable-column>

    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template
                     let-rowCount="rowCount"
                     let-pageSize="pageSize"
                     let-selectedCount="selectedCount"
                     let-curPage="curPage"
                     let-offset="offset">
            <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!canDisplayPaginator()"
                    (change)="table.onFooterPage($event)">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>
