import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DocumentTypeDocumentListDisplayDialogComponent} from './document-type-document-list-display-dialog.component';
import {FileDisplayModule} from '../../file-display/file-display.module';
import {StayDetailDocumentListModule} from '../../../../modules/stay/detail/document/list/stay-detail-document-list.module';
import {MatDividerModule} from '@angular/material/divider';
import {StayDetailDocumentModule} from '../../../../modules/stay/detail/document/stay-detail-document.module';

@NgModule({
    declarations: [
        DocumentTypeDocumentListDisplayDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FileDisplayModule,
        StayDetailDocumentListModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatDialogModule,
        MatProgressBarModule,
        MatDividerModule,
        StayDetailDocumentModule
    ]
})
export class DocumentTypeDocumentListDisplayDialogModule {}
