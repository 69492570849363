import {Injectable} from '@angular/core';
import {DataSetElementApiService} from '../../../../../core/api-services/data-set-element/data-set-element.api.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class StayDetailCodificationHistoryService {
    constructor(private _dataSetElementApiService: DataSetElementApiService,
                private _translateService: TranslateService) {}

    loadCodificationHistory(dataSetElementId: number): Promise<any> {
        return this._dataSetElementApiService
            .loadCodificationHistory(dataSetElementId)
            .toPromise();
    }

    getTranslationFromAction(action: string, type: string): string {
        switch (action) {
            case 'add first DP':
                return this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_ADD_DIAGNOSTIC_FIRST_DP');
            case 'add':
                return this._translateService.instant(type === 'diagnosis' ? 'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_ADD_DIAGNOSTIC' :
                    'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_ADD_ACT');
            case 'update':
                return this._translateService.instant(type === 'diagnosis' ? 'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_UPDATE_DIAGNOSTIC' :
                    'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_UPDATE_ACT');
            case 'delete':
                return this._translateService.instant(type === 'diagnosis' ? 'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_DELETE_DIAGNOSTIC' :
                    'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_DELETE_ACT');
            case 'confirmation':
                return this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION_EDIT_STATUS');
            default:
                return '';
        }
    }

    /**
     * Map grouping element to correct object to be used by GhmTagComponent
     * @private
     */
    mapHistoryGroupingType(history: any[]): any[] {
        return history?.map(element => {
            if (element.type === 'grouping') {
                return {
                    ...element,
                    data: {
                        ...element.data,
                        grouping: {
                            ...element.data.grouping,
                            healthGhm: {slug: element.data.grouping.new},
                            previousHealthGhm: {slug: element.data.grouping.previousGrouping?.ghmSlug}
                        }
                    }
                };
            } else {
                return element;
            }
        }) || [];
    }
}
