import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionValuationComponent} from './search-engine-condition-valuation.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionValuationService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.VALUATION_IA'),
            'predictionRevaluation',
            SearchEngineConditionValuationComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            revaluationCodificationLabel: '',
            revaluationScore: '',
            source: []
        };
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.revaluationCodificationLabel = this.apiParams.args.codificationLabel[0];
        this.params.revaluationScore = this.apiParams.args.score.minValue * 100;
        this.params.source = ['ml'];
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {
            codificationLabel: [this.params.revaluationCodificationLabel],
            score: {
                minValue: this.params.revaluationScore ? this.params.revaluationScore / 100 : 0,
                operator: '>='
            },
            source: ['ml']
        };
    }

    isValidApiParams(args: any): boolean {
        return args?.codificationLabel?.length && args.codificationLabel[0] !== '';
    }
}
