import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailCodificationHistoryComponent} from './stay-detail-codification-history.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {FieldDisplayModule} from '../../../../../shared/components/field-display/field-display.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {GhmTagModule} from '../../../../../shared/components/ghm-tag/ghm-tag.module';

@NgModule({
    declarations: [
        StayDetailCodificationHistoryComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FieldDisplayModule,
        NgbTooltipModule,
        GhmTagModule,
        MatCardModule,
        MatDividerModule
    ],
    exports: [
        StayDetailCodificationHistoryComponent
    ],
})
export class StayDetailCodificationHistoryModule {}
