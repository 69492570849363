import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionUserAssignComponent} from './search-engine-condition-user-assign.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionUserAssignService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super(['userAssign'],
            _translateService.instant('userAssign'),
            'userAssign',
            SearchEngineConditionUserAssignComponent,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {userId: []};
        this.uriParams = {userId: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.userId) {
            this.uriParams.userId = this.params.userId.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.userId !== '') {
            this.params.userId = this.uriParams.userId.split(';');
        } else {
            this.params.userId = [];
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.userId = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.userId};
    }
}
