<div class="parent-element">
    <div class="text-color element-container"
         ctTooltip
         [templateComponent]="tooltipComponent"
         [right]="'-5px'"
         [config]="config"
    >
        <div class="align-center margin-right-10">
            <div class="diagnostic-slug margin-right-5">
                <span class="text-color {{ getClass() }}">
                    {{predictiveAct?.act?.slug}}</span>
            </div>
            <div class="text-color {{ getClass() }}">
                {{predictiveAct?.act?.name || predictiveAct?.act?.description}}
            </div>
        </div>
    </div>
</div>
