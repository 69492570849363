import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './core/authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {VersionCheckService} from './core/services/version-check.service';
import {environment} from '../environments/environment';
import {DateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import {NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ct-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    isActive: boolean;

    constructor(private _translateService: TranslateService,
                private _dateAdapter: DateAdapter<any>,
                private _versionCheckService: VersionCheckService,
                private _authenticationService: AuthenticationService,
                private _ngbTooltipConfig: NgbTooltipConfig) {
        this._ngbTooltipConfig.container = 'body';
    }

    ngOnInit() {
        if (environment.production) {
            this._versionCheckService.checkBuildVersion();
        }
        this._init();
    }

    private async _init() {
        try {
            await this._authenticationService.checkLocalStorage();
            const baseLanguage = this._translateService.currentLang.slice(0, 2);
            // For languages like fr_ch
            if (baseLanguage === 'fr') {
                this._dateAdapter.setLocale('fr');
                moment.locale('fr');
            } else {
                // Here and not in TranslationHelperService because import issue in its test file
                this._dateAdapter.setLocale(this._translateService.currentLang);
                moment.locale(this._translateService.currentLang);
            }
            this.isActive = true;
        } catch (e) {
            this._dateAdapter.setLocale('fr');
            moment.locale('fr');
            console.error(e);
            throw e;
        }
    }
}
