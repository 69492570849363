import {LayoutComponent} from './layout.component';
import {Ng2StateDeclaration} from '@uirouter/angular';

export const layoutStates: Ng2StateDeclaration[] = [
    {
        name: 'layout',
        abstract: true,
        component: LayoutComponent
    },
    {
        name: 'monitoring.**',
        url: '/monitoring',
        loadChildren: () => import('../monitoring/monitoring.module').then(m => m.MonitoringModule)
    },
    {
        name: 'prediction-analysis.**',
        url: '/prediction-analysis',
        loadChildren: () => import('../prediction-analysis/prediction-analysis.module').then(m => m.PredictionAnalysisModule)
    },
    {
        name: 'diagnostic-automatic.**',
        url: '/diagnostic-automatic',
        loadChildren: () => import('../diagnostic/automatic/diagnostic-automatic.module').then(m => m.DiagnosticAutomaticModule)
    },
    {
        name: 'configuration.**',
        url: '/configuration',
        loadChildren: () => import('../configuration/configuration.module').then(m => m.ConfigurationModule)
    },
    {
        name: 'feedback-list.**',
        url: '/feedback',
        loadChildren: () => import('../feedback/feedback.module').then(m => m.FeedbackModule)
    },
    {
        name: 'chronic-diagnostic-list.**',
        url: '/chronic-diagnostic',
        loadChildren: () => import('../chronic-diagnostic/chronic-diagnostic.module').then(m => m.ChronicDiagnosticModule)
    },
    {
        name: 'diagnostic-note-list.**',
        url: '/diagnostic-note',
        loadChildren: () => import('../diagnostic/note/diagnostic-note.module').then(m => m.DiagnosticNoteModule)
    },
    {
        name: 'document-type-list.**',
        url: '/document-type',
        loadChildren: () => import('../document-type/document-type.module').then(m => m.DocumentTypeModule)
    },
    {
        name: 'storyboard-list.**',
        url: '/storyboard',
        loadChildren: () => import('../storyboard/storyboard.module').then(m => m.StoryboardModule)
    },
    {
        name: 'log.**',
        url: '/log',
        loadChildren: () => import('../log/log.module').then(m => m.LogModule)
    },
    {
        name: 'health-document-anonymization.**',
        url: '/health-document/:healthDocumentId/anonymize',
        loadChildren: () => import('../health/document/anonymization/health-document-anonymization.module').then(m => m.HealthDocumentAnonymizationModule)
    },
    {
        name: 'stay-list.**',
        url: '/data-set/:dataSetId/codification/:codificationId',
        loadChildren: () => import('../stay/list/default/stay-list-default.module').then(m => m.StayListDefaultModule)
    },
    {
        name: 'stay-list-external.**',
        url: '/data-set/:dataSetId/codification/:codificationId/external',
        loadChildren: () => import('../stay/list/external/stay-list-external.module').then(m => m.StayListExternalModule)
    },
    {
        name: 'stay-list-rehabilitation.**',
        url: '/data-set/:dataSetId/codification/:codificationId/rehabilitation',
        loadChildren: () => import('../stay/list/rehabilitation/stay-list-rehabilitation.module').then(m => m.StayListRehabilitationModule)
    },
    {
        name: 'stay-detail-old.**',
        url: '/data-set/:dataSetId/codification/:codificationId/element/:dataSetElementId',
        loadChildren: () => import('../stay/detail/stay-detail.module').then(m => m.StayDetailModule)
    },
    {
        name: 'stay-detail.**',
        url: '/data-set/:dataSetId/codification/:codificationId/element/:dataSetElementId/v2',
        loadChildren: () => import('../stay/detail/stay-detail.module').then(m => m.StayDetailModule)
    },
    {
        name: 'patient-list.**',
        url: '/patient',
        loadChildren: () => import('../patient/list/patient-list.module').then(m => m.PatientListModule)
    },
    {
        name: 'patient-detail.**',
        url: '/patient/:id',
        loadChildren: () => import('../patient/detail/patient-detail.module').then(m => m.PatientDetailModule)
    },
    {
        name: 'filter-search-stay-list.**',
        url: '/filter-search',
        loadChildren: () => import('../filter-search/stay-list/default/filter-search-stay-list-default.module').then(m => m.FilterSearchStayListDefaultModule)
    },
    {
        name: 'filter-search-stay-list-external.**',
        url: '/filter-search/external',
        loadChildren: () => import('../filter-search/stay-list/external/filter-search-stay-list-external.module').then(m => m.FilterSearchStayListExternalModule)
    },
    {
        name: 'filter-search-patient-list.**',
        url: '/filter-search',
        loadChildren: () => import('../filter-search/patient-list/filter-search-patient-list.module').then(m => m.FilterSearchPatientListModule)
    },
    {
        name: 'user-list.**',
        url: '/user',
        loadChildren: () => import('../user/user.module').then(m => m.UserModule)
    },
    {
        name: 'user-edit-password.**',
        url: '/user/:id/edit-password',
        loadChildren: () => import('../user/edit-password/user-edit-password.module').then(m => m.UserEditPasswordModule)
    },
    {
        name: 'quality-control.**',
        url: '/quality-control',
        loadChildren: () => import('../quality-control/default/quality-control-default.module').then(m => m.QualityControlDefaultModule)
    },
    {
        name: 'quality-control-imprecise-diagnostic.**',
        url: '/quality-control-imprecise-diagnostic',
        loadChildren: () => import('../quality-control/imprecise-diagnostic/quality-control-imprecise-diagnostic.module').then(m => m.QualityControlImpreciseDiagnosticModule)
    },
    {
        name: 'quality-control-near-automation.**',
        url: '/quality-control-near-automation',
        loadChildren: () => import('../quality-control/near-automation/quality-control-near-automation.module').then(m => m.QualityControlNearAutomationModule)
    },
    {
        name: 'dashboard.**',
        url: '/dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        name: 'dashboard-custom.**',
        url: '/dashboard-custom',
        loadChildren: () => import('../dashboard-custom/dashboard-custom.module').then(m => m.DashboardCustomModule)
    },
    {
        name: 'alert-list.**',
        url: '/alert',
        loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule)
    },
    {
        name: 'alert-unsubscribe.**',
        url: '/alert/:alertId/unsubscribe',
        loadChildren: () => import('../alert/unsubscribe/alert-unsubscribe.module').then(m => m.AlertUnsubscribeModule)
    },
    {
        name: 'ml-report-list.**',
        url: '/machine-learning-report-list',
        loadChildren: () => import('../machine-learning/report-list/machine-learning-report-list.module').then(m => m.MachineLearningReportListModule)
    },
    {
        name: 'machine-learning-generic-call.**',
        url: '/machine-learning-generic-call',
        loadChildren: () => import('../machine-learning/generic-call/machine-learning-generic-call.module').then(m => m.MachineLearningGenericCallModule)
    },
    {
        name: 'visualization-detail.**',
        url: '/visualization/:visualizationId',
        loadChildren: () => import('../visualization/detail/visualization-detail.module').then(m => m.VisualizationDetailModule)
    },
    {
        name: 'visualization-creation.**',
        url: '/visualization/create',
        loadChildren: () => import('../visualization/creation/visualization-creation.module').then(m => m.VisualizationCreationModule)
    },
    {
        name: 'visualization-edition.**',
        url: '/visualization/:visualizationId/edit',
        loadChildren: () => import('../visualization/edition/visualization-edition.module').then(m => m.VisualizationEditionModule)
    },
    {
        name: 'worklist-user.**',
        url: '/worklist/',
        loadChildren: () => import('src/app/modules/worklist-user/worklist-user.module').then(m => m.WorklistUserModule)
    },
    {
        name: 'data-monitoring.**',
        url: '/data-monitoring/',
        loadChildren: () => import('src/app/modules/data-monitoring/data-monitoring.module').then(m => m.DataMonitoringModule)
    }
];
