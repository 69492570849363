import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionNumberOfStaysComponent} from './search-engine-condition-number-of-stays.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionNumberOfStaysService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['nbStays'],
            _translateService.instant('PATIENT.LIST.SEARCH.NUMBER_OF_STAYS'),
            'nbStays',
            SearchEngineConditionNumberOfStaysComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {nbStaysMin: null, nbStaysMax: null};
        this.uriParams = {nbStays: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.updateUriParamsComparisonNumber(this.uriParams, 'nbStays', this.params, 'nbStaysMin', 'nbStaysMax');
    }

    convertToParams(): void {
        this.updateParamsComparisonNumber(this.uriParams, 'nbStays', this.params, 'nbStaysMin', 'nbStaysMax');
    }

    convertToParamsFromApiParams(): void {
        this.params.nbStaysMin = this.apiParams.args.minValue;
        this.params.nbStaysMax = this.apiParams.args.maxValue ?? null;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.getComparisonArgsObject(this.params.nbStaysMin, this.params.nbStaysMax)};
    }

    isValid(fields?: any): boolean {
        return this.isValidComparisonNumbers(fields, 'nbStays');
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
