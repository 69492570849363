<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="add-dialog-content">
    <h1 mat-dialog-title>{{'DATA_SET.CODIFICATION.ASSIGN_STAY_USER' | translate}}</h1>

    <form>
        <mat-dialog-content>
            <div class="row pt-1 align-center-center" *ngFor="let userDb of usersDb">
                    <div class="col-md-6">{{userDb.firstName}} {{userDb.lastName}}</div>
                    <div class="col-md-6">
                        <button mat-raised-button
                                class="me-3"
                                color="primary"
                                type="button"
                                (click)="addUser(userDb.id)"
                                [disabled]="isSelected(userDb.id)"
                               >
                            {{'BUTTON.ADD' | translate| uppercase}}
                        </button>
                        <button mat-button
                                type="button"
                                (click)="removeUser(userDb.id)"
                                class="color-blue-on-white">
                            {{'BUTTON.DELETE' | translate | uppercase}}
                        </button>
                </div>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-raised-button
                    type="button"
                    mat-dialog-close=""
                    color="primary"
                    (click)="sendUser()"
                    class="justify-content-end"
            >
                {{'BUTTON.VALIDATE' | translate | uppercase}}
            </button>
        </mat-dialog-actions>
    </form>
</div>
