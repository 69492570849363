import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataSetElement} from '../../../../shared/data-set-element.model';

@Component({
    selector: 'ct-stay-detail-codification-act-add-dialog',
    templateUrl: './stay-detail-codification-act-add-dialog.component.html',
    styleUrls: ['./stay-detail-codification-act-add-dialog.component.scss']
})
export class StayDetailCodificationActAddDialogComponent implements OnInit {
    dataSetElement: DataSetElement;
    acts: any[];
    launchValidation: boolean;
    isSSRUseCase: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<StayDetailCodificationActAddDialogComponent>) { }

    ngOnInit() {
        this.dataSetElement = this.data.dataSetElement;
        this.isSSRUseCase = this.data.isSSRUseCase;
    }

    openAddDiagnosticDialog() {
        this.dialogRef.close({openAddDiagnosticDialog: true});
    }

    validate() {
        this.launchValidation = true;
    }

    onValidationDone() {
        this.dialogRef.close();
    }
}
