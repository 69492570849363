import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionHealthMovementComponent} from './search-engine-condition-health-movement.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionHealthMovementExitService extends SearchEngineCondition {
    paramKey = 'movementExitId';
    placeholder = 'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_EXIT';
    useCase = 'exit';

    constructor(private _translateService: TranslateService) {
        super(['movementExitId'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_EXIT'),
            'movementExitId',
            SearchEngineConditionHealthMovementComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {movementExitId: []};
        this.uriParams = {movementExitId: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.movementExitId) {
            this.uriParams.movementExitId = this.params.movementExitId.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.movementExitId) {
            this.params.movementExitId = this.uriParams.movementExitId.split(';')?.map(id => Number.parseFloat(id));
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.movementExitId = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.movementExitId};
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields['movementExitId']) &&
            !this.isEmpty(fields['movementExitId']));
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
