import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionPresenceDaysComponent} from './search-engine-condition-presence-days.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionPresenceDaysService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['presenceDays'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.PRESENCE_DAYS'),
            'presenceDays',
            SearchEngineConditionPresenceDaysComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            presenceDays: {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false} // Represents each day of the week
        };
        this.uriParams = {presenceDays: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    private _getPresenceDaysString(): string {
        if (this.params.presenceDays) {
            return <string>Object.values(this.params.presenceDays).reduce((accumulator, currentValue) => {
                const isCheckedNumber = currentValue ? 1 : 0;
                return `${accumulator}${isCheckedNumber}`;
            }, '');
        }
        return '';
    }

    convertToUriParams(): void {
        this.uriParams.presenceDays = this._getPresenceDaysString();
    }

    private _getPresenceDaysObject(presenceDaysString: string): any {
        if (presenceDaysString) {
            const obj = {};
            for (let i = 0; i < presenceDaysString.length; i++) {
                obj[i] = presenceDaysString[i] === '1';
            }
            return obj;
        }
    }

    convertToParams(): void {
        this.params.presenceDays = this._getPresenceDaysObject(this.uriParams.presenceDays);
    }

    convertToParamsFromApiParams(): void {
        this.params.presenceDays = this._getPresenceDaysObject(this.apiParams.args.value);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {value: this._getPresenceDaysString()};
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields['presenceDays']) &&
            !this.isEmpty(fields['presenceDays']));
    }

    isValidApiParams(args: any): boolean {
        return !!args?.value;
    }
}
