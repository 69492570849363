import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';
import {Patient} from '../../../modules/patient/shared/patient.model';
import {DataHelperService} from '../../services/data.helper.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PatientApiService {
    private _BASE_URL = 'patient';

    constructor(private _http: HttpService) { }

    getAll(body: any, urlRequestParams?: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);
        return this._http
            .post(`${this._BASE_URL}/search`, body, {params: urlRequestParams})
            .pipe(map(res => {
                return {
                    ...res,
                    total: res.meta.total,
                    lastPage: res.meta.last_page,
                    currentPage: res.meta.current_page
                };
            }));
    }

    get(patientId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${patientId}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    getPatientStays(patientId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${patientId}/stay`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    getPatientStayHistory(patientId: number, dataSetContentId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${patientId}/stay-history/${dataSetContentId}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    exportSearch(body: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);
        return this._http
            .post(`${this._BASE_URL}/search/export`, body, {responseType: 'blob'});
    }

    updatePatientIPP(patientId: number, body: any, urlRequestParams?: any): Observable<Patient> {
        return this._http
            .patch(`${this._BASE_URL}/${patientId}/update-ipp`, body, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
