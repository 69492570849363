import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import {DataSetElementService} from '../../shared/data-set-element.service';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {StayDetailService} from '../stay-detail.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {PatientDetailService} from '../../../patient/detail/patient-detail.service';

@Component({
    selector: 'ct-stay-detail-info',
    templateUrl: './stay-detail-info.component.html',
    styleUrls: ['./stay-detail-info.component.scss']
})
export class StayDetailInfoComponent implements OnInit, OnDestroy {
    private _codificationId: number;
    private _subscriptions: Subscription[] = [];
    private _canDisplayMissingIPPIndicatorFromConfig: boolean;
    private _showAdditionalStayInfoDefaultValue: boolean;

    @Input() dataSetElement: DataSetElement;
    @Input() dataSetElementParent: DataSetElement;

    patientStayHistory: any[] = [];
    diagnosticHistory: any = {};
    activeTabId: number;
    patientAge: string;
    isPatientStayHistoryLoading: boolean;
    isUpdatePriceLoading: boolean;
    hasClassingActs: boolean;
    numberOfPatientStays = 0;
    canDisplayBilling: boolean;
    isTypeRehabilitation: boolean;
    isTypeExternal: boolean;
    showAdditionalInfo: boolean;
    entryMode: string;
    exitMode: string;
    entryOrigin: string;
    exitDestination: string;

    constructor(public $state: StateService,
                public dateHelperService: DateHelperService,
                private _dataSetElementService: DataSetElementService,
                private _stayDetailService: StayDetailService,
                private _patientDetailService: PatientDetailService,
                private _translateService: TranslateService,
                private _broadcastService: BroadcastService,
                private _configurationService: ConfigurationService,
                private _translationHelperService: TranslationHelperService) {
    }

    ngOnInit() {
        this._initConfig();
        this._initAvailableFeatures();
        this._codificationId = this.$state.params.codificationId;
        this.activeTabId = this.dataSetElement?.id;
        this.isTypeRehabilitation = this._dataSetElementService.isType(this.dataSetElement, 'rehabilitation');
        this.isTypeExternal = this._dataSetElementService.isType(this.dataSetElement, 'external');
        this._formatPatientAge();
        this._loadPatientStayHistory();
        this._subscribeToBroadcast();
        this._formatPatientEntry();
        this._formatPatientExit();
    }

    private _subscribeToBroadcast(): void {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetail::editPatientIPP':
                        if (res.data) {
                            this.dataSetElement.dataSetContent.healthPatient.ipp = res.data.IPP;
                        }
                        break;
                    case 'stay::updatePrice':
                        this._updatePrice(res.data);
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private _initAvailableFeatures(): void {
        this.hasClassingActs = this._translationHelperService.isFeatureAvailable('classingActs');
    }

    private _initConfig(): void {
        this.canDisplayBilling = this._configurationService.getConfigurationContent('front', 'health.canDisplayBilling');
        this._canDisplayMissingIPPIndicatorFromConfig = this._configurationService.getConfigurationContent('front', 'patient.canDisplayMissingIPPIndicator');
        this._showAdditionalStayInfoDefaultValue = this._configurationService.getConfigurationContent('front', 'health.showAdditionalStayInfoDefaultValue');
        this.showAdditionalInfo = this._showAdditionalStayInfoDefaultValue;
    }

    private async _loadPatientStayHistory(): Promise<void> {
        if (this._hasDataSetElementDataSetContent() &&
            this.dataSetElement.dataSetContent.healthPatientId) {
            try {
                this.isPatientStayHistoryLoading = true;
                // We load also all the patients stays to know if there is only one stay to display tooltip
                const [patientStayHistory, patientStays] = await Promise.all([
                    this._stayDetailService.loadPatientStayHistory(this.dataSetElement.dataSetContent.healthPatientId, this.dataSetElement.dataSetContentId),
                    this._patientDetailService.loadPatientStays(this.dataSetElement.dataSetContent.healthPatientId, {})
                ]);
                this.patientStayHistory = patientStayHistory;
                this.numberOfPatientStays = patientStays ? patientStays.length : 0;
                this.diagnosticHistory = this._stayDetailService.formatDiagnosticHistory(this.patientStayHistory);
                this._broadcastService.send('stayDetailInfo::loadPatientStayHistoryDone', {diagnosticHistory: this.diagnosticHistory});
                this.isPatientStayHistoryLoading = false;
            } catch (e) {
                this.isPatientStayHistoryLoading = false;
                console.error(e);
                throw e;
            }
        }
    }

    /**
     * Return a string according to patient age in days
     * @private
     */
    private _formatPatientAge(): void {
        if (this.dataSetElement?.dataSetContent?.age || this.dataSetElement?.dataSetContent?.age === 0) {
            this.patientAge = this._patientDetailService.formatPatientAge(this.dataSetElement.dataSetContent.age);
        } else {
            this.patientAge = '';
        }
    }

    private _formatPatientEntry(): void {
        this.entryMode = this.dataSetElement?.dataSetContent?.healthMovementEntrance?.name ?
            this._patientDetailService.formatPatientMovement(this.dataSetElement.dataSetContent.healthMovementEntrance.name)[0] : '';
        this.entryOrigin = this.dataSetElement?.dataSetContent?.healthMovementEntrance?.name ?
            this._patientDetailService.formatPatientMovement(this.dataSetElement.dataSetContent.healthMovementEntrance.name)[1] : '';
    }

    private _formatPatientExit(): void {
        this.exitMode = this.dataSetElement?.dataSetContent?.healthMovementExit?.name ?
            this._patientDetailService.formatPatientMovement(this.dataSetElement.dataSetContent.healthMovementExit.name)[0] : '';
        this.exitDestination = this.dataSetElement?.dataSetContent?.healthMovementExit?.name ?
            this._patientDetailService.formatPatientMovement(this.dataSetElement.dataSetContent.healthMovementExit.name)[1] : '';
    }

    private _updatePrice(data: any): void {
        if (data &&
            data.data &&
            data.data.dataSetContent) {
            this.isUpdatePriceLoading = true;
            setTimeout(() => {
                this.dataSetElement.dataSetContent.pricing = data.data.dataSetContent.pricing;
                this.isUpdatePriceLoading = false;
            }, 1000);
        }
    }

    private _hasDataSetElementDataSetContent(): boolean {
        return !!(this.dataSetElement &&
            this.dataSetElement.dataSetContent);
    }

    private _hasCtGhm(): boolean {
        return !!(this._hasDataSetElementDataSetContent() &&
            this.dataSetElement.dataSetContent.ctHealthGhsGhm &&
            this.dataSetElement.dataSetContent.ctHealthGhsGhm.healthGhm &&
            this.dataSetElement.dataSetContent.ctHealthGhsGhm.healthGhm.slug);
    }

    private _hasCtGme(): boolean {
        return !!(this._hasDataSetElementDataSetContent() &&
            this.dataSetElement.dataSetContent.healthStayAdditional &&
            this.dataSetElement.dataSetContent.healthStayAdditional.ctHealthGmtGme &&
            this.dataSetElement.dataSetContent.healthStayAdditional.ctHealthGmtGme.healthGme &&
            this.dataSetElement.dataSetContent.healthStayAdditional.ctHealthGmtGme.healthGme.slug);
    }

    hasRSSGhm(): boolean {
        return !!(this._hasDataSetElementDataSetContent() &&
            this.dataSetElement.dataSetContent.healthGhm &&
            this.dataSetElement.dataSetContent.healthGhm.slug);
    }

    hasRSSGme(): boolean {
        return !!(this._hasDataSetElementDataSetContent() &&
            this.dataSetElement.dataSetContent.healthStayAdditional &&
            this.dataSetElement.dataSetContent.healthStayAdditional.healthGme &&
            this.dataSetElement.dataSetContent.healthStayAdditional.healthGme.slug);
    }

    canDisplayCtGhm(): boolean {
        if (this._hasCtGhm()) {
            return !this.hasRSSGhm() ? true : this.dataSetElement.dataSetContent.healthGhm.slug !==
                this.dataSetElement.dataSetContent.ctHealthGhsGhm.healthGhm.slug;
        }
        return false;
    }

    canDisplayCtGme(): boolean {
        if (this._hasCtGme()) {
            return !this.hasRSSGme() ? true : this.dataSetElement.dataSetContent.healthStayAdditional.healthGme.slug !==
                this.dataSetElement.dataSetContent.healthStayAdditional.ctHealthGmtGme.healthGme.slug;
        }
        return false;
    }

    hasHistoryDiagnostic(dataSetElementId: number, codificationLabelId: number): boolean {
        if (this.diagnosticHistory[dataSetElementId]) {
            const diagnosticToFind = this.diagnosticHistory[dataSetElementId].find(diagnostic => diagnostic.codificationLabelId === codificationLabelId);
            return !!diagnosticToFind;
        }
        return false;
    }

    /**
     * Get diagnostics of according dataSetElement and filter by codificationLabelId
     * @param dataSetElementId
     * @param codificationLabelId
     */
    filterHistoryDiagnostics(dataSetElementId: number, codificationLabelId: number): any[] {
        if (this.diagnosticHistory[dataSetElementId]) {
            return this.diagnosticHistory[dataSetElementId].filter(diagnostic => diagnostic.codificationLabelId === codificationLabelId);
        }
        return [];
    }

    getGender(gender: number): string {
        if (gender === 1) {
            return this._translateService.instant('HEALTH.PATIENT.MAN');
        } else if (gender === 2) {
            return this._translateService.instant('HEALTH.PATIENT.WOMAN');
        }
        return '';
    }

    getPatientName(healthPatient: any): string {
        if (healthPatient &&
            healthPatient.firstName &&
            healthPatient.lastName) {
            return `${healthPatient.firstName.charAt(0).toUpperCase()}${healthPatient.firstName.slice(1)} ${healthPatient.lastName.charAt(0).toUpperCase()}${healthPatient.lastName.slice(1)}`;
        }
        return null;
    }

    goToPatientDetail(): void {
        this._stayDetailService.goToPatientDetail(this.dataSetElement);
    }

    canDisplayPatientInfo(): boolean {
        return !!(!this.isPatientStayHistoryLoading &&
            this._hasDataSetElementDataSetContent() &&
            this.dataSetElement.dataSetContent.healthPatient &&
            this.dataSetElement.dataSetContent.healthPatient.ipp);
    }

    canDisplayMissingIPPIndicator(): boolean {
        return !!(this._canDisplayMissingIPPIndicatorFromConfig &&
            this._hasDataSetElementDataSetContent() &&
            this.dataSetElement.dataSetContent.healthPatient &&
            !this.dataSetElement.dataSetContent.healthPatient.ipp);
    }

    rumIdIncludesTmp(rumId: string): boolean {
        return rumId ? rumId.includes('tmp') : true;
    }

    openPatientIPPEditDialog(): void {
        this._stayDetailService.openPatientIPPEditDialog(this.dataSetElement.dataSetContent.healthPatient.id);
    }
    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
