<mat-form-field class="full-width">
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let selectedDiagnostic of selectedDiagnostics"
                  [matTooltip]="diagnosticSearchService.getTooltipText(selectedDiagnostic, isDiagnosticSeverityLevelActivated)"
                  matTooltipPosition="above"
                  [value]="selectedDiagnostic.slug"
                  [removable]="true"
                  (removed)="removeChip(selectedDiagnostic)">
            {{selectedDiagnostic.slug}}
            <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input #diagnosticInput
               [placeholder]="placeholder"
               [formControl]="formControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               (keydown.enter)="enterKeyPressed.emit(true)">
    </mat-chip-list>

    <mat-progress-bar mode="indeterminate"
                      class="autocomplete-progress-bar"
                      *ngIf="isLoading"></mat-progress-bar>

    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="selectDiagnostic($event)">
        <mat-option *ngFor="let searchedDiagnostic of searchedDiagnostics | async"
                    [value]="searchedDiagnostic">
            {{diagnosticSearchService.getDisplayText(searchedDiagnostic, isDiagnosticSeverityLevelActivated)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
