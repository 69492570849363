import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EstablishmentService} from '../../../../core/services/establishment.service';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject} from 'rxjs';

@Component({
    selector: 'ct-search-engine-condition-establishment',
    templateUrl: './search-engine-condition-establishment.component.html',
    styleUrls: ['./search-engine-condition-establishment.component.scss']
})
export class SearchEngineConditionEstablishmentComponent implements OnInit {

    constructor(private _establishmentService: EstablishmentService) {
    }

    @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;

    @Input() condition: any;

    /** list of the available establishments */
    public establishments: any[] = [];

    /** control for the selected establishments for multi-selection */
    public selectedEstablishments = new FormControl([]);

    /** control for the MatSelect filter keyword multi-selection */
    public establishmentSearch = new FormControl('');

    /** list of establishments filtered by search keyword */
    public filteredEstablishments = new ReplaySubject(1);

    toggleAllMapCallback = (element: any) => element?.systemId;

    async ngOnInit(): Promise<void> {
        await this._loadAllEstablishments();

        // Set initial values from sessionStorage
        this.selectedEstablishments.setValue(
            this.establishments.filter(establishment => this.condition.service.params['establishment'].includes(establishment.value))
        );

        // load the initial establishments list
        this.filteredEstablishments.next(this.establishments.slice());

        // listen for search field value changes
        this.establishmentSearch.valueChanges
            .subscribe(() => {
                this.filterEstablishments();
            });

        this.selectedEstablishments.valueChanges
            .subscribe(() => {
                this.condition.service.params['establishment'] = this.selectedEstablishments.value.map((establishment: { value: any; }) => establishment.value);
            });
    }

    protected filterEstablishments() {
        if (!this.establishments) {
            return;
        }

        let search = this.establishmentSearch.value;

        if (!search) {
            this.filteredEstablishments.next(this.establishments.slice());
            return;
        }

        search = search.toLowerCase();

        // filter the establishments
        this.filteredEstablishments.next(
            this.establishments.filter(establishment => establishment.name.toLowerCase().indexOf(search) > -1)
        );
    }

    private _mapToGenericData(establishments: any[]): any[] {
        if (!establishments) {
            return [];
        }

        return establishments.map(establishment => ({
            name: establishment.systemId
                + (establishment.name
                        ? ' - ' + establishment.name
                        : ''
                ),
            value: establishment.systemId,
        }));
    }

    private async _loadAllEstablishments(): Promise<void> {
        try {
            const res = await this._establishmentService.loadAllEstablishments();

            this.establishments = this._mapToGenericData(res);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    compareWithCallback(objectFromOption: any, objectFromParam: any): boolean {
        return objectFromOption?.systemId === objectFromParam?.systemId;
    }
}
