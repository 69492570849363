import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationUserApiService {
    private _BASE_URL = 'auth/user';

    constructor(private _http: HttpService) {}

    get(userId: number, urlRequestParams?: any) {
        // If no userId then returns the current authenticated user
        const url = userId ? `${this._BASE_URL}/${userId}` : this._BASE_URL;
        return this._http
            .get(url, {params: urlRequestParams});
    }
}
