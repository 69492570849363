import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionIsReadyService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['isReady'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.DISPLAY_STAY_READY'),
            'isReady',
            null,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {isReady: false};
        this.uriParams = {isReady: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.uriParams.isReady = this.params.isReady ? '1' : '';
    }

    convertToParams(): void {
        this.params.isReady = this.uriParams.isReady === '1';
    }

    convertToParamsFromApiParams(): void {
        this.params.isReady = this.apiParams.args.value === '1';
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {value: this.params.isReady ? '1' : ''};
    }
}
