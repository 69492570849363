import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';

@Component({
    selector: 'ct-search-engine-condition-ghm-gme-severity',
    templateUrl: './search-engine-condition-ghm-gme-severity.component.html'
})
export class SearchEngineConditionGhmGmeSeverityComponent implements OnInit, AfterViewInit {
    @ViewChild('multipleSelect', { static: true }) matSelect;

    condition: any;
    useCase: string;
    placeHolder: string;
    severities: any[];
    paramKey: string;

    constructor(public colorService: ColorService) {}

    ngAfterViewInit() {
        // To fix jumping scroll when select an option
        (<any> this.matSelect)._getItemHeight = () => this.matSelect._triggerFontSize * 1.85;
    }

    ngOnInit() {
        this.placeHolder = (this.useCase === 'ghm' ? 'DATA_SET.CODIFICATION.SEARCH.GHM_SEVERITY' : 'DATA_SET.CODIFICATION.SEARCH.GME_SEVERITY');
        this.paramKey = (this.useCase === 'ghm' ? 'ghmSeverity' : 'gmeSeverity');
        if (this.condition) {
            this.severities = this.condition.service.severities;
        }
    }

    isIndeterminate() {
        return this.condition.service.params[this.paramKey] && this.severities &&
            this.condition.service.params[this.paramKey].length !== 0 &&
            this.condition.service.params[this.paramKey].length !== this.severities.length;
    }

    isChecked() {
        return this.condition.service.params[this.paramKey] && this.severities &&
            this.condition.service.params[this.paramKey].length === this.severities.length;
    }

    toggleAll(event: any) {
        // To prevent the checkbox to be checked because when we update the ids
        // the isChecked method updates the checkbox checked attribute
        if (event) {
            event.preventDefault();
        }
        if (this.condition.service.params[this.paramKey] && this.severities) {
            if (this.condition.service.params[this.paramKey].length === this.severities.length) {
                this.condition.service.params[this.paramKey] = [];
            } else {
                this.condition.service.params[this.paramKey] = this.severities
                    .slice(0)
                    .map(ghm => ghm.severity);
            }
        }
    }
}
