import {Injectable} from '@angular/core';
import {FeedbackApiService} from '../../core/api-services/feedback/feedback.api.service';
import {MatDialog} from '@angular/material/dialog';
import {FeedbackAddDialogComponent} from './add-dialog/feedback-add-dialog.component';
import {interval, Observable} from 'rxjs';
import {mergeMap, startWith} from 'rxjs/operators';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {BroadcastService} from '../../core/services/broadcast.service';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    private _numberOfUnreadFeedbackItems: number;

    get numberOfUnreadFeedbackItems() {
        return this._numberOfUnreadFeedbackItems;
    }

    constructor(private _matDialog: MatDialog,
                private _authenticationService: AuthenticationService,
                private _broadcastService: BroadcastService,
                private _feedbackApiService: FeedbackApiService) {
        this._numberOfUnreadFeedbackItems = 0;
        if (this._authenticationService.hasRole('admin') ||
            this._authenticationService.hasRole('feedback-viewer')) {
            this._loadNumberOfUnreadFeedbackItems$()
                .subscribe(data => this._numberOfUnreadFeedbackItems = data ? data.length : 0);
        }
    }

    increaseNumberOfUnreadFeedbackItems(): void {
        this._numberOfUnreadFeedbackItems++;
        this._broadcastService.send('menu::updateMenuItemBadgeValue', {menuItemGroupId: 7, state: 'feedback-list', newBadgeValue: this._numberOfUnreadFeedbackItems});
    }

    decreaseNumberOfUnreadFeedbackItems(): void {
        this._numberOfUnreadFeedbackItems--;
        this._broadcastService.send('menu::updateMenuItemBadgeValue', {menuItemGroupId: 7, state: 'feedback-list', newBadgeValue: this._numberOfUnreadFeedbackItems});
    }

    private _loadNumberOfUnreadFeedbackItems$(): Observable<any> {
        // status === 1 => read
        // status === 2 => unread
        const params = {'filter[status]': 1};
        // call every 5 minutes
        return interval(300000)
            .pipe(startWith(0),
                mergeMap(() => this._feedbackApiService.getAll(params)));
    }

    async loadAllFeedbackItems(loadOnlyArchived = false) {
        try {
            const params = {include: 'user'};
            if (loadOnlyArchived) {
                params['filter[trashed]'] = 'only';
            }
            return await this._feedbackApiService
                .getAll(params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async createFeedback(body: any) {
        try {
            return await this._feedbackApiService
                .create(body)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateFeedbackStatus(feedbackId: number, status: number) {
        try {
            const params = {include: 'user'};
            const body = {status};
            return await this._feedbackApiService
                .update(feedbackId, body, params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async deleteFeedback(feedbackId: number, force = false) {
        try {
            const params: any = {include: 'user'};
            if (force) {
                params.force = true;
            }
            return await this._feedbackApiService
                .delete(feedbackId, params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    openFeedbackAddDialog() {
        this._matDialog.open(FeedbackAddDialogComponent, {
            data: {
                service: this
            },
            autoFocus: true,
            panelClass: 'feedback-add-dialog'
        });
    }
}
