import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {DiagnosticService} from '../../../../../diagnostic/diagnostic.service';
import {StayDetailCodificationDiagnosticService} from '../stay-detail-codification-diagnostic.service';
import {DataSetElement} from '../../../../shared/data-set-element.model';
import {MatChipList} from '@angular/material/chips';
import {TranslationHelperService} from '../../../../../../core/services/translation.helper.service';
import {TranslateService} from '@ngx-translate/core';
import {DiagnosticSearchService} from '../../../../../../shared/components/diagnostic-search/diagnostic-search.service';
import {CodificationLabelEnum} from "../../../../shared/codification-label-enum";

@Component({
    selector: 'ct-stay-detail-codification-diagnostic-add-autocomplete',
    templateUrl: './stay-detail-codification-diagnostic-add-autocomplete.component.html',
    styleUrls: ['./stay-detail-codification-diagnostic-add-autocomplete.component.scss']
})
export class StayDetailCodificationDiagnosticAddAutocompleteComponent implements OnInit, AfterContentChecked {
    @ViewChild('diagnosticInput', {static: true}) diagnosticInput: ElementRef<HTMLInputElement>;

    @Input() dataSetElement: DataSetElement;
    @Input() predictiveDiagnostics;
    @Input() diagnosticsToDisplay: any[];
    @Input() codificationLabelSlug: CodificationLabelEnum;
    @Input() codificationLabelId: number;
    @Input() isDisabled: boolean;
    @Input() matChipList: MatChipList;
    @Input() cypressIdValue: string;

    @Output() addDiagnostic: EventEmitter<any> = new EventEmitter<any>();

    formControl = new FormControl();
    searchedDiagnostics: Observable<any>;
    isAutocompleteLoading: boolean;
    addPlaceholder: string;
    isDiagnosticSeverityLevelActivated: boolean;

    constructor(public diagnosticSearchService: DiagnosticSearchService,
                private _diagnosticService: DiagnosticService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService,
                private _changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
        this.isDiagnosticSeverityLevelActivated = this._translationHelperService.isFeatureAvailable('diagnosticSeverityLevel') &&
            [CodificationLabelEnum.DA, CodificationLabelEnum.DA_ROOT].includes(this.codificationLabelSlug);
        this.addPlaceholder = `${this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.DIAGNOSTIC.ADD')} ${this._translationHelperService.getCodificationLabel((this.codificationLabelSlug))}`;
        this.searchedDiagnostics =
            this.formControl.valueChanges
                .pipe(startWith(''),
                    debounceTime(200),
                    switchMap(value => {
                        this.isAutocompleteLoading = true;
                        if (value && value.length > 0) {
                            return this._search$(value);
                        } else {
                            this.isAutocompleteLoading = false;
                            return EMPTY;
                        }
                    }));
    }

    ngAfterContentChecked(): void {
        this._changeDetector.detectChanges();
    }

    private _search$(query: string): Observable<any> {
        const date = this.dataSetElement.dataSetContent.endDate || this.dataSetElement.dataSetContent.startDate;
        return this._diagnosticService
            .loadDiagnosticsWithES$(query, false, date)
            .pipe(map(res => {
                // Verification is only done for DA if there is a DP
                if (this.codificationLabelSlug === CodificationLabelEnum.DA
                    && this.dataSetElement.dataSetElementDiagnostic.some(diagnostic =>
                        diagnostic.codificationLabel.slug === CodificationLabelEnum.DP
                    )) {
                    this._detectExcludingDiagnostics(res.data);
                }
                this.isAutocompleteLoading = false;
                return res.data;
            }));
    }

    /**
     * Add the diagnostic to the chips list (not if already added)
     * and add the children if the selected diagnostic is a parent
     * @param event
     */
    selectDiagnostic(event: any) {
        if (event) {
            const selectedDiagnostic = event.option.value;
            this.diagnosticInput.nativeElement.value = '';
            this.formControl.setValue(null);
            const data = {
                diagnostic: selectedDiagnostic,
                codificationLabelId: this.codificationLabelId,
                referencialPrice: this._stayDetailCodificationDiagnosticService.getRevaluationValue(selectedDiagnostic, this.predictiveDiagnostics, this.dataSetElement),
                isPrediction: false,
                isCreation: true
            };
            this.addDiagnostic.emit(data);
        }
    }

    getTooltipText(diagnostic: any, i: number): string {
        const el = document.getElementById(i.toString());
        if (el &&
            el.children[0]) {
            const text = `${diagnostic.slug} ${diagnostic.name || ''}`;
            return el.children[0].scrollWidth > el.children[0].clientWidth ? text : '';
        } else {
            return '';
        }
    }

    /**
     * Detect if the diagnostics are excluded by the DP
     * @param data the DAs
     */
    private _detectExcludingDiagnostics(data: any) {
        data.forEach(diagnostic => {
            diagnostic.excluded = this.dataSetElement.excludingDiagnostics.includes(diagnostic.id);
        });
    }
}
