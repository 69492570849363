import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[ctCustomHtmlAttribute]'
})
export class CustomHtmlAttributeDirective implements OnInit {
    @Input() htmlAttributeName: string;
    @Input() htmlAttributeValue: string;

    constructor(private _element: ElementRef,
                private _renderer: Renderer2) {}

    private _setAttribute(): void {
        if (this.htmlAttributeName &&
            this.htmlAttributeValue) {
            this._renderer.setAttribute(this._element.nativeElement, this.htmlAttributeName, this.htmlAttributeValue);
        }
    }

    ngOnInit(): void {
        this._setAttribute();
    }
}
