import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'ct-search-engine-condition-near-automation-date-dialog',
    templateUrl: './search-engine-condition-custom-date-dialog.component.html',
    styleUrls: ['./search-engine-condition-custom-date-dialog.component.scss']
})
export class SearchEngineConditionCustomDateDialogComponent implements OnInit {
    daysToSubtract = [14, 21, 28];
    dateSubtractValue: number;

    constructor(private _dialogRef: MatDialogRef<SearchEngineConditionCustomDateDialogComponent>) { }

    ngOnInit() {
    }

    selectRange(amount: number, isMonth: boolean = false) {
        this._dialogRef.close({amount, isMonth});
    }
}
