<div class="row">
    <div class="col no-padding">
        <div class="header-content-container"
             [ngClass]="{'visibility-hidden' : !canDisplayBackButton()}">
            <div class="back-button"
                 (click)="goBackToList()">
                <mat-icon>arrow_back</mat-icon>
                <span>{{getBackButtonText() | translate}}</span>

                <stay-user-button [dataSetElement]="dataSetElement"
                                    [isClickable]="false"></stay-user-button>
            </div>

            <ng-container *ngIf="dataSetElement">
                <div class="middle-block">
                    <div class="last-filter-search-name"
                         [ngClass]="{'visibility-hidden' : !(fromFilterSearchList && lastFilterSearchViewedName)}">
                        {{lastFilterSearchViewedName}}
                        <span class="filter-search-updated"
                              *ngIf="isFilterSearchUpdated">&nbsp;({{'STUFF.UPDATED_LOWERCASE' | translate}})</span>
                    </div>
                </div>

                <div class="switch-stay"
                     *ngIf="currentDataSetElementNumber && totalDataSetElements">
                    <mat-icon class="margin-right-5"
                              [ngClass]="{'visibility-hidden': noMorePreviousDataSetElement()}"
                              (click)="switchDataSetElement('previous')">chevron_left</mat-icon>

                    <span>{{'STAY.STAY' | translate}} {{currentDataSetElementNumber}}/{{totalDataSetElements}}</span>

                    <mat-icon class="margin-left-5"
                              [ngClass]="{'visibility-hidden': noMoreNextDataSetElement()}"
                              (click)="switchDataSetElement('next')">chevron_right</mat-icon>
                </div>
            </ng-container>
        </div>
    </div>
</div>
