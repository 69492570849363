import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionIpDmsComponent} from './search-engine-condition-ip-dms.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionIpDmsService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super(['ghmIpDMS'],
            _translateService.instant('ACRONYM.IP-DMS'),
            'ghmIpDMS',
            SearchEngineConditionIpDmsComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {ghmIpDMSMin: null, ghmIpDMSMax: null};
        this.uriParams = {ghmIpDMS: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.updateUriParamsComparisonNumber(this.uriParams, 'ghmIpDMS', this.params, 'ghmIpDMSMin', 'ghmIpDMSMax');
    }

    convertToParams(): void {
        this.updateParamsComparisonNumber(this.uriParams, 'ghmIpDMS', this.params, 'ghmIpDMSMin', 'ghmIpDMSMax');
    }

    convertToParamsFromApiParams(): void {
        this.params.ghmIpDMSMin = this.apiParams.args.minValue;
        this.params.ghmIpDMSMax = this.apiParams.args.maxValue ?? null;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.getComparisonArgsObject(this.params.ghmIpDMSMin, this.params.ghmIpDMSMax)};
    }

    isValid(fields?: any): boolean {
        return this.isValidComparisonNumbers(fields, 'ghmIpDMS');
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
