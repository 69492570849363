<form [formGroup]="dateForm"
      (ngSubmit)="enterKeyPress.emit(true)">
    <mat-form-field class="max-width-110 margin-right-10">
        <input matInput
               formControlName="validityFrameStartDate"
               [placeholder]="'DATA_SET.CODIFICATION.SEARCH.AFTER' | translate"
               [matDatepicker]="startDate"
               [max]="dateForm.controls['validityFrameStartDate'].value">
        <mat-datepicker-toggle matSuffix
                               [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate
                        startView="year"></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="max-width-110">
        <input matInput
               formControlName="validityFrameEndDate"
               [placeholder]="'DATA_SET.CODIFICATION.SEARCH.BEFORE' | translate"
               [matDatepicker]="endDate"
               [min]="dateForm.controls['validityFrameEndDate'].value">
        <mat-datepicker-toggle matSuffix
                               [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate
                        startView="year"></mat-datepicker>
    </mat-form-field>

    <ng-container *ngIf="dateForm.errors?.outRangeDate">
        <mat-error class="margin-bottom-10">
            {{'ERROR.START_DATE_SHOULD_BE_BEFORE_TO_END_DATE_BIS' | translate}}
        </mat-error>

        <mat-error class="margin-bottom-10">
            {{'ERROR.END_DATE_SHOULD_BE_AFTER_TO_START_DATE_BIS' | translate}}
        </mat-error>
    </ng-container>
    <input type="submit" hidden>
</form>
