<div class="parent-element">
    <div class="text-color element-container"
         ctTooltip
         [templateComponent]="tooltipComponent"
         [config]="config"
         [right]="'-5px'"
         [ngClass]="[getDiagnosticLabelColor(predictiveDiagnostic)]">
        <div class="align-center margin-right-10">
            <div class="diagnostic-slug"
                 [ngClass]="{'cursor-pointer': predictiveDiagnostic?.diagnostic?.diagnosticNote?.length}"
                 (click)="openDiagnosticNoteDisplayDialog($event)">
                <span [ngbTooltip]="getSeverityLevelTooltip()">
                    {{predictiveDiagnostic?.diagnostic?.slug || predictiveDiagnostic?.diagnostic?.diagnosticId}}</span>
                <sup *ngIf="predictiveDiagnostic?.diagnostic?.diagnosticNote?.length > 0">
                    <mat-icon class="diagnostic-note-icon text-color"
                              [ngClass]="[getDiagnosticLabelColor(predictiveDiagnostic)]">assignments</mat-icon>
                </sup>
            </div>

            <div>
                {{predictiveDiagnostic?.diagnostic?.name || predictiveDiagnostic?.diagnostic?.description}}
            </div>
        </div>

        <div class="align-center">
            <ng-container *ngIf="!displayOnlySimplifiedScore">
                <div class="text-end text-color margin-right-10"
                     *ngIf="canDisplayGrouping()"
                     [ngClass]="[getGroupingClass()]">
                    <div>
                        <span *ngIf="!isTypeRehabilitation">{{predictiveDiagnostic?.healthGhsGhm?.healthGhmSlug}}</span>
                        <span *ngIf="isTypeRehabilitation">{{predictiveDiagnostic?.healthGmtGme?.healthGmeSlug}}</span>
                    </div>
                    <div>
                        <span *ngIf="referencialPrice > 0">+</span>{{referencialPrice | number: '1.2-2'}}€
                    </div>
                </div>

                <div
                    *ngIf="canDisplayLevel()"
                    class="diagnostic-level"
                >
                    {{'STUFF.LEVEL' | translate}}. {{predictiveDiagnostic?.diagnostic?.diagnosticLevel?.level}}
                </div>
            </ng-container>

            <div
                *ngIf="isNearAutomationQualityControl"
                class="display-flex"
            >
                <ng-container *ngIf="!getDiagnosticFromCodification(predictiveDiagnostic)">
                    <button
                        [matTooltip]="'TOOLTIP.VALIDATE' | translate"
                        mat-icon-button
                        class="validate-icon"
                        (click)="validateAutomation(predictiveDiagnostic)"
                    >
                        <mat-icon>done</mat-icon>
                    </button>

                    <button
                        [matTooltip]="'TOOLTIP.INVALIDATE' | translate"
                        mat-icon-button
                        class="cancel-icon"
                        (click)="cancelAutomation(predictiveDiagnostic.id)"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </ng-container>

                <button mat-icon-button
                        [matTooltip]="'TOOLTIP.TEXT_INFO' | translate"
                        (click)="showJustification()">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <div class="simplified-score-container"
                 *ngIf="canDisplaySimplifiedScore"
                 [ngClass]="{'margin-right': (groupByParent.value || isMixedDisplayActivated) && predictiveDiagnostic?.simplifiedScore > SimplifiedScoreEnum.HIGH}">
                <ct-simplified-score [value]="predictiveDiagnostic?.simplifiedScore"
                                     [tooltip]="getSimplifiedScoreTooltip()"></ct-simplified-score>
            </div>
        </div>
    </div>
</div>
