import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'ct-search-including-excluding-operator',
    templateUrl: './search-including-excluding-operator.component.html',
    styleUrls: ['./search-including-excluding-operator.component.scss']
})
export class SearchIncludingExcludingOperatorComponent implements OnInit {
    @Input() param: string;
    @Input() isExcluding = false;

    @Output() paramChange: EventEmitter<string> = new EventEmitter<string>();

    operators: {value: string, text: string}[] = [];

    constructor() { }

    ngOnInit(): void {
        this.operators = [
            {
                value: this.isExcluding ? 'NAND' : 'AND',
                text: this.isExcluding ? 'DATA_SET.CODIFICATION.SEARCH.EXCLUDING_AND' : 'DATA_SET.CODIFICATION.SEARCH.INCLUDING_AND'
            },
            {
                value: this.isExcluding ? 'NOR' : 'OR',
                text: this.isExcluding ? 'DATA_SET.CODIFICATION.SEARCH.EXCLUDING_OR' : 'DATA_SET.CODIFICATION.SEARCH.INCLUDING_OR'
            }
        ];
    }

}
