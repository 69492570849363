import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackBarService} from '../../../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {DiagnosticNoteService} from '../diagnostic-note.service';
import {DiagnosticNote} from '../diagnostic-note.model';

@Component({
    selector: 'ct-diagnostic-note-edit-dialog',
    templateUrl: './diagnostic-note-edit-dialog.component.html',
    styleUrls: ['./diagnostic-note-edit-dialog.component.scss']
})
export class DiagnosticNoteEditDialogComponent implements OnInit {
    private _diagnosticNoteService: DiagnosticNoteService;

    diagnosticNote: DiagnosticNote;
    isLoading = false;
    diagnosticNoteData: any = {
        name: '',
        diagnostics: [],
        text: '',
        file: null
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<DiagnosticNoteEditDialogComponent>,
                public dialog: MatDialog,
                private _snackBarService: SnackBarService,
                private _translateService: TranslateService) { }

    ngOnInit() {
        this._diagnosticNoteService = this.data.service;
        this.diagnosticNote = this.data.diagnosticNote;

        if (this.diagnosticNote) {
            this.diagnosticNoteData = {
                name: this.diagnosticNote.name,
                diagnostics: this.diagnosticNote.diagnostics.map(el => el.slug),
                text: this.diagnosticNote.text,
                file: null
            };
        }
    }

    private _buildFormData() {
        // Need a FormData because we have a file
        // otherwise it's not multipart
        const data = new FormData();
        data.append('name', this.diagnosticNoteData.name);
        if (this.diagnosticNoteData.file) {
            data.append('file', this.diagnosticNoteData.file);
        }
        if (this.diagnosticNoteData.text) {
            data.append('text', this.diagnosticNoteData.text);
        }

        if (this.diagnosticNoteData.diagnostics &&
            this.diagnosticNoteData.diagnostics.length > 0) {
            // If it's an edition and we don't update diagnostics then
            // this.diagnosticNoteData.diagnostics is only a list of slugs
            const diagnosticIds: any = typeof this.diagnosticNoteData.diagnostics[0] === 'object' ?
                this.diagnosticNoteData.diagnostics.map(el => el.id) :
                this.diagnosticNote.diagnostics.map(el => el.id);
            diagnosticIds.forEach((id, index) => {
                data.append(`diagnosticIds[${index}]`, id);
            });
        }
        return data;
    }

    async validate() {
        try {
            this.isLoading = true;
            let newDiagnosticNote;
            const formData = this._buildFormData();
            if (!this.diagnosticNote) {
                newDiagnosticNote = await this._diagnosticNoteService
                    .createDiagnosticNote(formData);
            } else {
                newDiagnosticNote = await this._diagnosticNoteService
                    .updateDiagnosticNote(this.diagnosticNote.id, formData);
            }
            this.isLoading = false;
            this._snackBarService.success(this._translateService.instant(!this.diagnosticNote ? 'SUCCESS.CREATION' : 'SUCCESS.UPDATE'));
            this.dialogRef.close({diagnosticNote: newDiagnosticNote, isUpdate: !!this.diagnosticNote});
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    getFileToUpload(file: File) {
        this.diagnosticNoteData.file = file;
    }
}
