<mat-toolbar class="mat-elevation-z5">
    <h4 class="h4">{{getTitle() || ''}}</h4>

    <!--Subscribe to alert button-->
    <button mat-icon-button
            *ngIf="canDisplaySubscribeToCurrentModuleButton()"
            [matTooltip]="'TOOLTIP.SUBSCRIBE' | translate"
            (click)="subscribeToAlert()">
        <mat-icon>add_alert</mat-icon>
    </button>

    <!--Unsubscribe from alert button-->
    <button mat-icon-button
            *ngIf="canDisplayUnsubscribeFromCurrentModuleButton()"
            [matTooltip]="'TOOLTIP.UNSUBSCRIBE' | translate"
            (click)="subscribeToAlert(true)">
        <mat-icon>unsubscribe</mat-icon>
    </button>

    <span class="spacer"></span>

    <button
        (click)="submitIssue()"
        class="btn-jira"
        mat-raised-button
        color="primary">
        {{ 'TOOLTIP.JIRA_COLLECTOR' | translate }}
        <mat-icon class="white-icon">contact_support</mat-icon>
    </button>

    <span class="spacer"></span>

    <img class="logo"
        [src]="'./assets/images/logo.png'"
        (click)="openVersionDisplayDialog($event)"/>

    <!--Generic call for refresh csv button-->
    <button mat-icon-button
            *ngIf="canDisplayOrbisButton"
            (click)="updateFile()">
        <mat-icon>play_for_work</mat-icon>
    </button>
    <!--Generic call for ML button-->
    <button mat-icon-button
            *ngIf="canDisplayMLGenericCall"
            (click)="goToMLGenericCallPage()">
        <mat-icon>link</mat-icon>
    </button>

    <!--Documentation button-->
    <button mat-icon-button
            *ngIf="canDisplayDocumentation"
            (click)="openDocumentationDialog()">
        <mat-icon>help</mat-icon>
    </button>

    <!-- Feedback button -->
    <button mat-icon-button
            class="margin-right-5"
            (click)="openFeedbackAddDialog($event)">
        <mat-icon>comment</mat-icon>
    </button>

    <!--Alert list button-->
    <button mat-icon-button
            class="alert-button"
            *ngIf="canDisplayAlertList"
            (click)="goToAlertList()">
        <mat-icon>warning</mat-icon>
    </button>

    <!-- More menu button -->
    <button mat-icon-button
            class="profile-button"
            [matMenuTriggerFor]="toolBarMenu">
        <img [src]="userPicture"
             class="user-picture"/>
    </button>

    <mat-menu #toolBarMenu="matMenu">
        <ng-template matMenuContent>
            <button mat-menu-item
                    (click)="goToEditPassword()">
                <mat-icon>vpn_key</mat-icon>
                <span>{{'TOOLBAR.UPDATE_PWD' | translate}}</span>
            </button>

            <button mat-menu-item
                    (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{'TOOLBAR.LOGOUT' | translate}}</span>
            </button>
        </ng-template>
    </mat-menu>
</mat-toolbar>
