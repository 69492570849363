import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailCodificationNearAutomationConfirmDialogComponent} from './stay-detail-codification-near-automation-confirm-dialog.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {GhmTagModule} from '../../../../../../shared/components/ghm-tag/ghm-tag.module';

@NgModule({
    declarations: [
        StayDetailCodificationNearAutomationConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        NgbTooltipModule,
        GhmTagModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [
        StayDetailCodificationNearAutomationConfirmDialogComponent
    ]
})
export class StayDetailCodificationNearAutomationConfirmDialogModule {}
