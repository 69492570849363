import {Component, Input} from '@angular/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {TranslateService} from '@ngx-translate/core';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {StateService} from '@uirouter/core';
import {StayDetailCodificationService} from '../codification/stay-detail-codification.service';

/**
 * Defines the SMR tabs' index.
 *
 * @var Object
 */
export const SmrTabs = {
    FirstWeek: 1,
    OnGoing: 2,
};

@Component({
    selector: 'ct-period-select',
    templateUrl: './period-select.component.html',
    styleUrls: ['period-select.component.scss'],
})

/**
 * Class PeriodSelectComponent
 *
 * Component used to switch to different period of an SSR week
 * or MCO RUM from the Stay Detail view.
 */
export class PeriodSelectComponent {

    @Input() public activeTabId: Number;
    @Input() public canDisplayRUM: Boolean;
    @Input() public dataSetElement: DataSetElement;
    @Input() public dataSetElementParent: DataSetElement;
    @Input() public isTypeExternal: Boolean;
    @Input() public isTypeRehabilitation: Boolean;
    @Input() public selectedSSRWeek: any;
    @Input() public SSRWeeks: any[];

    constructor(
        public $state: StateService,
        private _broadcastService: BroadcastService,
        private _dateHelperService: DateHelperService,
        private _stayDetailCodificationService: StayDetailCodificationService,
        private _translateService: TranslateService,
    ) {
    }

    public readonly String = String;

    /**
     * Return the dataset element's medical unit label.
     *
     * @param dataSetElement
     */
    public getMedicalUnitLabel(dataSetElement: DataSetElement): string | null {
        if (!this._hasMedicalUnitLabel(dataSetElement)) {
            return null;
        }

        return String(dataSetElement.dataSetContent.medicalUnit.name
            || dataSetElement.dataSetContent.medicalUnit.code
            || dataSetElement.dataSetContent.medicalUnit.id);
    }

    /**
     * Returns whether a dataset element has a medical unit.
     *
     * @param dataSetElement
     * @private
     */
    private _hasMedicalUnitLabel(dataSetElement: DataSetElement): boolean {
        return Boolean(
            dataSetElement?.dataSetContent?.medicalUnit
            && (dataSetElement.dataSetContent.medicalUnit.name
                || dataSetElement.dataSetContent.medicalUnit.code
                || dataSetElement.dataSetContent.medicalUnit.id
            ));
    }

    /**
     * Returns whether the rum includes tmp.
     *
     * @param rumId
     */
    public rumIdIncludesTmp(rumId: string | null): boolean {
        return rumId
            ? rumId.includes('tmp')
            : true;
    }

    /**
     * Returns a human readable date from raw value.
     *
     * @param date
     */
    public getDateToDisplay(date: string | null): string {
        if (!date) {
            return '';
        }

        const time = this._dateHelperService.getTime(date);

        return time
            ? `${this._dateHelperService.mysqlToFrench(date)} ${this._translateService.instant('STUFF.AT_LOWERCASE')} ${time}`
            : this._dateHelperService.mysqlToFrench(date);
    }

    /**
     * Switch to another SSR week based on the given direction.
     *
     * @param direction
     */
    goToPreviousOrNextSRRWeek(direction: string) {
        if (!this.SSRWeeks || !this.selectedSSRWeek) {
            return;
        }

        const indexOfCurrentWeek = this.SSRWeeks.findIndex((obj) => obj.value === this.selectedSSRWeek.value);
        const indexOfSelectedWeek = direction === 'previous'
            ? indexOfCurrentWeek - 1
            : indexOfCurrentWeek + 1;

        if (!this.SSRWeeks[indexOfSelectedWeek]) {
            return;
        }

        this.goToDataSetElement(this.SSRWeeks[indexOfSelectedWeek].value);
    }

    /**
     * Display the given Stay details in a new page or a modal.
     *
     * @param dataSetElementId
     */
    goToDataSetElement(dataSetElementId: number) {
        const isDialog = this.$state.params.isDialog === 'true';

        if (isDialog) {
            this.openStayModal(dataSetElementId);

            return;
        }

        const params = {
            ...this.$state.params,
            dataSetElementId,
            access: 'dataSetElementId'
        };

        if (this.dataSetElementParent?.children?.length) {
            params['isFirstSSRWeek'] = dataSetElementId === this.dataSetElementParent.children[0].id
                ? 'true'
                : 'false';
        }

        this.$state.go(this.$state.current.name, params);
    }

    /**
     * Display the given Stay details in a modal dialog.
     *
     * Closes the current Stay detail dialog and opens a new one based
     * on the provided dataSetElementId.
     * Enables seamless switching between Stay details.
     *
     * @param dataSetElementId
     */
    private openStayModal(dataSetElementId: number) {
        this._broadcastService.send('stayDetailInfo::reload', {dataSetElementId});
    }

    duplicateFirstSSRWeekCodification() {
        this._stayDetailCodificationService.openConfirmDialog(this.dataSetElement);
    }
}
