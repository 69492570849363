import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionDependencyComponent} from './search-engine-condition-dependency.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionRelationshipDependencyService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'relationshipDependency';
    placeholder = 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.RELATIONSHIP';
    data: {id: number, text: string, level: number}[] = [
        {id: 1, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.1', level: 1},
        {id: 2, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.2', level: 2},
        {id: 3, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.3', level: 3},
        {id: 4, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.4', level: 4}
    ];

    constructor(private _translateService: TranslateService) {
        super(['relationshipDependency'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.RELATIONSHIP'),
            'relationshipDependency',
            SearchEngineConditionDependencyComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {relationshipDependency: []};
        this.uriParams = {relationshipDependency: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.relationshipDependency) {
            this.uriParams.relationshipDependency = this.params.relationshipDependency.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.relationshipDependency) {
            this.params.relationshipDependency = this.uriParams.relationshipDependency.split(';')?.map(id => Number.parseFloat(id));
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.behaviourDependency = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.relationshipDependency};
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields['relationshipDependency']) &&
            !this.isEmpty(fields['relationshipDependency']));
    }
}
