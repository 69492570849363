<mat-form-field class="minimum">
    <input matInput
           type="number"
           step="any"
           min="0"
           [max]="condition.service.params.ghmIpDMSMax"
           [(ngModel)]="condition.service.params.ghmIpDMSMin"
           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MINIMUM' | translate">
</mat-form-field>

<mat-form-field>
    <input matInput
           type="number"
           step="any"
           [min]="condition.service.params.ghmIpDMSMin"
           [(ngModel)]="condition.service.params.ghmIpDMSMax"
           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MAXIMUM' | translate">
</mat-form-field>
