import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'ct-file-display-dialog',
    templateUrl: './file-display-dialog.component.html',
    styleUrls: ['./file-display-dialog.component.scss']
})
export class FileDisplayDialogComponent implements OnInit {
    src: string;
    filename: string;
    title: string;
    extension: string;
    displayDocumentData: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.src = this.data.src;
        this.title = this.data.title;
        this.filename = this.data.filename;
        this.extension = this.src.split('.').pop().toLowerCase();
        this.displayDocumentData = {src: this.src, filename: this.filename};
    }
}
