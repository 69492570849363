import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailDocumentComponent} from './stay-detail-document.component';
import {StayDetailJustificationModule} from '../justification/stay-detail-justification.module';
import {StayDetailDocumentElementComponent} from './element/stay-detail-document-element.component';
import {StayDetailDocumentContentTextComponent} from './content/text/stay-detail-document-content-text.component';
import {StayDetailDocumentContentStructuredComponent} from './content/structured/stay-detail-document-content-structured.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '../../../../shared/pipes/pipes.module';
import {FileDisplayModule} from '../../../../shared/components/file-display/file-display.module';
import {FileDisplayButtonModule} from '../../../../shared/components/file-display/button/file-display-button.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {StayDetailCollapseButtonModule} from '../shared/stay-detail-collapse-button/stay-detail-collapse-button.module';
import {FeatureToggleDirectiveModule} from '../../../../shared/directives/feature-toggle/feature-toggle.directive.module';
import {StayDetailDocumentListModule} from './list/stay-detail-document-list.module';

@NgModule({
    declarations: [
        StayDetailDocumentComponent,
        StayDetailDocumentElementComponent,
        StayDetailDocumentContentTextComponent,
        StayDetailDocumentContentStructuredComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),
        NgxDatatableModule,
        StayDetailJustificationModule,
        StayDetailDocumentListModule,
        StayDetailCollapseButtonModule,
        PipesModule,
        FileDisplayModule,
        FileDisplayButtonModule,
        FeatureToggleDirectiveModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatDividerModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatMenuModule
    ],
    exports: [
        StayDetailDocumentComponent,
        StayDetailDocumentElementComponent,
        StayDetailDocumentContentTextComponent,
        StayDetailDocumentContentStructuredComponent,
        StayDetailDocumentListModule
    ],
})
export class StayDetailDocumentModule {}
