import {LoginComponent} from './login/login.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {AuthenticationComponent} from './authentication.component';
import {NeedAccessRightsPageComponent} from './need-access-rights-page/need-access-rights-page.component';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {StateService} from '@uirouter/core';

export const authenticationStates: Ng2StateDeclaration[] = [
    {
        name: 'authentication',
        abstract: true,
        component: AuthenticationComponent
    },
    {
        name: 'authentication.login',
        url  : '/login',
        component: LoginComponent
    },
    {
        name: 'authentication.forgot-password',
        url  : '/forgot-password',
        component: ForgotPasswordComponent
    },
    {
        name: 'authentication.reset-password',
        url: '/reset-password/:token',
        component: ResetPasswordComponent
    },
    {
        name: 'authentication.need-access-rights',
        url: '/need-access-rights',
        component: NeedAccessRightsPageComponent,
        onExit: function(transition: Transition) {
            // If the current user has no role then we redirect to the need access rights page
            const service = transition.injector().get(AuthenticationService);
            const stateService = transition.injector().get(StateService);
            if (service &&
                stateService &&
                service.hasNoRole()) {
                stateService.go('authentication.need-access-rights');
            }
        }
    }
];

