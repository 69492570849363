import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../../http/http.service';

@Injectable({
    providedIn: 'root'
})


export class CodificationGroupLabelCodificationLabelApiService {
    private _BASE_URL = 'codification-group-label';

    constructor(private _http: HttpService) {}

    getAll(codificationGroupLabelId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${codificationGroupLabelId}/codification-label`, {params: urlRequestParams})
            .pipe(map((res: any) => res.data));
    }
}
