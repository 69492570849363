import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    private _SNACKBAR_DEFAULT_CONFIG: MatSnackBarConfig = {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 7000
    };

    constructor(private _snackBar: MatSnackBar,
                private _translateService: TranslateService) { }

    /**
     * Display a green success snackbar
     * @param message
     */
    success(message: string) {
        const config: MatSnackBarConfig = {
            ...this._SNACKBAR_DEFAULT_CONFIG,
            panelClass: 'success'
        };
        this._snackBar.open(message,
            this._translateService.instant('BUTTON.CLOSE'),
            config);
    }

    /**
     * Display a red error snackbar
     * @param message
     */
    error(message: string) {
        const config: MatSnackBarConfig = {
            ...this._SNACKBAR_DEFAULT_CONFIG,
            panelClass: 'error'
        };
        this._snackBar.open(message,
            this._translateService.instant('BUTTON.CLOSE'),
            config);
    }
}
