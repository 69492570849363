import {Component, Input, OnInit} from '@angular/core';
import {StayDetailCodificationHistoryService} from './stay-detail-codification-history.service';
import {DateHelperService} from '../../../../../core/services/date.helper.service';
import {TranslateService} from '@ngx-translate/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {StringHelperService} from '../../../../../core/services/string.helper.service';
import {BroadcastService} from '../../../../../core/services/broadcast.service';

@Component({
    selector: 'ct-stay-detail-codification-history',
    templateUrl: './stay-detail-codification-history.component.html',
    styleUrls: ['./stay-detail-codification-history.component.scss']
})
export class StayDetailCodificationHistoryComponent implements OnInit {
    @Input() dataSetElement: DataSetElement;

    history: any[] = [];
    isTypeRehabilitation: boolean;

    constructor(private _stayDetailCodificationHistoryService: StayDetailCodificationHistoryService,
                private _translateService: TranslateService,
                private _dataSetElementService: DataSetElementService,
                private _dateHelperService: DateHelperService,
                private _broadcastService: BroadcastService) {
    }

    ngOnInit(): void {
        this.isTypeRehabilitation = this._dataSetElementService.isType(this.dataSetElement, 'rehabilitation');
        this._loadCodificationHistory();
    }

    private async _loadCodificationHistory(): Promise<void> {
        try {
            if (this.dataSetElement) {
                const res = await this._stayDetailCodificationHistoryService
                    .loadCodificationHistory(this.dataSetElement.id);
                this._broadcastService.send('dataSetElement:codification:history', res);
                if (res) {
                    this.history = [...res.codification, ...res.confirmation.map(c => {
                        return {
                            ...c,
                            data: {
                                ...c.data,
                                action: 'confirmation'
                            },
                            type: 'confirmation'
                        };
                    })];
                    this.history = this._stayDetailCodificationHistoryService.mapHistoryGroupingType(this.history);
                    this.sortedHistoryByCreatedDate();
                }

            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    getUserName(element: any): string {
        if (element) {
            return `${StringHelperService.capitalizeFirstLetterOfEachWord(element.firstName)} ${StringHelperService.capitalizeFirstLetterOfEachWord(element.lastName)}`;
        }
        return '';
    }

    getTranslationFromAction(element: any): string {
        return element ? this._stayDetailCodificationHistoryService.getTranslationFromAction(element.data?.action, element.type) : '';
    }

    getDisplayDate(date: string): string {
        return date ? `${this._dateHelperService.mysqlToFrench(date)} ${this._translateService.instant('STUFF.AT_LOWERCASE')} ${this._dateHelperService.getTime(date)}` : '';
    }

    getDisplayStatus(status: number): string {
        switch (status) {
            case 1:
                return `${this._translateService.instant('STAY.STATUS_PRICING_IMPACT')}`;
            case 2:
                return `${this._translateService.instant('STAY.STATUS_VERIFIED')}`;
            case 3:
                return `${this._translateService.instant('STAY.STATUS_WAITING')}`;
            case 4:
                return `${this._translateService.instant('STAY.STATUS_PRIMO_CODAGE')}`;
            case 5:
                return `${this._translateService.instant('STAY.STATUS_NO_PRICING_IMPACT')}`;
            default:
                return `${this._translateService.instant('STAY.STATUS_UNCHECKED')}`;
        }
    }

    getHealthGhm(element: any): {} {
        if (element?.slug?.ghm) {
            element.slug = element.slug.ghm;
        }
        return element;
    }

    sortedHistoryByCreatedDate(): void {
        this.history = this.history.sort((h1, h2) => new Date(h2.createdAt).valueOf() - new Date(h1.createdAt).valueOf());
    }
}
