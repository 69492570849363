<div *ngIf="active"
     class="layout-fill align-center-center login-content">
    <form *ngIf="loginForm"
          [formGroup]="loginForm">
        <mat-card class="login-card">
            <mat-card-header>
                <mat-card-title class="login-card-title">
                    <span class="mat-headline">{{'AUTHENTICATION.TITLE' | translate}}</span>
                </mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <ng-container *ngIf="!loading">
                    <div class="error-message align-center-center"
                         *ngIf="error">
                        <span id="errorMessage">{{errorMessage}}</span>
                    </div>

                    <div class="form-field-container">
                        <mat-icon class="mat-icon-prefix"
                                  [ngClass]="{'input-focused': isEmailInputFocused &&
                               !formControls['email'].errors &&
                                !error}">person</mat-icon>
                        <mat-form-field [ngClass]="{'form-field-invalid': error}">
                            <input matInput
                                   required
                                   type="email"
                                   formControlName="email"
                                   [placeholder]="'INPUT.EMAIL' | translate"
                                   (focus)="this.isEmailInputFocused = !this.isEmailInputFocused"
                                   (blur)="onEmailInputBlur()">
                            <mat-error *ngFor="let validationError of validationErrors.email">
                                <ng-container *ngIf="formControls['email'].hasError(validationError.type)">
                                    {{validationError.message}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-field-container">
                        <mat-icon class="mat-icon-prefix"
                                  [ngClass]="{'input-focused': isPasswordInputFocused &&
                               !formControls['password'].errors &&
                                !error}">lock</mat-icon>
                        <mat-form-field [ngClass]="{'form-field-invalid': error}">
                            <input matInput
                                   required
                                   type="password"
                                   formControlName="password"
                                   [placeholder]="'INPUT.PASSWORD' | translate"
                                   (focus)="isPasswordInputFocused = !isPasswordInputFocused"
                                   (blur)="isPasswordInputFocused = !isPasswordInputFocused">

                            <mat-error *ngFor="let validationError of validationErrors.password">
                                <ng-container *ngIf="formControls['password'].hasError(validationError.type)">
                                    {{validationError.message}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="hasMailServer"
                         class="align-center-center">
                        <a class="forgot-password"
                           (click)="goToForgotPassword()">
                            {{'AUTHENTICATION.FORGOT_PASSWORD' | translate}}
                        </a>
                    </div>
                </ng-container>

                <!--Loading spinner-->
                <div class="align-center-center">
                    <mat-progress-spinner *ngIf="loading"
                                          mode="indeterminate"
                                          [diameter]="170">
                    </mat-progress-spinner>
                </div>
            </mat-card-content>

            <mat-card-actions *ngIf="!loading">
                <button mat-raised-button
                        type="submit"
                        color="primary"
                        [disabled]="isUserDenied || !loginForm.valid"
                        (click)="login()">
                    {{('BUTTON.CONNECTION' | translate) | uppercase}}
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>

