import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionDpPredictiveGoldSilverComponent} from './search-engine-condition-dp-predictive-gold-silver.component';
import {ApiCriteria, SearchEngineCondition} from '../../../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDpPredictiveGoldService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_DP_GOLD'),
            'dpPredictiveGold',
            SearchEngineConditionDpPredictiveGoldSilverComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            primaryDiagnosticMaxSimplifiedScore: '40;='
        };
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.primaryDiagnosticMaxSimplifiedScore = '40;=';
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.type = 'primaryDiagnosticMaxSimplifiedScore';
        this.apiParams.args = {minValue: 40, operator: '='};
    }
}
