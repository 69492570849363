<div>
    <ng-container *ngIf="isMCOUseCase">
        <mat-checkbox class="margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.DP"
                      (change)="updateDiagnosticCodificationLabelParam('DP')">
            <span>{{'ACRONYM.DP' | translate}}</span>
        </mat-checkbox>

        <mat-checkbox class="margin-right-10"
                      *ngIf="canDisplayDR"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.DR"
                      (change)="updateDiagnosticCodificationLabelParam('DR')">
            <span>{{'ACRONYM.DR' | translate}}</span>
        </mat-checkbox>
    </ng-container>

    <ng-container *ngIf="isSSRUseCase">

        <mat-checkbox class="margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.MP"
                      (change)="updateDiagnosticCodificationLabelParam('MP')">
            <span>{{'ACRONYM.MP' | translate}}</span>
        </mat-checkbox>

        <mat-checkbox class="margin-right-10"
                      [color]="colorService.getColorAccordingToCurrentState()"
                      [(ngModel)]="canUseCodificationLabels.AE"
                      (change)="updateDiagnosticCodificationLabelParam('AE')">
            <span>{{'ACRONYM.AE' | translate}}</span>
        </mat-checkbox>
    </ng-container>

    <mat-checkbox class="margin-right-10"
                  [color]="colorService.getColorAccordingToCurrentState()"
                  [(ngModel)]="canUseCodificationLabels.DA"
                  (change)="updateDiagnosticCodificationLabelParam('DA')">
        <span>{{'ACRONYM.DA' | translate}}</span>
    </mat-checkbox>

    <ct-search-including-excluding-operator class="margin-right-10"
                                  *ngIf="canDisplayConditionSelect()"
                                  [(param)]="condition.service.params.diagnosticOperator"></ct-search-including-excluding-operator>
</div>

<ct-diagnostic-search [(param)]="condition.service.params.diagnosticSlug"></ct-diagnostic-search>
