import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {StayDetailDocumentListComponent} from './stay-detail-document-list.component';
import {DocumentTypeDocumentListDisplayButtonModule} from '../../../../../shared/components/document-type-document-list-display/button/document-type-document-list-display-button.module';

@NgModule({
    declarations: [
        StayDetailDocumentListComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        DocumentTypeDocumentListDisplayButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatMenuModule
    ],
    exports: [
        StayDetailDocumentListComponent
    ],
})
export class StayDetailDocumentListModule {}
