import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {HighlightComponent} from './highlight.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        HighlightComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        HighlightComponent
    ]
})
export class HighlightModule {}
