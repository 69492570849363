import * as moment from 'moment';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

export type LogType = 'Error' | 'Warning' | 'Information';

export interface LogFields {
    environment: string;
    userId: number;
    clientName: string;
    url: string;
    requestPath?: string;
    elapsedTime?: number;
    revaluation?: number;
}

interface LogEntry {
    type: LogType;
    message: string;
    data: LogFields;
}

enum LoggerEvents {
    Flush = 1
}

export class Logger {
    private readonly _ENV_FIELD = 'environment';
    private readonly _USER_ID_FIELD = 'userId';
    private readonly _CLIENT_NAME_FIELD = 'clientName';
    private readonly _ELAPSED_MS_FIELD = 'elapsedMilliseconds';
    private readonly _REQUEST_PATH_FIELD = 'requestPath';
    private readonly _URL_FIELD = 'url';
    private readonly _REVALUATION_FIELD = 'revaluation';

    private _logEntry: LogEntry;
    private _logListener = new Subject<LoggerEvents>();
    private _logEndpoint: string;

    constructor(logEndpoint: string) {
        this._logEndpoint = logEndpoint || '';
        this._logListener
            .pipe(filter(event => event === LoggerEvents.Flush))
            .subscribe(() => this._sendLog());
    }

    get logEndpoint(): string {
        return this._logEndpoint;
    }

    set logEndpoint(value: string) {
        this._logEndpoint = value;
    }

    private _sendLog() {
        if (!this._logEntry) {
            return;
        }

        if (environment.production) {
            const body = this._buildBody(this._logEntry);
            const req = new XMLHttpRequest();
            // tslint:disable-next-line:no-console
            req.open('POST', this._logEndpoint, true);
            req.send(body);
        }
    }

    private _buildBody(entry: LogEntry) {
        const { type, message, data } = entry;
        const level = type;
        const date = moment();
        const body = this._getFields(data);
        body['level'] = level;
        body['message'] = message;
        body['@timestamp'] = date.toISOString();
        return JSON.stringify(body);
    }

    private _getFields(data: LogFields) {
        return {
            [this._ENV_FIELD]: data.environment,
            [this._USER_ID_FIELD]: data.userId,
            [this._CLIENT_NAME_FIELD]: data.clientName,
            [this._ELAPSED_MS_FIELD]: data.elapsedTime,
            [this._REQUEST_PATH_FIELD]: data.requestPath,
            [this._URL_FIELD]: data.url,
            [this._REVALUATION_FIELD]: data.revaluation
        };
    }

    log(type: LogType, message: string, data: LogFields) {
        this._logEntry = {
            type,
            message,
            data
        };
        this._logListener.next(LoggerEvents.Flush);
    }
}
