import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionHealthMovementComponent} from './search-engine-condition-health-movement.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionHealthMovementEntranceService extends SearchEngineCondition {
    paramKey = 'movementEntranceId';
    placeholder = 'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_ENTRANCE';
    useCase = 'entrance';

    constructor(private _translateService: TranslateService) {
        super(['movementEntranceId'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_ENTRANCE'),
            'movementEntranceId',
            SearchEngineConditionHealthMovementComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {movementEntranceId: []};
        this.uriParams = {movementEntranceId: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.movementEntranceId) {
            this.uriParams.movementEntranceId = this.params.movementEntranceId.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.movementEntranceId) {
            this.params.movementEntranceId = this.uriParams.movementEntranceId.split(';')?.map(id => Number.parseFloat(id));
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.movementEntranceId = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.movementEntranceId};
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields['movementEntranceId']) &&
            !this.isEmpty(fields['movementEntranceId']));
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
