import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DiagnosticNoteEditDialogComponent} from './diagnostic-note-edit-dialog.component';
import {UploadFileButtonModule} from '../../../../shared/components/upload-file-button/upload-file-button.module';
import {MatButtonModule} from '@angular/material/button';
import {DiagnosticSearchModule} from '../../../../shared/components/diagnostic-search/diagnostic-search.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        DiagnosticNoteEditDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),
        UploadFileButtonModule,
        DiagnosticSearchModule,
        MatDialogModule,
        MatProgressBarModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule
    ]
})
export class DiagnosticNoteEditDialogModule {}
