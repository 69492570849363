import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import * as _ from 'lodash-es';
import {StayDetailDiagnosticPredictionListService} from './stay-detail-diagnostic-prediction-list.service';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {AppLogService} from '../../../../../core/app-log/app-log.service';
import {SearchEngineAdvancedService} from '../../../../search-engine/advanced/search-engine-advanced.service';
import {CodificationLabelsModel} from '../../../shared/codification-labels.model';

@Component({
    selector: 'ct-stay-detail-diagnostic-prediction-list',
    templateUrl: './stay-detail-diagnostic-prediction-list.component.html',
    styleUrls: ['./stay-detail-diagnostic-prediction-list.component.scss']
})
export class StayDetailDiagnosticPredictionListComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];
    private _canDisplayDiagnosticRoot: boolean;
    private _pDiagTypeDisplay: string;
    private _aDiagTypeDisplay: string;
    private _isReady: boolean;
    private _changeLimitDisplay: boolean;
    private _canDisplayAllStayPredictionsForNearAutomation: boolean;

    @Input() dataSetElement: DataSetElement;
    @Input() codificationLabels: CodificationLabelsModel;
    @Input() codificationLabelIds: number[];
    @Input() diagnostics: any[];
    @Input() predictiveDiagnostics: any[];
    @Input() orderBy: any;
    @Input() canDisplayPredictionListMenu = true;
    @Input() predictionListMaxHeight = '350px';
    @Input() predictionListMenuStyle: any;
    @Input() isNearAutomationQualityControl: boolean;
    @Input() isTypeRehabilitation: boolean;
    @Input() displayOnlySimplifiedScore: boolean;
    @Input() canDisplayTooltip: boolean;
    @Input() filtersSearch: any;

    @Output() firstPredictiveDiagnosticLoaded: EventEmitter<any> = new EventEmitter<any>();

    isMixedDisplayActivated: boolean;
    isFilterSearchSelected: boolean;
    isFilterSearchUseCase: boolean;
    isFilterSearchPredictionList: boolean;
    expandChildren = [];
    predictiveDiagnosticsToDisplay: any[];
    predictiveDiagnosticsToDisplayHaveChildren: boolean;
    menuOptions = {
        orderBy: {value: [], active: false},
        groupByParent: {value: false, active: false},
        display: {value: true, active: false},
        forceDisplay: {value: false, active: false}
    };

    constructor(public $state: StateService,
                public _predictionListService: StayDetailDiagnosticPredictionListService,
                private _broadcastService: BroadcastService,
                private _configurationService: ConfigurationService,
                private _logService: AppLogService,
                private _searchEngineAdvancedService: SearchEngineAdvancedService) { }

    ngOnInit() {
        this.isFilterSearchPredictionList = this.codificationLabelIds.includes(-1);
        this._initConfig();
        this._initMenuOptions();
        this.isFilterSearchSelected = this.$state.params.forFilterSearch === 'true';
        this.isFilterSearchUseCase = this.isFilterSearchSelected || this.$state.params.fromFilterSearchList === 'true' || this.$state.params.fromQualityControl === 'true';
        this.predictiveDiagnosticsToDisplay = this._getUpdatedPredictiveDiagnostics();
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'predictiveDiagnostic::updateReferencialPrice':
                    case 'diagnosticPredictionListMenu::changeOption':
                        this.predictiveDiagnosticsToDisplay = this._getUpdatedPredictiveDiagnostics();
                        break;
                    case 'dataSetElement::updateIsReady':
                        this._updateMenuDisplayOption();
                        this.predictiveDiagnosticsToDisplay = this._getUpdatedPredictiveDiagnostics();
                        break;
                    case 'stayDetailFilterSearch::reload':
                        if (this.isFilterSearchPredictionList && res.data) {
                            this.filtersSearch = res.data.filtersSearch;
                            this.predictiveDiagnosticsToDisplay = this._getUpdatedPredictiveDiagnostics();
                            this.isFilterSearchSelected = !res.data.isSelected;
                        }
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    getDiagnosisMissingSlugFilter(filter: any) {
        return this._searchEngineAdvancedService.filterSearchQueryGetCondition(
            'diagnosisMissing', filter?.params?.query?.args?.criteria
        );
    }
    hasDiagnosisMissingSlugFilter(filter: any) {
        return !!this._searchEngineAdvancedService.filterSearchQueryHasCondition(
            'diagnosisMissing', filter?.params?.query?.args?.criteria
        );
    }
    get hasDiagnosisMissingSlug(): boolean {
        return !!this._searchEngineAdvancedService.filterSearchQueryHasCondition(
            'diagnosisMissing',
        );
    }

    private get _getDiagnosisMissing(): any {
        return this._searchEngineAdvancedService.filterSearchQueryGetCondition(
            'diagnosisMissing',
        );
    }

    get hasActPresenceSlug(): boolean {
        return !!this._searchEngineAdvancedService.filterSearchQueryHasCondition(
            'actPresence',
        );
    }
    private _initMenuOptions() {
        this.menuOptions.orderBy.value = this.orderBy;
        this.menuOptions.orderBy.active = true;
        this._updateMenuDisplayOption();
        this.menuOptions.groupByParent.value = this._setGroupByParent();
        this.menuOptions.groupByParent.active = !this.isMixedDisplayActivated && this._canDisplayDiagnosticRoot;
        this.menuOptions.forceDisplay.value = this.isMixedDisplayActivated;
        this.menuOptions.forceDisplay.active = this._changeLimitDisplay;
    }

    private _initConfig() {
        this._isReady = this._configurationService.getConfigurationContent('front', 'dataSetElement.isReady');
        this._canDisplayDiagnosticRoot = this._configurationService.getConfigurationContent('front', 'diagnostic.diagnosticRoot');
        this._changeLimitDisplay = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.option.changeLimitDisplay');
        this._pDiagTypeDisplay = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.typeDisplay');
        this._aDiagTypeDisplay = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.typeDisplay');
        this.isMixedDisplayActivated = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.isMixedDisplayActivated');
        this._canDisplayAllStayPredictionsForNearAutomation = this._configurationService.getConfigurationContent('front', 'nearAutomation.displayAllStayPredictions');
    }

    private _updateMenuDisplayOption() {
        this.menuOptions.display.value = !this._isReady || this.dataSetElement.isReady;
        this.menuOptions.display.active = this._isReady && !this.dataSetElement.isReady;
    }

    /**
     * Set groupByParent menu option value
     * @private
     */
    private _setGroupByParent() {
        if (this.codificationLabelIds.some(el => [this.codificationLabels.DP, this.codificationLabels.DP_ROOT].includes(el))) {
            return this._canDisplayDiagnosticRoot &&
                this._pDiagTypeDisplay === 'diagnosticRoot';
        } else if (!this.isFilterSearchPredictionList &&
            this.codificationLabelIds.some(el => [this.codificationLabels.DA, this.codificationLabels.DA_ROOT].includes(el))) {
            return this._canDisplayDiagnosticRoot &&
                this._aDiagTypeDisplay === 'diagnosticRoot';
        }
        return false;
    }

    private _filterAccordingToDiagnosticSlugs(tmpPredictiveDiagnostics: any[]) {
        if (this.isFilterSearchPredictionList) {
            if (this.filtersSearch && this.hasDiagnosisMissingSlugFilter(this.filtersSearch)) {
                tmpPredictiveDiagnostics = this._predictionListService.limitToDiagnostics(tmpPredictiveDiagnostics, this.getDiagnosisMissingSlugFilter(this.filtersSearch)?.args?.slugs);
            } else {
                tmpPredictiveDiagnostics = [];
            }
        }
        return tmpPredictiveDiagnostics;
    }

    /**
     * Main process here
     * @param menuOptions
     * @param forHasMorePredictions
     * Need to pass menuOptions as param because we don"t use this.menuOptions in hasMorePredictions method
     * @private
     */
    private _getUpdatedPredictiveDiagnostics(menuOptions: any = this.menuOptions, forHasMorePredictions: boolean = false) {
        if (this.codificationLabelIds) {
            if (this.isFilterSearchPredictionList &&
                this.isFilterSearchUseCase &&
                !this.isFilterSearchSelected) {
                return [];
            } else {
                let tmpPredictiveDiagnostics: any[];
                if (menuOptions.groupByParent.value) {
                    tmpPredictiveDiagnostics = this._predictionListService.filterByCodificationLabelId(_.cloneDeep(this.predictiveDiagnostics), this.codificationLabelIds);

                    tmpPredictiveDiagnostics = this._filterAccordingToDiagnosticSlugs(tmpPredictiveDiagnostics);

                    tmpPredictiveDiagnostics = this._predictionListService.fusionPredictiveDiagnostics(tmpPredictiveDiagnostics);

                    tmpPredictiveDiagnostics = this._predictionListService.treeify(tmpPredictiveDiagnostics);

                    this._predictionListService.applyChildrenScore(tmpPredictiveDiagnostics);
                } else {
                    const codificationLabelIds = this.isMixedDisplayActivated ? this.codificationLabelIds : this._predictionListService.getChildrenCodificationLabelIds(this.codificationLabelIds);
                    tmpPredictiveDiagnostics = this._predictionListService
                        .filterByCodificationLabelId(_.cloneDeep(this.predictiveDiagnostics), codificationLabelIds);
                    tmpPredictiveDiagnostics = this._filterAccordingToDiagnosticSlugs(tmpPredictiveDiagnostics);

                    tmpPredictiveDiagnostics = this._predictionListService.fusionPredictiveDiagnostics(tmpPredictiveDiagnostics);
                }

                const canApplyScoreAndLimitFilters = this.codificationLabelIds.some(id => [this.codificationLabels.DP, this.codificationLabels.DA, this.codificationLabels.FP, this.codificationLabels.MP, this.codificationLabels.AE].includes(id));
                const isDPTab = this.codificationLabelIds.some(id => [this.codificationLabels.DP, this.codificationLabels.DP_ROOT].includes(id));
                if (!this.isFilterSearchPredictionList) {
                    // If diagnostic.prediction.isMixedDisplayActivated and only for AI predictions (not the one displayed in the filterSearch box)
                    if (this.isMixedDisplayActivated) {
                        tmpPredictiveDiagnostics = this._predictionListService.treeify(tmpPredictiveDiagnostics);
                        tmpPredictiveDiagnostics = this._predictionListService.filterExactAndRootDiagnostics(tmpPredictiveDiagnostics);
                    } else if (canApplyScoreAndLimitFilters) {
                        const predictiveDiagnosticSlugsToInclude = this._getDiagnosisMissing?.args?.slugs ?? [];
                        tmpPredictiveDiagnostics =
                            this._predictionListService.filterByScore(tmpPredictiveDiagnostics, predictiveDiagnosticSlugsToInclude, menuOptions, this.codificationLabelIds);
                    }
                } else if (!this.isFilterSearchUseCase && this.hasDiagnosisMissingSlug) {
                    // If we have param diagnosticMissingSlug then we display only these DA
                     tmpPredictiveDiagnostics = this._predictionListService
                         .filterByDiagnosticMissingSlug(tmpPredictiveDiagnostics, this._getDiagnosisMissing?.args?.slugs);
                }

                if (canApplyScoreAndLimitFilters && !this.isFilterSearchPredictionList) {
                    // Need to do it last
                    this._predictionListService.limitList(tmpPredictiveDiagnostics, menuOptions, this.codificationLabelIds);
                }
                if (!forHasMorePredictions &&
                    (this.isMixedDisplayActivated || menuOptions.groupByParent.value)) {
                    this.predictiveDiagnosticsToDisplayHaveChildren = tmpPredictiveDiagnostics ? tmpPredictiveDiagnostics.some(el => el.children && el.children.length) : false;
                }

                return tmpPredictiveDiagnostics;
            }
        }
        return [];
    }

    /**
     * To determine if we display the load more predictions button
     */
    hasMorePredictions() {
        if (this.predictiveDiagnosticsToDisplay) {
            const menuOptions = _.cloneDeep(this.menuOptions);
            menuOptions.forceDisplay.value = !this.menuOptions.forceDisplay.value;
            return menuOptions.forceDisplay.active && this._getUpdatedPredictiveDiagnostics(menuOptions, true).length !== this.predictiveDiagnosticsToDisplay.length;
        }
        return false;
    }

    orderPredictiveDiagnosticsToDisplay(dataToOrder: any[]) {
        const values = this.menuOptions.orderBy.value;
        const orderBy = [];
        const order = [];
        if (values.length > 0) {
            values.forEach((value: string) => {
                // For descending order the string begins with -
                if (value.substring(0, 1) === '-') {
                    orderBy.push(value.substr(1));
                    order.push('desc');
                } else {
                    orderBy.push(value);
                    order.push('asc');
                }
            });
        }
        return _.orderBy(dataToOrder, orderBy, order);
    }

    /**
     * Expand children div when groupByParent is activated
     * @param predictiveDiagnostic
     * @param index
     */
    expand(predictiveDiagnostic: any, index: number) {
        if (predictiveDiagnostic &&
            predictiveDiagnostic.children &&
            predictiveDiagnostic.children.length > 0) {
            this.expandChildren[index] = !this.expandChildren[index];
        }
    }

    updateForceDisplay() {
        if (this.menuOptions &&
            this.menuOptions.forceDisplay) {
            // Need to do this to update object reference
            this.menuOptions.forceDisplay = {
                ...this.menuOptions.forceDisplay,
                value: !this.menuOptions.forceDisplay.value
            };
            this.predictiveDiagnosticsToDisplay = this._getUpdatedPredictiveDiagnostics();

            if (this.codificationLabelIds) {
                if (this.codificationLabelIds.includes(this.codificationLabels.DP)) {
                    this._logService.logInfo(`See ${!this.menuOptions.forceDisplay.value ? 'less' : 'more'} DP predictions`);
                } else if (this.codificationLabelIds.includes(this.codificationLabels.DA)) {
                    this._logService.logInfo(`See ${!this.menuOptions.forceDisplay.value ? 'less' : 'more'} DA predictions`);
                }
            }
        }
    }

    canDisplayGroupParentStyle() {
        return this.isMixedDisplayActivated || this.menuOptions.groupByParent.value;
    }

    removePredictiveDiagnostic(event: {predictiveDiagnosticId: number}) {
        this.predictiveDiagnosticsToDisplay = this.predictiveDiagnosticsToDisplay.filter((diag) => diag.id !== event.predictiveDiagnosticId);
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
