import {Component, Input, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../../modules/configuration/configuration.service';
import {ColumnMode} from '@swimlane/ngx-datatable';

@Component({
    selector: 'ct-document-highlight-preview-structured',
    templateUrl: './document-highlight-preview-structured.component.html',
    styleUrls: ['./document-highlight-preview-structured.component.scss']
})
export class DocumentHighlightPreviewStructuredComponent implements OnInit {
    private _highlightData = {
        documentTypeId: 1,
        valueColumnSlug: '',
        highThresholdColumnSlug: '',
        lowThresholdColumnSlug: '',
    };

    @Input() documentTypeId: number;
    @Input() highlight: any;

    columnModeForce: ColumnMode = ColumnMode.force;

    constructor(private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this._initConfig();
    }

    private _initConfig() {
        this._highlightData.documentTypeId = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.documentTypeId');
        this._highlightData.valueColumnSlug = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.valueColumnSlug');
        this._highlightData.highThresholdColumnSlug = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.highThresholdColumnSlug');
        this._highlightData.lowThresholdColumnSlug = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.lowThresholdColumnSlug');
    }

    // Has to be an arrow function otherwise this is not the class context
    getRowClass = (row: any) => {
        if (row &&
            this._highlightData &&
            this.documentTypeId) {
            if (this._highlightData.documentTypeId === this.documentTypeId) {
                const value = row[this._highlightData.valueColumnSlug];
                const displayHighlight = value < row[this._highlightData.lowThresholdColumnSlug] || value > row[this._highlightData.highThresholdColumnSlug];
                return {
                    'highlight-red': displayHighlight
                };
            }
            return '';
        }
        return '';
    }

}
