import {Component, Input, OnInit} from '@angular/core';
import {MedicalUnitService} from '../../../health/medical-unit/medical-unit.service';
import {MedicalUnit} from '../../../health/medical-unit/medical-unit.model';

@Component({
    selector: 'ct-search-engine-condition-medical-unit',
    templateUrl: './search-engine-condition-medical-unit.component.html',
    styleUrls: ['./search-engine-condition-medical-unit.component.scss']
})
export class SearchEngineConditionMedicalUnitComponent implements OnInit {
    @Input() condition: any;

    medicalUnits: MedicalUnit[] = [];
    isLoading: boolean;
    toggleAllMapCallback = (element: any) => element?.id;

    constructor(private _medicalUnitService: MedicalUnitService) { }

    ngOnInit() {
        this._loadAllMedicalUnits();
    }

    private _mapToGenericData(medicalUnits: MedicalUnit[]): any[] {
        if (medicalUnits) {
            return medicalUnits.map(medicalUnit => {
                const text = `${medicalUnit.code === medicalUnit.name ? '' : medicalUnit.code + ' - '}${medicalUnit.name}`;
                const value = this.toggleAllMapCallback(medicalUnit);
                return {...medicalUnit, text, value};
            });
        }
        return [];
    }

    private async _loadAllMedicalUnits(): Promise<void> {
        try {
            this.isLoading = true;
            const medicalUnits = await this._medicalUnitService.loadAllMedicalUnits();
            this.medicalUnits = this._mapToGenericData(medicalUnits);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    compareWithCallback(objectFromOption: any, objectFromParam: any): boolean {
        return objectFromOption?.id === objectFromParam?.id;
    }
}
