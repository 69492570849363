import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {DataSetElement} from '../../../../shared/data-set-element.model';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from '../../../../../../core/services/translation.helper.service';
import {LayoutService} from '../../../../../layout/layout.service';
import {BroadcastService} from '../../../../../../core/services/broadcast.service';
import {SnackBarService} from '../../../../../../core/services/snack-bar.service';
import {DataSetElementService} from '../../../../shared/data-set-element.service';
import * as _ from 'lodash-es';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StayDetailEditActExecutionDateDialogComponent} from '../edit-act-date-dialog/stay-detail-edit-act-execution-date-dialog.component';
import {DateHelperService, DateComparisonLevel} from '../../../../../../core/services/date.helper.service';

@Component({
    selector: 'ct-stay-detail-codification-act-edition',
    templateUrl: './stay-detail-codification-act-edition.component.html',
    styleUrls: ['./stay-detail-codification-act-edition.component.scss']
})


export class StayDetailCodificationActEditionComponent implements OnInit, OnChanges, OnDestroy {
    private _subscriptions: Subscription[] = [];
    private _originalDataSetContentLinkActs: any;
    private _actsToAdd: any = {};
    private _actsToDelete: any = {
        classing: {},
        other: {}
    };

    @Input() dataSetElement: DataSetElement;
    @Input() autoCompletePanelWidth = '90%';
    @Input() disableEdition = false;
    @Input() validateButton = true;
    @Input() isSSRUseCase = false;
    @Input() fieldTranslationKey = 'HEALTH.ACTS';
    @Input() isBasicAct = false;
    @Input() triggerValidation: boolean;
    @Input() actType: string;

    @Output() validationDone: EventEmitter<string> = new EventEmitter<string>();
    isClassingActsActivated: boolean;
    isEditionMode = false;

    constructor(private _matDialog: MatDialog,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _layoutService: LayoutService,
                private _broadcastService: BroadcastService,
                private _snackBarService: SnackBarService,
                private _dataSetElementService: DataSetElementService,
                public dateHelperService: DateHelperService,
                ) {

    }

    ngOnInit() {
        this.isEditionMode = !this.disableEdition && !this.validateButton;
        this._originalDataSetContentLinkActs = this.dataSetElement.dataSetContent.linkAct ? _.cloneDeep(this.dataSetElement.dataSetContent.linkAct) : {};
        this._initAvailableFeatures();
        this._subscribeToBroadcast();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes &&
            changes.triggerValidation &&
            changes.triggerValidation.currentValue) {
            this.validate(false)
                .then(hasUpdated => {
                    if (hasUpdated) {
                        this.validationDone.emit(this.actType);
                    }
                });
        }
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'act::resetLinkActs':
                        this.dataSetElement.dataSetContent.linkAct = this._originalDataSetContentLinkActs;
                        break;
                    case 'act::addActs':
                        if (res &&
                            res.data) {
                            this._addActsToDisplay(res.data.acts);
                        }
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private _initAvailableFeatures() {
        this.isClassingActsActivated = this._translationHelperService.isFeatureAvailable('classingActs');
    }

    private _addActsToDisplay(newActs: any[]) {
        if (newActs) {
            newActs = newActs.filter(act => act.type === this.actType);
            const newClassingActs = [];
            const newOtherActs = [];
            newActs.forEach(act => (act.classing ? newClassingActs.push(act) : newOtherActs.push(act)));
            if (newClassingActs.length) {
                this._addActsToDataSetContentLinkActs('classing', newActs);
            }
            if (newOtherActs.length) {
                this._addActsToDataSetContentLinkActs('other', newActs);
            }
            this._broadcastService.send('stayDetailCodificationAct::updateLinkActs');
        }
    }

    private _addActsToDataSetContentLinkActs(actClassification: string, newActs: any[]) {
        const previousValue = _.cloneDeep(this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification]);
        this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification].length = 0;
        [].push.apply(this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification],
            previousValue.concat(newActs));
    }

    hasActs() {
        return !!(this.dataSetElement.dataSetContent.linkAct &&
            this.dataSetElement.dataSetContent.linkAct[this.actType] &&
            ((this.dataSetElement.dataSetContent.linkAct[this.actType].classing &&
                    this.dataSetElement.dataSetContent.linkAct[this.actType].classing.length) ||
                (this.dataSetElement.dataSetContent.linkAct[this.actType].other &&
                    this.dataSetElement.dataSetContent.linkAct[this.actType].other.length)));
    }

    getTooltip(act: any, isClassing: boolean): string {
        if (this.isClassingActsActivated &&
            this.actType === 'ccam') {
            const suffix = isClassing ? this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.CLASSING') : this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.NOT_CLASSING');
            if (this.hasSimilarAct(act, isClassing)) {
                return `${this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.ACT.SIMILAR_ACT')} \n\n ${act.name || ''} (${suffix})`;
            }
            return `${act.name || ''} (${suffix})`;
        }
        return (act.name || '');
    }
    private _deleteActFromDataSetContentLinkActs(actClassification: string, deletedAct: any) {
        const previousValue = _.cloneDeep(this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification]);
        this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification].length = 0;
        this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification].push.apply(this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification],
            previousValue.filter(el => {
                const isEqual = this.dateHelperService.datesAreEqual(el.execution_date, deletedAct.execution_date, DateComparisonLevel.MINUTE);
                return !(el.id === deletedAct.id && isEqual);
            }));
    }
    private async _deleteAct(act: any, actClassification: string) {
        try {
            if (act) {
               await this._dataSetElementService
                    .deleteAct(this.dataSetElement.id, act.id, act.execution_date);
                // Update act list  when trigger deletion from dialog
                this._deleteActFromDataSetContentLinkActs(actClassification, act);
                this._broadcastService.send('stayDetailCodificationAct::updateLinkActs');
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async _addActs() {
        try {
            await this._dataSetElementService
                .addActs(this.dataSetElement.id,
                    {data: [{actId: this._actsToAdd.id, execution_date: this._actsToAdd.execution_date}] , type: this.actType});
            this._broadcastService.send('stayDetailCodificationAct::updateLinkActs');
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _getPromises() {
        const promises = [];
        let hasDeleted = false;
        if (this._actsToAdd &&
            this._actsToDelete.classing &&
            this._actsToDelete.other) {
            if (this._actsToAdd.id) {
                promises.push(this._addActs());
            }
            if (this._actsToDelete.classing.id) {
                promises.push(this._deleteAct(this._actsToDelete.classing, 'classing'));
                hasDeleted = true;
            }
            if (this._actsToDelete.other.id) {
                promises.push(this._deleteAct(this._actsToDelete.other, 'other'));
                hasDeleted = true;
            }
        }
        return {promises, hasDeleted};
    }

    async validate(canLeaveEditionMode: boolean) {
        try {
            const hasActsToAddOrDelete = (this._actsToAdd && this._actsToAdd.id) ||
                (this._actsToDelete.classing && this._actsToDelete.classing.length) ||
                (this._actsToDelete.other && this._actsToDelete.other.length);
            if (hasActsToAddOrDelete) {
                this._layoutService.startLoading();
                const {promises, hasDeleted} = this._getPromises();
                await Promise.all(promises);
                // After successfully added or remove acts then reset the arrays
                this._actsToAdd = {};
                this._actsToDelete.classing = {};
                this._actsToDelete.other = {};
                if (hasDeleted) {
                    // To reload grouping
                    this._broadcastService.send('act::deleteActs', {success: true});
                }
                this._layoutService.stopLoading();
                this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            }
            if (canLeaveEditionMode) {
                this.isEditionMode = false;
            }
            return hasActsToAddOrDelete;
        } catch (e) {
            console.error(e);
            this._layoutService.stopLoading();
            throw e;
        }
    }

    handleActAction(useCase: string, act: any, actClassification?: string) {
        if (act) {
            if (useCase === 'addition') {
                act.execution_date = this.dataSetElement.dataSetContent.endDate;
                this.openExecuteDateDisplayDialog(act, false, actClassification);
            } else {
                this._deleteAct(act, actClassification);
                this._deleteActFromDataSetContentLinkActs(actClassification, act);
            }
        }
    }

    hasSimilarAct(linkAct: any, isClassing: boolean): boolean {
        const executionDate = linkAct.execution_date;
        if (isClassing) {
            const actsWithSameExecutionDate = this.dataSetElement.dataSetContent.linkAct[this.actType].classing
                .filter(act => act?.execution_date === executionDate && act?.id === linkAct?.id);
            return actsWithSameExecutionDate.length > 1;
        } else if (!isClassing) {
            const actsWithSameExecutionDate = this.dataSetElement.dataSetContent.linkAct[this.actType].other
                .filter(act => act?.execution_date === executionDate && act?.id === linkAct?.id);
            return actsWithSameExecutionDate.length > 1;
        }
        return false;
    }

    openExecuteDateDisplayDialog(linkAct: any, isEditMode: boolean = false, actClassification = null) {
        const dialogRef: MatDialogRef<StayDetailEditActExecutionDateDialogComponent> =
            this._matDialog.open(StayDetailEditActExecutionDateDialogComponent, {
                data: {
                    linkAct
                },
                autoFocus: false,
                panelClass: 'data-set-element-dates-edit-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(async res => {
                if (res) {
                    if (isEditMode) {
                        this._actsToAdd = res.new;
                        if (res.old.classing) {
                            this._actsToDelete.classing = res.old;
                        } else {
                            this._actsToDelete.other = res.old;
                        }
                    } else {
                        this._actsToAdd = res.new;
                    }
                    await this.validate(false);
                    actClassification = actClassification || (res.new.classing ? 'classing' : 'other');
                    if (!this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification]) {
                        this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification] = [];
                    }
                    this.dataSetElement.dataSetContent.linkAct[this.actType][actClassification].push(res.new);
                }
            });
    }



    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
