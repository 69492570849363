import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ct-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() icon: string;
    @Input() title: string;
    @Input() largeMargin: boolean;
    @Input() withoutMargin: boolean;
    @Input() color: string;
    @Input() useGradient = true;

    constructor() {}

    ngOnInit() {
    }

    getClasses() {
        const classes = {
            'with-margin': !this.withoutMargin,
            'large-margin': this.largeMargin
        };
        if (!this.useGradient) {
            classes[`md-${this.color}`] = true;
        } else {
            classes['gradient-blue'] = true;
        }
        return classes;
    }

}
