import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BroadcastService} from '../../../core/services/broadcast.service';
import * as $ from 'jquery';
import {StateService} from '@uirouter/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ct-tab-list',
    templateUrl: './tab-list.component.html',
    styleUrls: ['./tab-list.component.scss']
})
export class TabListComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    @Input() activeTabId: number;
    @Input() broadcastMessage: string;
    @Input() horizontalScrolling = false;
    // For stay display tabs we need more padding
    @Input() hugePadding = false;
    @Input() numberOfTabs = 3;

    @Output() activeTabIdChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() isFilterSearchMetric: null|boolean = false;

    tabs: any[] = [];
    multipleLines = false;
    activeTab = {};
    tabWidth = '33%';

    constructor(public $state: StateService,
                private _broadcastService: BroadcastService) { }

    ngOnInit() {
        this._subscribeToBroadcast();
        // We have to calculate tabWidth according to numberOfTabs
        // because otherwise if we set the width independently we have
        // one letter per line
        if (this.numberOfTabs > 0) {
            this.tabWidth = `${Math.floor(100 / this.numberOfTabs)}%`;
        }
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'tabList::deleteTab':
                        if (res.data) {
                            this._deleteTab(res.data.id);
                        }
                        break;
                    default:
                }
            });
        this._subscriptions.push(sub);
    }

    updateTabData(id: number, currentTab: any, newTab: any) {
        if (newTab) {
            // tab is null if id is null
            const tab = this._getTab(id) ?? null;
            Object.keys(newTab).forEach(key => {
                // If id is null then it means we just want to update
                // from outside the component
                if (id === null &&
                    currentTab) {
                    currentTab[key] = newTab[key];
                } else if (tab) {
                    tab[key] = newTab[key];
                }
            });
        }
    }

    private _getTab(id: number) {
        return this.tabs
            .find(tab => tab.id === id);
    }

    private _getTabPosition(id: number) {
        return this.tabs
            .map(tab => tab.id)
            .indexOf(id);
    }

    private _deleteTab(id: number) {
        const tabIndex = this.tabs.findIndex(tab => tab.id === id);
        if (tabIndex !== -1) {
            this.tabs.splice(tabIndex, 1);
        }
    }

    private _getHeight(tab) {
        // We want the height without the padding
        const style = window.getComputedStyle(tab, null);
        const outerHeight = parseFloat(style
            .getPropertyValue('height')
            .replace('px', ''));
        const paddingTop = parseFloat(style
            .getPropertyValue('padding-top')
            .replace('px', ''));
        const paddingBottom = parseFloat(style
            .getPropertyValue('padding-bottom')
            .replace('px', ''));

        return (outerHeight - paddingTop - paddingBottom);
    }

    /**
     * Add new tab or update its data
     * @param tabToAdd
     */
    public addTab(tabToAdd: any) {
        const tabEl = this._getTab(tabToAdd.id);
        if (!tabEl) {
            if (!this.activeTabId) {
                this.activeTabId = tabToAdd.id;
            }
            this.tabs.push(tabToAdd);
            if (!this.activeTab) {
                this.activeTab = this.tabs
                    .find(tab => tab.id === this.activeTabId);
            }
        } else {
            this.updateTabData(null, tabEl, tabToAdd);
        }

        if (this.horizontalScrolling) {
            // Timeout because otherwise multipleLines
            // initialize too soon
            setTimeout(() => {
                const tabs = document.getElementsByClassName('tab-item label');
                Array.from(tabs).forEach(tab => {
                    let height = this._getHeight(tab);
                    // Because of the border-bottom
                    if (tab.className.indexOf('active') !== -1) {
                        height -= 2;
                    }
                    if (height > 21) {
                        this.multipleLines = true;
                    }
                });
            }, 50);

            // Scroll to the active tab if the fourth one or more
            const tabToScrollPosition = this._getTabPosition(this.activeTabId);
            if (tabToScrollPosition !== -1 && tabToScrollPosition > 2) {
                setTimeout(() => {
                    $('#container-tabs').animate({
                        scrollLeft: $('.tab-item.label.active').position().left
                    }, 'fast');
                }, 50);
            }
        }
    }

    switchTab(id: number, event: any) {
        if (event) {
            event.stopPropagation();
        }
        this.activeTabId = id;
        this.activeTabIdChange.emit(this.activeTabId);
        if (this.broadcastMessage) {
            this._broadcastService.send(this.broadcastMessage, {id: this.activeTabId});
        }
    }

    getTabItemColorClass() {
        if (this.$state &&
            this.$state.current) {
            // @ts-ignore
            const color: string = this.$state.current.color;
            return color ? `color-${color}` : '';
        }
        return '';
    }

    getTabWidth() {
        if (this.tabs &&
            this.tabWidth &&
            this.numberOfTabs) {
            return this.tabs.length > this.numberOfTabs ? this.tabWidth : 'auto';
        }
    }

    swipeRight(event) {
        event.preventDefault();
        $('#container-tabs').animate({
            scrollLeft: '+=100px'
        }, 'fast');
    }

    swipeLeft(event) {
        event.preventDefault();
        $('#container-tabs').animate({
            scrollLeft: '-=100px'
        }, 'fast');
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
