import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {TranslationHelperService} from '../../../../../core/services/translation.helper.service';
import {StayDetailCodificationDiagnosticService} from '../diagnostic/stay-detail-codification-diagnostic.service';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {LayoutService} from '../../../../layout/layout.service';
import {StayDetailCodificationNearAutomationConfirmDialogComponent} from './confirm-dialog/stay-detail-codification-near-automation-confirm-dialog.component';
import {DataSetElementService} from '../../../shared/data-set-element.service';

@Injectable({
    providedIn: 'root'
})
export class StayDetailCodificationNearAutomationService {
    constructor(private _dialog: MatDialog,
                private _broadcastService: BroadcastService,
                private _layoutService: LayoutService,
                private _dataSetElementService: DataSetElementService,
                private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService,
                private _translationHelperService: TranslationHelperService,
                private _translateService: TranslateService) {}

    private _openConfirmDialog(dataSetElement: DataSetElement) {
        const confirmDialogRef: MatDialogRef<StayDetailCodificationNearAutomationConfirmDialogComponent> =
            this._dialog.open(StayDetailCodificationNearAutomationConfirmDialogComponent, {
                autoFocus: false,
                disableClose: true,
                data: {
                    dataSetElement
                }
            });

        confirmDialogRef
            .afterClosed()
            .subscribe(data => {
                if (data) {
                    switch (data.action) {
                        case 'goToNextStay':
                            this._broadcastService.send('stayDetailCodificationNearAutomation::goToNextStay');
                            break;
                        case 'goBackToList':
                            this._broadcastService.send('stayDetailCodificationNearAutomation::goBackToList');
                            break;
                        case 'cancel':
                            this._broadcastService.send('stayDetailCodificationNearAutomation::cancel');
                            break;
                        default:
                            this._broadcastService.send('stayDetailCodificationNearAutomation::cancel');
                    }
                }
            });
    }

    private _buildPromises(dataSetElement: DataSetElement, diagnostics: any[]): Promise<void>[]  {
        const promises: Promise<void>[] = [];

        if (dataSetElement && diagnostics) {
            diagnostics.forEach(diagnostic => {
                const price = diagnostic.pricing ? diagnostic.pricing - dataSetElement.dataSetContent?.pricing : null;
                const data = {
                    isNearAutomation: true,
                    diagnostic: {...diagnostic, isLoading: false},
                    codificationLabelId: diagnostic.codificationLabelId,
                    isPrediction: true,
                    referencialPrice: price
                };

                promises.push(this._stayDetailCodificationDiagnosticService.updateDiagnosticCodification(dataSetElement, 1, data));
            });
        }

        return promises;
    }

    async validateAutomation(
        dataSetElement: DataSetElement,
        diagnostics: any[],
        isNearAutomationQualityControl: boolean
    ): Promise<void> {
        try {
            this._layoutService.startLoading();

            const promises: Promise<void>[] = this._buildPromises(dataSetElement, diagnostics);
            await Promise.all(promises);

            this._layoutService.stopLoading();

            if (!isNearAutomationQualityControl) {
                this._openConfirmDialog(dataSetElement);
            }
        } catch (e) {
            this._layoutService.stopLoading();
            console.error(e);
            throw e;
        }
    }

    async cancelAutomation(diagnosticPredictionId: number): Promise<void> {
        try {
            this._layoutService.startLoading();
            await this._dataSetElementService.deleteDataSetElementPrediction(diagnosticPredictionId);
            this._layoutService.stopLoading();
        } catch (e) {
            this._layoutService.stopLoading();
            console.error(e);
            throw e;
        }
    }
}
