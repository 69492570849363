import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class StayDetailInfoAdditionalService {
    constructor(private _translateService: TranslateService) {}

    private _getTranslation(dayOfTheWeek: number): string {
        switch (dayOfTheWeek) {
            case 1:
                return this._translateService.instant('TIME.DAY.MONDAY_ABBR');
            case 2:
                return this._translateService.instant('TIME.DAY.TUESDAY_ABBR');
            case 3:
                return this._translateService.instant('TIME.DAY.WEDNESDAY_ABBR');
            case 4:
                return this._translateService.instant('TIME.DAY.THURSDAY_ABBR');
            case 5:
                return this._translateService.instant('TIME.DAY.FRIDAY_ABBR');
            case 6:
                return this._translateService.instant('TIME.DAY.SATURDAY_ABBR');
            case 7:
                return this._translateService.instant('TIME.DAY.SUNDAY_ABBR');
            default:
                return '';
        }
    }

    getPresenceDaysText(presenceDays: string): string {
        if (presenceDays) {
            const daysAsArray = [];
            for (let i = 0; i < presenceDays.length; i++) {
                if (presenceDays[i] === '1') {
                    daysAsArray.push(this._getTranslation(i + 1));
                }
            }
            return daysAsArray.length > 0 ? daysAsArray.join('-') : this._translateService.instant('STUFF.NONE_MALE');
        }
        return '';
    }
}
