import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {BroadcastService} from '../../../core/services/broadcast.service';

@Component({
    selector: 'ct-highlight',
    templateUrl: './highlight.component.html',
    styleUrls: ['./highlight.component.scss']
})
export class HighlightComponent implements AfterViewInit {
    private _text: string;

    @ViewChild('text', { static: true }) textElement: ElementRef;

    constructor(private _broadcastService: BroadcastService) { }

    ngAfterViewInit() {
        this._text = this.textElement.nativeElement.textContent;
    }

    removeTag() {
        this._broadcastService.send('highlight::remove', {text: this._text, tag: 'highlight'});
    }
}
