import {Injectable} from '@angular/core';
import {MixedDocumentTypeApiService} from '../../core/api-services/document-type/mixed-document-type.api.service';
import {MixedDocumentTypes} from '../search-engine/condition/document-type/search-engine-condition-document-type.component';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../configuration/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentTypeService {

    constructor(private _mixedDocumentTypeApiService: MixedDocumentTypeApiService,
                private _configurationService: ConfigurationService,
                private _translateService: TranslateService) {
      }

    getInferredDocumentTypesThresholds(): {crh: number, cro: number, anapath: number} {
        return {
            crh: this._configurationService.getConfigurationContent('front', 'health.document.documentTypeFromMLDisplayThresholds.crh') || null,
            cro: this._configurationService.getConfigurationContent('front', 'health.document.documentTypeFromMLDisplayThresholds.cro') || null,
            anapath: this._configurationService.getConfigurationContent('front', 'health.document.documentTypeFromMLDisplayThresholds.anapath') || null,
        };
    }

    private _getInferredDocumentTypeTranslation(name: string) {
        switch (name) {
            case 'anapath':
                return this._translateService.instant('DOCUMENT_TYPE.ANAPATH');
            case 'cro':
                return this._translateService.instant('DOCUMENT_TYPE.CRO');
            case 'crh':
                return this._translateService.instant('DOCUMENT_TYPE.CRH');
            default:
                return '';
        }
    }

    private _getMixedDocumentTypesUpdated(mixedDocumentTypes: MixedDocumentTypes): MixedDocumentTypes {
        if (mixedDocumentTypes?.inferredDocumentTypes) {
            const mapInferredDocumentTypes = mixedDocumentTypes.inferredDocumentTypes
                .reduce((accumulator, currentValue) => {
                    if (currentValue.name !== 'other') {
                        // We don't want the other inferred doc type
                        accumulator.push({
                            ...currentValue,
                            name: this._getInferredDocumentTypeTranslation(currentValue.name),
                            slug: currentValue.name,
                            isInferredDocumentType: true
                        });
                    }
                    return accumulator;
                }, []);
            return {
                documentTypes: [...mixedDocumentTypes.documentTypes],
                inferredDocumentTypes: mapInferredDocumentTypes
            };
        }
        return {inferredDocumentTypes: [], documentTypes: mixedDocumentTypes?.documentTypes || []};
    }

    async loadAllMixedDocumentTypes(params?: any): Promise<any> {
        try {
            const res = await this._mixedDocumentTypeApiService
                .getAll(params)
                .toPromise();
            return this._getMixedDocumentTypesUpdated(res);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
