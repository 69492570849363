import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterContentChecked, ChangeDetectorRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import {catchError, debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { MatChipList } from '@angular/material/chips';
import { ActService } from '../../../../../../core/services/act.service';
import { DataSetElement } from '../../../../shared/data-set-element.model';

@Component({
    selector: 'ct-stay-detail-codification-act-add-autocomplete',
    templateUrl: './stay-detail-codification-act-add-autocomplete.component.html',
    styleUrls: ['./stay-detail-codification-act-add-autocomplete.component.scss']
})
export class StayDetailCodificationActAddAutocompleteComponent implements OnInit, AfterContentChecked {
    @ViewChild('actInput', { static: true }) actInput: ElementRef<HTMLInputElement>;

    @Input() dataSetElement: DataSetElement;
    @Input() canDisplayNonePlaceholder = true;
    @Input() matChipList: MatChipList;
    @Input() autoCompletePanelWidth = '90%';
    @Input() actType: string;

    @Output() actToAdd: EventEmitter<any> = new EventEmitter<any>();

    formControl = new FormControl();
    searchedActs: Observable<any>;
    isAutocompleteLoading: boolean;

    constructor(
        private _actService: ActService,
        private _changeDetector: ChangeDetectorRef,
    ) { }

     ngOnInit() {
        this.searchedActs =
            this.formControl.valueChanges
                .pipe(startWith(''),
                    debounceTime(200),
                    switchMap(value => {
                        this.isAutocompleteLoading = true;
                        if (value && value.length > 0) {
                            return this._search(value);
                        } else {
                            this.isAutocompleteLoading = false;
                            return EMPTY;
                        }
                    }));
    }

    ngAfterContentChecked(): void {
        this._changeDetector.detectChanges();
    }

    private _search(query: string) {
        const date = this.dataSetElement.dataSetContent.endDate || this.dataSetElement.dataSetContent.startDate;
        return this._actService
            .loadHealthActsWithES(query, this.actType, date)
            .pipe(
                map(res => {
                    this.isAutocompleteLoading = false;
                    return res;
                }),
                catchError(error => {
                    console.error('Error during act search:', error);
                    return EMPTY;
                })
            );
    }

    selectAct(event: any) {
        if (event) {
            this.actInput.nativeElement.value = '';
            this.formControl.setValue(null);
            this.actToAdd.emit(event.option.value);
        }
    }

    getTooltipText(act: any, i: number): string {
        const el = document.getElementById(i.toString());
        if (el &&
            el.children[0]) {
            const text = `${act.slug} ${act.name || ''}`;
            return el.children[0].scrollWidth > el.children[0].clientWidth ? text : '';
        }
        return '';
    }
}
