<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="subscribe-dialog-content">
    <h1 mat-dialog-title
        class="text-center"><span [innerHTML]="titleText | safeHtml"></span></h1>

    <mat-dialog-content *ngIf="!isUnsubscription">
        <div class="row margin-bottom-16">
            <div class="col no-padding">
                <p class="text-center font-size-17"
                   id="sendEmailToText"
                   [innerHTML]="sendEmailToText"></p>

                <ng-container *ngIf="filterSearch.isPublic && isEdition">
                    <p class="text-center font-size-17"
                       id="subscribeToManyMessage"
                       [innerHTML]="'FILTER_SEARCH.SUBSCRIBE_DIALOG.SUBSCRIBE_TO_MANY_MESSAGE' | translate"></p>
                    <div class="row pt-1" *ngFor="let userDb of usersDb">
                        <div class="col-md-6">{{userDb.firstName}} {{userDb.lastName}}
                            <mat-icon class="vertical-align-middle"
                                      [matTooltip]="'TOOLTIP.SUBSCRIBED' | translate"
                                      *ngIf="isUserSubscribed(userDb)">notification_important</mat-icon>
                        </div>
                        <div class="col-md-6">
                            <button mat-raised-button
                                    class="me-3"
                                    color="primary"
                                    type="button"
                                    (click)="assignUser(userDb)"
                            >
                                {{ (!isUserSubscribed(userDb) ? 'BUTTON.ADD' : 'BUTTON.DELETE') | translate| uppercase}}
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button
                mat-dialog-close
                class="color-blue-on-white"
                type="button">{{'BUTTON.CLOSE' | translate | uppercase}}</button>

        <button
                *ngIf="!isEdition"
                mat-raised-button
                type="submit"
                color="primary"
                [disabled]="isLoading"
                (click)="validate()">{{'BUTTON.VALIDATE' | translate | uppercase}}</button>
    </mat-dialog-actions>
</div>
