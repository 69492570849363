import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'ct-stay-detail-info-header',
    templateUrl: './stay-detail-info-header.component.html',
    styleUrls: ['./stay-detail-info-header.component.scss']
})
export class StayDetailInfoHeaderComponent implements OnInit {
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

    @Input() dataSetElement: DataSetElement;
    canDisplayStayRisk: boolean;
    isTypeExternal: boolean;

    constructor(public dataSetElementService: DataSetElementService,
                private _translateService: TranslateService,
                private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this.isTypeExternal = this.dataSetElementService.isType(this.dataSetElement, 'external');
        this._initConfig();
    }

    private _initConfig() {
        this.canDisplayStayRisk = this._configurationService.getConfigurationContent('front', 'health.canDisplayStayRisk');
    }

    getRiskTooltip() {
        if (this.dataSetElement) {
            if (this.dataSetElement.dataSetContent.hasDocumentOverlapRisk === true) {
                return this._translateService.instant('TOOLTIP.HIGH_STAY_RISK');
            } else if (this.dataSetElement.dataSetContent.hasDocumentOverlapRisk === false) {
                return this._translateService.instant('TOOLTIP.LOW_STAY_RISK');
            } else {
                return this._translateService.instant('TOOLTIP.UNKNOWN_STAY_RISK');
            }
        }
        return '';
    }

    openMenu(event: any) {
        if (event) {
            event.stopPropagation();
        }
        this.menuTrigger.openMenu();
    }
}
