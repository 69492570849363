import {Injectable} from '@angular/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SearchEngineConditionStayTypeService extends SearchEngineCondition {
    private _useCase: string;

    get useCase(): string {
        return this._useCase;
    }

    set useCase(value: string) {
        this._useCase = value;
    }
    constructor(private _translateService: TranslateService) {
        super(['type'],
            _translateService.instant('STAY.TYPE'),
            'type',
            null,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        const useCaseStorage = sessionStorage.getItem('use-case-type');
        const defaultApiParams = apiParams?.args?.values ?? null;
        let defaultUriValue = this.useCase === 'filterSearch' ? useCaseStorage : null;
        if (defaultApiParams && defaultApiParams.length) {
            defaultUriValue = defaultApiParams.join(';');
        }
        const defaultUriParams = this._getStayTypeUriParam(defaultUriValue);
        this.params = {type:  this.convertToUri(defaultUriParams)};
        this.uriParams = {type: defaultUriParams};
    }

    private _getStayTypeUriParam(stayTypeParam: string): string {
        switch (stayTypeParam) {
            case 'external':
                return '2';
            case 'default;recurring':
                return '1';
            case 'rehabilitation':
                return '0';
            default:
                return '-1';
        }
    }

    public getUriParamByType(type: string): any {
        return this._getStayTypeUriParam(type);
    }

    convertToUriParams(): void {
        this.uriParams.type = this._getStayTypeUriParam(this.params.type);
    }

    public _getStayTypeParam(stayType: string): string {
        switch (stayType) {
            case '2':
                return 'external';
            case '1':
                return 'default;recurring';
            case '0':
                return 'rehabilitation';
            default:
                return 'default;recurring;rehabilitation';
        }
    }
    private _getApiStayTypeParam(stayType: string): string[] {
        switch (stayType) {
            case '2':
                return ['external'];
            case '1':
                return ['default', 'recurring'];
            case '0':
                return ['rehabilitation'];
            default:
                return ['default', 'recurring', 'rehabilitation'];
        }
    }

    /**
     *  Convert '1' to 'rehabilitation'
     *          '0' to 'default'
     *          '-1' to 'default;rehabilitation'
     *          '2' to 'external'
     */
    convertToUri(stayType: string): string {
        return this._getStayTypeParam(stayType);
    }
    convertToApi(stayType: string): string[] {
        return this._getApiStayTypeParam(stayType);
    }

    convertToParams(): void {
        this.params.type = this._getStayTypeParam(this.uriParams.type);
    }

    convertToParamsFromApiParams(): void {
        this.params.type = this._getStayTypeParam(this.apiParams.args.value);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.type !== 'default;rehabilitation') {
            this.apiParams.args = {value: this._getStayTypeUriParam(this.params.type)};
        }
    }

    getApiParams(): ApiCriteria {
        return null;
    }
}
