import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DocumentHighlightPreviewStructuredComponent} from './document-highlight-preview-structured.component';

@NgModule({
    declarations: [
        DocumentHighlightPreviewStructuredComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        NgxDatatableModule,
    ],
    exports: [
        DocumentHighlightPreviewStructuredComponent
    ]
})
export class DocumentHighlightPreviewStructuredModule {}
