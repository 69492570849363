import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FileDisplayComponent} from './file-display.component';
import {PipesModule} from '../../pipes/pipes.module';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';

@NgModule({
    declarations: [
        FileDisplayComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        PipesModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatToolbarModule
    ],
    exports: [
        FileDisplayComponent
    ]
})
export class FileDisplayModule {}
