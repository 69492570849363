import {Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {StayDetailDocumentElementService} from './stay-detail-document-element.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {AppLogService} from '../../../../../core/app-log/app-log.service';
import {DateHelperService} from '../../../../../core/services/date.helper.service';
import {StayDetailService} from '../../stay-detail.service';
import {FileSrcService} from '../../../../../core/services/file-src.service';

@Component({
    selector: 'ct-stay-detail-document-element',
    templateUrl: './stay-detail-document-element.component.html',
    styleUrls: ['./stay-detail-document-element.component.scss']
})
export class StayDetailDocumentElementComponent implements OnInit, OnDestroy {
    private _highlightList = [];
    private _subscriptions: Subscription[] = [];

    // Without it the highlight is made before the lines are loaded
    private _areLinesLoaded = false;

    @ViewChildren(MatMenuTrigger) menuTriggerList: QueryList<MatMenuTrigger>;

    @Input() dataSetElement: DataSetElement;
    @Input() document: any;
    @Input() patientStays: any[];

    tabs = [
        {
            id: 1,
            label: this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.SYNTHESIS'),
            disabled: true
        },
        {
            id: 2,
            label: this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.ORIGINAL'),
            disabled: false
        }
    ];
    canSummarize: boolean;
    isLoading: boolean;
    structuredDataQuery: string;
    canDisplayStayRisk: boolean;
    canDisplayDocumentTypeFromML: boolean;
    displayDocumentData: any;

    constructor(public $state: StateService,
                public dateHelperService: DateHelperService,
                private _broadcastService: BroadcastService,
                private _stayDetailService: StayDetailService,
                private _stayDetailDocumentElementService: StayDetailDocumentElementService,
                private _translateService: TranslateService,
                private _configurationService: ConfigurationService,
                private _logService: AppLogService,
                private _fileSrcService: FileSrcService) {
    }

    ngOnInit() {
        this._initConfig();
        this._initSynthesisTab();
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser && currentUser.roles) {
            const roles = currentUser.roles.filter(role => 'admin' === role.name);
            this.canSummarize = roles.length > 0;
        }
        if (this.document) {
            this.document.activeTabId = this.document.activeTabId || 1;
        }
        this._setDisplayDocumentData();
        this._openDocumentOnInit();
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailDocumentList::clickOnDocument':
                        if (res.data) {
                            if (this.document.id === res.data.documentId) {
                                if (this._areLinesLoaded) {
                                    this.document.isOpened = true;
                                    this._broadcastService.send('stayDetailDocument::focusDocument', {documentId: res.data.documentId});
                                } else {
                                    this._loadLines(true)
                                        .then(() => {
                                            // Without timeout focus is not done
                                            setTimeout(() => {
                                                this._broadcastService.send('stayDetailDocument::focusDocument', {documentId: res.data.documentId});
                                            }, 100);
                                        });
                                }
                            } else {
                                this.document.isOpened = false;
                            }
                        }
                        break;
                    case 'stayDetailDocument::highlight':
                        if (res.data &&
                            res.data.tab) {
                            if (this._areLinesLoaded) {
                                this._highLightTextInDocument(res.data.tab);
                            } else if (this._canLoadLines(res.data.tab)) {
                                // Don't load lines if no match
                                this._loadLines(false)
                                    .then(() => this._highLightTextInDocument(res.data.tab));
                            }
                        }
                        break;
                    case 'stayDetailDocument::openCloseAllDocuments':
                        this._onOpenCloseAllDocuments(res);
                        break;
                    default:
                }
            });
        this._subscriptions.push(sub);
    }

    private _initConfig() {
        this.canDisplayStayRisk = this._configurationService.getConfigurationContent('front', 'health.canDisplayStayRisk');
        this.canDisplayDocumentTypeFromML = this._configurationService.getConfigurationContent('front', 'health.document.canDisplayDocumentTypeFromML');
    }

    private _initSynthesisTab(): void {
        if (this.document?.documentType &&
            !this.document.documentType.structuredData) {
            const synthesisTab = this.tabs[0];
            synthesisTab.disabled = !this.document.documentType.summarize && !this.document.documentType.indexer;
            synthesisTab.label += synthesisTab.disabled ? ` ${this._translateService.instant('ERROR.UNAVAILABLE')}` : '';
            if (synthesisTab.disabled === true || !this.document.documentType.summarize) {
                this.document.activeTabId = 2;
            }
        }
    }

    private _openDocumentOnInit() {
        if (this.document &&
            (this.document.documentType &&
                this.document.documentType.order === 1) ||
            (this.canDisplayDocumentTypeFromML &&
                this.document.inferredDocumentType &&
                this.document.inferredDocumentType.name === 'crh')) {
            this.openDocument(false);
        }
    }

    private _setDisplayDocumentData() {
        if (this.document) {
            this.displayDocumentData = {
                src: this.getDisplayDocumentSrc(),
                filename: this.document.filename
            };
        }
    }

    private _onOpenCloseAllDocuments(res: any) {
        if (res &&
            res.data) {
            const toOpen = res.data.status;
            const type = res.data.type;
            const isPatientDoc = type === 'patient';
            const isStayDoc = type === 'stay';
            if (type === 'all' ||
                (isPatientDoc && this.document.documentCreatedAt) ||
                (isStayDoc && !this.document.documentCreatedAt)) {
                if (!this._areLinesLoaded &&
                    toOpen) {
                    this._loadLines(true);
                } else {
                    this.document.isOpened = toOpen;
                }
            }
        }
    }

    private _canLoadLines(data: any) {
        // Don't load lines if no match and not this document
        return !!(data && data.highlightList && data.highlightList.length &&
            (data.healthDocumentId === -1 ||
                data.healthDocumentId &&
                data.healthDocumentId.includes(this.document.id)));
    }

    private async _loadLines(openDocument: boolean = true) {
        try {
            this.isLoading = true;
            this.document.healthDocumentContent = {};
            const params: any = {};
            if (this.document.documentType &&
                this.document.documentType.structuredData &&
                this.document.subDocumentId &&
                this.document.subDocumentId.length > 0) {
                params.subDocumentId = this.document.subDocumentId.join(',');
            }
            const res = await this._stayDetailDocumentElementService
                .loadHealthDocumentLines(this.document, params);
            this.document.healthDocumentContent = res.healthDocumentContent ? res.healthDocumentContent : {};
            this.document.structuredData = res.structuredData ? res.structuredData : {};
            if (this.document.documentType &&
                !this.document.documentType.structuredData) {
                this._transformLines();
            }
            this.document.isOpened = openDocument;
            this.isLoading = false;
            this._areLinesLoaded = true;
        } catch (e) {
            this.document.isOpened = false;
            this.isLoading = false;
            this._areLinesLoaded = false;
            throw e;
        }
    }

    private _transformLines() {
        this._stayDetailDocumentElementService.transformHealthDocumentContentText(this.document);
        if (this._highlightList &&
            this._highlightList.length > 0) {
            this._highLightTextInDocument({highlightList: this._highlightList});
        }
    }

    private _highLightTextInDocument(data: any) {
        if (data) {
            this._highlightList = data.highlightList;
            this._stayDetailDocumentElementService.highLightTextInDocument(data, this.document);
        }
    }

    openMenu(event, id: number) {
        if (this.menuTriggerList) {
            event.stopPropagation();
            const menuTrigger = this.menuTriggerList.find((trigger: any) => trigger._element.id === id);
            if (menuTrigger) {
                menuTrigger.openMenu();
            }
        }
    }

    async openDocument(focusDocument: boolean = true) {
        if (this.document.isOpened === true) {
            this.document.isOpened = false;
        } else if (!this._areLinesLoaded) {
            await this._loadLines();
            if (focusDocument) {
                this._broadcastService.send('stayDetailDocument::focusDocument', {documentId: this.document.id});
            }
        } else {
            this.document.isOpened = true;
        }

        if (this.document.documentType) {
            const data = {
                documentId: this.document.id,
                documentType: this.document.documentType.name || this.document.documentType.slug,
                inferredDocumentType: this.document.inferredDocumentType ? this.document.inferredDocumentType.name : 'undefined',
                isOpened: this.document.isOpened,
                isPatientDocument: !!this.document.documentCreatedAt
            };
            this._broadcastService.send('stayDetailDocument::openCloseDocument', data);
        }
    }

    switchTab(event: any, tab: any) {
        if (event) {
            event.stopPropagation();
        }
        if (tab && !tab.disabled) {
            this.document.activeTabId = tab.id;
            const message = tab.id === 1 ? 'Looking at document summary' : 'Looking at original document';
            this._logService.logInfo(message);
        }
    }

    goToHealthDocumentAnonymization() {
        const functionToCall: () => void = () => this.$state.go('health-document-anonymization', {healthDocumentId: this.document.id});
        this._stayDetailService.openUpdateStayStatusConfirmDialog(this.dataSetElement, functionToCall);
    }

    async attachDocument(event: any, stay?: any) {
        try {
            if (event) {
                event.stopPropagation();
            }
            this.isLoading = true;
            let newDocuments: any;
            // It's a document patient then attach to stay
            if (this.document.documentCreatedAt) {
                const dataSetElementId = stay ? stay.id : this.dataSetElement.id;
                newDocuments = await this._stayDetailDocumentElementService
                    .attachOrDetachHealthDocumentToStay(dataSetElementId, this.document.id, 'attach');
            } else if (this.dataSetElement.dataSetContent.healthPatient) {
                newDocuments = await this._stayDetailDocumentElementService
                    .attachOrDetachHealthDocumentToPatient(this.dataSetElement.dataSetContent.healthPatient.id, this.document.id, this.dataSetElement.id, 'attach');
            }
            this._broadcastService.send('stayDetailDocument::attach', {documents: newDocuments});
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    async detachDocument() {
        try {
            this.isLoading = true;
            let newDocuments: any;
            // If it's a document patient
            if (this.document.documentCreatedAt) {
                if (this.dataSetElement.dataSetContent.healthPatient) {
                    newDocuments = await this._stayDetailDocumentElementService
                        .attachOrDetachHealthDocumentToPatient(this.dataSetElement.dataSetContent.healthPatient.id, this.document.id, this.dataSetElement.id, 'detach');
                }
            } else {
                newDocuments = await this._stayDetailDocumentElementService
                    .attachOrDetachHealthDocumentToStay(this.dataSetElement.id, this.document.id, 'detach');
            }
            this._broadcastService.send('stayDetailDocument::detach', {documents: newDocuments});
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    async summarizeDocument() {
        try {
            this.isLoading = true;
            const newDocument = await this._stayDetailDocumentElementService
                .summarizeHealthDocument(this.document.id);
            // Update and transform lines
            this.document.healthDocumentContent = newDocument.healthDocumentContent ? newDocument.healthDocumentContent : {};
            if (this.document.documentType &&
                !this.document.documentType.structuredData) {
                this._transformLines();
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    getDocumentCreationDate(creationDate: string) {
        if (creationDate) {
            return moment(creationDate).format('DD/MM/YYYY');
        }
        return '';
    }

    getInferredDocumentTypeName() {
        if (this.document &&
            this.document.inferredDocumentType) {
            switch (this.document.inferredDocumentType.name) {
                case 'crh':
                    return this._translateService.instant('DOCUMENT_TYPE.CRH');
                case 'cro':
                    return this._translateService.instant('DOCUMENT_TYPE.CRO');
                case 'anapath':
                    return this._translateService.instant('DOCUMENT_TYPE.ANAPATH');
                default:
                    return '';
            }
        }
        return '';
    }

    getChipColor(creationDate: string) {
        return this._stayDetailService
            .getChipColor(this.dataSetElement, creationDate);
    }

    getDisplayDocumentSrc() {
        return this._fileSrcService.getDocumentSrc(this.document);
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
