import {Component, OnInit} from '@angular/core';
import {MenuItem, MenuService} from '../menu.service';
import {BroadcastService} from '../../../services/broadcast.service';
import * as _ from 'lodash-es';
import {TransitionOptions} from '@uirouter/core/lib/transition/interface';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'ct-menu-group-sub-menu',
    templateUrl: './menu-group-sub-menu.component.html',
    styleUrls: ['./menu-group-sub-menu.component.scss']
})
export class MenuGroupSubMenuComponent implements OnInit {
    itemGroupName: string;
    items: MenuItem[];
    isMainMenuCollapsed: boolean;


    constructor(public $state: StateService,
                private _menuService: MenuService,
                private _broadcastService: BroadcastService) { }

    ngOnInit(): void {
    }

    orderMenuItems(): MenuItem[] {
        return _.orderBy(this.items, ['rank'], ['asc']);
    }

    private _goToState(item: MenuItem): void {
        if (item) {
            const options: TransitionOptions = {reload: false, inherit: false};
            if (this.$state.current.name === item.state) {
                // If we load the same state when we set reload option to true to empty search params
                options.reload = true;
            }
            if (this.$state.current.name === 'stay-detail') {
                // le changement de page se fait dans la page détail après le broadcast plutot qu'ici pour faire apparaître la pop-up
                 this._broadcastService.send('menu::openMenu', [item.state, item.params, options]);
            } else {
                this.$state.go(item.state, item.params, options);
            }
        }
    }

    onItemClick(item: MenuItem): void {
        this._goToState(item);
        this._menuService.handleClickOnItem(item);
        this._broadcastService.send('tooltipDirective::destroy');
    }

    canDisplayBadge(item: MenuItem): boolean {
        return this._menuService.canDisplayItemBadge(item);
    }
}
