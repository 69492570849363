<mat-form-field>
    <mat-select
        [formControl]="selectedEstablishments"
        [placeholder]="'DATA_SET.CODIFICATION.SEARCH.FINESS_ID' | translate"
        [multiple]="true"
    >
        <mat-option>
            <ngx-mat-select-search
                [placeholderLabel]="'BUTTON.SEARCH' | translate"
                [formControl]="establishmentSearch"
            ></ngx-mat-select-search>
        </mat-option>
        <mat-option
            *ngFor="let establishment of filteredEstablishments | async"
            [value]="establishment"
        >
            {{establishment.name}}
        </mat-option>
    </mat-select>
</mat-form-field>

