import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HealthMovement} from '../../../modules/health/movement/health-movement.model';

@Injectable({
    providedIn: 'root'
})
export class HealthMovementApiService {
    private _BASE_URL = 'health-movement';

    constructor(private _http: HttpService) { }

    getHealthMovementsEntrance(urlRequestParams?: any): Observable<HealthMovement[]> {
        return this._http
            .get(`${this._BASE_URL}-entrance`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    getHealthMovementsExit(urlRequestParams?: any): Observable<HealthMovement[]> {
        return this._http
            .get(`${this._BASE_URL}-exit`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
