import {Injectable} from '@angular/core';
import {HttpService} from '../../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class DataSetCodificationApiService {
    private _BASE_URL = 'data-set';

    constructor(private _http: HttpService) {}

    get(dataSetId: number, codificationId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetId}/codification/${codificationId}`, {params: urlRequestParams});
    }
}
