<div *ngFor="let param of condition.service.params.query; index as i; trackBy: trackByFn">
    <div class="query-field-container margin-left--10">
        <div class="display-flex">
            <button mat-icon-button
                    type="button"
                    matTooltip="{{'BUTTON.DELETE' | translate}}"
                    (click)="removeQueryCondition(i)">
                <mat-icon>remove_circle</mat-icon>
            </button>

            <button mat-icon-button
                    type="button"
                    matTooltip="{{'BUTTON.ADD' | translate}}"
                    (click)="addQueryCondition()">
                <mat-icon>add_circle</mat-icon>
            </button>
            <mat-form-field class="width-100 padding-right-5">
                <mat-select
                    placeholder="{{'FILTER_SEARCH.LIST.SEARCH.SORT_BY' | translate}}"
                    [panelClass]="['condition-select', 'mat-' + colorService.getColorAccordingToCurrentState()]"
                    (selectionChange)="updateDocumentsSort($event.value)">

                    <mat-option
                        *ngFor="let documentType of DocumentSortTypeEnum | keyvalue" [value]="documentType.value">
                        {{ 'FILTER_SEARCH.LIST.SEARCH.' +  documentType.key | translate }}
                    </mat-option>

                    <mat-progress-bar *ngIf="isLoading"
                                      mode="indeterminate"
                                      class="autocomplete-progress-bar"></mat-progress-bar>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="width-100">
                <mat-select #multipleSelect
                            multiple
                            placeholder="{{'DATA_SET.CODIFICATION.SEARCH.DOCUMENT_TYPE' | translate}}"
                            name="qDocumentTypeId[{{i}}]"
                            [panelClass]="['condition-select', 'mat-' + colorService.getColorAccordingToCurrentState()]"
                            [(ngModel)]="param.qDocumentTypeId"
                            [disabled]="documentTypes.length === 0"
                            (selectionChange)="selectionChange(param.qDocumentTypeId, i)">
                    <mat-option>
                        <ngx-mat-select-search
                            [placeholderLabel]="'BUTTON.SEARCH' | translate"
                            [formControl]="documentTypesSearch"
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-checkbox [color]="colorService.getColorAccordingToCurrentState()"
                                  [indeterminate]="isIndeterminate(i)"
                                  [checked]="isChecked(i)"
                                  (click)="toggleAll(i, $event)">
                        <span *ngIf="!isChecked(i)"> {{ 'DATA_SET.CODIFICATION.SEARCH.CHECK_ALL' | translate }} </span>
                        <span *ngIf="isChecked(i)"> {{ 'DATA_SET.CODIFICATION.SEARCH.UNCHECK_ALL' | translate }} </span>
                    </mat-checkbox>

                    <mat-option *ngFor="let documentType of documentTypes"
                                [value]="documentType.id">
                        {{ (documentType.name && (selectedDocumentTypeSort === DocumentSortTypeEnum.NAME)) ? documentType.name : documentType.slug }}
                        <span *ngIf="documentType.structuredData"><sup><b>S</b></sup></span>
                    </mat-option>
                </mat-select>
                <mat-progress-bar *ngIf="isLoading"
                                  mode="indeterminate"
                                  class="autocomplete-progress-bar"></mat-progress-bar>
            </mat-form-field>
        </div>

        <div class="q-field">
            <div class="align-center full-width">
                <mat-form-field class="full-width margin-right-10">
                    <textarea matInput
                              cdkTextareaAutosize
                              type="text"
                              [disabled]="documentTypes.length === 0"
                              [cdkAutosizeMinRows]="1"
                              [(ngModel)]="param.qMust"
                              [placeholder]="'DATA_SET.CODIFICATION.SEARCH.QUERY_QMUST' | translate"
                              (keydown.enter)="search();false">
                    </textarea>
                </mat-form-field>

                <mat-checkbox class="ignore-checkbox"
                              [color]="colorService.getColorAccordingToCurrentState()"
                              [(ngModel)]="param.ignoreNegated">
                    <span>{{ 'DATA_SET.CODIFICATION.SEARCH.IGNORE_NEGATIONS' | translate }}</span>
                </mat-checkbox>
            </div>

            <mat-form-field class="full-width">
                <textarea matInput
                          cdkTextareaAutosize
                          type="text"
                          [disabled]="documentTypes.length === 0"
                          [cdkAutosizeMinRows]="1"
                          [(ngModel)]="param.qNot"
                          [placeholder]="'DATA_SET.CODIFICATION.SEARCH.QUERY_QNOT' | translate"
                          (keydown.enter)="search();false">
                </textarea>
            </mat-form-field>

            <mat-form-field class="full-width">
                <textarea matInput
                          cdkTextareaAutosize
                          type="text"
                          [disabled]="documentTypes.length === 0"
                          [cdkAutosizeMinRows]="1"
                          [(ngModel)]="param.qOpt"
                          [placeholder]="'DATA_SET.CODIFICATION.SEARCH.QUERY_QOPT' | translate"
                          (keydown.enter)="search();false">
                </textarea>
            </mat-form-field>
        </div>
    </div>
</div>
