<mat-form-field class="full-width">
    <mat-chip-list #chipList
                   [required]="required">
        <mat-chip *ngFor="let selectedAct of selectedActs"
                  [matTooltip]="getTooltipText(selectedAct)"
                  matTooltipPosition="above"
                  [value]="selectedAct.slug"
                  [removable]="true"
                  (removed)="removeChip(selectedAct)">
            {{selectedAct.slug}}
            <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input #actInput
               cdkFocusInitial
               [placeholder]="'HEALTH.ACTS' | translate"
               [formControl]="formControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList">
    </mat-chip-list>

    <mat-progress-bar mode="indeterminate"
                      class="autocomplete-progress-bar"
                      *ngIf="isLoading"></mat-progress-bar>

    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="selectAct($event)">
        <mat-option *ngFor="let searchedAct of searchedActs | async; index as i"
                    [value]="searchedAct"
                    [ngbTooltip]="getTooltipText(searchedAct, i)"
                    [container]="'body'"
                    tooltipClass="ngb-tooltip"
                    placement="left">
            {{searchedAct.slug}} {{searchedAct.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
