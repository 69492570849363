import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomHtmlAttributeDirective} from './custom-html-attribute.directive';

@NgModule({
    declarations: [
        CustomHtmlAttributeDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CustomHtmlAttributeDirective
    ]
})
export class CustomHtmlAttributeDirectiveModule {}
