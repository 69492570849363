<mat-card class="no-padding full-height">
    <mat-card-content class="full-height">
        <div class="row full-height">
            <div class="col no-padding align-self-center">
                <ct-warning-indicator *ngIf="predictiveDiagnostic?.diagnostic?.codifiable && canDisplayImpreciseStayDateIndicator()"
                                      [dataSetElement]="dataSetElement"
                                      [tooltipText]="'TOOLTIP.IMPRECISE_STAY_DATE' | translate"
                                      [classes]="['margin-left-10', 'margin-right-5']"></ct-warning-indicator>

                <ng-container *ngIf="!impreciseDiagnostic">
                    <ng-container *ngFor="let label of labels">
                        <button mat-icon-button
                                class="hover-background-color {{getClasses(label)}}"
                                *ngIf="predictiveDiagnostic?.diagnostic?.codifiable"
                                [ngClass]="{'background-color': label.id === predictionElementComponent.getDiagnosticFromCodification(predictiveDiagnostic)?.codificationLabel?.id}"
                                [matTooltip]="getMatTooltip(label)"
                                (click)="updateCodification(label)">
                            {{label.name}}
                        </button>
                    </ng-container>

                    <button mat-icon-button
                            [matTooltip]="'TOOLTIP.TEXT_INFO' | translate"
                            (click)="showJustification()">
                        <mat-icon>search</mat-icon>
                    </button>

                    <button *ngIf="canUpdateGold"
                            mat-icon-button
                            [matTooltip]="'TOOLTIP.FIX_GOLD' | translate"
                            (click)="updateGold()">
                        <i class="fas fa-trophy text-color md-yellow-700 strikeout"></i>
                    </button>
                </ng-container>

                <ng-container *ngIf="impreciseDiagnostic">
                    <button mat-icon-button
                            [matTooltip]="'TOOLTIP.REPLACE_BY_PRECISE_DIAGNOSTIC' | translate"
                            (click)="replaceByPreciseDiagnostic()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
    </mat-card-content>
</mat-card>
