import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-select-with-static-data',
    templateUrl: './search-engine-condition-select-with-static-data.component.html',
    styleUrls: ['./search-engine-condition-select-with-static-data.component.scss']
})
export class SearchEngineConditionSelectWithStaticDataComponent implements OnInit {
    @Input() condition: any;
    @Input() data: any[] = [];
    @Input() paramKey: string;
    @Input() placeholder: string;

    constructor() { }

    ngOnInit(): void {
    }

}
