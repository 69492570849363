import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {VersionDisplayDialogComponent} from './version-display-dialog.component';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        VersionDisplayDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatProgressSpinnerModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule
    ]
})
export class VersionDisplayDialogModule {}
