import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertApiService} from '../../core/api-services/alert/alert.api.service';
import {SnackBarService} from '../../core/services/snack-bar.service';
import {Alert} from './alert.model';
import * as _ from 'lodash-es';
import {User} from '../user/user.model';
import {BroadcastService} from '../../core/services/broadcast.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private _subscribedAlerts: Alert[] = [];

    constructor(private _translateService: TranslateService,
                private _snackBarService: SnackBarService,
                private _broadcastService: BroadcastService,
                private _alertApiService: AlertApiService) {
    }

    get subscribedAlerts(): Alert[] {
        return this._subscribedAlerts;
    }

    getSubscribedAlertByName(name: string): Alert | undefined {
        return this._subscribedAlerts.find(alert => alert.name === name);
    }

    async loadAllAlerts() {
        try {
            const params = {
                'filter[parent_id]': null
            };
            return await this._alertApiService
                .getAll(params, false)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadSubscribedAlerts(params?: any) {
        try {
            this._subscribedAlerts = await this._alertApiService
                .getAllSubscribed(params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateAlert(alertId: number, body: any) {
        try {
            const res = await this._alertApiService
                .update(alertId, body)
                .toPromise();
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateAlertSubscribers(alertName: string, body: any) {
        try {
            const res = await this._alertApiService
                .updateAlertSubscribers(alertName, body)
                .toPromise();
            this._broadcastService.send('alert::updateAlertSubscribers');
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _updateSubscribedAlerts(status: boolean, newAlert: Alert) {
        if (status) {
            this._subscribedAlerts.push(newAlert);
        } else {
            const subscribedAlertsBeforeDeletion = _.cloneDeep(this._subscribedAlerts);
            this._subscribedAlerts.length = 0;
            [].push.apply(this._subscribedAlerts, subscribedAlertsBeforeDeletion.filter(el => el.id !== newAlert.id));
        }
    }

    async subscribeToAlert(alertName: string, toMany: boolean = false, usersEmail?: string[]) {
        try {
            let alert;
            if (!toMany) {
                alert = await this._alertApiService.subscribeByName(alertName).toPromise();
            } else {
                const body = {usersEmail};
                alert = await this._alertApiService.subscribeManyByName(alertName, body).toPromise();
            }
            if (alert) {
                this._updateSubscribedAlerts(true, alert);
                this._broadcastService.send('alert::updateAlertSubscribers');
                this._snackBarService.success(this._translateService.instant('SUCCESS.SUBSCRIBE'));
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async unsubscribeFromAlert(alertId: number, alertName?: string) {
        try {
            const alert = !alertName ? await this._alertApiService.unsubscribe(alertId).toPromise() :
                await this._alertApiService.unsubscribeByName(alertName).toPromise();
            if (alert) {
                this._updateSubscribedAlerts(false, alert);
                this._broadcastService.send('alert::updateAlertSubscribers');
                this._snackBarService.success(this._translateService.instant('SUCCESS.UNSUBSCRIBE'));
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadAlertSubscribers(alertName: string): Promise<User[]> {
        try {
            return await this._alertApiService
                .getAlertSubscribers(alertName)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
