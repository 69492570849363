import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../../modules/configuration/configuration.service';
import {CodificationLabelEnum} from '../../modules/stay/shared/codification-label-enum';

@Injectable({
    providedIn: 'root'
})
export class TranslationHelperService {

    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService) {
        this._translateService.addLangs(this._LANGUAGES);
    }

    private static _LANGUAGES_DATA = {
        fr: {
            codificationLabels: {
                DP: 'DP',
                DR: 'DR',
                DA: 'DA',
                DP_ROOT: 'DP_ROOT',
                DA_ROOT: 'DA_ROOT',
                HEALTH_STAY_SEVERITY: 'HEALTH_STAY_SEVERITY',
                MP: 'MP',
                AE: 'AE',
            },
            severities: {
                ghm: ['1', '2', '3', '4', 'A', 'B', 'C', 'D', 'T', 'J', 'E', 'Z'],
                gme: ['0', '1', '2']
            },
            MCO: ['DP', 'DR', 'DA'],
            SSR: ['MP', 'AE', 'DA'],
            ACT: {
                MCO: ['CCAM'],
                SSR: ['CCAM', 'CSARR']
            }
        },
        fr_ch: {
            codificationLabels: {
                DP: 'DP',
                DR: 'DR',
                DA: 'DA',
                DP_ROOT: 'DP_ROOT',
                DA_ROOT: 'DA_ROOT',
                HEALTH_STAY_SEVERITY: 'HEALTH_STAY_SEVERITY',
                MP: 'MP',
                AE: 'AE'
            },
            severities: {
                ghm: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'Z'],
                gme: ['0', '1', '2']
            },
            MCO: ['DP', 'DA'],
            SSR: ['MP', 'AE', 'DA'],
            ACT: {
                MCO: ['APCHOP'],
                SSR: ['APCHOP', 'CSARR']
            }
        },
        de: {
            codificationLabels: {
                DP: 'DP',
                DR: 'DR',
                DA: 'DA',
                DP_ROOT: 'DP_ROOT',
                DA_ROOT: 'DA_ROOT',
                HEALTH_STAY_SEVERITY: 'HEALTH_STAY_SEVERITY',
                MP: 'MP',
                AE: 'AE'
            },
            severities: {
                ghm: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'Z'],
                gme: ['0', '1', '2']
            },
            MCO: ['DP', 'DA'],
            SSR: ['MP', 'AE', 'ND']
        }
    };
    private static _currentLang: string;
    private _LANGUAGES = ['fr', 'de'];
    private _DRG_LANGUAGES = ['de', 'fr_ch'];
    private _AVAILABLE_FEATURES = {
        DR: ['fr'],
        GHS_GMT: ['fr'],
        RUM: ['fr', 'fr_ch'],
        diagnosticSeverityLevel: ['fr'],
        documentation: ['fr', 'fr_ch'],
        staySeverity: ['fr'],
        lamda: ['fr'],
        stayLessThan3Days: ['fr'],
        classingActs: ['fr', 'de'],
        CSARRActs: ['fr']
    };
    // To display states name in toolbar
    STATES_TRANSLATIONS = {
        'stay-list': 'TOOLBAR.STAY_LIST',
        'stay-list-all': 'TOOLBAR.STAY_LIST_ALL',
        'stay-list-external': 'TOOLBAR.STAY_LIST_EXTERNAL',
        'stay-list-rehabilitation': 'TOOLBAR.STAY_LIST',
        'stay-detail': 'TOOLBAR.STAY_DETAIL',
        'stay-detail-old': 'TOOLBAR.STAY_DETAIL',
        'worklist-user': 'TOOLBAR.WORKLIST_USER',
        'data-monitoring': 'TOOLBAR.DATA_MONITORING',
        'filter-search-stay-list': 'TOOLBAR.FILTER_SEARCH_STAY_LIST',
        'filter-search-stay-list-external': 'TOOLBAR.FILTER_SEARCH_STAY_LIST_EXTERNAL',
        'filter-search-patient-list': 'TOOLBAR.FILTER_SEARCH_PATIENT_LIST',
        'quality-control': 'TOOLBAR.QUALITY_CONTROL',
        'quality-control-imprecise-diagnostic': 'TOOLBAR.QUALITY_CONTROL_IMPRECISE_DIAGNOSTIC',
        'quality-control-near-automation': 'TOOLBAR.QUALITY_CONTROL_NEAR_AUTOMATION',
        'diagnostic-note-list': 'TOOLBAR.DIAGNOSTIC_NOTE_LIST',
        'chronic-diagnostic-list': 'TOOLBAR.CHRONIC_DIAGNOSTIC_LIST',
        'layout.diagnostic-note-detail': 'TOOLBAR.DIAGNOSTIC_NOTE_DETAIL',
        'user-list': 'TOOLBAR.USER_LIST',
        'layout.user-detail': 'TOOLBAR.USER_DETAIL',
        'user-edit-password': 'TOOLBAR.EDIT_PASSWORD',
        'prediction-analysis': 'TOOLBAR.PREDICTION_ANALYSIS',
        'diagnostic-automatic': 'TOOLBAR.AUTO_DIAGNOSIS',
        'monitoring': 'TOOLBAR.MONITORING',
        'configuration': 'TOOLBAR.CONFIGURATION',
        'health-document-anonymization': 'TOOLBAR.ANONYMIZATION',
        'document-type-list': 'TOOLBAR.DOCUMENT_TYPE_LIST',
        'layout.document-type-document-list': 'TOOLBAR.DOCUMENT_TYPE_DOCUMENT_LIST',
        'log': 'TOOLBAR.LOG',
        'feedback-list': 'TOOLBAR.FEEDBACK_LIST',
        'storyboard-list': 'TOOLBAR.STORYBOARD_LIST',
        'layout.storyboard-detail': 'TOOLBAR.STORYBOARD_DETAIL',
        'patient-list': 'TOOLBAR.PATIENT_LIST',
        'patient-detail': 'TOOLBAR.PATIENT_DETAIL',
        'dashboard': 'TOOLBAR.DASHBOARD',
        'dashboard-custom': 'TOOLBAR.DASHBOARD_CUSTOM',
        'dashboard-custom-creation': 'TOOLBAR.DASHBOARD_CUSTOM_CREATION',
        'dashboard-custom-detail': 'TOOLBAR.DASHBOARD_CUSTOM_DETAIL',
        'dashboard-custom-edition': 'TOOLBAR.DASHBOARD_CUSTOM_EDITION',
        'visualization-detail': 'TOOLBAR.VISUALIZATION_DETAIL',
        'visualization-creation': 'TOOLBAR.VISUALIZATION_CREATION',
        'visualization-edition': 'TOOLBAR.VISUALIZATION_EDITION',
        'alert-list': 'TOOLBAR.ALERT_LIST',
        'alert-unsubscribe': 'TOOLBAR.ALERT_UNSUBSCRIBE',
        'ml-report-list': 'TOOLBAR.MACHINE_LEARNING_REPORT_LIST',
        'machine-learning-generic-call': 'TOOLBAR.MACHINE_LEARNING_GENERIC_CALL'
    };

    public getCodificationLabel(codificationLabelSlug: CodificationLabelEnum) {
        const codificationLabels = this.getCodificationLabels();
        return codificationLabels[codificationLabelSlug] ?? '';
    }

    private async _loadLanguageConfig() {
        try {
            const languageConfig = await this._configurationService
                .loadSingleConfiguration('language');
            if (languageConfig &&
                languageConfig.name === 'language' &&
                languageConfig.content) {
                return languageConfig.content.toString();
            }
            return '';
        } catch (e) {
            throw e;
        }
    }

    async initTranslateLanguage() {
        try {
            const language = await this._loadLanguageConfig() || 'fr';
            this._translateService.use(language);
            TranslationHelperService._currentLang = language;
        } catch (e) {
            this._translateService.use('fr');
            TranslationHelperService._currentLang = 'fr';
            throw e;
        }
    }

    /**
     * Return true if feature is available for this language
     * @param feature
     */
    isFeatureAvailable(feature: string): boolean {
        return this._AVAILABLE_FEATURES[feature] ? this._AVAILABLE_FEATURES[feature].includes(TranslationHelperService._currentLang) : false;
    }

    isCurrentLanguageDRG() {
        return !!(TranslationHelperService._currentLang && this._DRG_LANGUAGES.includes(TranslationHelperService._currentLang));
    }

    getStateDisplayName(stateName: string) {
        if (stateName) {
            return this.STATES_TRANSLATIONS[stateName] ? this._translateService.instant(this.STATES_TRANSLATIONS[stateName]) : '';
        }
        return '';
    }

    getCodificationLabels() {
        if (TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang]) {
            return TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang].codificationLabels;
        }
        return {};
    }

    getCodificationActLabelsByAppType(appType: string) {
        if (TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang]?.ACT) {
            return TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang].ACT[appType];
        }
        return {};
    }

    getCodificationLabelsByAppType(appType: string, appTypeIsMCOAndSSR?: boolean) {
        if (TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang]) {
            if (appTypeIsMCOAndSSR) {
                return TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang].MCO.concat(TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang].SSR);
            }
            return TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang][appType] ?? [];
        }
        return [];
    }

    getSeverities(useCase: string): any[] {
        if (TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang] &&
            TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang].severities &&
            TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang].severities[useCase]) {
            return TranslationHelperService._LANGUAGES_DATA[TranslationHelperService._currentLang].severities[useCase];
        }
        return [];
    }
}




