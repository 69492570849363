import { StayUserButtonModule } from './../../../../shared/components/stay-user-button/stay-user-button.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {StayDetailHeaderComponent} from './stay-detail-header.component';

@NgModule({
    declarations: [
        StayDetailHeaderComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        StayUserButtonModule,
    ],
    exports: [
        StayDetailHeaderComponent
    ],
})
export class StayDetailHeaderModule {}
