import {Injectable} from '@angular/core';
import * as moment from 'moment';


export enum DateComparisonLevel {
    YEAR,
    MONTH,
    DAY,
    HOUR,
    MINUTE,
    SECOND,
}

@Injectable({
    providedIn: 'root'
})


export class DateHelperService {

    constructor() { }

    static toMysql(date: Date) {
        return date ? date.getFullYear() + '-' +
            (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' +
            (date.getDate() < 10 ? '0' : '') + date.getDate() : null;
    }

    static mysqlToInput(date: string) {
        if (date) {
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const day = date.substring(8, 10);
            return month + '/' + day + '/' + year;
        }
        return '';
    }

    static getDate() {
        const m = moment();
        return m.isValid() ? m.toDate() : new Date();
    }

    static getDateRangeByType(type: string) {
        const data: any = {};
        switch (type) {
            case 'yesterday':
                data.startDate = moment()
                    .subtract(1, 'days')
                    .toDate();
                data.endDate = moment()
                    .subtract(1, 'days')
                    .toDate();
                break;
            case 'today':
                data.startDate = this.getDate();
                data.endDate = this.getDate();
                break;
            case 'last7Days':
                data.startDate = moment()
                    .subtract(7, 'days')
                    .toDate();
                data.endDate = moment()
                    .toDate();
                break;
            case 'last30Days':
                data.startDate = moment()
                    .subtract(30, 'days')
                    .toDate();
                data.endDate = moment()
                    .toDate();
                break;
            case 'last12Months':
                data.startDate = moment()
                    .subtract(12, 'months')
                    .toDate();
                data.endDate = moment()
                    .toDate();
                break;
            case 'currentMonth':
                data.startDate = moment()
                    .startOf('month')
                    .toDate();
                data.endDate = moment()
                    .toDate();
                break;
            case 'currentYear':
                data.startDate = moment()
                    .startOf('year')
                    .toDate();
                data.endDate = moment()
                    .toDate();
                break;
            case 'lastMonth':
                data.startDate = moment()
                    .subtract(1, 'months')
                    .startOf('month')
                    .toDate();
                data.endDate = moment()
                    .subtract(1, 'months')
                    .endOf('month')
                    .toDate();
                break;
            case 'lamda':
                data.startDate = moment()
                    .subtract(1, 'years')
                    .subtract(1, 'months')
                    .startOf('month')
                    .toDate();
                data.endDate = moment()
                    .subtract(1, 'years')
                    .endOf('year')
                    .toDate();
                break;
        }
        return data;
    }

    mysqlToFrench(date: string) {
        if (date) {
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const day = date.substring(8, 10);
            return day + '/' + month + '/' + year;
        }
        return '';
    }

    mysqlToString(endDate: string, scale: string): string {
        if (endDate &&
            scale) {
            const dateSplit = endDate.split('-');
            if (dateSplit && dateSplit.length > 1) {
                const year = moment(dateSplit[0], 'YYYY').format('YYYY');
                let month = moment(dateSplit[1], 'MM').format('MMM');
                switch (scale) {
                    case 'year':
                        return year;
                    case 'month':
                        return `${month} ${year}`;
                    case 'week':
                    case 'day':
                        // Need to pass month day and not just day because otherwise 31 is invalid
                        let day = moment(`${month} ${dateSplit[2]}`, 'MMM DD').format('DD');
                        if (scale === 'week') {
                            const date = moment().year(+year).week(+dateSplit[1].substring(1)).startOf('isoWeek');
                            month = date.format('MMM');
                            day = date.format('DD');
                        }
                        return `${day} ${month} ${year}`;
                    default:
                        return '';
                }
            }
        }
        return '';
    }

    getTime(date: string, returnMilliseconds?: boolean) {
        if (date) {
            return !returnMilliseconds ? date.substring(11, 16) : date.substring(11, 19);
        }
        return '';
    }

    getDiffInDays(startDate: string, endDate: string) {
        if (startDate &&
            endDate) {
            return moment(endDate).diff(moment(startDate), 'days');
        }
        return 0;
    }

    getDateRangeBySubtract(amount: number, unit: any) {
        const data: any = {
            startDate: '',
            endDate: ''
        };
        if (amount) {
            data.startDate = moment()
                .subtract(amount, unit)
                .toDate();
            data.endDate = moment()
                .toDate();
        }
        return data;
    }

    mysqlDateTimeToFrench(date: string): string {
        if (date) {
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const day = date.substring(8, 10);
            const hour = date.substring(11, 13);
            const minute = date.substring(14, 16);
            return day + '/' + month + '/' + year + ' ' + hour + ':' + minute;
        }
        return '';
    }

    InputExtractTime(date: string) {
        if (date) {
            return date.substring(11, 16);
        }
        return '';
    }
    InputExtractDate(date: string) {
        if (date) {
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const day = date.substring(8, 10);
            return day + ':' + month + ':' + year;
        }
        return '';
    }


     datesAreEqual(date1: string, date2: string, level: DateComparisonLevel): boolean {
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        // Compare the dates based on the specified level
        switch (level) {
            case DateComparisonLevel.YEAR:
                return d1.getFullYear() === d2.getFullYear();
            case DateComparisonLevel.MONTH:
                return (
                    d1.getFullYear() === d2.getFullYear() &&
                    d1.getMonth() === d2.getMonth()
                );
            case DateComparisonLevel.DAY:
                return (
                    d1.getFullYear() === d2.getFullYear() &&
                    d1.getMonth() === d2.getMonth() &&
                    d1.getDate() === d2.getDate()
                );
            case DateComparisonLevel.HOUR:
                return (
                    d1.getFullYear() === d2.getFullYear() &&
                    d1.getMonth() === d2.getMonth() &&
                    d1.getDate() === d2.getDate() &&
                    d1.getHours() === d2.getHours()
                );
            case DateComparisonLevel.MINUTE:
                return (
                    d1.getFullYear() === d2.getFullYear() &&
                    d1.getMonth() === d2.getMonth() &&
                    d1.getDate() === d2.getDate() &&
                    d1.getHours() === d2.getHours() &&
                    d1.getMinutes() === d2.getMinutes()
                );
            case DateComparisonLevel.SECOND:
                return (
                    d1.getFullYear() === d2.getFullYear() &&
                    d1.getMonth() === d2.getMonth() &&
                    d1.getDate() === d2.getDate() &&
                    d1.getHours() === d2.getHours() &&
                    d1.getMinutes() === d2.getMinutes() &&
                    d1.getSeconds() === d2.getSeconds()
                );
            default:
                throw new Error('Invalid date comparison level');
        }
    }
}
