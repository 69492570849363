import {Injectable} from '@angular/core';
import {HealthMovementApiService} from '../../../core/api-services/health-movement/health-movement.api.service';
import {HealthMovement} from './health-movement.model';

@Injectable({
    providedIn: 'root'
})
export class HealthMovementService {
    constructor(private _healthMovementApiService: HealthMovementApiService) {}

    async loadAllHealthMovements(useCase: string, params?: any): Promise<(HealthMovement)[]> {
        try {
            if (useCase === 'entrance') {
                return await this._healthMovementApiService
                    .getHealthMovementsEntrance(params)
                    .toPromise();
            } else {
                return await this._healthMovementApiService
                    .getHealthMovementsExit(params)
                    .toPromise();
            }
        } catch (e) {
            throw e;
        }
    }
}
