import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionDependencyComponent} from './search-engine-condition-dependency.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDressingOrToiletingDependencyService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'dressingOrToiletingDependency';
    placeholder = 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DRESSING';
    data: {id: number, text: string, level: number}[] = [
        {id: 1, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.1', level: 1},
        {id: 2, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.2', level: 2},
        {id: 3, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.3', level: 3},
        {id: 4, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.4', level: 4}
    ];

    constructor(private _translateService: TranslateService) {
        super(['dressingOrToiletingDependency'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DRESSING'),
            'dressingOrToiletingDependency',
            SearchEngineConditionDependencyComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {dressingOrToiletingDependency: []};
        this.uriParams = {dressingOrToiletingDependency: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.dressingOrToiletingDependency) {
            this.uriParams.dressingOrToiletingDependency = this.params.dressingOrToiletingDependency.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.dressingOrToiletingDependency) {
            this.params.dressingOrToiletingDependency = this.uriParams.dressingOrToiletingDependency.split(';')?.map(id => Number.parseFloat(id));
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.behaviourDependency = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.dressingOrToiletingDependency};
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields['dressingOrToiletingDependency']) &&
            !this.isEmpty(fields['dressingOrToiletingDependency']));
    }
}
