import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {StayDetailDocumentService} from './stay-detail-document.service';
import {Subscription} from 'rxjs';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import {DocumentTypeListService} from '../../../document-type/list/document-type-list.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {AppLogService} from '../../../../core/app-log/app-log.service';
import {PatientDetailService} from '../../../patient/detail/patient-detail.service';
import {StayDetailDocumentElementService} from './element/stay-detail-document-element.service';

@Component({
    selector: 'ct-stay-detail-document',
    templateUrl: './stay-detail-document.component.html',
    styleUrls: ['./stay-detail-document.component.scss']
})
export class StayDetailDocumentComponent implements OnInit, OnDestroy {
    @ViewChild('header') headerElement: ElementRef;
    @ViewChildren('cardList') cardList: QueryList<any>;

    private _subscriptions: Subscription[] = [];

    @Input() dataSetElement: DataSetElement;
    @Input() leftSideDisplayState: string;
    @Input() codificationHistoryDisplayState: string;
    @Input() commentDisplayState: string;

    @Output() toggleLeftSideCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() toggleCodificationHistoryCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() toggleCommentCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();

    stayDocumentsGroupedByInferredDocType: any = {};
    patientDocumentsGroupedByInferredDocType: any = {};
    query: any;
    isLoading: boolean;
    isPre: boolean;
    canDisplayJustificationSearchInput: boolean;
    canDisplayStayRisk: boolean;
    canDisplayDocumentTypeFromML: boolean;
    docNb = 0;
    stayDocNb: number;
    patientDocNb: number;
    patientStays: any[];

    // Document types from ML
    // undefined means doc inferredDocumentType property is null
    inferredDocumentTypes = ['crh', 'cro', 'anapath', 'other', 'undefined'];

    constructor(public $state: StateService,
                private _stayDetailDocumentService: StayDetailDocumentService,
                private _documentTypeListService: DocumentTypeListService,
                private _patientDetailService: PatientDetailService,
                private _broadcastService: BroadcastService,
                private _configurationService: ConfigurationService,
                private _logService: AppLogService,
                private _stayDetailDocumentElementService: StayDetailDocumentElementService) {
    }

    ngOnInit() {
        this._initConfig();
        this._loadData();
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailDocument::focusDocument':
                        this._focusDocument(res.data.documentId);
                        break;
                    case 'stayDetailDocument::detach':
                    case 'stayDetailDocument::attach':
                        this._updateDocuments(res.data.documents);
                        break;
                    case 'stayDetailFilterSearch::reload':
                        this.canDisplayJustificationSearchInput = res.data ? res.data.isSelected : false;
                        break;
                    case 'stayDetailRightSide::scrollToTop':
                        this._scrollToTop();
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private _initConfig() {
        this.isPre = this._configurationService.getConfigurationContent('front', 'health.document.isPreDefault');
        this.canDisplayStayRisk = this._configurationService.getConfigurationContent('front', 'health.canDisplayStayRisk');
        this.canDisplayJustificationSearchInput = (!this.$state.params.fromFilterSearchList || this.$state.params.fromFilterSearchList === 'false') && (!this.$state.params.fromQualityControl || this.$state.params.fromQualityControl === 'false')  && (!this.$state.params.forFilterSearch || this.$state.params.forFilterSearch === 'false');
        this.canDisplayDocumentTypeFromML = this._configurationService.getConfigurationContent('front', 'health.document.canDisplayDocumentTypeFromML');
    }

    private async _loadData() {
        try {
            this.isLoading = true;
            const promises = [this._loadAllDocuments()];
            if (this.canDisplayStayRisk) {
                // We need to load patient stays only for patient docs
                promises.push(this._patientDetailService.loadPatientStays(this.dataSetElement.dataSetContent.healthPatientId, {include: 'contents,establishment'}));
            }
            const res = await Promise.all(promises);
            this.patientStays = res[1] ? this._stayDetailDocumentService.filterPatientStays(res[1], this.dataSetElement) : [];
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    private _scrollToTop() {
        this.headerElement.nativeElement.scrollIntoView();
    }

    private _focusDocument(documentId: number) {
        const selectedItem = this.cardList.find(card =>
            card.nativeElement.id === 'document' + documentId);
        if (selectedItem) {
            selectedItem.nativeElement.scrollIntoView();
        }
    }

    private _updateDocuments(documents: any) {
        if (documents) {
            documents.stayDocument = this._processDocuments(documents.stayDocument, false);
            documents.patientDocument = this._processDocuments(documents.patientDocument, true);
            this.docNb = this.canDisplayStayRisk ? this.stayDocNb + this.patientDocNb : this.stayDocNb;
            this._broadcastService.send('stayDetailDocument::documentsNumber', {
                docNb: this.docNb
            });
            if (this.canDisplayDocumentTypeFromML) {
                this.stayDocumentsGroupedByInferredDocType = this._stayDetailDocumentService.groupDocumentsByInferredDocumentType(documents.stayDocument);
                this.patientDocumentsGroupedByInferredDocType = this._stayDetailDocumentService.groupDocumentsByInferredDocumentType(documents.patientDocument);
            } else {
                this.stayDocumentsGroupedByInferredDocType = {undefined: documents.stayDocument};
                this.patientDocumentsGroupedByInferredDocType = {undefined: documents.patientDocument};
            }
            this._broadcastService.send('stayDetailDocument::updateDocumentsDone', {
                documents: {
                    stayDocuments: this.stayDocumentsGroupedByInferredDocType,
                    patientDocuments: this.patientDocumentsGroupedByInferredDocType
                }
            });
        }
    }

    private async _loadAllDocuments() {
        try {
            const documents = await this._stayDetailDocumentService
                .loadAllDocuments(this.dataSetElement.id);
            this._updateDocuments(documents);
            return documents;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Set isOpened attribute for document and load columns for
     * structured documents
     * @private
     */
    private _processDocuments(documents: any[], forPatientDocuments: boolean) {
        if (documents) {
            // Don't display documents which have document type display set to false
            documents = documents.filter(document => document && document.documentType && document.documentType.display);
            const nbDocuments = documents.length;
            let structuredDocTypeId = {};
            const nbDocsByDocumentType = {};
            documents.forEach((document, index) => {
                if (document.documentType) {
                    document.subDocumentId = [];
                    if (document.documentType.structuredData === false &&
                        nbDocsByDocumentType[document.documentTypeId]) {
                        nbDocsByDocumentType[document.documentTypeId]++;
                    } else {
                        nbDocsByDocumentType[document.documentTypeId] = 1;
                    }
                    // If we have more than 10 documents we close all by default
                    if (nbDocuments > 10 || document.documentType.structuredData === true) {
                        document.isOpened = false;
                    }
                    document.name = document.filename.replace(/\.[^/.]+$/, '');

                    // Get columns data foreach structured document once by documentType
                    if (document.documentType.structuredData) {
                        if (typeof structuredDocTypeId[document.documentType.id] === 'undefined') {
                            this._stayDetailDocumentElementService._getStructuredDataColumns(document)
                                .then(columns => {
                                    document.columns = columns || [];
                                });
                            const dataInfo = {};
                            dataInfo[document.documentType.id] = index;
                            structuredDocTypeId = {
                                ...structuredDocTypeId,
                                ...dataInfo
                            };
                        } else {
                            const tmpDocument = documents[structuredDocTypeId[document.documentType.id]];
                            if (tmpDocument) {
                                tmpDocument.subDocumentId.push(document.id);
                                document.subDocumentId = false;
                            }
                        }
                    }
                }
            });

            const values: number[] = Object.values(nbDocsByDocumentType);
            const docNb = values.reduce(((accumulator, currentValue) => accumulator + currentValue), 0);
            if (!forPatientDocuments) {
                this.stayDocNb = docNb;
            } else {
                this.patientDocNb = docNb;
            }
            return documents;
        }
        this.stayDocNb = 0;
        this.patientDocNb = 0;
        return [];
    }

    openCloseDocuments(status: boolean, type: string) {
        this._broadcastService.send('stayDetailDocument::openCloseAllDocuments', {status, type});
        if (type) {
            const messageDocType = type === 'all' ? 'all' : type === 'stay' ? 'stay' : 'patient';
            this._logService.logInfo(status === true ? `Open ${messageDocType} documents` : `Close ${messageDocType} documents`);
        }
    }

    switchCanDisplayJustificationSearchInput() {
        if (this.$state &&
            this.$state.params) {
            this.canDisplayJustificationSearchInput = true;
            sessionStorage.removeItem('currentFilterSearch');
            const params: any = {
                ...this.$state.params,
                dataSetElementId: this.dataSetElement.id,
                AIValuation: '',
                forFilterSearch: 'false'
            };
            this.$state.transitionTo(this.$state.current.name, params)
                .then(() => {
                    this._broadcastService.send('stayDetailFilterSearch::reload', {isSelected: true, reloadOnlyFirstTab: true});
                    this._broadcastService.send('stayDetailJustification::search');
                });
        }
    }

    hasDocuments(useCase: string): boolean {
        const keys = useCase === 'stay' ? Object.keys(this.stayDocumentsGroupedByInferredDocType) : Object.keys(this.patientDocumentsGroupedByInferredDocType);
        return !!keys?.length;
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
