import {ComponentFactoryResolver, ComponentRef, Directive, HostListener, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {TooltipComponent} from '../../components/tooltip/tooltip.component';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[ctTooltip]'
})
export class TooltipDirective implements OnDestroy, OnInit {
    private _timeoutId: number;
    private _subscription: Subscription;

    @Input() templateComponent: any;
    @Input() right: string;
    @Input() overrideRightCssProperty: boolean;
    @Input() config: any;

    componentRef: ComponentRef<TooltipComponent>;

    constructor(private _componentFactoryResolver: ComponentFactoryResolver,
                private _viewContainerRef: ViewContainerRef,
                private _broadCastService: BroadcastService) {
    }

    ngOnInit(): void {
        this._subscription = this._broadCastService
            .broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'tooltipDirective::destroy':
                        this.destroy();
                        break;
                    default:
                }
            });
    }

    /**
     * Generate component inside ng-template
     * @private
     */
    private _generateNgTemplate(): void {
        if (this.templateComponent) {
            const resolveComponentFactoryTemplate = this._componentFactoryResolver.resolveComponentFactory(this.templateComponent);
            const templateComponent: any = this.componentRef.instance.templateContainerRef.createComponent(resolveComponentFactoryTemplate);
            if (this.config) {
                // Instantiate variables
                Object.keys(this.config).forEach(key => {
                    templateComponent.instance[key] = this.config[key];
                });
            }
        }
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        if (this.templateComponent) {
            if (this.componentRef) {
                return;
            }
            clearTimeout(this._timeoutId);
            this._timeoutId = setTimeout(() => {
                const factory = this._componentFactoryResolver.resolveComponentFactory(TooltipComponent);
                this.componentRef = this._viewContainerRef.createComponent(factory);
                this.componentRef.instance.right = this.right;
                this.componentRef.instance.overrideRightCssProperty = this.overrideRightCssProperty;
                this.componentRef.instance.componentRef = this.componentRef;
                this.componentRef.instance.tooltipDirective = this;
                this._generateNgTemplate();
            }, 100);
        }
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        clearTimeout(this._timeoutId);
        // Timeout because otherwise the tooltip is destroyed
        // during the transition between the parent and the tooltip
        this._timeoutId = setTimeout(() => {
            if (this.componentRef &&
                !this.componentRef.instance.isHovering) {
                this.destroy();
            }
        }, 100);
    }

    destroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }

    ngOnDestroy() {
        this.destroy();
        this._subscription.unsubscribe();
    }
}
