import {Component, Input, OnInit} from '@angular/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {DateHelperService} from '../../../../../core/services/date.helper.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionHospitalizationTypeService} from '../../../../search-engine/condition/hospitalization-type/search-engine-condition-hospitalization-type.service';
import {StayDetailInfoAdditionalService} from './stay-detail-info-additional.service';

@Component({
    selector: 'ct-stay-detail-info-additional',
    templateUrl: './stay-detail-info-additional.component.html',
    styleUrls: ['./stay-detail-info-additional.component.scss']
})
export class StayDetailInfoAdditionalComponent implements OnInit {
    @Input() dataSetElement: DataSetElement;
    @Input() isTypeRehabilitation: boolean;

    constructor(public dateHelperService: DateHelperService,
                private _translateService: TranslateService,
                private _stayDetailInfoAdditionalService: StayDetailInfoAdditionalService,
                private _configurationService: ConfigurationService) { }

    ngOnInit(): void {
    }

    hasHealthStayAdditional(): boolean {
        return !!this.dataSetElement?.dataSetContent?.healthStayAdditional;
    }

    getPresenceDaysText(presenceDays: string): string {
        return this._stayDetailInfoAdditionalService.getPresenceDaysText(presenceDays);
    }

    getHospitalizationTypeText(hospitalizationType: number): string {
        if (SearchEngineConditionHospitalizationTypeService.data) {
            const key = SearchEngineConditionHospitalizationTypeService.data.find(el => el.id === hospitalizationType)?.text;
            return key ? this._translateService.instant(key) : '';
        }
        return '';
    }

    getDoctorName(healthDoctor: any): string {
        if (healthDoctor) {
            let stringToReturn = healthDoctor.firstName ? `${healthDoctor.firstName} ` : '';
            if (healthDoctor.lastName) {
                stringToReturn += healthDoctor.lastName;
            }
            return stringToReturn;
        }
        return '';
    }
}
