import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ct-field-display',
    templateUrl: './field-display.component.html',
    styleUrls: ['./field-display.component.scss']
})
export class FieldDisplayComponent implements OnInit {
    @Input() key: string;
    @Input() value: any;
    @Input() unit: string;
    @Input() justifyContent: string;
    @Input() isKeyBold: boolean;
    @Input() isValueBold: boolean;
    @Input() isValueDisabled: boolean;
    @Input() tooltipText: string;
    @Input() tooltipPlacement = 'bottom';
    @Input() htmlAttributeName: string;
    @Input() htmlAttributeValue: string;

    constructor() { }

    ngOnInit() {
    }

}
