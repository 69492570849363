import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionNumberComparisonComponent} from '../../shared/search-engine-condition-number-comparison/search-engine-condition-number-comparison.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionNumberOfSessionsService extends SearchEngineCondition {
    // Because of shared component
    paramMinKey = 'nbMin';
    paramMaxKey = 'nbMax';
    placeholder = 'DATA_SET.CODIFICATION.SEARCH.NUMBER_OF_SESSIONS';

    constructor(private _translateService: TranslateService) {
        super(['nbSession'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.NUMBER_OF_SESSIONS'),
            'nbSession',
            SearchEngineConditionNumberComparisonComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {nbMin: null, nbMax: null};
        this.uriParams = {nbSession: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.updateUriParamsComparisonNumber(this.uriParams, 'nbSession', this.params, 'nbMin', 'nbMax');
    }

    convertToParams(): void {
        this.updateParamsComparisonNumber(this.uriParams, 'nbSession', this.params, 'nbMin', 'nbMax');
    }

    convertToParamsFromApiParams(): void {
        this.params.nbMin = this.apiParams.args.minValue;
        this.params.nbMax = this.apiParams.args.maxValue ?? null;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.getComparisonArgsObject(this.params.nbMin, this.params.nbMax)};
    }

    isValid(fields?: any): boolean {
        return this.isValidComparisonNumbers(fields, 'nbSession');
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
