import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {UploadFileButtonComponent} from './upload-file-button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        UploadFileButtonComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        UploadFileButtonComponent
    ]
})
export class UploadFileButtonModule {}
