import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StayDetailCodificationActEditionComponent} from './stay-detail-codification-act-edition.component';
import {StayDetailCodificationActAddAutocompleteComponent} from '../add-autocomplete/stay-detail-codification-act-add-autocomplete.component';
import {CustomHtmlAttributeDirectiveModule} from '../../../../../../shared/directives/custom-html-attribute/custom-html-attribute.directive.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FieldDisplayModule} from '../../../../../../shared/components/field-display/field-display.module';

@NgModule({
    declarations: [
        StayDetailCodificationActEditionComponent,
        StayDetailCodificationActAddAutocompleteComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        NgbTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatInputModule,
        MatRadioModule,
        MatDialogModule,
        MatDividerModule,
        MatChipsModule,
        MatAutocompleteModule,
        CustomHtmlAttributeDirectiveModule,
        MatDatepickerModule,
        FormsModule,
        FieldDisplayModule
    ],
    exports: [
        StayDetailCodificationActEditionComponent
    ],
})
export class StayDetailCodificationActEditionModule {}
