import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {FileDisplayDialogComponent} from '../dialog/file-display-dialog.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ct-file-display-button',
    templateUrl: './file-display-button.component.html',
    styleUrls: ['./file-display-button.component.scss']
})
export class FileDisplayButtonComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];
    private _dialogTypes = ['pdf', 'jpg', 'png', 'jpeg'];
    private _extension: string;
    private _filename: string;

    @Input() src: string;
    @Input() title: string;

    @Input() set filename(newFilename: string) {
        if (newFilename &&
            newFilename !== this._filename) {
            this._filename = newFilename;
            this._extension = this._filename.split('.').pop().toLowerCase();
            this._setType();
        }
    }

    @Output() dialogClosed = new EventEmitter<boolean>();

    icon: string;
    tooltip: string;

    constructor(private _translateService: TranslateService,
                private _matDialog: MatDialog) { }

    ngOnInit() {}

    private _setType() {
        this.tooltip = this._translateService.instant('TOOLTIP.DISPLAY_ORIGINAL_DOC');
        this.icon = 'insert_drive_file';
    }

    public displayDocument(event: any) {
        if (event) {
            event.stopPropagation();
        }
        const dialogRef = this._matDialog.open(FileDisplayDialogComponent, {
            data: {
                src: this.src,
                filename: this._filename,
                title: this.title
            },
            autoFocus: false,
            panelClass: 'file-display-dialog'
        });

        const sub = dialogRef
            .afterClosed()
            .subscribe(() => {
                this.dialogClosed.emit(true);
            });
        this._subscriptions.push(sub);
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
