import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {DocumentHighlightPreviewComponent} from './document-highlight-preview.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PipesModule} from '../../pipes/pipes.module';
import {DocumentHighlightPreviewStructuredModule} from './structured/document-highlight-preview-structured.module';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        DocumentHighlightPreviewComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        DocumentHighlightPreviewStructuredModule,
        PipesModule,
        NgxDatatableModule,
        MatIconModule,
        MatDividerModule,
        MatTooltipModule
    ],
    exports: [
        DocumentHighlightPreviewComponent
    ]
})
export class DocumentHighlightPreviewModule {}
