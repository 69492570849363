import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MedicationComponent} from './medication.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MedicationDetailDialogComponent} from './detail-dialog/medication-detail-dialog.component';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
    declarations: [
        MedicationComponent,
        MedicationDetailDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        PipesModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        MedicationComponent
    ]
})
export class MedicationModule {}
