import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionHasDocumentEssentialService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['hasDocumentEssential'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.DISPLAY_STAY_WITH_DOC_ESSENTIAL'),
            'hasDocumentEssential',
            null,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {hasDocumentEssential: ''};
        this.uriParams = {hasDocumentEssential: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.uriParams.hasDocumentEssential = this.params.hasDocumentEssential;
    }

    convertToParams(): void {
        this.params.hasDocumentEssential = this.uriParams.hasDocumentEssential;
    }

    convertToParamsFromApiParams(): void {
        this.params.hasDocumentEssential = this.apiParams.args.value;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {value: this.params.hasDocumentEssential};
    }
}
