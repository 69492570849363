import {DataSetElement} from '../../modules/stay/shared/data-set-element.model';

export class DataHelperService {
    static isDefinedAndNotNull(value: any): boolean {
        return typeof value !== 'undefined' &&
            value !== null;
    }

    static isObject(value: any): boolean {
        return DataHelperService.isDefinedAndNotNull(value) && typeof value === 'object' && !Array.isArray(value);
    }

    static deleteEmptyUndefinedNullFromObject(object: any): void {
        if (object) {
            Object.keys(object).forEach(key => {
                if (typeof object[key] === 'undefined' ||
                    object[key] === '' ||
                    object[key] === null) {
                    delete object[key];
                }
            });
        }
    }

    static addInnerHitsToData(res: any): any {
        if (res) {
            if (res.data) {
                return {
                    ...res,
                    data: res.data.map(element => {
                        if (element.innerHits &&
                            element.innerHits.documentContent) {
                            return {
                                ...element,
                                innerHits: {
                                    ...element.innerHits,
                                    documentContent: element.innerHits.documentContent.filter(el => {
                                        return el ? typeof el.highlight !== 'undefined' && el.highlight !== null && el.highlight.content : false;
                                    })
                                }
                            };
                        } else {
                            return element;
                        }
                    })
                };
            }
        }
        return res;
    }

    static decodeUriParams(uriParams: any): void {
        if (uriParams) {
            for (const key in uriParams) {
                if (uriParams.hasOwnProperty(key) &&
                    uriParams[key]) {
                    // Sometimes we need to decode once or twice and others none
                    // (depending on how the uriParams are sent to the router)
                    // We do a try catch because it's throws an error
                    // if we try to decode a string with an % character
                    try {
                        uriParams[key] = decodeURIComponent(decodeURIComponent(uriParams[key]));
                    } catch (e) {
                        try {
                            uriParams[key] = decodeURIComponent(uriParams[key]);
                        } catch (e) {}
                    }
                }
            }
        }
    }

    static buildDocuments(documentsFromEs: any[]): any[] {
        return documentsFromEs?.map(element => (
            {
                ...element,
                documentType: {
                    ...element.documentType,
                    name: element.documentType.displayName
                }
            }
        )) || [];
    }

    static buildDataSetElementActs(actsFromEs: any[]): any[] {
        return actsFromEs?.map(element => ({act: element})) || [];
    }

    static buildDataSetElementDiagnostics(diagnosticsFromEs: any[]): any[] {
        return diagnosticsFromEs?.map(element => ({
            ...element,
            pricing: element.revaluation,
            codificationLabel: {
                id: element.codificationLabelId,
                name: element.codificationLabelSlug,
                slug: element.codificationLabelSlug
            },
            diagnostic: {
                id: element.diagnosticId,
                slug: element.diagnosticSlug,
                name: element.diagnosticName,
                parentId: element.diagnosticParentId,
                diagnosticNote: element.diagnosticNotes,
                diagnosticLevel: {
                    level: element.diagnosticLevel
                }
            }
        })) || [];
    }

    static buildDataSetElements(data: any[], isChild = false, stayId?: string): DataSetElement[] {
        const dataSetElements = [];
        if (data) {
            data.forEach(element => {
                const stay = isChild ? element : element.data;
                const dataSetElement = {
                    id: stay.dataSetElementId,
                    dataSetContentId: stay.dataSetContentId,
                    dataSetContent: {
                        id: stay.dataSetContentId,
                        stayId: isChild ? stayId : stay.stayId,
                        stayIdOriginal: isChild ? null : stay.stayIdOriginal,
                        rumId: isChild ? stay.subStayId : null,
                        ageInDays: stay.ageInDays,
                        ageInYears: stay.ageInYears,
                        ctGhm: stay.ctGhm,
                        ctGme: stay.ctGme,
                        ghm: stay.ghm,
                        gme: stay.gme,
                        nbDay: stay.nbDay,
                        startDate: stay.startDate,
                        endDate: stay.endDate,
                        medicalUnit: stay.medicalUnit,
                        updatedAt: stay.updatedAt,
                        isBilled: stay.billed,
                        pricing: stay.price,
                        type: stay.type,
                        linkAct: this.buildDataSetElementActs(stay.acts) || [],
                        establishment: stay.establishment,
                        user: stay.user
                    },
                    totalDocument: stay.nbDocuments,
                    totalEssentialDocument: stay.nbDocumentsEssential,
                    hasDocumentOverlapRisk: stay.documentOverlapRisk,
                    isReady: stay.ready,
                    primaryDiagnosticRecallIndex: stay.primaryDiagnosticRecallIndex,
                    codificationDataSetElementStatus: {
                        aiCodedStatus: stay.aiCodedStatus,
                        status: stay.codificationStatus,
                    },
                    documents: this.buildDocuments(stay.documents) || [],
                    dataSetElementDiagnostic: this.buildDataSetElementDiagnostics(stay.diagnostics) || [],
                    dataSetElementDiagnosticPredictive: this.buildDataSetElementDiagnostics(stay.diagnosticPredictions) || [],
                    children: stay.subStays ? this.buildDataSetElements(stay.subStays, true, stay.stayId) : null,
                };
                isChild ? dataSetElements.push(dataSetElement) : dataSetElements.push({data: dataSetElement, innerHits: element.innerHits});
            });
        }
        return dataSetElements;
    }
}
