import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FeedbackService} from '../feedback.service';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {StateService} from '@uirouter/core';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'ct-feedback-add-dialog',
    templateUrl: './feedback-add-dialog.component.html',
    styleUrls: ['./feedback-add-dialog.component.scss']
})
export class FeedbackAddDialogComponent implements OnInit {
    private _feedbackService: FeedbackService;

    feedbackForm: FormGroup;
    isLoading: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public $state: StateService,
                private _formBuilder: FormBuilder,
                private _snackBarService: SnackBarService,
                private _translateService: TranslateService,
                private _dialogRef: MatDialogRef<FeedbackAddDialogComponent>,
                private _broadcastService: BroadcastService) {
        this._feedbackService = this.data.service;
    }

    ngOnInit() {
        this.feedbackForm = this._formBuilder
            .group({
                object: ['', [Validators.required]
                ],
                message: [
                    '', [Validators.required]
                ]
            });
    }

    private _deleteNullableParams(params: any) {
        if (params) {
            Object.keys(params).forEach(key => {
                if (params[key] === '' ||
                    params[key] === null ||
                    typeof params[key] === 'undefined') {
                    delete params[key];
                }
            });
        }
    }

    async validate() {
        try {
            this.isLoading = true;
            const params = _.cloneDeep(this.$state.params);
            this._deleteNullableParams(params);
            const body = {
                ...this.feedbackForm.value,
                state: this.$state.current.name,
                params: JSON.stringify(params),
                status: 1
            };
            await this._feedbackService.createFeedback(body);
            this._feedbackService.increaseNumberOfUnreadFeedbackItems();
            this._snackBarService.success(this._translateService.instant('SUCCESS.ADD_FEEDBACK'));
            if (this.$state.current.name === 'feedback-list') {
                this._broadcastService.send('feedback::add');
            }
            this.isLoading = false;
            this._dialogRef.close();
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }
}
