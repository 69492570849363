import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MedicalUnit} from '../../../modules/health/medical-unit/medical-unit.model';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'ct-medical-unit-search',
    templateUrl: './medical-unit-search.component.html',
    styleUrls: ['./medical-unit-search.component.scss']
})
export class MedicalUnitSearchComponent implements OnInit {
    @ViewChild('medicalUnitInput', { static: true }) medicalUnitInput: ElementRef<HTMLInputElement>;

    private _param: number[];

    @Input() medicalUnits: MedicalUnit[] = [];
    @Input() set param(newParam: number[]) {
        if (newParam && this._param !== newParam) {
            this._param = newParam;
            this._initSelectedMedicalUnits();
        }
    }

    @Output() paramChange: EventEmitter<any> = new EventEmitter<any>();

    selectedMedicalUnits: MedicalUnit[] = [];
    searchedMedicalUnits$: Observable<MedicalUnit[]>;
    formControl: FormControl = new FormControl();

    constructor() { }

    ngOnInit(): void {
        this.searchedMedicalUnits$ =
            this.formControl.valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._getFilteredMedicalUnits(value)));
        this._initSelectedMedicalUnits();
    }

    private _getFilteredMedicalUnits(query: string): MedicalUnit[] {
        if (this.medicalUnits) {
            if (query && query.length > 0) {
                return this.medicalUnits
                    .filter(medicalUnit => (medicalUnit.code && medicalUnit.code.toLowerCase().includes(query.toLowerCase())) ||
                        (medicalUnit.name && medicalUnit.name.toLowerCase().includes(query.toLowerCase())));
            }
            return this.medicalUnits;
        }
        return [];
    }

    private _initSelectedMedicalUnits(): void {
        if (this.medicalUnits &&
            this._param) {
            this.selectedMedicalUnits = this.medicalUnits.filter(medicalUnit => this._param.includes(medicalUnit.id));
        }
    }

    getTooltipText(medicalUnit: MedicalUnit): string {
        return medicalUnit ? medicalUnit.name : '';
    }

    /**
     * Add the medical unit to the chips list (not if already added)
     * @param event
     */
    selectMedicalUnit(event: any): void {
        if (event) {
            if (!this.selectedMedicalUnits) {
                this.selectedMedicalUnits = [];
            }
            const searchedMedicalUnit = event.option.value || {};
            const medicalUnit = this.selectedMedicalUnits.find(el => el.id === searchedMedicalUnit.id);
            if (!medicalUnit) {
                if (searchedMedicalUnit !== {}) {
                    this.selectedMedicalUnits.push(searchedMedicalUnit);
                }
                // We update the service params
                this.paramChange
                    .emit(this.selectedMedicalUnits.map(el => el.id));
            }
            this.medicalUnitInput.nativeElement.value = '';
            this.formControl.setValue(null);
        }
    }

    removeChip(medicalUnitToRemove: MedicalUnit): void {
        if (this.selectedMedicalUnits &&
            medicalUnitToRemove) {
            const newSelectedMedicalUnits = this.selectedMedicalUnits.filter(el => el.id !== medicalUnitToRemove.id);
            this.selectedMedicalUnits = newSelectedMedicalUnits;
            // We update the service params
            this.paramChange
                .emit(newSelectedMedicalUnits.map(el => el.id));
        }
    }
}
