<mat-form-field class="minimum">
    <input matInput
           type="number"
           step="1"
           min="0"
           [max]="condition.service.params.nbStaysMax"
           [(ngModel)]="condition.service?.params.nbStaysMin"
           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MINIMUM' | translate">
</mat-form-field>

<mat-form-field>
    <input matInput
           type="number"
           step="any"
           [min]="condition.service.params.nbStaysMin"
           [(ngModel)]="condition.service.params.nbStaysMax"
           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MAXIMUM' | translate">
</mat-form-field>
