import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordApiService {

    constructor(private _http: HttpService) {
    }

    checkToken(token: string) {
        return this._http
            .get(`reset-password/${token}`)
            .pipe(map(res => res.data));
    }

    initPassword(token: string, body: any) {
        return this._http.
            post(`init-password/${token}`, body);
    }

    forgotPassword(token: string, body: any) {
        return this._http
            .post(`reset-forgot-password/${token}`, body);
    }

    expiredPassword(token: string, body: any) {
        return this._http
            .post(`reset-expired-password/${token}`, body);
    }
}
