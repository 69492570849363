import {Component, OnInit} from '@angular/core';
import {DocumentTypeListService} from '../../../document-type/list/document-type-list.service';
import {Observable} from 'rxjs';
import {StructuredDataService} from './structured-data.service';
import {map} from 'rxjs/operators';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {ColorService} from '../../../../core/services/color.service';

@Component({
    selector: 'ct-search-engine-condition-query-structured',
    templateUrl: './search-engine-condition-query-structured.component.html',
    styleUrls: ['./search-engine-condition-query-structured.component.scss']
})
export class SearchEngineConditionQueryStructuredComponent implements OnInit {
    condition: any;
    documentTypes: any[] = [];
    columnsData = {};
    autoCompleteInputs = [[
        {
            value: '',
            isLoading: false
        }
    ]];
    searchedTexts$: Observable<any>;
    isLoading: boolean;

    constructor(private _documentTypeListService: DocumentTypeListService,
                private _structuredDataService: StructuredDataService,
                public colorService: ColorService,
                private _configurationService: ConfigurationService) {
    }

    ngOnInit() {
        this.isLoading = true;
        this._loadDocumentTypes();
    }

    private async _loadDocumentTypes() {
        try {
            const res = await this._documentTypeListService
                .loadAllDocumentTypes({
                    structuredData: true,
                    indexer: true,
                    sort: 'name'
                });

            this.documentTypes = res.data;
            // There because otherwise structuredData is empty
            this._initAutoCompleteInputs();
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
        }
    }

    private _initAutoCompleteInputs() {
        this.condition.service.params.structuredData.forEach((param, index) => {
            this.autoCompleteInputs[index] = [];
            param.qStructuredDocumentTypeColumn.forEach((col, childIndex) => {
                this.autoCompleteInputs[index][childIndex] = {
                    value: col.q,
                    isLoading: false
                };
            });
        });
    }

    private _search$(input: any, columnId: number): Observable<any> {
        const query = {
            columnId: columnId,
            value: input.value
        };
        input.isLoading = true;
        return this._structuredDataService
            .searchValue$(query)
            .pipe(map(res => {
                input.isLoading = false;
                return res;
            }));
    }

    launchSearch(input: any, column: any) {
        column.q = input.value;
        this.searchedTexts$ = this._search$(input, column.columnId);
    }

    selectOption(event: any, column: any) {
        if (event) {
            column.q = event.option.value;
        }
    }

    trackByFn(index: number) {
        return index;
    }

    isFieldAlreadyUsed(columns: any[], column: any) {
        const columnToFind = columns.find(el => el.columnId === column.id);
        return !!columnToFind;
    }

    /**
     * First level
     */
    addStructuredQueryCondition() {
        this.condition.service.params.structuredData.push({
            qStructuredDocumentTypeId: '',
            qStructuredDocumentTypeColumn: [],
            columns: []
        });
        this.autoCompleteInputs.push([
            {
                value: '',
                isLoading: false
            }
        ]);
    }

    removeStructuredQueryCondition(index: number) {
        if (this.condition.service.params.structuredData.length > 1) {
            this.condition.service.params.structuredData.splice(index, 1);
            this.autoCompleteInputs.splice(index, 1);
        }
    }

    /**
     * Second level
     * @param parentIndex
     */
    addStructuredField(parentIndex: number) {
        this.condition.service.params.structuredData[parentIndex].qStructuredDocumentTypeColumn.push({
            q: '',
            operator: '',
            startDate: '',
            endDate: '',
            min: '',
            max: '',
            columnId: '',
            columnType: ''
        });
        this.autoCompleteInputs[parentIndex].push({
            value: '',
            isLoading: false
        });
    }

    removeStructuredField(parentIndex: number, index: number, force: boolean) {
        if (force) {
            this.condition.service.params.structuredData[parentIndex].qStructuredDocumentTypeColumn = [];
            this.autoCompleteInputs[parentIndex] = [{
                value: '',
                isLoading: false
            }];
        } else if (!force &&
            this.condition.service.params.structuredData[parentIndex].qStructuredDocumentTypeColumn.length > 1) {
            this.condition.service.params.structuredData[parentIndex].qStructuredDocumentTypeColumn.splice(index, 1);
            this.autoCompleteInputs[parentIndex].splice(index, 1);
        }
    }

    documentTypeChange(documentTypeId, parentIndex: number) {
        documentTypeId = parseFloat(documentTypeId);
        if (!this.columnsData[documentTypeId] ||
            this.columnsData[documentTypeId].length === 0) {
            this._documentTypeListService
                .loadColumns$(documentTypeId)
                .toPromise()
                .then(data => {
                    if (!this.condition.service.params.structuredData[parentIndex].qStructuredDocumentTypeColumn[0]) {
                        this.addStructuredField(parentIndex);
                    } else {
                        this.removeStructuredField(parentIndex, 0, true);
                        this.addStructuredField(parentIndex);
                    }
                    this.condition.service.params.structuredData[parentIndex].columns = data;
                    this.columnsData[documentTypeId] = data;
                });
        } else {
            // The columns have already been fetched
            for (const key in this.columnsData) {
                if (+key === documentTypeId) {
                    this.condition.service.params.structuredData[parentIndex].columns = this.columnsData[key];
                    this.removeStructuredField(parentIndex, 0, true);
                    this.addStructuredField(parentIndex);
                }
            }
        }
    }

    structuredFieldChange(columnId: string, index: number, parentIndex: number) {
        const param = this.condition.service.params.structuredData[parentIndex];
        if (param.columns.length > 0) {
            param.columns.forEach(column => {
                if (column.id === parseFloat(columnId)) {
                    param.qStructuredDocumentTypeColumn[index].columnId = column.id;
                    const dataTypeList = this.getSelectedColumn(param.qStructuredDocumentTypeColumn[index].columnId, parentIndex)?.dataType;
                    if (dataTypeList?.length === 1 ) {
                        param.qStructuredDocumentTypeColumn[index].columnType = dataTypeList[0];
                        param.qStructuredDocumentTypeColumn[index].dataType = [dataTypeList[0]];
                    }
                }
            });
        }
    }

    dataTypeFieldChange(columnId: string, index: string | number, parentIndex: number, type: string) {
        const param = this.condition.service.params.structuredData[parentIndex];
        if (param.columns.length > 0) {
            param.columns.forEach(column => {
                if (column.id === parseFloat(columnId)) {
                    param.qStructuredDocumentTypeColumn[index].columnType = type;
                    param.qStructuredDocumentTypeColumn[index].dataType = [type];
                }
            });
        }
    }

    dateChange(parentIndex: number, index: number, event: any, period: string) {
        if (event && event.value) {
            if (period === 'start') {
                this.condition.service.params.structuredData[parentIndex].qStructuredDocumentTypeColumn[index].startDate = new Date(event.value);
            } else if (period === 'end') {
                this.condition.service.params.structuredData[parentIndex].qStructuredDocumentTypeColumn[index].endDate = new Date(event.value);
            }
        }
    }

    getSelectedColumn(index: number, parentIndex) {
        const param = this.condition.service.params.structuredData[parentIndex];
         return param.columns.find((element) => element.id === index);
    }

    getDataType(dataType: string|string[]) {
        return Array.isArray(dataType) ? dataType[0] : dataType;
    }
}
