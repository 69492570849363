import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabListComponent} from './tab-list.component';
import {TabListItemComponent} from './item/tab-list-item.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatBadgeModule} from '@angular/material/badge';

@NgModule({
    declarations: [
        TabListComponent,
        TabListItemComponent
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatTooltipModule,
        MatIconModule,
        NgbTooltipModule,
        MatBadgeModule
    ],
    exports: [
        TabListComponent,
        TabListItemComponent
    ]
})
export class TabListModule {}
