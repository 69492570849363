import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionStringValueComponent} from '../../shared/search-engine-condition-string-value/search-engine-condition-string-value.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionIppService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'ipp';
    placeholder = 'ACRONYM.IPP';

    constructor(private _translateService: TranslateService) {
        super(['ipp'],
            _translateService.instant('ACRONYM.IPP'),
            'ipp',
            SearchEngineConditionStringValueComponent,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {ipp: null};
        this.uriParams = {ipp: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    private _storeIppList(obj: any): void {
        let ippList = null;
        if (obj.ipp) {
            const separatorRegex = /[,;|_-]/;
            if (obj.ipp.match(separatorRegex)) {
                ippList = obj.ipp
                    .split(separatorRegex)
                    .map(ipp => ipp.trim())
                    .filter(ipp => ipp !== '');
            } else {
                ippList = obj.ipp
                    .split(' ')
                    .filter(ipp => ipp !== '');
            }
        }
        ippList && !!ippList[0] ? sessionStorage.setItem('patientSearchIppList', JSON.stringify(ippList)) : sessionStorage.setItem('patientSearchIppList', '');
    }

    convertToUriParams(): void {
        this.uriParams.ipp = this.params.ipp;
        this._storeIppList(this.params);
    }

    convertToParams(): void {
        this.params.ipp = this.uriParams.ipp;
        this._storeIppList(this.uriParams);
    }
    convertToParamsFromApiParams(): void {
        this.params.ipp = this.apiParams.args.values[0];
        this._storeIppList(this.params);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.ipp) {
            this.apiParams.args = {values: this.params.ipp.split( /[,; |_-]/)};
            this._storeIppList(this.params);
        }
    }
}
