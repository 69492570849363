import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private _loadingInstances: boolean[] = []; // For the case where we call startLoading() and stopLoading() on the same page
    private _isLoading: boolean;
    private _isLoadingSubject: BehaviorSubject<boolean>;

    get isLoading$(): Observable<boolean> {
        return this._isLoadingSubject.asObservable();
    }

    constructor() {
        this._isLoading = false;
        this._isLoadingSubject = new BehaviorSubject<boolean>(false);
    }

    startLoading(): void {
        this._isLoading = true;
        this._loadingInstances.push(true);
        this._isLoadingSubject.next(true);
    }

    stopLoading(): void {
        this._loadingInstances.pop();
        if (!this._loadingInstances.length) {
            this._isLoading = false;
            this._isLoadingSubject.next(false);
        }
    }
}
