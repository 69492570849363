import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {StayDetailDataSetElementDatesEditDialogComponent} from './stay-detail-data-set-element-dates-edit-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        StayDetailDataSetElementDatesEditDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule
    ]
})
export class StayDetailDataSetElementDatesEditDialogModule {}
