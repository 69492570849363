<mat-toolbar class="dialog-toolbar">
    <h2 *ngIf="!isLoading">{{medication.name || '...'}}</h2>
    <h2 *ngIf="isLoading">...</h2>

    <button mat-icon-button
            mat-dialog-close>
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</mat-toolbar>

<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="row padding-top-16 padding-bottom-16"
     *ngIf="!isLoading">
    <div class="col">
        <p [innerHTML]="medication.description | safeHtml"></p>
        <h3 class="h3 font-weight-700">{{'MEDICATION.THERAPEUTIC_INDICATION' | translate}}</h3>
        <p [innerHTML]="medication.posologie | safeHtml"></p>
    </div>
</div>
