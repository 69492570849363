import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MedicationDetailDialogComponent} from './detail-dialog/medication-detail-dialog.component';
import {Medication} from './medication.model';
import {TranslateService} from '@ngx-translate/core';
import {MedicationApiService} from '../../../core/api-services/medication/medication.api.service';

@Injectable({
    providedIn: 'root'
})
export class MedicationService {
    constructor(private _matDialog: MatDialog,
                private _translateService: TranslateService,
                private _medicationApiService: MedicationApiService) {}

    private _medications: Medication[] = [];

    private _cleanPosologie(medication: Medication) {
        if (medication.posologie === '\\N') {
            medication.posologie = this._translateService.instant('MEDICATION.NO_DESCRIPTION');
        }
    }

    async loadMedication(medicationId: number) {
        try {
            const medicToFind = this._medications.find(m => m.medicamentId === medicationId);
            if (medicToFind) {
                return medicToFind;
            } else {
                const medication: Medication = await this._medicationApiService
                    .get(medicationId)
                    .toPromise();

                this._cleanPosologie(medication);
                this._medications.push(medication);
                return medication;
            }
        } catch (e) {
            throw e;
        }
    }

    openMedicationDetailDialog(medicationId: number) {
        this._matDialog.open(MedicationDetailDialogComponent, {
            data: {
                service: this,
                medicationId
            },
            autoFocus: false,
            panelClass: 'medication-detail-dialog'
        });
    }
}
