import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class AlertApiService {
    private _BASE_URL = 'alert';

    constructor(private _http: HttpService) { }

    getAll(urlRequestParams?: any, deleteEmptyNullParams: boolean = true) {
        return this._http
            .get(this._BASE_URL, {params: urlRequestParams}, deleteEmptyNullParams)
            .pipe(map(res => res.data));
    }

    getAllSubscribed(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/subscribed`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    getAlertSubscribers(alertName: string, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${alertName}/subscriptions`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    update(alertId: number, body: any) {
        return this._http
            .patch(`${this._BASE_URL}/${alertId}`, body)
            .pipe(map(res => res.data));
    }

    updateAlertSubscribers(alertName: string, body: any) {
        return this._http
            .patch(`${this._BASE_URL}/${alertName}/subscriptions`, body)
            .pipe(map(res => res.data));
    }

    subscribeByName(alertName: string) {
        return this._http
            .post(`${this._BASE_URL}/${alertName}/subscribe`, null)
            .pipe(map(res => res.data));
    }

    subscribeManyByName(alertName: string, body: any) {
        return this._http
            .post(`${this._BASE_URL}/${alertName}/subscriptions`, body)
            .pipe(map(res => res.data));
    }

    unsubscribe(alertId: number) {
        return this._http
            .delete(`${this._BASE_URL}/${alertId}/unsubscribe`)
            .pipe(map(res => res.data));
    }

    unsubscribeByName(alertName: string) {
        return this._http
            .delete(`${this._BASE_URL}/${alertName}/unsubscribe`)
            .pipe(map(res => res.data));
    }
}
