import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionGhmGmeComponent} from './search-engine-condition-ghm-gme.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionGmeService extends SearchEngineCondition {
    useCase = 'gme';

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('ACRONYM.GME'),
            'gme',
            SearchEngineConditionGhmGmeComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {gme: null};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.ghm = this.apiParams.args.value;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.gme) {
            this.apiParams.args = {value: this.params.gme};
        }
    }

    isValidApiParams(args: any): boolean {
        return !!args?.value;
    }
}
