import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileDisplayButtonComponent} from './file-display-button.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        FileDisplayButtonComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        FileDisplayButtonComponent
    ]
})
export class FileDisplayButtonModule {}
