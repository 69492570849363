import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StatusLightComponent} from './status-light.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        StatusLightComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        StatusLightComponent
    ]
})
export class StatusLightModule {}
