import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-number-of-stays',
    templateUrl: './search-engine-condition-number-of-stays.component.html',
    styleUrls: ['./search-engine-condition-number-of-stays.component.scss']
})
export class SearchEngineConditionNumberOfStaysComponent implements OnInit {
    condition: any;

    constructor() {
    }

    ngOnInit() {
    }

}
