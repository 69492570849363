import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionGhmGmeSeverityComponent} from './search-engine-condition-ghm-gme-severity.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionGhmSeverityService extends SearchEngineCondition {
    useCase = 'ghm';
    severities = [];

    constructor(private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.GHM_SEVERITY'),
            'ghmSeverity',
            SearchEngineConditionGhmGmeSeverityComponent,
            true);
        this.init();
    }

    getSeverities(useCase: string): any[] {
        const severities = this._translationHelperService.getSeverities(useCase);
        return severities?.map(severity => ({severity: severity})) || [];
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.severities = this.getSeverities('ghm');
        this.params = {ghmSeverity: []};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.ghmSeverity = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.ghmSeverity};
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
