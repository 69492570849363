import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarService} from '../../../../../core/services/snack-bar.service';
import {StayDetailService} from '../../stay-detail.service';

@Component({
    selector: 'ct-stay-detail-patient-ipp-edit-dialog',
    templateUrl: './stay-detail-patient-ipp-edit-dialog.component.html',
    styleUrls: ['./stay-detail-patient-ipp-edit-dialog.component.scss']
})
export class StayDetailPatientIppEditDialogComponent implements OnInit {
    private _stayDetailService: StayDetailService;
    private _patientId: number;

    IPP: string;
    isLoading: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                private _dialogRef: MatDialogRef<StayDetailPatientIppEditDialogComponent>,
                private _translateService: TranslateService,
                private _snackBarService: SnackBarService) { }

    ngOnInit(): void {
        this._stayDetailService = this.data.service;
        this._patientId = this.data.patientId;
    }

    isValidationDisabled() {
        return !this.IPP;
    }

    async validate() {
        try {
            this.isLoading = true;
            const patient = await this._stayDetailService.updatePatientIPP(this._patientId, this.IPP);
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            this.isLoading = false;
            if (patient) {
                this._dialogRef.close(patient.ipp);
            }
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }
}
