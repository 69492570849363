import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailFilterSearchMenuComponent} from './stay-detail-filter-search-menu.component';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    declarations: [
        StayDetailFilterSearchMenuComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        MatDividerModule,
        MatButtonModule,
        MatMenuModule
    ],
    exports: [
        StayDetailFilterSearchMenuComponent
    ],
})
export class StayDetailFilterSearchMenuModule {}
