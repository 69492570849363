import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'ct-select-operator',
    templateUrl: './select-operator.component.html',
    styleUrls: ['./select-operator.component.scss']
})
export class SelectOperatorComponent implements OnInit {
    operators = [
      '>', '<', '=', '>=', '<=', '>=<', '><'
    ];
    @Input() label: string;
    @Input() data: any;

    @Output() dataChange: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}
