import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionHasDocumentOverlapRiskService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super(['hasDocumentOverlapRisk'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.STAY_STATUS'),
            'hasDocumentOverlapRisk',
            null,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {hasNoDocumentOverlapRisk: false};
        // Be careful, not the same name
        this.uriParams = {hasDocumentOverlapRisk: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.uriParams.hasDocumentOverlapRisk = this._getApiParamValue(this.params.hasNoDocumentOverlapRisk);
    }
    convertToParams(): void {
        if (['0', '-1', '0;-1'].includes(this.uriParams.hasDocumentOverlapRisk)) {
            this.params.hasNoDocumentOverlapRisk = true;
        }
    }

    convertToParamsFromApiParams(): void {
        if (['0', '-1', '0;-1'].includes(this.apiParams.args.value)) {
            this.params.hasNoDocumentOverlapRisk = true;
        }
    }

    private _getApiParamValue(hasNoDocumentOverlapRisk: boolean): any[] {
        return hasNoDocumentOverlapRisk === true ? ['0', '-1'] : [];
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this._getApiParamValue(this.params.hasNoDocumentOverlapRisk)};
    }
}
