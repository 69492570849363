import {Injectable} from '@angular/core';
import {HttpService} from '../../../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class DataSetCodificationDataSetElementApiService {
    private _BASE_URL = 'data-set';

    constructor(private _http: HttpService) {}

    update(dataSetId: number, codificationId: any, dataSetElementId: number, data: any, urlRequestParams?: any) {
        return this._http
            .patch(`${this._BASE_URL}/${dataSetId}/codification/${codificationId}/data-set-element/${dataSetElementId}`, data, {params: urlRequestParams});
    }
}
