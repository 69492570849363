import {Injectable} from '@angular/core';
import {HttpService} from '../../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DashboardActivityApiService {
    private _BASE_URL = 'dashboard/activity';
    private _BASE_URL_METRICS = `${this._BASE_URL}/metrics`;

    constructor(private _http: HttpService) {}

    postEvent(event: any, urlRequestParams?: any) {
        const params = {
            ...urlRequestParams,
            hideError: 'true'
        };
        return this._http
            .post(`${this._BASE_URL}`, event, {params});
    }

    getTimeSpentMetrics(urlRequestParams: any) {
        return this._http
            .get(`${this._BASE_URL_METRICS}/time-spent`, {params: urlRequestParams})
            .pipe(map(res => res.data[0]));
    }
}
