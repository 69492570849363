import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StayDetailDocumentListService {
    constructor() {}

    setIsOpenedProperty(documentsAsObj: any, status: boolean): void {
        if (documentsAsObj) {
            Object.values(documentsAsObj).forEach(documentTypesAsObj => {
                if (documentTypesAsObj) {
                    Object.values(documentTypesAsObj).forEach((documentsAsArray: any[]) => {
                        if (documentsAsArray) {
                            documentsAsArray.forEach(el => el.isOpened = status);
                        }
                    });
                }
            });
        }
    }
}
