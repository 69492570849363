import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {HealthMovementService} from '../../../health/movement/health-movement.service';
import {HealthMovement} from '../../../health/movement/health-movement.model';
import * as _ from 'lodash-es';

@Component({
    selector: 'ct-search-engine-condition-health-movement',
    templateUrl: './search-engine-condition-health-movement.component.html',
    styleUrls: ['./search-engine-condition-health-movement.component.scss']
})
export class SearchEngineConditionHealthMovementComponent implements OnInit, AfterViewInit {
    @ViewChild('multipleSelect', { static: true }) matSelect;

    @Input() useCase: string;
    @Input() paramKey: string;
    @Input() placeholder: string;

    condition: any;
    healthMovements: HealthMovement[] = [];
    healthMovementsGroupByMode: any = {};
    isLoading: boolean;

    constructor(private _healthMovementService: HealthMovementService) { }

    ngOnInit(): void {
        this._loadAllHealthMovements();
    }

    ngAfterViewInit() {
        // To fix jumping scroll when select an option
        (<any> this.matSelect)._getItemHeight = () => this.matSelect._triggerFontSize * 1.85;
    }

    getHealthMovementModes(): string[] {
        return this.healthMovementsGroupByMode ? Object.keys(this.healthMovementsGroupByMode) : [];
    }

    private async _loadAllHealthMovements(): Promise<void> {
        try {
            this.isLoading = true;
            this.healthMovements = await this._healthMovementService
                .loadAllHealthMovements(this.useCase);
            if (this.healthMovements) {
                this.healthMovements.forEach((movement) => {
                    if (movement.name) {
                        movement.name = movement.name.replace('|', ' ');
                    }
                });
                this.healthMovementsGroupByMode = _.groupBy(this.healthMovements, 'mode');
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    getPrefix(movement: HealthMovement): string {
        if (movement) {
            const prefix = this.useCase === 'entrance' ? movement.origin : movement.destination;
            return prefix ? `${prefix} - ` : '';
        }
        return '';
    }

    isIndeterminate(): boolean {
        return this.condition.service.params[this.paramKey]?.length !== 0 &&
            this.condition.service.params[this.paramKey]?.length !== this.healthMovements?.length;
    }

    isIndeterminateMode(mode: string): boolean {
        const modeMovementIds: number[] = this._getMovementsIdsByMode(mode);
        const movementsIdsFilteredByServiceParam = this._getMovementsIdsUnionWithServiceParamIds(modeMovementIds);
        return movementsIdsFilteredByServiceParam?.length !== 0 &&
            movementsIdsFilteredByServiceParam?.length !== modeMovementIds?.length;
    }

    isChecked(): boolean {
        return this.condition.service.params[this.paramKey]?.length === this.healthMovements?.length;
    }

    isCheckedMode(mode: string): boolean {
        const modeMovementIds: number[] = this._getMovementsIdsByMode(mode);
        const movementsIdsFilteredByServiceParam = this._getMovementsIdsUnionWithServiceParamIds(modeMovementIds);
        return movementsIdsFilteredByServiceParam?.length === modeMovementIds?.length;
    }

    private _getMovementsIdsByMode(mode: string): number[] {
        return this.healthMovementsGroupByMode && this.healthMovementsGroupByMode[mode]?.map(movement => movement.id) || [];
    }

    private _getMovementsIdsUnionWithServiceParamIds(movementIds: number[]): number[] {
        if (this.condition?.service?.params[this.paramKey] &&
            movementIds) {
            return movementIds.filter(id => this.condition.service.params[this.paramKey].indexOf(id) !== -1);
        }
        return [];
    }

    private _toggleAll(): void {
        this.condition.service.params[this.paramKey] = this.isChecked() ? [] : (this.healthMovements?.slice(0).map(el => el.id) || []);
    }

    private _toggleAllByMode(mode: string): void {
        if (this.condition?.service?.params[this.paramKey]) {
            const modeMovementIds: number[] = this._getMovementsIdsByMode(mode) || [];
            if (this.isCheckedMode(mode)) {
                this.condition.service.params[this.paramKey] = this.condition.service.params[this.paramKey].filter(id => !modeMovementIds.includes(id));
            } else {
                this.condition.service.params[this.paramKey] = this.condition.service.params[this.paramKey].concat(modeMovementIds);
            }
        }
    }

    toggleAll(event: any, mode?: string): void {
        // To prevent the checkbox to be checked because when we update the ids
        // the isChecked method updates the checkbox checked attribute
        if (event) {
            event.preventDefault();
        }
        mode ? this._toggleAllByMode(mode) : this._toggleAll();
    }
}
