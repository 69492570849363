<div>
    <mat-icon *ngIf="hasLength()"
              class="material-icons  md-icon-green">check</mat-icon>
    <mat-icon *ngIf="!hasLength()"
              class="material-icons  md-icon-red">close</mat-icon>
    {{'PASSWORD_VALIDATOR.12_CHARACTERS' | translate}}
</div>

<div>
    <mat-icon *ngIf="hasLower()"
              class="md-icon-green">check</mat-icon>
    <mat-icon *ngIf="!hasLower()"
              class="md-icon-red">close</mat-icon>
    {{'PASSWORD_VALIDATOR.LOWERCASE' | translate}}
</div>

<div>
    <mat-icon *ngIf="hasUpper()"
              class="md-icon-green">check</mat-icon>
    <mat-icon *ngIf="!hasUpper()"
              class="md-icon-red">close</mat-icon>
    {{'PASSWORD_VALIDATOR.UPPERCASE' | translate}}
</div>

<div>
    <mat-icon *ngIf="hasSpecial()"
              class="md-icon-green">check</mat-icon>
    <mat-icon *ngIf="!hasSpecial()"
              class="material-icons  md-icon-red">close</mat-icon>
    {{'PASSWORD_VALIDATOR.SPECIAL_CHARACTER' | translate}}
    <span class="md-text-disabled">(:-+,<>()_'=!@#$%^&*?.;/)</span>
</div>

<div>
    <mat-icon *ngIf="hasNumeric()"
              class="md-icon-green">check</mat-icon>
    <mat-icon *ngIf="!hasNumeric()"
              class="md-icon-red">close</mat-icon>
    {{'PASSWORD_VALIDATOR.NUMBER' | translate}}
</div>
