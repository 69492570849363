import {Injectable} from '@angular/core';
import {HttpClient, HttpParameterCodec, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {Moment} from 'moment';
import {AppLogService} from '../app-log/app-log.service';
import {DataHelperService} from '../services/data.helper.service';

class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private _BASE_URL = `${environment.apiUrl}/api/v2`;

    constructor(public http: HttpClient,
                private _logService: AppLogService) { }

    private static _setRequestOptions(customOptions: any, deleteEmptyNullParams: boolean = true): any {
        let options: any = {
            withCredentials: true
        };

        if (customOptions) {
            if (customOptions.params) {
                if (deleteEmptyNullParams) {
                    DataHelperService.deleteEmptyUndefinedNullFromObject(customOptions.params);
                }
                options = {
                    ...options,
                    params: new HttpParams({
                        fromObject: {...customOptions.params},
                        encoder: new CustomEncoder()
                    })
                };
            }
            if (customOptions.responseType) {
                options = {
                    ...options,
                    responseType: customOptions.responseType
                };
            }
        }

        return options;
    }

    /**
     * Send log error to ES
     * @param error
     * @param startMoment
     * @param url
     * @param method
     * @private
     */
    private _sendLogErrorToLogStash(error: any, startMoment: Moment, url: string, method: string) {
        const requestDuration = moment().diff(startMoment, 'milliseconds');
        this._logService.logError(`${method} error code ${error.status}`, requestDuration, url);
    }

    private _request(method: string, url: string, body?: any, options?: any) {
        return new Observable((observer: any) => {
            const requestBeginTime = moment();
            options = options || {};
            if (body) {
                options = {
                    ...options,
                    body
                };
            }
            // If goes well then return it
            // otherwise send log error to logStash
            this.http.request(method, url, options)
                .subscribe(response => {
                        observer.next(response);
                        observer.complete();
                    },
                    error => {
                        this._sendLogErrorToLogStash(error, requestBeginTime, url, method);
                        observer.error(error);
                    }
                );
        });
    }

    getFile(url: string, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('GET', `${environment.apiUrl}/${url}`, null, options);
    }

    get(url: string, options?: any, deleteEmptyNullParams: boolean = true): Observable<any> {
        options = HttpService._setRequestOptions(options, deleteEmptyNullParams);
        return this._request('GET', `${this._BASE_URL}/${url}`, null, options);
    }

    post(url: string, body: any, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('POST', `${this._BASE_URL}/${url}`, body, options);
    }

    patch(url: string, body: any, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('PATCH', `${this._BASE_URL}/${url}`, body, options);
    }

    delete(url: string, body?: any, options?: any): Observable<any> {
        options = HttpService._setRequestOptions(options);
        return this._request('DELETE', `${this._BASE_URL}/${url}`, body, options);
    }
}
