import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {LayoutComponent} from './layout.component';
import {MenuModule} from '../../core/components/menu/menu.module';
import {UIRouterModule} from '@uirouter/angular';
import {layoutStates} from './layout.state';

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        CommonModule,
        UIRouterModule.forChild({states: layoutStates}),
        MenuModule,
        MatSnackBarModule,
        MatDialogModule
    ]
})
export class LayoutModule {}
