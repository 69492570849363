<div class="tag tag-{{color}} tag-raised align-center"
     (click)="changeValue()">
    <mat-icon *ngIf="obj && key">{{obj[key] === true ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
    <mat-icon *ngIf="icon"
              class="margin-right-5">{{icon}}</mat-icon>
    <img class="ct-logo"
         *ngIf="displayCTLogo"
         [src]="'./assets/images/icon.png'">
    <span>{{text}}</span>
</div>
