import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from './menu.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {ToolbarModule} from '../toolbar/toolbar.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {UIRouterModule} from '@uirouter/angular';
import {MatMenuModule} from '@angular/material/menu';
import {TooltipDirectiveModule} from '../../../shared/directives/tooltip/tooltip.directive.module';
import {MenuGroupSubMenuModule} from './group-sub-menu/menu-group-sub-menu.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatBadgeModule} from '@angular/material/badge';

@NgModule({
    declarations: [
        MenuComponent
    ],
    imports: [
        CommonModule,
        UIRouterModule,
        TranslateModule.forChild(),
        ToolbarModule,
        MenuGroupSubMenuModule,
        MatSidenavModule,
        MatIconModule,
        MatDividerModule,
        MatProgressBarModule,
        MatListModule,
        MatMenuModule,
        TooltipDirectiveModule,
        MatTooltipModule,
        MatBadgeModule,
    ],
    exports: [
        MenuComponent
    ],
})
export class MenuModule {}
