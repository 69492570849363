import {Component, Input, OnInit} from '@angular/core';
import {StayDetailActPredictionElementTooltipComponent} from './tooltip/stay-detail-act-prediction-element-tooltip.component';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'ct-stay-detail-act-prediction-element',
    templateUrl: './stay-detail-act-prediction-element.component.html',
    styleUrls: ['./stay-detail-act-prediction-element.component.scss',
        '../../diagnostic-prediction/element/stay-detail-diagnostic-prediction-element.component.scss']
})
export class StayDetailActPredictionElementComponent implements OnInit {
    config = {};
    @Input() predictiveAct: any;
    tooltipComponent: any;
    dataSetElement: DataSetElement;
    private isNearAutomationQualityControl: boolean;

    constructor(private _dataSetElementService: DataSetElementService,
                public $state: StateService,
    ) {
    }

    ngOnInit(): void {
        this.dataSetElement = this._dataSetElementService.dataSetElement;
        this.config = {
            predictiveAct: this.predictiveAct,
        };
        this.isNearAutomationQualityControl = this.$state.current.name === 'quality-control-near-automation';
        if (!this.isNearAutomationQualityControl) {
            this.tooltipComponent = StayDetailActPredictionElementTooltipComponent;
        }
    }

    getClass() {
        if (!this.dataSetElement) {
            return 'md-black-100 bold';
        }
        const linkAct = Object.values(this.dataSetElement?.dataSetContent?.linkAct);
        const isSelected = linkAct.some((act: any) => {
            return act.classing.some(a => a.id === this.predictiveAct.act.id) || act.other.some(a => a.id === this.predictiveAct.act.id);
        });
        return isSelected ? 'md-grey' : 'md-black-100 bold';
    }

}
