import {Injectable} from '@angular/core';
import * as _ from 'lodash-es';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {DiagnosticService} from '../../../../diagnostic/diagnostic.service';
import {CodificationLabelsModel} from '../../../shared/codification-labels.model';
import {SimplifiedScoreEnum} from '../../../../diagnostic/diagnostic.model';

@Injectable({
    providedIn: 'root'
})
export class StayDetailDiagnosticPredictionListService {
    codificationLabels: CodificationLabelsModel;

    constructor(private _configurationService: ConfigurationService,
                private _diagnosticService: DiagnosticService) {
        this.codificationLabels = this._diagnosticService.labels;
    }

    private  _greaterOrEqualScore(predictiveDiagnostic: any,
                                  predictiveDiagnosticSlugsToInclude: string[],
                                  menuOptions: any,
                                  codificationLabelIds: number[]) {
        const pDiagCm = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.confidenceMin');
        const pDiagCd = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.confidenceDefined');

        const aDiagCm = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.confidenceMin');
        const aDiagCd = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.confidenceDefined');
        const aDiagRootCm = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.root.confidenceMin');
        const aDiagRootCd = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.root.confidenceDefault');

        const mDiagCm = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.morbidManifestationDiagnostic.confidenceMin');
        const mDiagCd = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.morbidManifestationDiagnostic.confidenceDefined');

        const eDiagCm = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.etiologicalAffectionDiagnostic.confidenceMin');
        const eDiagCd = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.etiologicalAffectionDiagnostic.confidenceDefined');

        let aDiagLvlDisplay: boolean;
        let aDiagLvlCm: number;
        let aDiagLvlCd: number;
        if (predictiveDiagnostic &&
            predictiveDiagnostic.diagnostic &&
            predictiveDiagnostic.diagnostic.diagnosticLevel &&
            predictiveDiagnostic.diagnostic.diagnosticLevel.level) {
            aDiagLvlDisplay =
                this._configurationService.getConfigurationContent('front', `diagnostic.prediction.associateDiagnostic.level.${predictiveDiagnostic.diagnostic.diagnosticLevel.level}.display`);
            aDiagLvlCm =
                this._configurationService.getConfigurationContent('front', `diagnostic.prediction.associateDiagnostic.level.${predictiveDiagnostic.diagnostic.diagnosticLevel.level}.confidenceMin`);
            aDiagLvlCd =
                this._configurationService.getConfigurationContent('front', `diagnostic.prediction.associateDiagnostic.level.${predictiveDiagnostic.diagnostic.diagnosticLevel.level}.confidenceDefined`);
        }

        let result = false;
        switch (true) {
            case (codificationLabelIds.includes(this.codificationLabels.DP) ||
                codificationLabelIds.includes(this.codificationLabels.DP_ROOT)) :
                result = menuOptions.forceDisplay.value ? predictiveDiagnostic.score >= pDiagCm :
                    predictiveDiagnostic.score >= pDiagCd;
                break;
            case (codificationLabelIds.includes(this.codificationLabels.DA) ||
                codificationLabelIds.includes(this.codificationLabels.DA_ROOT)) :
                if (predictiveDiagnostic.diagnostic.parentId !== null) {
                    if (aDiagLvlDisplay) {
                        if (menuOptions.forceDisplay.value) {
                            // For DA_ROOT with a score 0 that we want to display when show all
                            if (predictiveDiagnosticSlugsToInclude &&
                                predictiveDiagnosticSlugsToInclude.length > 0 &&
                                predictiveDiagnosticSlugsToInclude.includes(predictiveDiagnostic.diagnostic.slug)) {
                                result = true;
                            } else {
                                result = predictiveDiagnostic.score >= aDiagLvlCm;
                            }
                        } else {
                            result = predictiveDiagnostic.score >= aDiagLvlCd;
                        }
                    } else {
                        result = menuOptions.forceDisplay.value ? predictiveDiagnostic.score >= aDiagCm : predictiveDiagnostic.score >= aDiagCd;
                    }
                } else {
                    if (menuOptions.forceDisplay.value) {
                        // For DA_ROOT with a score 0 that we want to display when show all
                        if (predictiveDiagnosticSlugsToInclude &&
                            predictiveDiagnosticSlugsToInclude.length > 0 &&
                            predictiveDiagnosticSlugsToInclude.includes(predictiveDiagnostic.diagnostic.slug)) {
                            result = true;
                        } else {
                            result = predictiveDiagnostic.score >= ((aDiagRootCm !== null) ? aDiagRootCm : aDiagCm);
                        }
                    } else {
                        result = predictiveDiagnostic.score >= ((aDiagRootCd !== null) ? aDiagRootCd : aDiagCd);
                    }
                }
                break;
            case codificationLabelIds.includes(this.codificationLabels.MP) :
                result = menuOptions.forceDisplay.value ? predictiveDiagnostic.score >= mDiagCm :
                    predictiveDiagnostic.score >= mDiagCd;
                break;
            case codificationLabelIds.includes(this.codificationLabels.AE) :
                result = menuOptions.forceDisplay.value ? predictiveDiagnostic.score >= eDiagCm :
                    predictiveDiagnostic.score >= eDiagCd;
                break;
            default:
        }
        return result;
    }

    private _greaterOrEqualScoreForExactAndRootCodes(predictiveDiagnostic: any, isExactCode: boolean) {
        const exactCodeThreshold = {};

        exactCodeThreshold[this.codificationLabels.DP] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.exactCodeConfidenceThreshold');
        exactCodeThreshold[this.codificationLabels.DP_ROOT] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.exactCodeConfidenceThreshold');
        exactCodeThreshold[this.codificationLabels.DA] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.exactCodeConfidenceThreshold');
        exactCodeThreshold[this.codificationLabels.DA_ROOT] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.exactCodeConfidenceThreshold');
        exactCodeThreshold[this.codificationLabels.MP] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.morbidManifestationDiagnostic.exactCodeConfidenceThreshold');
        exactCodeThreshold[this.codificationLabels.AE] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.etiologicalAffectionDiagnostic.exactCodeConfidenceThreshold');

        const rootCodeThresholds = {};
        rootCodeThresholds[this.codificationLabels.DP] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.rootCodeConfidenceThreshold');
        rootCodeThresholds[this.codificationLabels.DP_ROOT] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.rootCodeConfidenceThreshold');
        rootCodeThresholds[this.codificationLabels.DA] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.rootCodeConfidenceThreshold');
        rootCodeThresholds[this.codificationLabels.DA_ROOT] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.rootCodeConfidenceThreshold');
        rootCodeThresholds[this.codificationLabels.MP] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.morbidManifestationDiagnostic.rootCodeConfidenceThreshold');
        rootCodeThresholds[this.codificationLabels.AE] = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.etiologicalAffectionDiagnostic.rootCodeConfidenceThreshold');

        const threshold = isExactCode ? exactCodeThreshold[predictiveDiagnostic.codificationLabelId] : rootCodeThresholds[predictiveDiagnostic.codificationLabelId];
        return predictiveDiagnostic.score >= threshold;
    }

    private _limitTo(menuOptions: any, labelIds: number[]) {
        const pDiagLimMax = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.limitMax');
        const pDiagLimMin = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.limitMin');

        const aDiagLimMax = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.limitMax');
        const aDiagLimMin = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.limitMin');

        const mDiagLimMax = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.morbidManifestationDiagnostic.limitMax');
        const mDiagLimMin = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.morbidManifestationDiagnostic.limitMin');

        const eDiagLimMax = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.etiologicalAffectionDiagnostic.limitMax');
        const eDiagLimMin = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.etiologicalAffectionDiagnostic.limitMin');

        let result = null;
        switch (true) {
            case (labelIds.includes(this.codificationLabels.DP) ||
                labelIds.includes(this.codificationLabels.DP_ROOT)) :
                result = menuOptions.forceDisplay.value ? pDiagLimMax :
                    pDiagLimMin;
                break;
            case (labelIds.includes(this.codificationLabels.DA) ||
                labelIds.includes(this.codificationLabels.DA_ROOT)) :
                result = menuOptions.forceDisplay.value ? aDiagLimMax :
                    aDiagLimMin;
                break;
            case labelIds.includes(this.codificationLabels.MP) :
                result = menuOptions.forceDisplay.value ? mDiagLimMax :
                    mDiagLimMin;
                break;
            case labelIds.includes(this.codificationLabels.AE) :
                result = menuOptions.forceDisplay.value ? eDiagLimMax :
                    eDiagLimMin;
                break;
            default:
        }
        return result;
    }

    /*region ---- Order ----*/
    private _orderBySlug(predictiveDiagnostics: any[], order: string = 'asc'): any[] {
        return _.orderBy(predictiveDiagnostics, ['diagnostic.slug'], [order]);
    }

    private _orderByScore(predictiveDiagnostics: any[]): any[] {
        return predictiveDiagnostics.sort(function (a, b) {
            if (a.simplifiedScore > b.simplifiedScore) {
                return -1;
            } else if (b.simplifiedScore > a.simplifiedScore) {
                return 1;
            } else if (a.score > b.score) {
                return -1;
            } else if (b.score > a.score) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    /*endregion*/

    /*region ---- Treeify ----*/
    /**
     * Transform array with parents and children to parents with children property
     */
    treeify(predictiveDiagnostics: any[]) {
        const newPredictiveDiagnostics = [];
        const lookup = {};
        // We fill a temp obj with keys as predictiveDiagnostic.diagnostic.id and values as predictiveDiagnostic obj (which is a reference to predictiveDiagnostics element)
        // and we add a children property to predictiveDiagnostic
        predictiveDiagnostics.forEach(predictiveDiagnostic => {
            lookup[predictiveDiagnostic.diagnostic.id] = predictiveDiagnostic;
            predictiveDiagnostic.children = [];
        });
        // For each predictiveDiagnostic, if it's parent we add it to newPredictiveDiagnostics array
        // otherwise we add it to temp obj element's (which is a reference to predictiveDiagnostics element) children property
        predictiveDiagnostics.forEach(predictiveDiagnostic => {
            if (lookup[predictiveDiagnostic.diagnostic.parentId] &&
                predictiveDiagnostic.diagnostic.parentId !== null) {
                lookup[predictiveDiagnostic.diagnostic.parentId].children.push(predictiveDiagnostic);
            } else {
                newPredictiveDiagnostics.push(predictiveDiagnostic);
            }
        });
        return newPredictiveDiagnostics;
    }

    /*endregion*/

    /*region ---- Apply ----*/
    /**
     * Set parent score to max between children diagnostic score
     * @param predictiveDiagnostics
     */
    applyChildrenScore(predictiveDiagnostics: any[]) {
        if (predictiveDiagnostics) {
            predictiveDiagnostics.forEach(predictiveDiagnostic => {
                if (predictiveDiagnostic.children && predictiveDiagnostic.children.length > 0) {
                    const maxChildrenScore =
                        Math.max(...predictiveDiagnostic.children.map(el => el.score));
                    const maxChildrenSimplifiedScore =
                        Math.max(...predictiveDiagnostic.children.map(el => el.simplifiedScore));
                    predictiveDiagnostic.score = Math.max(predictiveDiagnostic.score, maxChildrenScore);
                    predictiveDiagnostic.simplifiedScore = Math.max(predictiveDiagnostic.simplifiedScore, maxChildrenSimplifiedScore);
                }
            });
        }
    }

    /*endregion*/

    /*region ---- Get ----*/
    getChildrenCodificationLabelIds(codificationLabelIds: number[]) {
        if (codificationLabelIds) {
            // Different than DP_ROOT or DA_ROOT
            return codificationLabelIds.filter(id => id !== this.codificationLabels.DP_ROOT && id !== this.codificationLabels.DA_ROOT);
        }
        return [];
    }

    /*endregion*/

    /*region ---- Filter ----*/
    private _filterExactDiagnostics(predictiveDiagnostics: any[]) {
        if (predictiveDiagnostics) {
            // If we keep it if predictiveDiagnostic.score >= exact code threshold
            return predictiveDiagnostics.filter(predictiveDiagnostic =>
                // If there is no predictiveDiagnostic.id it means it's not a prediction from AI but a diagnostic
                // coming from ES then we don't want to check its score
                predictiveDiagnostic.id && this._greaterOrEqualScoreForExactAndRootCodes(predictiveDiagnostic, true));
        }
        return [];
    }

    filterExactAndRootDiagnostics(predictiveDiagnostics: any[]): any[] {
        // Link to doc : https://think.cothinking.org/collective-thinking/documentation/-/blob/develop/global-meaning-front-doc/how-to_guides/configure_predictions_mixed_display.md
        let filteredPredictiveDiagnostics = [];
        predictiveDiagnostics.forEach(predictiveDiagnostic => {
            if (predictiveDiagnostic.diagnostic) {
                const parentId = predictiveDiagnostic.diagnostic.parentId;
                if (parentId === null) {
                    // If it's a parent
                    const isRootCodeScoreValid = this._greaterOrEqualScoreForExactAndRootCodes(predictiveDiagnostic, false);
                    const childrenToKeep = predictiveDiagnostic.children ? this._filterExactDiagnostics(predictiveDiagnostic.children) : [];
                    if (isRootCodeScoreValid && !childrenToKeep.length) {
                        // We want to display the parent (with a valid score) and it's children even if their score is not valid
                        filteredPredictiveDiagnostics.push(predictiveDiagnostic);
                    } else if (childrenToKeep.length) {
                        // We display its children whose score is valid
                        filteredPredictiveDiagnostics = [
                            ...filteredPredictiveDiagnostics,
                            ...childrenToKeep
                        ];
                    }
                } else if (this._greaterOrEqualScoreForExactAndRootCodes(predictiveDiagnostic, true)) {
                    // If it's a child we keep it if its score >= exact code threshold
                    filteredPredictiveDiagnostics.push(predictiveDiagnostic);
                }
            }
        });
        return filteredPredictiveDiagnostics;
    }

    filterByCodificationLabelId(predictiveDiagnostics: any[], codificationLabelIds: number[]) {
        if (predictiveDiagnostics &&
            codificationLabelIds) {
            return predictiveDiagnostics.filter(diagnostic => codificationLabelIds.includes(diagnostic.codificationLabelId));
        }
        return [];
    }

    keepOnlySilverPredictions(predictiveDiagnostics: any[]): any[] {
        return predictiveDiagnostics ? predictiveDiagnostics.filter(predictiveDiagnostic => predictiveDiagnostic.simplifiedScore > 3) : [];
    }

    filterByScore(predictiveDiagnostics: any[],
                  predictiveDiagnosticSlugsToExclude: any[],
                  menuOptions: any,
                  codificationLabelIds: number[]) {
        if (predictiveDiagnostics &&
            predictiveDiagnosticSlugsToExclude &&
            codificationLabelIds &&
            menuOptions) {
            return predictiveDiagnostics.filter(predictiveDiagnostic => {
                if (predictiveDiagnostic.simplifiedScore <= SimplifiedScoreEnum.HIGH &&
                    (!menuOptions.display.value ||
                        !this._greaterOrEqualScore(predictiveDiagnostic, predictiveDiagnosticSlugsToExclude, menuOptions, codificationLabelIds))) {
                    return false;
                } else if (predictiveDiagnostic.children &&
                    predictiveDiagnostic.children.length > 0) {
                    predictiveDiagnostic.children = this.filterByScore(predictiveDiagnostic.children, predictiveDiagnosticSlugsToExclude, menuOptions, codificationLabelIds);
                    return true;
                } else {
                    return true;
                }
            });
        }
        return [];
    }

    filterByDiagnosticMissingSlug(predictiveDiagnostics: any[],
                                  diagnosticSlugs: string[]) {
        if (predictiveDiagnostics && diagnosticSlugs) {
            return this.limitToDiagnostics(predictiveDiagnostics, diagnosticSlugs);
        }
        return [];
    }

    limitToDiagnostics(predictiveDiagnostics: any[], diagnosticSlugs: string[]) {
        if (predictiveDiagnostics && diagnosticSlugs) {
            return predictiveDiagnostics.filter(predictiveDiagnostic => diagnosticSlugs.includes(predictiveDiagnostic.diagnostic.slug));
        }
        return [];
    }

    /*endregion*/

    /*region ---- Fusion ----*/
    fusionPredictiveDiagnostics(predictiveDiagnostics: any[]) {
        predictiveDiagnostics = this._orderBySlug(predictiveDiagnostics);
        for (let i = predictiveDiagnostics.length - 1; i >= 0; i--) {
            if (i > 0 &&
                predictiveDiagnostics[i] &&
                predictiveDiagnostics[i - 1]) {
                // Can have duplicates between DA / DA_RULE / DA_ROOT and DP / DP_ROOT
                const isDPCase = [this.codificationLabels.DP, this.codificationLabels.DP_ROOT].includes(predictiveDiagnostics[i - 1].codificationLabelId) && [this.codificationLabels.DP, this.codificationLabels.DP_ROOT].includes(predictiveDiagnostics[i].codificationLabelId);
                const isDACase = [this.codificationLabels.DA, this.codificationLabels.DA_ROOT].includes(predictiveDiagnostics[i - 1].codificationLabelId) && [this.codificationLabels.DA, this.codificationLabels.DA_ROOT].includes(predictiveDiagnostics[i].codificationLabelId);
                if (predictiveDiagnostics[i - 1].diagnostic.slug === predictiveDiagnostics[i].diagnostic.slug &&
                    (isDPCase || isDACase)) {
                    const previousDiagnosticChildren = _.cloneDeep(predictiveDiagnostics[i - 1].children);
                    // We choose to keep the one with max score
                    predictiveDiagnostics[i - 1] =
                        predictiveDiagnostics[i - 1].score > predictiveDiagnostics[i].score ? predictiveDiagnostics[i - 1] : predictiveDiagnostics[i];
                    if (previousDiagnosticChildren) {
                        // Because predictiveDiagnostics[i - 1] is now predictiveDiagnostics[i]
                        predictiveDiagnostics[i - 1].children = [
                            ...previousDiagnosticChildren,
                            ...predictiveDiagnostics[i].children
                        ];
                    }
                    predictiveDiagnostics.splice(i, 1);
                }
            }
        }
        predictiveDiagnostics.forEach(predictiveDiagnostic => {
            if (predictiveDiagnostic.children &&
                predictiveDiagnostic.children.length) {
                predictiveDiagnostic.children = this.fusionPredictiveDiagnostics(predictiveDiagnostic.children);
            }
        });
        return predictiveDiagnostics;
    }

    /*endregion*/

    /*region ---- Limit ----*/
    limitList(predictiveDiagnostics: any[], menuOptions: any, codificationLabelIds: number[]) {
        predictiveDiagnostics = this._orderByScore(predictiveDiagnostics);
        predictiveDiagnostics.splice(Number.parseFloat(this._limitTo(menuOptions, codificationLabelIds)), 10000);
    }

    /*endregion*/
}
