<div class="row">
    <div class="col padding-16">
        <ng-container *ngIf="history.length">
            <mat-card class="margin-bottom-10"
                      *ngFor="let element of history">
                <ng-container *ngIf="element.type !== 'grouping'; else grouping">
                    <ct-field-display class="display-block margin-bottom-10"
                                      [isKeyBold]="true"
                                      [key]="'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.ACTION' | translate"
                                      [value]="getTranslationFromAction(element)"></ct-field-display>

                    <ct-field-display class="display-block margin-bottom-10"
                                      *ngIf="element.type === 'diagnosis' || element.type === 'act'"
                                      [isKeyBold]="true"
                                      [key]="'LOG.SLUG' | translate"
                                      [value]="element.data?.analyzerSlug"></ct-field-display>
                    <ct-field-display class="display-block margin-bottom-10"
                                      *ngIf="element.data.action === 'update status'"
                                      [isKeyBold]="true"
                                      [key]="'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.STATUS' | translate"
                                      [value]="getDisplayStatus(element.data?.status)"></ct-field-display>

                    <ct-field-display class="display-block margin-bottom-10"
                                      [isKeyBold]="true"
                                      [key]="'STUFF.DATE' | translate"
                                      [value]="getDisplayDate(element.createdAt)"></ct-field-display>


                    <ct-field-display class="display-block margin-bottom-10"
                                      [isKeyBold]="true"
                                      [key]="'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.USER' | translate"
                                      [value]="getUserName(element)"></ct-field-display>
                </ng-container>

                <ng-template #grouping>
                    <div class="margin-bottom-10">
                        <b>{{'STUFF.PREVIOUS' | translate}} </b>
                        <ng-container [ngTemplateOutlet]="GHM"
                                      [ngTemplateOutletContext]="{$implicit: element, ghmKey: 'previousHealthGhm'}"></ng-container>

                        <ng-container [ngTemplateOutlet]="price"
                                      [ngTemplateOutletContext]="{$implicit: element, priceKey: 'previousPrice', isPrevious: true}"></ng-container>
                    </div>

                    <div class="margin-bottom-10">
                        <ng-container [ngTemplateOutlet]="GHM"
                                      [ngTemplateOutletContext]="{$implicit: element, ghmKey: 'healthGhm'}"></ng-container>

                        <ng-container [ngTemplateOutlet]="price"
                                      [ngTemplateOutletContext]="{$implicit: element, priceKey: 'newPrice', isPrevious: false}"></ng-container>
                    </div>
                </ng-template>
            </mat-card>
        </ng-container>

        <ng-template #GHM
                     let-element
                     let-ghmKey="ghmKey">
            <b *ngIf="!isTypeRehabilitation"
               [ngbTooltip]="'TOOLTIP.GHM_RSS' | translate"
               placement="right">{{'ACRONYM.GHM' | translate}} : </b>
            <b *ngIf="isTypeRehabilitation"
               [ngbTooltip]="'TOOLTIP.GME_RSS' | translate"
               placement="right">{{'ACRONYM.GME' | translate}} : </b>

            <ct-ghm-tag  [healthGhm]="getHealthGhm(element.data.grouping[ghmKey])"
                         [healthGhmDescriptionKey]="'name'"
                         [tooltipPlacement]="'right'"
                         [canDisplaySupIcon]="true"></ct-ghm-tag>
        </ng-template>

        <ng-template #price
                     let-element
                     let-priceKey="priceKey"
                     let-isPrevious="isPrevious">
            <div>
                <b *ngIf="isPrevious">{{'STUFF.PREVIOUS' | translate}} </b>
                <span class="ct-text-field font-weight-700">{{'DATA_SET.CODIFICATION_ELEMENT.PRICING' | translate}} :</span>
                <span [ngbTooltip]="'TOOLTIP.PRICE_GHS_ADJUST_TO_STAY_LENGTH_WITHOUT_ADDED_FORFAIT' | translate"
                      placement="top"
                      tooltipClass="ngb-tooltip">{{element.data.grouping[priceKey]}} €</span>
            </div>
        </ng-template>

        <ng-container *ngIf="!history.length">
            <div class="text-disabled text-center">
                {{'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION_HISTORY.NO_HISTORY' | translate}}
            </div>
        </ng-container>
    </div>
</div>
