import {Component, Inject, OnInit} from '@angular/core';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DateHelperService} from '../../../../../core/services/date.helper.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarService} from '../../../../../core/services/snack-bar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateValidator} from '../../../../../shared/validators/date.validator';

@Component({
    selector: 'ct-stay-detail-data-set-element-dates-edit-dialog',
    templateUrl: './stay-detail-data-set-element-dates-edit-dialog.component.html',
    styleUrls: ['./stay-detail-data-set-element-dates-edit-dialog.component.scss']
})
export class StayDetailDataSetElementDatesEditDialogComponent implements OnInit {
    private _dataSetElement: DataSetElement;

    datesForm: FormGroup;
    isLoading: boolean;

    get formControls() {
        return this.datesForm.controls;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                private _formBuilder: FormBuilder,
                private _dialogRef: MatDialogRef<StayDetailDataSetElementDatesEditDialogComponent>,
                private _translateService: TranslateService,
                private _snackBarService: SnackBarService,
                private _dateHelperService: DateHelperService,
                private _dataSetElementService: DataSetElementService) { }

    ngOnInit(): void {
        this._dataSetElement = this.data.dataSetElement;
        this._initForm();
        this._initDates();
    }

    private _initForm() {
        this.datesForm = this._formBuilder
            .group(
                {
                    startDate: [null, [Validators.required]],
                    endDate: [null, [Validators.required]],
                    startDateTime: ['', [Validators.required]],
                    endDateTime: ['', [Validators.required]],
                },
                {
                    validators: [DateValidator.outRangeDateValidator('startDate', 'endDate')],
                    updateOn: 'change'
                }
            );
    }

    private _initDates() {
        if (this._dataSetElement) {
            this.datesForm.controls['startDate'].patchValue(new Date(DateHelperService.mysqlToInput(this._dataSetElement.dataSetContent.startDate)));
            this.datesForm.controls['endDate'].patchValue(new Date(DateHelperService.mysqlToInput(this._dataSetElement.dataSetContent.endDate)));
            this.datesForm.controls['startDateTime'].patchValue(this._dateHelperService.getTime(this._dataSetElement.dataSetContent.startDate));
            this.datesForm.controls['endDateTime'].patchValue(this._dateHelperService.getTime(this._dataSetElement.dataSetContent.endDate));
        }
    }

    private _addTimeToDate(date: string, useCase: string) {
        if (date &&
            useCase) {
            return this.datesForm.controls[useCase + 'Time'] ?
                `${date} ${this.datesForm.controls[useCase + 'Time'].value}:00` : date;
        }
        return '';
    }

    isValidationDisabled() {
        return this.datesForm.invalid;
    }

    async validate() {
        try {
            this.isLoading = true;
            const startDate = this._addTimeToDate(DateHelperService.toMysql(this.datesForm.controls['startDate'].value), 'startDate') || this._dataSetElement.dataSetContent.startDate;
            const endDate = this._addTimeToDate(DateHelperService.toMysql(this.datesForm.controls['endDate'].value), 'endDate') || this._dataSetElement.dataSetContent.endDate;
            const body = {startDate, endDate};
            await this._dataSetElementService.updateDataSetElementDates(this._dataSetElement.id, body);
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            this.isLoading = false;
            this._dialogRef.close(body);
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }
}
