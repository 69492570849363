import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailCodificationActComponent} from './stay-detail-codification-act.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StayDetailCodificationActEditionModule} from './edition/stay-detail-codification-act-edition.module';
import {StayDetailCodificationActAddDialogComponent} from './add-dialog/stay-detail-codification-act-add-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { StayDetailEditActExecutionDateDialogComponent } from './edit-act-date-dialog/stay-detail-edit-act-execution-date-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {PipesModule} from '../../../../../shared/pipes/pipes.module';

@NgModule({
    declarations: [
        StayDetailCodificationActComponent,
        StayDetailCodificationActAddDialogComponent,
        StayDetailEditActExecutionDateDialogComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        StayDetailCodificationActEditionModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        MatInputModule,
        PipesModule
    ],
    exports: [
        StayDetailCodificationActComponent
    ],
})
export class StayDetailCodificationActModule {}
