<h1 mat-dialog-title><span [innerHTML]="title | translate | safeHtml"></span></h1>
<h2 mat-dialog-title *ngIf="status"><span>{{'DATA_MONITORING.STAY_DETAIL.STAY_CONFIRMATION_STATUS_DIALOG' | translate}}</span>
    <div class="stay-status background-color"
         [ngClass]="[dataSetElementService.getStatusColor(+stayStatus[status].value)]">
    <span>{{stayStatus[status].text | translate | uppercase}}</span>
    </div>
</h2>
<mat-dialog-actions>
    <button #cancelButton
            mat-button
            class="color-blue-on-white"
            *ngIf="canClose"
            (click)="confirm('false')">
        <span *ngIf="!cancelButtonText">{{'BUTTON.CANCEL' | translate | uppercase}}</span>
        <span *ngIf="cancelButtonText">{{cancelButtonText | uppercase}}</span>
    </button>
    <button
        *ngIf="status"
            mat-button
            class="color-blue-on-white"
            (click)="confirm('waiting')">
        <span>{{'BUTTON.WAITING' | translate | uppercase}}</span>
    </button>
    <button #validateButton
            mat-raised-button
            color="primary"
            [ngStyle]="{'min-width': validateButtonCustomMinWidth}"
            (click)="confirm('true')">
        <span *ngIf="!validateButtonText">{{'BUTTON.CONFIRM' | translate | uppercase}}</span>
        <span *ngIf="validateButtonText">{{validateButtonText | uppercase}}</span>
    </button>
</mat-dialog-actions>
