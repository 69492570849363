import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../../modules/user/user.model';
import {UserService} from '../../../modules/user/user.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FilterSearchService} from '../../../modules/filter-search/shared/filter-search.service';

@Component({
    selector: 'ct-worklist-user-element',
    templateUrl: './worklist-user-element.component.html',
    styleUrls: ['./worklist-user-element.component.scss']
})

export class WorklistUserElementComponent implements OnInit {
    isLoading: boolean;
    usersDb: User[];
    user: User;
    oldUserList: any[] = [];
    userList: any[] = [];
    constructor(public dialogRef: MatDialogRef<WorklistUserElementComponent>,
                @Inject(MAT_DIALOG_DATA) public data,
                public dialog: MatDialog,
                private _userService: UserService,
                private _filterSearchService: FilterSearchService) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        Promise.all([
            this.loadUser()
        ]).then(() => {
            this.isLoading = false;
        });
    }
    private async getWorklistUserData(): Promise<any> {
        const params = {
            include: ['workListUser']
        };
        const data = await this._filterSearchService.loadFilterSearch(this.data.filterSearchId, params);
        const userList = [];
        data.workListUser.forEach(
            (element) => {
                userList.push(element.id);
            }
        );
        return userList;
    }

    private async loadUser(): Promise<void> {
        this.usersDb = await this._userService.loadUsers();
        this.oldUserList = await this.getWorklistUserData();
        this.userList = this.oldUserList.slice();
        if (this.data?.userList?.length > 0) {
            this.data.userList.forEach( item => {
                    this.addUser(item.id);
                });
        }
    }

     public isSelected(userDbId: number): boolean {
        return !!this.userList.find(id => id === userDbId);
    }

    public addUser(Userid: number): void {
        if (this.usersDb.find(({id}) => id === Userid)) {
            if (!this.userList.find(id => id === Userid)) {
                this.userList.push(Userid);
            }
        }
    }

    public removeUser(findId: number): void {
        if (this.userList.findIndex(id => id === findId) !== -1) {
            this.userList.splice(this.userList.findIndex(id => id === findId), 1);
        }
    }
    sendUser(): void {
        const diff = this.oldUserList.filter(x => this.userList.indexOf(x) === -1);
        this.dialogRef.close({userList: this.userList, removeUserList: diff});
    }
}
