import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class DataWebApiService {
    private _BASE_URL = 'data/web/send-diagnosis-codification';

    constructor(private _http: HttpService) {}

    post(body: any): Observable<any[]> {
        return this._http
            .post(`${this._BASE_URL}`, body)
            .pipe(map(res => res.data));
    }
}
