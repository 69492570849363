<div class="margin-bottom-30">
    <div class="display-inline-block margin-right-30"
         *ngIf="dataSetElement.dataSetContent && dataSetElement.dataSetContent.ctHealthGhsGhm && dataSetElement.dataSetContent.ctHealthGhsGhm.healthGhm">
        <b [ngbTooltip]="'TOOLTIP.GHM_CT' | translate"
           placement="right">{{'ACRONYM.GHM' | translate}} CT : </b>

        <ct-ghm-tag [healthGhm]="dataSetElement.dataSetContent?.ctHealthGhsGhm?.healthGhm"
                    [healthGhmDescriptionKey]="'name'"
                    [tooltipPlacement]="'right'"
                    [canDisplaySupIcon]="true"></ct-ghm-tag>
    </div>

    <div class="display-inline-block">
        <span class="ct-text-field font-weight-700">{{'DATA_SET.CODIFICATION_ELEMENT.PRICING' | translate}} :</span>
        <span class="no-data margin-right-5"
              *ngIf="!dataSetElement.dataSetContent?.pricing">N/A</span>
        <span *ngIf="dataSetElement.dataSetContent?.pricing"
              [ngbTooltip]="'TOOLTIP.PRICE_GHS_ADJUST_TO_STAY_LENGTH_WITHOUT_ADDED_FORFAIT' | translate"
              placement="top"
              tooltipClass="ngb-tooltip">{{dataSetElement.dataSetContent?.pricing}} €</span>
    </div>
</div>

<h1 mat-dialog-title>{{'NEAR_AUTOMATION.VALIDATION_MESSAGE' | translate}}</h1>

<mat-dialog-actions>
    <button #cancelButton
            mat-button
            class="color-blue-on-white"
            (click)="confirm({action: 'cancel'})">
        <span>{{'NEAR_AUTOMATION.DIALOG_CANCEL_BUTTON_TEXT' | translate | uppercase}}</span>
    </button>

    <button #validateButton
            mat-raised-button
            color="primary"
            *ngIf="!isLastStay"
            (click)="confirm({action: 'goToNextStay'})">
        <span>{{'NEAR_AUTOMATION.DIALOG_GO_TO_NEXT_STAY_BUTTON_TEXT' | translate | uppercase}}</span>
    </button>

    <button #validateButton
            mat-raised-button
            color="primary"
            *ngIf="isLastStay"
            (click)="confirm({action: 'goBackToList'})">
        <span>{{'NEAR_AUTOMATION.DIALOG_GO_BACK_TO_LIST_BUTTON_TEXT' | translate | uppercase}}</span>
    </button>
</mat-dialog-actions>
