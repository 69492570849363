import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FilterSearchService} from '../filter-search.service';
import {TranslateService} from '@ngx-translate/core';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {FormBuilder, FormGroup, ValidatorFn} from '@angular/forms';
import {DateValidator} from '../../../../shared/validators/date.validator';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {Subscription} from 'rxjs';

class CustomValidator {
    public static hasAtLeastOneDateValidator(): ValidatorFn {
        return (formGroup: FormGroup) => {
            return (!formGroup.controls['validityFrameStartDate'].value && !formGroup.controls['validityFrameEndDate'].value) ? {noDate: true} : null;
        };
    }
}

@Component({
    selector: 'ct-filter-search-edit-validity-frame',
    templateUrl: './filter-search-edit-validity-frame.component.html',
    styleUrls: ['./filter-search-edit-validity-frame.component.scss']
})
export class FilterSearchEditValidityFrameComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];
    @Input() filterSearch: any;
    @Input() useCase: string;

    @Output() formValuesChange = new EventEmitter<any>();
    @Output() enterKeyPress = new EventEmitter<boolean>();

    dateForm: FormGroup;

    constructor(private _filterSearchService: FilterSearchService,
                private _formBuilder: FormBuilder,
                private _hotKeysService: HotkeysService,
                private _dateHelperService: DateHelperService,
                private _translateService: TranslateService) {
    }

    ngOnInit() {
        this._initForm();
        const sub = this.dateForm.valueChanges.subscribe(() => {
            this._emitFormsValues();
        });
        this._subscriptions.push(sub);
        this._hotKeysService
            .add(new Hotkey('enter', (): boolean => {
                this.enterKeyPress.emit(true);
                return false; // Prevent bubbling
            }));
    }

    private _emitFormsValues() {
        const data = {
            dateFormInvalidPropertyValue: this.dateForm.invalid,
            validityFrameStartDate: this.dateForm.controls.validityFrameStartDate.value,
            validityFrameEndDate: this.dateForm.controls.validityFrameEndDate.value
        };
        this.formValuesChange.emit(data);
    }

    private _initForm() {
        this.dateForm = this._formBuilder
            .group(
                {
                    validityFrameStartDate: [null],
                    validityFrameEndDate: [null]
                },
                {
                    validators: [CustomValidator.hasAtLeastOneDateValidator(), DateValidator.outRangeDateValidator('validityFrameStartDate', 'validityFrameEndDate')],
                    updateOn: 'change'
                }
            );
        this._initValidityFrame();
    }

    private _initValidityFrame() {
        let startDate;
        let endDate;
        if (this.useCase === 'add') {
            const data = this._filterSearchService.getDefaultValidityFrame();
            startDate = data.startDate;
            endDate = data.endDate;
        } else if (this.useCase === 'edit') {
            startDate = new Date(DateHelperService.mysqlToInput(this.filterSearch.validFrom));
            endDate = new Date(DateHelperService.mysqlToInput(this.filterSearch.validTo));
        }
        this.dateForm.controls.validityFrameStartDate.patchValue(startDate);
        this.dateForm.controls.validityFrameEndDate.patchValue(endDate);
        this._emitFormsValues();
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => sub.unsubscribe());
        }
    }
}
