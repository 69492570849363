import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpService} from '../../http/http.service';

export interface MenuItemFromApi {
    id: number;
    name: string;
    params: string;
    state: string;
    status: boolean;
    visibility: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MenuApiService {
    private _BASE_URL = 'menu';

    constructor(private _http: HttpService) {}

    getAll(urlRequestParams?: any): Observable<MenuItemFromApi[]> {
        return this._http
            .get(`${this._BASE_URL}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
