import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class FeedbackApiService {
    private _BASE_URL = 'feedback';

    constructor(private _http: HttpService) {}

    getAll(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    create(body: any) {
        return this._http
            .post(`${this._BASE_URL}`, body)
            .pipe(map(res => res.data));
    }

    update(feedbackId: number, body: any, urlRequestParams?: any) {
        return this._http
            .patch(`${this._BASE_URL}/${feedbackId}`, body, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    delete(feedbackId: number, urlRequestParams?: any) {
        return this._http
            .delete(`${this._BASE_URL}/${feedbackId}`, null, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
