import {ConfigurationService} from 'src/app/modules/configuration/configuration.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import {StayListService} from '../../list/shared/stay-list.service';
import {StayDetailService} from '../stay-detail.service';
import {AppLogService} from '../../../../core/app-log/app-log.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ct-stay-detail-header',
    templateUrl: './stay-detail-header.component.html',
    styleUrls: ['./stay-detail-header.component.scss']
})
export class StayDetailHeaderComponent implements OnInit, OnDestroy {
    currentDataSetElementNumber: number;
    totalDataSetElements: number;
    isFilterSearchUpdated: boolean;
    fromFilterSearchList: boolean;
    lastFilterSearchViewedName: string;
    @Input() dataSetElement: DataSetElement;
    @Input() isNearAutomationQualityControl: boolean;
    private _subscriptions: Subscription[] = [];
    private _dataSetElementListingInfo = null;
    private _currentStoredDataSetElement: any;

    constructor(public $state: StateService,
                private _stayListService: StayListService,
                private _stayDetailService: StayDetailService,
                private _logService: AppLogService,
                private _broadcastService: BroadcastService,
                private _configurationService: ConfigurationService) {
    }

    ngOnInit() {
        if (this.dataSetElement) {
            this._initData();
        }
        this._subscribeToBroadcast();
    }

    fromPatientDetail() {
        return this.$state.params && this.$state.params.fromPatientDetail === 'true';
    }

    switchDataSetElement(direction: string) {
        if (this._currentStoredDataSetElement) {
            sessionStorage.removeItem('currentFilterSearch');
            let params: any = {};
            params = this._buildParams(params);
            const functionToCall: () => void = () => {
                if (direction === 'next') {
                    this._switchToNextDataSetElement(params, direction);
                } else if (direction === 'previous') {
                    this._switchToPreviousDataSetElement(params, direction);
                }
            };
            this._handleLeaveStayDetailPage(functionToCall);
        }
    }

    noMorePreviousDataSetElement() {
        if (!this._currentStoredDataSetElement) {
            return true;
        }
        return (this._dataSetElementListingInfo.currentPage === 1) &&
            !this._currentStoredDataSetElement.previousDataSetElementFirstChildId;
    }

    noMoreNextDataSetElement() {
        if (!this._currentStoredDataSetElement) {
            return true;
        }
        return (this._dataSetElementListingInfo.currentPage === this._dataSetElementListingInfo.lastPage) &&
            !this._currentStoredDataSetElement.nextDataSetElementFirstChildId;
    }

    canDisplayBackButton() {
        return this.$state.params && this.$state.params.isDialog !== 'true';
    }

    getBackButtonText() {
        return this._isPatientUseCase() ? 'DATA_SET.CODIFICATION_ELEMENT.BACK_TO_LIST_PATIENT' : 'DATA_SET.CODIFICATION_ELEMENT.BACK_TO_LIST';
    }

    goBackToList() {
        if (this._isPatientUseCase()) {
            this._stayDetailService.goToPatientDetail(this.dataSetElement);
            return;
        }
        // tslint:disable-next-line:prefer-const
        let {lastSearchParams, stateToGo} = this._getStateToGoAndLastSearchParams();
        if (!lastSearchParams) {
            lastSearchParams = {dataSetId: 1, codificationId: 1};
        } else if (!lastSearchParams['dataSetId'] ||
            !lastSearchParams['codificationId']) {
            lastSearchParams = {
                ...lastSearchParams,
                dataSetId: 1,
                codificationId: 1
            };
        }
        lastSearchParams['fromFilterSearchList'] = this.$state.params.fromFilterSearchList;
        lastSearchParams['fromStayDetailPage'] = true;
        lastSearchParams.page = this._getPageToGo(lastSearchParams) || lastSearchParams.page;
        const functionToCall: () => void = () => this.$state.go(stateToGo, lastSearchParams);
        this._handleLeaveStayDetailPage(functionToCall);
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'menu::openMenu':
                        const functionToCall: () => void = () => this.$state.go(res.data[0], res.data[1], res.data[2]);
                        this._handleLeaveStayDetailPage(functionToCall);
                        break;
                    case 'stayDetailCodificationNearAutomation::goToNextStay':
                        this.switchDataSetElement('next');
                        break;
                    case 'stayDetailCodificationNearAutomation::goBackToList':
                        this.goBackToList();
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private _initData() {
        const key = this._getListingInfoStorageKey();
        this._dataSetElementListingInfo = JSON.parse(sessionStorage.getItem(key));
        if (this.dataSetElement &&
            this.dataSetElement.parent &&
            this._dataSetElementListingInfo) {
            this._currentStoredDataSetElement = this._dataSetElementListingInfo.data
                .find(element => element.dataSetElementId === this.dataSetElement.parent.id);
            this.currentDataSetElementNumber = this._calculateCurrentDataSetElementNumber();
            this.totalDataSetElements = this._dataSetElementListingInfo.total;

        }
        this.fromFilterSearchList = this.$state.params.fromFilterSearchList === 'true';
        this.lastFilterSearchViewedName = sessionStorage.getItem('lastFilterSearchViewed') ? JSON.parse(sessionStorage.getItem('lastFilterSearchViewed')).name : null;
        this.isFilterSearchUpdated = sessionStorage.getItem('isFilterSearchUpdated') === 'true';
    }

    private _getListingInfoStorageKey() {
        if (this.$state.params.isDialog !== 'true') {
            return this.fromPatientDetail() || this.$state.params.fromPatientList === 'true' ? 'dataSetElementPatientListingInfo' : 'dataSetElementListingInfo';
        } else {
            return 'dataSetElementPredictionAnalysisListingInfo';
        }
    }

    private _calculateCurrentDataSetElementNumber() {
        let numberToReturn = 0;
        if (this._currentStoredDataSetElement) {
            const numberOfPreviousItems = (this._dataSetElementListingInfo.currentPage - 1) * this._dataSetElementListingInfo.perPage;
            const currentItemIndex =
                this._dataSetElementListingInfo
                    .data.map(el => el.dataSetElementId)
                    .indexOf(this._currentStoredDataSetElement.dataSetElementId) + 1;
            numberToReturn = numberOfPreviousItems + currentItemIndex;
        }
        const key = !this.fromPatientDetail() ? 'currentDataSetElementNumber' : 'currentDataSetElementPatientNumber';
        sessionStorage.setItem(key, numberToReturn.toString());
        return numberToReturn;
    }

    private _buildParams(params: any) {
        params = {
            forFilterSearch: 'false',
            access: 'dataSetElementId'
        };
        if (this.$state.params.fromQualityControlNearAutomation === 'true' &&
            this.$state.params.fromStayDetail !== 'true') {
            params.isNearAutomationDone = 'false';
        }
        // If we are fromFilterSearchList we set documentContent ... to lastFilterSearchViewed params
        if (this.$state.params.fromFilterSearchList === 'true') {
            const lastFilterSearchViewed = JSON.parse(sessionStorage.getItem('lastFilterSearchViewed'));
            if (lastFilterSearchViewed) {
                const filterSearchParams = JSON.parse(lastFilterSearchViewed.params) || {};
                params = {
                    ...params,
                    AIValuation: '',
                    fromFilterSearchList: 'true',
                    ...filterSearchParams
                };
            }
        } else {
            // Case we are navigating throw stays without search params
            params = {
                ...params,
                AIValuation: this.$state.params.AIValuation || '',
                fromFilterSearchList: 'false'
            };

            // Case we have search params we want them for previous/next stay
            const key = this.$state.params.fromPatientList === 'true' ? 'lastPatientSearchParams' : 'lastSearchParams';
            const lastSearchParams = JSON.parse(sessionStorage.getItem(key));
            if (lastSearchParams) {
                params = {
                    ...params,
                    ...lastSearchParams
                };
            }
        }
        return params;
    }

    /**
     * If we are in a dialog we send message via broadcast to close and re open dialog with new dataSetElement.
     * Otherwise we reload state with new dataSetElement
     * @param newStateParams
     * @private
     */
    private _makeAction(newStateParams: any) {
        if (this.$state.params.isDialog !== 'true') {
            this.$state.go(this.$state.current.name, newStateParams);
        } else {
            this._broadcastService.send('stayDetailInfo::reload', {dataSetElementId: newStateParams.dataSetElementId});
        }
    }

    private _switchToPreviousDataSetElement(params: any, direction: string) {
        this._logService.logInfo('Click on previous stay');
        if (this._currentStoredDataSetElement.previousDataSetElementFirstChildId) {
            params.dataSetElementId = this._currentStoredDataSetElement.previousDataSetElementFirstChildId;
            if (this.$state.params.fromQualityControl === 'true' ||
                this.$state.params.fromQualityControlNearAutomation === 'true' ||
                this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
                params.parentId = this._currentStoredDataSetElement.previousDataSetElementId;
            }
            const newStateParams = {...this.$state.params, ...params};
            this._makeAction(newStateParams);
        } else {
            this._stayListService.loadMoreDataSetElements(this.dataSetElement, this._dataSetElementListingInfo, direction)
                .then(objStored => {
                    if (objStored &&
                        objStored.data &&
                        objStored.data[objStored.data.length - 1]) {
                        params.dataSetElementId = objStored.data[objStored.data.length - 1].dataSetElementFirstChildId;
                        if (this.$state.params.fromQualityControl === 'true' ||
                            this.$state.params.fromQualityControlNearAutomation === 'true' ||
                            this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
                            params.parentId = objStored.data[objStored.data.length - 1].dataSetElementId;
                        }
                    }
                    const newStateParams = {...this.$state.params, ...params};
                    this._makeAction(newStateParams);
                });
        }
    }

    private _switchToNextDataSetElement(params: any, direction: string) {
        this._logService.logInfo('Click on next stay');
        if (this._currentStoredDataSetElement.nextDataSetElementFirstChildId) {
            params.dataSetElementId = this._currentStoredDataSetElement.nextDataSetElementFirstChildId;
            if (this.$state.params.fromQualityControl === 'true' ||
                this.$state.params.fromQualityControlNearAutomation === 'true' ||
                this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
                params.parentId = this._currentStoredDataSetElement.nextDataSetElementId;
            }
            const newStateParams = {...this.$state.params, ...params};
            this._makeAction(newStateParams);
        } else {
            this._stayListService.loadMoreDataSetElements(this.dataSetElement, this._dataSetElementListingInfo, direction)
                .then(objStored => {
                    if (objStored &&
                        objStored.data &&
                        objStored.data[0]) {
                        params.dataSetElementId = objStored.data[0].dataSetElementFirstChildId;
                        if (this.$state.params.fromQualityControl === 'true' ||
                            this.$state.params.fromQualityControlNearAutomation === 'true' ||
                            this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
                            params.parentId = objStored.data[0].dataSetElementId;
                        }
                        const newStateParams = {...this.$state.params, ...params};
                        this._makeAction(newStateParams);
                    }
                });
        }
    }

    private _isPatientUseCase() {
        return this.dataSetElement &&
            this.dataSetElement.dataSetContent &&
            this.dataSetElement.dataSetContent.healthPatient &&
            this.dataSetElement.dataSetContent.healthPatient.ipp &&
            this.$state.params &&
            this.$state.params.fromPatientDetail === 'true';
    }

    private _getPageToGo(searchParams: any) {
        if (searchParams) {
            const perPage = Number.parseFloat(searchParams.perPage) || 10;
            const currentDataSetElementNumber = Number.parseFloat(sessionStorage.getItem('currentDataSetElementNumber') || '1');
            return Math.ceil(currentDataSetElementNumber / perPage).toString();
        }
        return '1';
    }

    private _getStateToGoAndLastSearchParams() {
        let lastSearchParams = null;
        let stateToGo = '';
        if (!this.$state.params.fromQualityControl &&
            !this.$state.params.fromQualityControlNearAutomation &&
            !this.$state.params.fromQualityControlImpreciseDiagnostic) {
            lastSearchParams = JSON.parse(sessionStorage.getItem('lastSearchParams')) || {};
            stateToGo = this.$state.params.fromExternalStayList === 'true' ? 'stay-list-external' : this.$state.params.fromRehabilitationStayList === 'true' ? 'stay-list-rehabilitation' : 'stay-list';
        } else if (this.$state.params.fromQualityControl === 'true' ||
            this.$state.params.fromQualityControlNearAutomation === 'true' ||
            this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
            if (this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
                stateToGo = 'quality-control-imprecise-diagnostic';
            } else if (this.$state.params.fromQualityControlNearAutomation === 'true') {
                stateToGo = 'quality-control-near-automation';
            } else {
                stateToGo = 'quality-control';
            }
            lastSearchParams = JSON.parse(sessionStorage.getItem(`${stateToGo}-searchParams`)) || {};
        }
        return {
            lastSearchParams,
            stateToGo
        };
    }

    private _handleLeaveStayDetailPage(functionToCall: () => any) {
        this._stayDetailService.openUpdateStayStatusConfirmDialog(this.dataSetElement, functionToCall);
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
