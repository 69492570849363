import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-string-value',
    templateUrl: './search-engine-condition-string-value.component.html',
    styleUrls: ['./search-engine-condition-string-value.component.scss']
})
export class SearchEngineConditionStringValueComponent implements OnInit {
    @Input() condition: any;
    @Input() paramKey: string;
    @Input() placeholder = 'STUFF.VALUE';

    constructor() { }

    ngOnInit(): void {
    }

}
