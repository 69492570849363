import {Component, Input, OnInit} from '@angular/core';
import {DataSetElement} from '../../../modules/stay/shared/data-set-element.model';

@Component({
    selector: 'ct-warning-indicator',
    templateUrl: './warning-indicator.component.html',
    styleUrls: ['./warning-indicator.component.scss']
})
export class WarningIndicatorComponent implements OnInit {
    @Input() dataSetElement: DataSetElement;
    @Input() tooltipText: string;
    @Input() classes: string[];

    constructor() { }

    ngOnInit(): void {}
}
