<ng-container *ngIf="document">
    <mat-progress-bar mode="indeterminate"
                      *ngIf="isLoading"></mat-progress-bar>

    <div class="row cursor-pointer"
         (click)="openDocument()">
        <div class="col">
            <div class="header-container">
                <div class="document-type">
                    <div class="display-inline-block">
                        <mat-icon *ngIf="!document.isOpened">expand_more</mat-icon>
                        <mat-icon *ngIf="document.isOpened">expand_less</mat-icon>
                    </div>

                    <!--CreatedAt-->
                    <ng-container *ngIf="canDisplayStayRisk && document.documentCreatedAt && !document.documentType?.structuredData">
                        <div class="document-date tag active"
                             [ngClass]="'tag-' + getChipColor(document.documentCreatedAt)">
                            {{getDocumentCreationDate(document.documentCreatedAt)}}
                        </div>
                    </ng-container>

                    <span class="badge rounded-pill bg-secondary margin-left-5"
                          *ngIf="canDisplayDocumentTypeFromML && document.inferredDocumentType && document.inferredDocumentType.name && document.inferredDocumentType.name !== 'other'">
                            {{getInferredDocumentTypeName()}}</span>

                    <div class="display-inline-block">
                        <div class="text display-inline-block">
                            <h4 class="display-inline-block"
                                matTooltipPosition="below"
                                [matTooltip]="document.filename">{{document.documentType.name || document.documentType.slug}}</h4>

                            <!--SubType-->
                            <h4 class="display-inline-block"
                                *ngIf="document.documentType && document.documentType.subType">&nbsp;- {{document.documentType.subType}}</h4>
                        </div>
                        <mat-icon *ngIf="document.documentType && document.documentType.essential"
                                  [matTooltip]="'TOOLTIP.ESSENTIAL_DOC_TYPE' | translate">check_circle</mat-icon>
                    </div>

                    <!--Search input for structured data-->
                    <div class="structured-data-query-field"
                         *ngIf="document.documentType && document.documentType.structuredData && document.isOpened">
                        <mat-form-field [floatLabel]="'never'">
                            <input matInput
                                   type="text"
                                   name="structuredDataQuery"
                                   [(ngModel)]="structuredDataQuery"
                                   [placeholder]="('BUTTON.SEARCH' | translate) + '...'"
                                   (click)="$event.stopPropagation()">
                        </mat-form-field>
                    </div>
                </div>

                <!--Icons and tabs-->
                <div class="display-inline-block">
                    <div class="display-inline-block"
                         *ngIf="document.isOpened && !document.documentType?.structuredData">
                        <div class="tab"
                             *ngFor="let tab of tabs"
                             [ngClass]="{'active': document.activeTabId === tab.id, 'disabled': tab.disabled}"
                             (click)="switchTab($event, tab)">
                            {{tab.label}}
                        </div>
                    </div>

                    <ng-container *ngIf="canDisplayStayRisk && !document.documentType?.structuredData">
                        <button mat-icon-button
                                *ngIf="document.documentCreatedAt"
                                [id]="1"
                                [matMenuTriggerFor]="attachPatientDocumentMenu"
                                (click)="openMenu($event, 1)">
                            <mat-icon class="text-color md-green"
                                      [matTooltip]="'TOOLTIP.ATTACH_DOCUMENT_TO_STAY' | translate">playlist_add_check</mat-icon>
                        </button>

                        <button mat-icon-button
                                *ngIf="!document.documentCreatedAt && document.previousHealthPatientDocument"
                                (click)="attachDocument($event)">
                            <mat-icon class="text-color md-red"
                                      [matTooltip]="'TOOLTIP.ATTACH_DOCUMENT_TO_PATIENT' | translate">not_interested</mat-icon>
                        </button>

                        <mat-menu #attachPatientDocumentMenu="matMenu"
                                  [class]="'attach-patient-doc-menu'">
                            <ng-template matMenuContent>
                                <button mat-menu-item
                                        class="font-weight-bold"
                                        (click)="attachDocument($event)">{{'BUTTON.ATTACH_DOC_TO_CURRENT_STAY' | translate}}
                                </button>

                                <button mat-menu-item
                                        disabled
                                        class="font-weight-bold"
                                        *ngIf="patientStays && patientStays.length">{{'BUTTON.ATTACH_DOC_TO_PATIENT_STAY' | translate}}
                                </button>

                                <ng-container *ngFor="let stay of patientStays">
                                    <div mat-menu-item
                                         class="menu-stay-item"
                                         (click)="attachDocument($event, stay)">
                                        <span>{{'DATA_SET.CODIFICATION_ELEMENT.STAY_NUMBER' | translate}}</span>
                                        <span>{{stay.dataSetContent?.stayId || stay.dataSetContent?.stayIdOriginal}}</span> &nbsp;
                                        <span>({{dateHelperService.mysqlToFrench(stay.dataSetContent?.startDate)}}) - ({{dateHelperService.mysqlToFrench(stay.dataSetContent?.endDate)}})</span>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </mat-menu>
                    </ng-container>

                    <ct-file-display-button *ngIf="document.filename && !document.documentType?.structuredData"
                                            [src]="getDisplayDocumentSrc()"
                                            [filename]="document.filename"
                                            [title]="document.documentType.name || document.documentType?.slug"></ct-file-display-button>

                    <button mat-icon-button
                            [id]="2"
                            [matMenuTriggerFor]="documentMenu"
                            (click)="openMenu($event, 2)">
                        <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #documentMenu="matMenu">
                        <ng-template matMenuContent>
                            <button mat-menu-item
                                    *ngIf="!document.documentType?.structuredData"
                                    (click)="goToHealthDocumentAnonymization()">
                                <mat-icon>find_in_page</mat-icon>
                                <span>{{'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.ANONYMIZE_DOCUMENT' | translate}}</span>
                            </button>

                            <button mat-menu-item
                                    *ngIf="canSummarize"
                                    (click)="summarizeDocument()">
                                <mat-icon>subject</mat-icon>
                                <span>{{'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.SUMMARIZE_DOCUMENT' | translate}}</span>
                            </button>

                            <button mat-menu-item
                                    (click)="detachDocument()">
                                <mat-icon>delete</mat-icon>
                                <span>{{'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.DELETE_DOCUMENT' | translate}}</span>
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>

    <mat-divider *ngIf="document.isOpened"></mat-divider>

    <div class="row margin-top-10 padding-bottom-10"
         *ngIf="document.isOpened">
        <div class="col">
            <div class="highlight-container">
                <!-- Doc synthesis -->
                <div *ngIf="document.activeTabId === 1">
                    <ng-container *ngIf="!document.documentType?.structuredData && document.healthDocumentContent">
                        <div class="line">
                            <ct-stay-detail-document-content-text
                                [text]="document.healthDocumentContent.textModified"></ct-stay-detail-document-content-text>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="document.documentType?.structuredData && document.structuredData">
                        <ct-stay-detail-document-content-structured
                            *ngIf="document.structuredData.lines && document.structuredData.lines.length > 0"
                            [document]="document"
                            [searchQuery]="structuredDataQuery"></ct-stay-detail-document-content-structured>
                    </ng-container>
                </div>
            </div>

            <!-- Doc file -->
            <div *ngIf="!document.documentType?.structuredData && document.activeTabId === 2">
                <ct-file-display [data]="displayDocumentData"
                                 [containerHeight]="'500px'"
                                 [objectHeight]="'100%'"></ct-file-display>
            </div>
        </div>
    </div>
</ng-container>
