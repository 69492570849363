import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {DataSetCodification} from '../../shared/data-set-codification.model';
import {DataSetElement} from '../../shared/data-set-element.model';
import {DataSetElementService} from '../../shared/data-set-element.service';
import {Subscription} from 'rxjs';
import {CodificationLabelsModel} from '../../shared/codification-labels.model';

@Component({
    selector: 'ct-stay-detail-codification',
    templateUrl: './stay-detail-codification.component.html',
    styleUrls: ['./stay-detail-codification.component.scss']
})
export class StayDetailCodificationComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    @Input() dataSetElement: DataSetElement;
    @Input() dataSetElementParent: DataSetElement;
    @Input() dataSetCodification: DataSetCodification;
    @Input() predictiveDiagnostics: any[];
    @Input() diagnostics: any[];
    @Input() isNearAutomationQualityControl: boolean;
    @Input() SSRWeeks: any[];
    @Input() selectedSSRWeek: any;
    @Input() limitCodificationUpdateToDA: boolean;
    @Input() isTypeRehabilitation: boolean;
    @Input() isTypeExternal: boolean;
    @Input() canDisplayRUM: boolean;
    @Input() codificationLabels: CodificationLabelsModel;

    public canDisplayStayAct: boolean;

    constructor(
        public $state: StateService,
        private _dataSetElementService: DataSetElementService,
    ) {
    }

    ngOnInit() {
        this._initConfig();
    }

    private _initConfig() {
        this.canDisplayStayAct = !this._dataSetElementService.isType(this.dataSetElement, 'external');
    }

    ngOnDestroy() {
        if (!this._subscriptions?.length) {
            return;
        }

        this._subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }
}
