import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationRoleApiService {
    private _BASE_URL = 'authorization/roles';

    constructor(private _http: HttpService) {}

    getAll(urlRequestParams?: any) {
        return this._http
            .get(this._BASE_URL, {params: urlRequestParams})
            .pipe(map(obj => obj.data));
    }

    get(roleId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${roleId}`, {params: urlRequestParams});
    }

    getAllUserRoles(userId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/own`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    addUserRole(userId: number, body, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/assign`, {'userId': userId, ...body}, {params: urlRequestParams})
            .pipe(map(res => res.roles));
    }

    deleteUserRole(userId: number, roleId: number) {
        return this._http
            .delete(`${this._BASE_URL}/revoke`, {'userId': userId, 'roleId': roleId});
    }
}
