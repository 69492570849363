import {Component, Input, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../configuration/configuration.service';

@Component({
    selector: 'ct-search-engine-codification-type',
    templateUrl: './search-engine-condition-codification-type.component.html',
    styleUrls: ['./search-engine-condition-codification-type.component.scss']
})
export class SearchEngineConditionCodificationTypeComponent implements OnInit {
    @Input() condition: any;

    status: string;
    codificationTypes = [
        {
            value: '1',
            text: 'STAY.AI_CODED'
        },
        {
            value: '0',
            text: 'STAY.CODED_WITHOUT_AI'
        }
    ];

    constructor(private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this._initStatus();
        this._handleCanDisplayQualityControlNearAutomation();
    }

    private _handleCanDisplayQualityControlNearAutomation() {
        const canDisplayQualityControlNearAutomation = this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlNearAutomationModule');
        if (canDisplayQualityControlNearAutomation) {
            const elementToAdd = {
                value: '2',
                text: 'STAY.NEAR_AUTOMATION_CODED'
            };
            this.codificationTypes.splice(1, 0, elementToAdd);
        }
    }

    private _initStatus() {
        if (this.condition.service.params.aiCodedStatus &&
            this.condition.service.params.aiCodedStatus.length > 0) {
            const hasMatches = this.condition.service.params.aiCodedStatus.some(status => ['0', '1', '2'].includes(status));
            this.status = hasMatches ? '012' : '-1';
        } else {
            this.status = '012';
        }
    }

    changeStatus() {
        this.condition.service.params.aiCodedStatus = ['-1'];
    }
}
