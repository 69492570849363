import {FormGroup, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

export class DateValidator {
    public static outRangeDateValidator(formControlStartDateKey: string,
                                        formControlEndDateKey: string): ValidatorFn {
        return (formGroup: FormGroup) => {
            if (formGroup.controls[formControlStartDateKey].value &&
                formGroup.controls[formControlEndDateKey].value) {
                const startDateMoment = moment(formGroup.controls[formControlStartDateKey].value);
                const endDateMoment = moment(formGroup.controls[formControlEndDateKey].value);
                return (!startDateMoment.isSame(endDateMoment, 'day') &&
                    (startDateMoment.isAfter(endDateMoment, 'day') || endDateMoment.isBefore(startDateMoment, 'day'))) ? {outRangeDate: true} : null;
            }
            return null;
        };
    }
}
