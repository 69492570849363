<div class="sub-menu-container">
    <mat-list-item disabled
                   id="itemGroupName"
                   *ngIf="isMainMenuCollapsed">
        <b>{{itemGroupName}}</b>
    </mat-list-item>
    <ng-container *ngFor="let item of orderMenuItems()">
        <span [ngClass]="{'active': item.isActive}"
           *ngIf="item.canDisplay"
           (click)="onItemClick(item)">
            <mat-list-item>
                <span matBadgeColor="primary"
                      matBadgeSize="medium"
                      matBadgeOverlap="false"
                      [matBadgeHidden]="!canDisplayBadge(item)"
                      [matBadge]="item.badgeValue">{{item.name}}</span>
            </mat-list-item>
        </span>
    </ng-container>
</div>
