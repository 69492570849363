import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-ghs-gmt',
    templateUrl: './search-engine-condition-ghs-gmt.component.html'
})
export class SearchEngineConditionGhsGmtComponent implements OnInit {
    condition: any;
    useCase: string;
    placeHolder: string;
    paramKey: string;

    constructor() { }

    ngOnInit() {
        this.placeHolder = this.useCase === 'ghs' ? 'ACRONYM.GHS' : 'ACRONYM.GMT';
        this.paramKey = this.useCase;
    }

}
