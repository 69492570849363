<ng-container *ngIf="tabs.length > 0">
    <div *ngIf="!horizontalScrolling">
        <ng-container *ngFor="let tab of tabs">
            <div class="tab-item"
                 matBadgeSize="medium"
                 [matBadge]="tab.badgeValue"
                 [matBadgeHidden]="!tab.canDisplayBadge || tab.badgeValue === -1"
                 [ngClass]="{'active': activeTabId === tab.id, 'huge-padding': hugePadding}"
                 (click)="switchTab(tab.id, $event)">
                {{tab.label}}
            </div>
        </ng-container>
    </div>

    <div class="horizontal-scrolling"
         *ngIf="horizontalScrolling">
        <mat-icon  class="swipe-button"
                   *ngIf="tabs.length >= numberOfTabs"
                   (click)="swipeLeft($event)">keyboard_arrow_left</mat-icon>

        <div id="container-tabs"
             class="center-tabs">
            <div class="tab"
                 [ngStyle]="{'width': !isFilterSearchMetric ? getTabWidth() : 'auto'}"
                 *ngFor="let tab of tabs"
                 >
                <div class="tab-item label metric"
                     placement="top"
                     [ngbTooltip]="tab.tooltipTemplate"
                     [class]="getTabItemColorClass()"
                     [ngClass]="{'active': activeTabId === tab.id, 'huge-padding': hugePadding}"
                     (click)="switchTab(tab.id, $event)">
                    <span>{{tab.label | slice: 0:14}}{{tab.label.length > 14 ? '...' : ''}}</span>
                </div>
            </div>
        </div>

        <mat-icon class="swipe-button"
                  *ngIf="tabs.length >= numberOfTabs"
                  (click)="swipeRight($event)">keyboard_arrow_right</mat-icon>
    </div>

    <mat-divider></mat-divider>
    <ng-content></ng-content>
</ng-container>
