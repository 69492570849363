<div class="add-act-dialog-content">
    <h1 mat-dialog-title>{{'DATA_SET.CODIFICATION_ELEMENT.ACT.ADD_DIALOG.TITLE' |translate}}</h1>

    <button mat-raised-button
            color="primary"
            class="add-button"
            (click)="openAddDiagnosticDialog()">{{'DATA_SET.CODIFICATION_ELEMENT.DIAGNOSTIC.ADD_DIAGNOSTIC' | translate | uppercase}}</button>

    <mat-dialog-content>
        <ct-stay-detail-codification-act class="display-block margin-bottom-20"
                                         [dataSetElement]="dataSetElement"
                                         [autoCompletePanelWidth]="'50%'"
                                         [validateButton]="false"
                                         [isSSRUseCase]="isSSRUseCase"
                                         [triggerValidation]="launchValidation"
                                         (validationDone)="onValidationDone()"></ct-stay-detail-codification-act>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button
                mat-dialog-close
                type="button"
                class="color-blue-on-white">{{'BUTTON.CANCEL' | translate | uppercase}}
        </button>

        <button mat-raised-button
                type="submit"
                color="primary"
                (click)="validate()">{{'BUTTON.VALIDATE' | translate | uppercase}}
        </button>
    </mat-dialog-actions>
</div>
