<mat-form-field class="width-125">
    <mat-select #multipleSelect
                multiple
                [panelClass]="'condition-select'"
                [placeholder]="'DATA_SET.CODIFICATION.SEARCH.DOCUMENT_TYPES' | translate"
                [(ngModel)]="condition.service.params[paramKey]"
                [compareWith]="compareWithCallback"
                [disabled]="documentTypes?.length === 0">
        <mat-checkbox [color]="'primary'"
                      [indeterminate]="isIndeterminate()"
                      [checked]="isChecked()"
                      (click)="toggleAll($event)">
            <span *ngIf="!isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.CHECK_ALL' | translate}} </span>
            <span *ngIf="isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.UNCHECK_ALL' | translate}} </span>
        </mat-checkbox>

        <ng-container *ngFor="let documentType of documentTypes; index as i">
            <mat-option [value]="documentType.value"
                        [matTooltip]="getTooltip(documentType.isInferredDocumentType)">
                <span *ngIf="!documentType.isInferredDocumentType">{{documentType.text}}</span>
                <span class="badge rounded-pill bg-secondary"
                      *ngIf="documentType.isInferredDocumentType">{{documentType.text}}</span>
            </mat-option>
            <mat-divider *ngIf="isLastInferredDocumentType(i)"></mat-divider>
        </ng-container>
    </mat-select>
    <mat-progress-bar *ngIf="isLoading"
                      mode="indeterminate"
                      class="autocomplete-progress-bar"></mat-progress-bar>
</mat-form-field>
