<ng-container *ngIf="healthGhm?.slug; else noGhm">
    <b id="ghmSlug"
       class="tag active"
       ctCustomHtmlAttribute
       [ngClass]="[getDiagnosticLabelClass()]"
       [ngbTooltip]="healthGhm[healthGhmDescriptionKey] || ('TOOLTIP.NO_DESCRIPTION' | translate)"
       tooltipClass="ngb-tooltip"
       [placement]="tooltipPlacement"
       [htmlAttributeName]="htmlAttributeName"
       [htmlAttributeValue]="htmlAttributeValue">
        {{healthGhm.slug}}</b>

    <sup class="ghm-sup"
         *ngIf="canDisplaySupIcon">
        <mat-icon class="ghm-tooltip-icon"
                  [ngbTooltip]="healthGhm[healthGhmDescriptionKey] || ('TOOLTIP.NO_DESCRIPTION' | translate)"
                  tooltipClass="ngb-tooltip"
                  placement="right">info</mat-icon>
    </sup>
</ng-container>

<ng-template #noGhm>
    <span class="no-ghm-empty-field"
          ctCustomHtmlAttribute
          *ngIf="!canDisplayNoDataText"
          [htmlAttributeName]="htmlAttributeName"
          [htmlAttributeValue]="htmlAttributeValue"></span>
</ng-template>

<span id="noDataText"
      class="no-data"
      *ngIf="canDisplayNoDataText && !healthGhm?.slug">{{'ERROR.NO_FIELD' | translate}}</span>
