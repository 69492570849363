import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DocumentTypeValue} from '../../../../../shared/components/document-type-search/document-type-search.component';

@Component({
  selector: 'ct-search-engine-condition-document-type-select',
  templateUrl: './search-engine-condition-document-type-select.component.html',
  styleUrls: ['./search-engine-condition-document-type-select.component.scss']
})
export class SearchEngineConditionDocumentTypeSelectComponent implements OnInit, AfterViewInit {
    @ViewChild('multipleSelect', { static: true }) matSelect;

    @Input() condition: any;
    @Input() documentTypes: any[] = [];
    @Input() paramKey: string;
    @Input() toggleAllMapCallback: (any) => DocumentTypeValue;
    @Input() isLoading: boolean;

    constructor(private _translateService: TranslateService) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // To fix jumping scroll when select an option
        (<any> this.matSelect)._getItemHeight = () => this.matSelect._triggerFontSize * 1.85;
    }

    isIndeterminate(): boolean {
        return this.condition.service.params[this.paramKey]?.length !== 0 &&
            this.condition.service.params[this.paramKey]?.length !== this.documentTypes?.length;
    }

    isChecked(): boolean {
        return this.condition.service.params[this.paramKey]?.length === this.documentTypes?.length;
    }

    toggleAll(event: any): void {
        // To prevent the checkbox to be checked because when we update the ids
        // the isChecked method updates the checkbox checked attribute
        if (event) {
            event.preventDefault();
        }
        if (this.condition.service.params[this.paramKey]?.length === this.documentTypes?.length) {
            this.condition.service.params[this.paramKey] = [];
        } else {
            this.condition.service.params[this.paramKey] = this.documentTypes
                ?.slice(0)
                .map(element => this.toggleAllMapCallback(element));
        }
    }

    compareWithCallback(objectFromOption: any, objectFromParam: any): boolean {
        return objectFromOption?.documentTypeId === objectFromParam?.documentTypeId &&
            objectFromOption?.source === objectFromParam?.source;
    }

    isLastInferredDocumentType(index: number): boolean {
        return !!(this.documentTypes && this.documentTypes[index] &&
            this.documentTypes[index].isInferredDocumentType &&
            this.documentTypes[index + 1] &&
            !this.documentTypes[index + 1].isInferredDocumentType);
    }

    getTooltip(isInferredDocumentType: boolean): string {
        return isInferredDocumentType ? this._translateService.instant('TOOLTIP.INFERRED_DOCUMENT_TYPE') : '';
    }
}
