import { StayListService } from './../../../../../modules/stay/list/shared/stay-list.service';
import { UserService } from './../../../../../modules/user/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from './../../../../../modules/user/user.model';
import { Component, Inject, OnInit } from '@angular/core';
import {SnackBarService} from '../../../../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {BroadcastService} from '../../../../../core/services/broadcast.service';

@Component({
    selector: 'ct-stay-list-element-action-add-dialog-users',
    templateUrl: './stay-list-element-action-add-dialog-users.component.html',
    styleUrls : ['./stay-list-element-action-add-dialog-users.component.scss']
})

export class StayListElementActionAddDialogUsersComponent implements OnInit {
    isLoading: boolean;
    user?: User;
    usersDb: User[];

    constructor(
        public dialogRef: MatDialogRef<StayListElementActionAddDialogUsersComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public dialog: MatDialog,
        private _userService: UserService,
        private _stayListService: StayListService,
        private _snackBarService: SnackBarService,
        private _translateService: TranslateService,
        private _broadcastService: BroadcastService) {}

    ngOnInit() {

        this.isLoading = true;
        this.user = this.data.user;

        Promise.all([
            this._loadUsers()
        ]).then(() => {
            this.isLoading = false;
        });
    }

    private async _loadUsers() {
        this.usersDb = await this._userService.loadUsers();
        if (this.user) {
            if (this.user.fullName) {
                const splitName = this.user.fullName.split(' ');
                this.user.firstName = splitName[0];
                this.user.lastName = splitName[1];
            }
            const objWithIdIndex = this.usersDb.findIndex((obj) => obj.id === this.user.id);
            if (objWithIdIndex > -1) {
                this.usersDb.splice(objWithIdIndex, 1);
            }
        }
    }

    async assignUser(userDbId: number) {
        try {
            let res;
            const isMultiple = !!this.data.isMultiple;
            if (!isMultiple) {
                if (!this.user) {
                    res = await this._stayListService.addStayUser(
                        this.data.dataSetContentId,
                        userDbId
                    );
                } else {
                    res = await this._stayListService.updateStayUser(
                        this.data.dataSetContentId,
                        userDbId
                    );
                }
            } else {
                const {currentDataSetId, currentCodificationId, params, useCase } = this.data;
                const resp = await this._stayListService.attachRecordUser(currentDataSetId, userDbId, currentCodificationId, params, useCase);

                if (resp) {
                    this._broadcastService.send('stayList::reload::afterAssignUser');
                }
            }

            this.dialogRef.close({user: res?.user});
        } catch (e) {
            alert(e);
        }
    }

    async removeUser(userDbId: number) {
        try {
            const isMultiple = !!this.data.isMultiple;
            if (!isMultiple) {
                const res = await this._stayListService.removeStayUser(
                    this.data.dataSetContentId,
                    userDbId
                );
            } else {
                const { currentDataSetId, currentCodificationId, params, useCase } = this.data;
                const resp = await this._stayListService.detachRecordUser(currentDataSetId, userDbId, currentCodificationId, params, useCase);
                if (resp) {
                    this._broadcastService.send('stayList::reload::afterAssignUser');
                }
            }

            this.dialogRef.close({user: null});
        } catch (e) {
            alert(e);
        }
    }
}
