import {Component, OnInit} from '@angular/core';
import {SearchEngineConditionHospitalizationTypeService} from './search-engine-condition-hospitalization-type.service';

@Component({
    selector: 'ct-search-engine-condition-hospitalization-type',
    templateUrl: './search-engine-condition-hospitalization-type.component.html'
})
export class SearchEngineConditionHospitalizationTypeComponent implements OnInit {
    condition: any;
    data: { id: number, text: string }[] = SearchEngineConditionHospitalizationTypeService.data;


    constructor() {
    }

    ngOnInit(): void {
    }

}
