import {Injectable} from '@angular/core';
import {HttpService} from '../../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class MachineLearningScopeApiService {
    private _BASE_URL = 'machine-learning/scope';

    constructor(private _http: HttpService) {}

    computeScope(body: any, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/compute`, body, urlRequestParams);
    }

    configureScope(body: any, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/configure`, body, urlRequestParams);
    }

    updateDiagnosticGoldThreshold(diagnosticId: number,  body: any, urlRequestParams?: any) {
        return this._http
            .post(`diagnostic/${diagnosticId}/score-threshold/custom`, body, urlRequestParams);
    }
}
