export interface DataSetElement {
    children?: any[];
    diagnostic?: any[];
    codificationDataSetElementStatus?: any;
    daScore?: number;
    dataSetContent?: any;
    dataSetContentHealthStay?: any;
    dataSetContentId?: number;
    dataSetContentType?: string;
    documents?: any[];
    dataSetElementDiagnostic?: any[];
    dataSetElementDiagnosticPredictive?: any[];
    dataSetElementActPredictive?: any[];
    datasetelementScore?: any;
    excludingDiagnostics?: any[];
    filtersSearch?: any[];
    hasGold?: boolean;
    hasSilver?: boolean;
    highlight?: any;
    id?: number;
    parent?: any;
    parentId?: number;
    isCodified?: boolean;
    isReady?: boolean;
    totalDocument?: number;
    totalEssentialDocument?: number;
    primaryDiagnosticRecallIndex?: number;
    r1?: any;
    hasDocumentOverlapRisk?: boolean;
    agree?: boolean;
    user?: any;
}

export enum StatusName {
    UNCHECKED = 0,
    PRICING_IMPACT = 1,
    VERIFIED = 2,
    WAITING = 3,
    PRIMO_CODAGE = 4,
    NO_PRICING_IMPACT = 5,
}
