<button class="collapse-button"
        mat-icon-button
        [matTooltip]="(leftSideDisplayState === 'initial' ? 'TOOLTIP.DISPLAY_DOC_FULL_SCREEN' : 'TOOLTIP.EXIT_DOC_FULL_SCREEN') | translate"
        (click)="buttonClicked.emit(true)">
    <ng-container *ngIf="leftSideDisplayState==='none'">
        <mat-icon>chevron_right</mat-icon>
        <mat-icon class="second-icon-chevron">chevron_right</mat-icon>
    </ng-container>

    <ng-container *ngIf="leftSideDisplayState === 'initial'">
        <mat-icon>chevron_left</mat-icon>
        <mat-icon class="second-icon-chevron">chevron_left</mat-icon>
    </ng-container>
</button>
