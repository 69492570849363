import {Injectable} from '@angular/core';
import {EstablishmentApiService} from '../api-services/establishment/establishment.api.service';

@Injectable({
    providedIn: 'root'
})
export class EstablishmentService {
    constructor(private _establishmentApiService: EstablishmentApiService) {}

    loadAllEstablishments() {
        return this._establishmentApiService
            .getAll()
            .toPromise();
    }

    async addUserEstblishments(userId: number, newRoleIds: number[]) {
        return this._establishmentApiService.addUserEstblishments(userId, newRoleIds).toPromise();
    }

    async deleteUserEstblishments(userId: number, newRoleIds: number[]) {
        return this._establishmentApiService.deleteUserEstblishments(userId, newRoleIds).toPromise();

    }
}
