import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {StructuredDataApiService} from '../../../../core/api-services/structured-data/structured-data.api.service';

@Injectable({
    providedIn: 'root'
})
export class StructuredDataService {
    constructor(private _structuredDataApiService: StructuredDataApiService) {}

    /**
     * For autocomplete
     */
    searchValue$(query: any) {
        const params = {
            limit: 50,
            columnId: query.columnId,
            value: query.value
        };
        return this._structuredDataApiService
            .searchValue(params)
            .pipe(map(res => res.map(el => el.data)));
    }
}
