<!-- For SMR -->
<ng-container *ngIf="isTypeRehabilitation">
    <ct-tab-list
        class="tab-list align-center-center border-bottom"
        [(activeTabId)]="activeTabId"
        [hugePadding]="true"
        [broadcastMessage]="'stayDetailCodification::goToSSRWeek'"
    >
        <ct-tab-list-item
            [label]="'DATA_SET.CODIFICATION_ELEMENT.SRR.FIRST_WEEK' | translate"
            [id]="1"
        >
        </ct-tab-list-item>

        <!-- we need to count the first week (admission), otherwise we dont display all weeks if there is only 2 weeks-->
        <ct-tab-list-item *ngIf="SSRWeeks?.length"
          [label]="'DATA_SET.CODIFICATION_ELEMENT.SRR.NEXT_WEEKS' | translate"
          [id]="2">

        </ct-tab-list-item>
    </ct-tab-list>
</ng-container>

<div
    *ngIf="activeTabId === 2"
    id="week-selector"
    class="align-center-center bg-light"
>
    <div class="display-inline-block">
        <mat-icon
            class="cursor-pointer margin-right-5"
            *ngIf="!selectedSSRWeek?.isFirstChild"
            (click)="goToPreviousOrNextSRRWeek('previous')"
        >
            chevron_left
        </mat-icon>

        <mat-form-field class="display-inline-block margin-top-16 width-190">
            <mat-select
                [placeholder]="'DATA_SET.CODIFICATION_ELEMENT.SRR.WEEK_SELECTION' | translate"
                [(ngModel)]="selectedSSRWeek"
                (ngModelChange)="goToDataSetElement(selectedSSRWeek.value)"
            >
                <mat-option
                    *ngFor="let el of SSRWeeks"
                    [value]="el"
                >
                    {{el.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon
            class="cursor-pointer margin-left-5"
            *ngIf="!selectedSSRWeek?.isLastChild"
            (click)="goToPreviousOrNextSRRWeek('next')"
        >
            chevron_right
        </mat-icon>
    </div>

    <button
        mat-icon-button
        class="duplicate-codification-button"
        [matTooltip]="'TOOLTIP.DUPLICATE_FIRST_SSR_WEEK_CODIFICATION' | translate"
    >
        <mat-icon (click)="duplicateFirstSSRWeekCodification()">
            content_copy
        </mat-icon>
    </button>
</div>

<!-- For MCO -->
<ng-container *ngIf="!isTypeRehabilitation">
    <ct-tab-list
        class="tab-list"
        [(activeTabId)]="activeTabId"
        [horizontalScrolling]="true"
        [hugePadding]="true"
        [broadcastMessage]="'stayDetailCodification::goToDataSetElement'"
    >
        <ng-container *ngFor="let child of dataSetElementParent.children; index as i">

            <!-- Tabs -->
            <ct-tab-list-item
                [id]="child.id"
                [label]="getMedicalUnitLabel(child) ?? String(i + 1)"
                [tooltipTemplate]="tabTooltip"
            ></ct-tab-list-item>

            <!-- Tooltips -->
            <ng-template #tabTooltip>
                <div
                    class="display-inline-block"
                    *ngIf="!isTypeExternal && canDisplayRUM && !rumIdIncludesTmp(child.dataSetContent.rumId)"
                >
                    <span>{{'DATA_SET.CODIFICATION_ELEMENT.RUM_NUMBER' | translate}}</span>
                    <span>{{child.dataSetContent.rumId}}</span>
                </div>

                <ct-field-display
                    class="display-block"
                    [isKeyBold]="true"
                    [key]="'STUFF.FROM' | translate"
                    [value]="getDateToDisplay(child.dataSetContent.startDate)"
                ></ct-field-display>

                <ct-field-display
                    class="display-block"
                    [isKeyBold]="true"
                    [key]="'STUFF.TO' | translate"
                    [value]="getDateToDisplay(child.dataSetContent.endDate)"
                ></ct-field-display>

                <!-- Number of days -->
                <div
                    class="font-weight-700"
                    *ngIf="child.dataSetContent.nbDay !== null"
                >
                    <span>&nbsp;{{child.dataSetContent.nbDay || 0}} </span>
                    <div
                        class="display-inline-block"
                        [ngPlural]="child.dataSetContent.nbDay"
                    >
                        <ng-template ngPluralCase="0">
                            {{'STUFF.NIGHT_LOWERCASE' | translate}}
                        </ng-template>

                        <ng-template ngPluralCase="one">
                            {{'STUFF.NIGHT_LOWERCASE' | translate}}
                        </ng-template>

                        <ng-template ngPluralCase="other">
                            {{'STUFF.NIGHTS_LOWERCASE' | translate}}
                        </ng-template>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </ct-tab-list>
</ng-container>
