import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailSeverityComponent} from './stay-detail-severity.component';

@NgModule({
    declarations: [
        StayDetailSeverityComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    exports: [
        StayDetailSeverityComponent
    ],
})
export class StayDetailSeverityModule {}
