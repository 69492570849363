<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="add-dialog-content">
    <h1 mat-dialog-title>{{'DATA_SET.CODIFICATION.ASSIGN_STAY_USER' | translate}}</h1>

    <form>
        <mat-dialog-content>
            <div class="row pt-1 align-center-center" *ngIf="user">
                <div class="col-md-6">{{user.firstName}} {{user.lastName}}</div>
                <div class="col-md-6">
                    <button mat-raised-button
                        class="me-3"
                        color="primary"
                        type="button"
                        disabled>
                    {{'BUTTON.ADD' | translate| uppercase}}
                    </button>

                    <button mat-button
                        mat-dialog-close
                        type="button"
                        (click)="removeUser(user.id)"
                        class="color-blue-on-white">
                        {{'BUTTON.DELETE' | translate | uppercase}}
                    </button>
                </div>
            </div>
         <div class="row pt-1" *ngFor="let userDb of usersDb">
            <div class="col-md-6">{{userDb.firstName}} {{userDb.lastName}}</div>
            <div class="col-md-6">
                <button mat-raised-button
                    class="me-3"
                    color="primary"
                    type="button"
                    (click)="assignUser(userDb.id)"
                    [disabled]="user && user.id === userDb.id">
                {{'BUTTON.ADD' | translate| uppercase}}
                </button>

                <button mat-button
                    mat-dialog-close
                    type="button"
                    (click)="removeUser(userDb.id)"
                    class="color-blue-on-white">
                    {{'BUTTON.DELETE' | translate | uppercase}}
                </button>
                </div>
            </div>
        </mat-dialog-content>
    </form>
</div>
