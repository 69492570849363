import {animate, state, style, transition, trigger} from '@angular/animations';

export const displayAnimations = [
    trigger('displayProperty', [
        state('initial', style({display: 'initial'})),
        state('none', style({display: 'none'})),
        transition('initial => none', animate('100ms ease')),
        transition('none => initial', animate('100ms ease'))
    ])
];
