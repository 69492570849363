import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StayDetailInfoAdditionalComponent} from './stay-detail-info-additional.component';
import {FieldDisplayModule} from '../../../../../shared/components/field-display/field-display.module';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        StayDetailInfoAdditionalComponent
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule.forChild(),
        FieldDisplayModule,
        MatTooltipModule,
        MatDividerModule
    ],
    exports: [
        StayDetailInfoAdditionalComponent
    ]
})
export class StayDetailInfoAdditionalModule {}
