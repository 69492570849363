<div>
    <button mat-raised-button
            type="button"
            color="primary"
            (click)="fileInput.click()">{{'BUTTON.UPLOAD_FILE' | translate | uppercase}}</button>

    <ng-container *ngIf="file && file.name">
        <span class="margin-left-10 file-name" >{{file.name}}</span>
        <mat-icon class="remove-file-icon"
                  (click)="removeFile()">cancel</mat-icon>
    </ng-container>
</div>

<input hidden
       #fileInput
       type="file"
       (change)="getFileToUpload($event)">
