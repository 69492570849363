<ng-container >
    <div class="margin-top-10">
        <table class="table p-2">
            <tbody>
            <tr *ngFor="let dependency of dependencies" >
                <td class="border-0">
                    <b>{{ dependency.key | translate }}:</b>
                    <br>
                    {{ dependency.text }}
                </td>
            </tr>
            </tbody>
        </table>

        <div *ngIf="!dependencies?.length" class="align-center-center">
            <span class="text-disabled">{{'ERROR.NO_DATA' | translate}}</span>
        </div>
    </div>
</ng-container>
