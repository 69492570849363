<ng-container *ngIf="canDisplayMenu()">
    <button mat-raised-button
            color="primary"
            class="menu-button"
            [matMenuTriggerFor]="orderMenu">
        {{'BUTTON.ORDER' | translate}}
        <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #orderMenu="matMenu"
              [class]="'order-predictive-diagnostics-menu'">
        <ng-template matMenuContent>
            <button mat-menu-item
                    *ngIf="options.display.active"
                    (click)="switchDisplay()">
                <mat-icon>view_list</mat-icon>
                <span *ngIf="!options.display.value">{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.DISPLAY_PROPOSALS' | translate}}</span>
                <span *ngIf="options.display.value">{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.HIDE_PROPOSALS' | translate}}</span>
            </button>

            <ng-container *ngIf="options.display.value">
                <mat-divider *ngIf="options.display.active && options.groupByParent.active"></mat-divider>

                <button mat-menu-item
                        *ngIf="options.groupByParent.active"
                        (click)="switchGroupByParent()">
                    <mat-icon>view_list</mat-icon>
                    <span *ngIf="!options.groupByParent.value">{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.GROUP_BY_PARENT' | translate}}</span>
                    <span *ngIf="options.groupByParent.value">{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.GROUP_BY_CHILD' | translate}}</span>
                </button>

                <mat-divider *ngIf="options.groupByParent.active && options.orderBy.active"></mat-divider>

                <ng-container *ngIf="options.orderBy.active">
                    <button mat-menu-item
                            [ngClass]="{'active text-color md-blue md-icon-blue': isOrderByItemActive(['-score'])}"
                            (click)="switchOrderBy(['score'])">
                        <mat-icon [ngClass]="{'active': isOrderByItemActive(['-score'])}">sort</mat-icon>
                        <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.ORDER_BY_CONFIDENCE' | translate}}</span>
                    </button>

                    <button mat-menu-item
                            [ngClass]="{'active text-color md-blue md-icon-blue': isOrderByItemActive(['diagnostic.slug'])}"
                            (click)="switchOrderBy(['slug'])">
                        <mat-icon [ngClass]="{'active': isOrderByItemActive(['diagnostic.slug'])}">sort</mat-icon>
                        <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.ORDER_BY_CODE' | translate}}</span>
                    </button>

                    <button mat-menu-item
                            *ngIf="isGroupingActivated && codificationLabelIds.includes(1)"
                            [ngClass]="{'active text-color md-blue md-icon-blue': isOrderByItemActive(['healthGhsGhm.healthGhmSlug'])}"
                            (click)="switchOrderBy(['ghm'])">
                        <mat-icon [ngClass]="{'active': isOrderByItemActive(['healthGhsGhm.healthGhmSlug'])}">sort</mat-icon>
                        <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.ORDER_BY_GHM' | translate}}</span>
                    </button>

                    <ng-container *ngIf="isDiagnosticSeverityLevelActivated && codificationLabelIds.includes(3)">
                        <button mat-menu-item
                                [ngClass]="{'active text-color md-blue md-icon-blue': isOrderByItemActive(['-diagnostic.diagnosticLevel.level', 'diagnostic.slug'])}"
                                (click)="switchOrderBy(['level', 'slug'])">
                            <mat-icon [ngClass]="{'active': isOrderByItemActive(['-diagnostic.diagnosticLevel.level', 'diagnostic.slug'])}">sort</mat-icon>
                            <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.ORDER_BY_SEVERITY_CODE' | translate}}</span>
                        </button>

                        <button mat-menu-item
                                [ngClass]="{'active text-color md-blue md-icon-blue': isOrderByItemActive(['-diagnostic.diagnosticLevel.level', '-score'])}"
                                (click)="switchOrderBy(['level', 'score'])">
                            <mat-icon [ngClass]="{'active': isOrderByItemActive(['-diagnostic.diagnosticLevel.level', '-score'])}">sort</mat-icon>
                            <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.ORDER_BY_SEVERITY_CONFIDENCE' | translate}}</span>
                        </button>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>
    </mat-menu>
</ng-container>
