import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-authentication',
    templateUrl: './authentication.component.html'
})
export class AuthenticationComponent implements OnInit {
    constructor() { }

    ngOnInit() {
    }
}
