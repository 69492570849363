import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {StayDetailDocumentListService} from '../../../../modules/stay/detail/document/list/stay-detail-document-list.service';
import {FileSrcService} from '../../../../core/services/file-src.service';
import * as _ from 'lodash-es';
import {StayDetailDocumentElementService} from '../../../../modules/stay/detail/document/element/stay-detail-document-element.service';

@Component({
    selector: 'ct-document-type-document-list-display-dialog',
    templateUrl: './document-type-document-list-display-dialog.component.html',
    styleUrls: ['./document-type-document-list-display-dialog.component.scss']
})
export class DocumentTypeDocumentListDisplayDialogComponent implements OnInit, OnDestroy {
    private _selectedDocument: any;
    private _inferredDocumentTypeName: string;
    private _documentTypeName: string;
    public  healthDocument: any;

    documents: { stayDocuments: any; patientDocuments: any };
    displayDocumentData: any = {src: '', filename: ''};

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _broadcastService: BroadcastService,
                private _stayDetailDocumentListService: StayDetailDocumentListService,
                private _fileSrcService: FileSrcService,
                private _stayDetailDocumentElementService: StayDetailDocumentElementService) {
    }

    ngOnInit(): void {
        this.documents = this.data.documents;
        this._inferredDocumentTypeName = this.data.inferredDocumentTypeName;
        this._documentTypeName = this.data.documentTypeName;
        this._openDocumentOnInit();
    }

    private _getDocumentToOpen(key: string): any {
        if (this.documents &&
            this.documents[key] &&
            this.documents[key][this._inferredDocumentTypeName]) {
            return this.documents[key][this._inferredDocumentTypeName]
                .find(el => el.documentType && el.documentType.name === this._documentTypeName);
        }
        return null;
    }

    private _openDocumentOnInit(): void {
        const documentToOpen = this._getDocumentToOpen('stayDocuments') || this._getDocumentToOpen('patientDocuments');
        if (documentToOpen) {
            if (documentToOpen?.structuredDataLines?.length > 0) {
                this.healthDocument = _.cloneDeep(documentToOpen.structuredDataLines);
            }
            documentToOpen.isOpened = true;
            this.openDocument(documentToOpen);
        }
    }

    private _getFileSrc(document: any): string {
        return this._fileSrcService.getDocumentSrc(document);
    }

     private async _setDisplayDocumentData(document: any): Promise<void> {
        this.healthDocument = null;
        if (document) {
            if (document?.structuredDataLines?.length > 0) {
                document.documentType.structuredData = true;
                this.healthDocument = await this._stayDetailDocumentElementService
                    .loadHealthDocumentLines(document, null);
                await this._stayDetailDocumentElementService._getStructuredDataColumns(this.healthDocument)
                    .then(columns => {
                        this.healthDocument.columns = columns || [];
                    });
            }
            this.displayDocumentData = {
                src: this._getFileSrc(document),
                filename: document.filename,
                healthDocument: this.healthDocument
            };
        }
    }

    openDocument(document: any): void {
        if (document) {
            this._selectedDocument = document;
            this._setDisplayDocumentData(document);
        }
    }

    private _setIsOpenedToFalse(): void {
        this._stayDetailDocumentListService.setIsOpenedProperty(this.documents, false);
    }

    ngOnDestroy(): void {
        this._setIsOpenedToFalse();
    }
}
