import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'ct-upload-file-button',
    templateUrl: './upload-file-button.component.html',
    styleUrls: ['./upload-file-button.component.scss']
})
export class UploadFileButtonComponent {
    @Output() selectedFile: EventEmitter<File> = new EventEmitter<File>();
    file: File;

    constructor() { }

    getFileToUpload(event) {
        if (event) {
            this.file = event.target.files[0];
            this.selectedFile.emit(this.file);
        }
    }

    removeFile() {
        this.file = null;
        this.selectedFile.emit(null);
    }
}
