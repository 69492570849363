<div class="number-container">
    <div *ngIf="condition.service.params.unit">
        <mat-radio-group
            class="unit-block"
            aria-label="Select an option"
            ngDefaultControl
            [(ngModel)]="condition.service.params.unit"
        >
            <mat-radio-button *ngFor="let unit of ['year', 'day']"
                [value]="unit">{{ ('DATA_SET.CODIFICATION.SEARCH.' + unit | uppercase) | translate }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div>
        <mat-form-field>
            <input matInput
                   type="number"
                   step="any"
                   min="0"
                   [max]="condition.service.params[paramMaxKey]"
                   [(ngModel)]="condition.service.params[paramMinKey]"
                   [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MINIMUM' | translate">
        </mat-form-field>
        <span *ngIf="unit">{{unit}}</span>
    </div>

    <div>
        <mat-form-field>
            <input matInput
                   type="number"
                   step="any"
                   max="100000"
                   [min]="condition.service.params[paramMinKey]"
                   [(ngModel)]="condition.service.params[paramMaxKey]"
                   [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MAXIMUM' | translate">
        </mat-form-field>
        <span *ngIf="unit">{{unit}}</span>
    </div>
</div>


