import {Component, OnInit} from '@angular/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {StateService} from '@uirouter/angular';
import {CodificationLabelEnum} from "../../../stay/shared/codification-label-enum";

@Component({
    selector: 'ct-search-engine-valuation',
    templateUrl: './search-engine-condition-valuation.component.html',
    styleUrls: ['./search-engine-condition-valuation.component.scss']
})
export class SearchEngineConditionValuationComponent implements OnInit {
    private _DPCodificationLabel: string;
    private _MPCodificationLabel: string;
    private _AECodificationLabel: string;
    private _DACodificationLabel: string;
    private _sliderTicks: any;
    private _canDisplayRehabilitationStayModule: boolean;

    condition: any;
    codificationLabel: string;
    sliderValue: number;
    maxSliderValue: number;
    radioButtons: any[] = [];

    constructor(public $state: StateService,
                private _configurationService: ConfigurationService,
                private _translationHelperService: TranslationHelperService) { }

    ngOnInit(): void {
        this.codificationLabel = this.condition.service.params.revaluationCodificationLabel ? this.condition.service.params.revaluationCodificationLabel : '';
        this._initConfig();
        this._initLanguage();
        this._initRadioButtons();
        this._initSliderTicks();
        this._initSlider();
    }

    private _initConfig(): void {
        this._canDisplayRehabilitationStayModule = this._configurationService.appTypeIsMCOAndSSR() || this._configurationService.appTypeIs('SSR');
    }

    private _initLanguage(): void {
        this._DPCodificationLabel = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP) || 'DP';
        this._MPCodificationLabel = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.MP) || 'MP';
        this._AECodificationLabel = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.AE) || 'AE';
        this._DACodificationLabel = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DA) || 'DA';
    }

    private _initRadioButtons(): void {
        const elementsToAdd = this.$state.current.name !== 'stay-list-rehabilitation' ? [{value: 'DP', label: 'ACRONYM.DP'}] :
            [
                {value: 'MP', label: 'ACRONYM.MP'},
                {value: 'AE', label: 'ACRONYM.AE'}
            ];
        // We always have DA
        this.radioButtons = [...elementsToAdd, {value: 'DA', label: 'ACRONYM.DA'}];
    }

    private _initSliderTicks(): void {
        const ticksStringDA: string = this._configurationService.getConfigurationContent('front', `advancedSearch.valuation.confidenceSliderTicksDA`) || '';
        const ticksStringDP: string = this._configurationService.getConfigurationContent('front', `advancedSearch.valuation.confidenceSliderTicksDP`) || '';
        const ticksStringMP: string = this._configurationService.getConfigurationContent('front', `advancedSearch.valuation.confidenceSliderTicksMP`) || '';
        const ticksStringAE: string = this._configurationService.getConfigurationContent('front', `advancedSearch.valuation.confidenceSliderTicksAE`) || '';
        const ticksArrayDA: string[] = ticksStringDA.split(';');
        // We assume there is the same number of ticks between the codificationLabels
        this.maxSliderValue = ticksArrayDA.length || 1;
        this._sliderTicks = {
            DA: ticksArrayDA.map(el => +el),
            DP: ticksStringDP.split(';').map(el => +el),
            MP: ticksStringMP.split(';').map(el => +el),
            AE: ticksStringAE.split(';').map(el => +el),
        };
    }

    private _initSlider(): void {
        if (this.codificationLabel &&
            this._sliderTicks[this.codificationLabel] &&
            this.condition.service.params.revaluationScore) {
            const index = this._sliderTicks[this.codificationLabel].indexOf(this.condition.service.params.revaluationScore);
            this.sliderValue = index > -1 ? index + 1 : 1;
        } else {
            this.sliderValue = 1;
        }
    }

    updateValuationCodificationLabel(value: string): void {
        this.codificationLabel = value;
        switch (this.codificationLabel) {
            case 'DA':
                this.condition.service.params.revaluationCodificationLabel = this._DACodificationLabel;
                break;
            case 'DP':
                this.condition.service.params.revaluationCodificationLabel = this._DPCodificationLabel;
                break;
            case 'MP':
                this.condition.service.params.revaluationCodificationLabel = this._MPCodificationLabel;
                break;
            case 'AE':
                this.condition.service.params.revaluationCodificationLabel = this._AECodificationLabel;
                break;
            default:
                this.condition.service.params.revaluationCodificationLabel = '';
        }
        this.updateValuationScore();
    }

    updateValuationScore(): void {
        if (this.codificationLabel &&
            this._sliderTicks[this.codificationLabel]) {
            this.condition.service.params.revaluationScore = this._sliderTicks[this.codificationLabel][this.sliderValue - 1];
        }
    }
}
