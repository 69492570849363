import {NgModule} from '@angular/core';
import {SafeUrlPipe} from './safe-url.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import { TrucantePipe } from './trucante.pipe';

@NgModule({
    declarations: [
        SafeUrlPipe,
        SafeHtmlPipe,
        TrucantePipe
    ],
    exports: [
        SafeUrlPipe,
        SafeHtmlPipe,
        TrucantePipe
    ]
})
export class PipesModule {}
