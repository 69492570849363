<div class="display-inline-flex"
     [matTooltip]="tooltip"
     matTooltipPosition="below">
  <ng-container *ngIf="value <= 3">
      <mat-icon class="circle-icon">{{getCircle(1, value)}}</mat-icon>
      <mat-icon class="circle-icon">{{getCircle(2, value)}}</mat-icon>
      <mat-icon class="circle-icon">{{getCircle(3, value)}}</mat-icon>
  </ng-container>

  <!--Gold/silver-->
  <i class="fas fa-trophy text-color"
     *ngIf="canDisplaySimplifiedScore()"
     [ngClass]="{'md-yellow-700': canDisplaySimplifiedScore('gold'), 'md-grey-500': canDisplaySimplifiedScore('silver')}"></i>
</div>
