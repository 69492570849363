import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionIsBilledService extends SearchEngineCondition {
    private _useCase: string;

    get useCase(): string {
        return this._useCase;
    }

    set useCase(value: string) {
        this._useCase = value;
    }

    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService) {
        super(['isBilled'],
            _translateService.instant('STUFF.BILLING_STATE'),
            'isBilled',
            null,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        const defaultValues = this._getDefaultIsBilled();
        this.params = {isBilled: defaultValues.isBilledParam};
        this.uriParams = {isBilled: defaultValues.isBilledUriParam};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    private _getDefaultIsBilled(): { isBilledParam: string, isBilledUriParam: string } {
        const objToReturn = {isBilledParam: 'all', isBilledUriParam: '-1'};
        switch (this.useCase) {
            case 'stayList':
                objToReturn.isBilledParam = this._configurationService.getConfigurationContent('front', 'advancedSearch.isBilled.stayListDefaultValue');
                objToReturn.isBilledUriParam = this._getIsBilledUriParam(objToReturn.isBilledParam);
                break;
            case 'qualityControl':
                objToReturn.isBilledParam = this._configurationService.getConfigurationContent('front', 'advancedSearch.isBilled.qualityControlDefaultValue');
                objToReturn.isBilledUriParam = this._getIsBilledUriParam(objToReturn.isBilledParam);
                break;
            default:
        }
        return objToReturn;
    }

    private _getIsBilledUriParam(isBilledParam: string): string {
        switch (isBilledParam) {
            case 'billed':
                return '1';
            case 'notBilled':
                return '0';
            default:
                return '-1';
        }
    }

    convertToUriParams(): void {
        this.uriParams.isBilled = this._getIsBilledUriParam(this.params.isBilled);
    }

    private _getIsBilledParam(isBilled: string): string {
        switch (isBilled) {
            case '1':
               return 'billed';
            case '0':
                return 'notBilled';
            default:
                return 'all';
        }
    }

    convertToParams(): void {
        this.params.isBilled = this._getIsBilledParam(this.uriParams.isBilled);
    }

    convertToParamsFromApiParams(): void {
        this.params.isBilled = this._getIsBilledParam(this.apiParams.args.value);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.isBilled !== 'all') {
            this.apiParams.args = {value: this._getIsBilledUriParam(this.params.isBilled)};
        }
    }
}
