<mat-form-field class="full-width">
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let selectedMedicalUnit of selectedMedicalUnits"
                  [matTooltip]="getTooltipText(selectedMedicalUnit)"
                  matTooltipPosition="above"
                  [value]="selectedMedicalUnit.code"
                  [removable]="true"
                  (removed)="removeChip(selectedMedicalUnit)">
            {{selectedMedicalUnit.code}}
            <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input #medicalUnitInput
               [placeholder]="'MEDICAL_UNIT.MEDICAL_UNITS' | translate"
               [formControl]="formControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList">
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="selectMedicalUnit($event)">
        <mat-option *ngFor="let searchedMedicalUnit of searchedMedicalUnits$ | async; index as i"
                    [value]="searchedMedicalUnit">
            {{(searchedMedicalUnit.code !== searchedMedicalUnit.name) ? (searchedMedicalUnit.code + ' - ') : ''}}
            {{searchedMedicalUnit.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
