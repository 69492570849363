import {Injectable} from '@angular/core';
import {HttpService} from '../../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PatientHealthDocumentApiService {
    private _BASE_URL = 'patient';

    constructor(private _http: HttpService) {}

    attach(patientId: number, healthDocumentId: number, body: any) {
        return this._http
            .post(`${this._BASE_URL}/${patientId}/health-document/${healthDocumentId}/attach`, body)
            .pipe(map(res => res.data));
    }

    detach(patientId: number, healthDocumentId: number, body: any) {
        return this._http
            .delete(`${this._BASE_URL}/${patientId}/health-document/${healthDocumentId}/detach`, body)
            .pipe(map(res => res.data));
    }
}
