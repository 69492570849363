import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarService} from '../services/snack-bar.service';
import * as moment from 'moment';
import {AppLogService} from '../app-log/app-log.service';
import {tap} from 'rxjs/operators';

export class ApiHttpInterceptor implements HttpInterceptor {

    constructor(private _translateService: TranslateService,
                private _snackBarService: SnackBarService,
                private _logService: AppLogService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const startOfRequest = moment();
        return next.handle(request).pipe(
            tap((response: HttpEvent<any>) => {
                if (response instanceof HttpResponse) {
                    const diff = moment().diff(startOfRequest, 'seconds');
                    if (diff >= 10) {
                        this._logService.logWarning(`${request.method} request: "${request.url}" took ${diff} seconds`);
                    }
                }
            }, (err: any) => {
                const hideErrorParam: any = request.params.get('hideError');
                const canDisplayError = hideErrorParam === 'false' || hideErrorParam === false || hideErrorParam === null;
                if (canDisplayError &&
                    err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 403:
                            this._snackBarService.error(this._translateService.instant('ERROR.HTTP_FORBIDDEN'));
                            break;
                        case 422:
                        case 500:
                            this._snackBarService.error(this._translateService.instant('ERROR.TRY_AGAIN'));
                    }
                }
            }));
    }
}
