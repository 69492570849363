import {Injectable} from '@angular/core';
import {PatientApiService} from '../../../core/api-services/patient/patient.api.service';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {Patient} from '../shared/patient.model';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../../configuration/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class PatientDetailService {
    private readonly _DATE_SORT_TYPE: string;

    constructor(private _patientApiService: PatientApiService,
                private _configurationService: ConfigurationService,
                private _translateService: TranslateService) {
        this._DATE_SORT_TYPE = this._configurationService.getConfigurationContent('front', 'advancedSearch.stayResultsDateSortType') || 'endDate';
    }

    /**
     * Because loadPatientStays() doesn't return dataSetElement objects
     * we need to build the array of classes to display the list
     * @param stays
     * @param isChild
     * @private
     */
    private _buildDataSetElements(stays: any[], isChild = false): any[] {
        const dataSetElements = [];
        if (stays) {
            stays.forEach(stay => {
                // stay is a dataSetContent and stay.contents is a dataSetElement
                if (stay.contents) {
                    const dataSetElement = {
                        ...stay.contents,
                        dataSetContent: {
                            ...stay,
                            ageInDays: stay.age,
                            ghm: stay.healthGhm,
                            ctGhm: {
                                slug: stay.ctHealthGhsGhm && stay.ctHealthGhsGhm.ghmSlug ? stay.ctHealthGhsGhm.ghmSlug : ''
                            },
                            gme: stay.children && stay.children[0] && stay.children[0].healthStayAdditional ? stay.children[0].healthStayAdditional.healthGme : null,
                            ctGme: {
                                slug: stay.children && stay.children[0] && stay.children[0].healthStayAdditional && stay.children[0].healthStayAdditional.ctHealthGmtGme ? stay.children[0].healthStayAdditional.ctHealthGmtGme.gmeSlug : ''
                            }
                        },
                        totalDocument: stay.totalDocument,
                        totalEssentialDocument: stay.totalDocumentEssential,
                        hasDocumentOverlapRisk: stay.hasDocumentOverlapRisk,
                        isReady: stay.isReady,
                        codificationDataSetElementStatus: stay.contents.codificationDataSetElementStatus || {},
                        dataSetElementDiagnostic: stay.contents.dataSetElementDiagnostic,
                        children: stay.children ? this._buildDataSetElements(stay.children, true) : null,
                    };
                    delete dataSetElement.dataSetContent.children;
                    delete dataSetElement.dataSetContent.contents;
                    delete dataSetElement.dataSetContent.healthGhm;
                    delete dataSetElement.dataSetContent.ctHealthGhsGhm;
                    delete dataSetElement.dataSetContent.healthStayAdditional;
                    isChild ? dataSetElements.push(dataSetElement) : dataSetElements.push({data: dataSetElement});
                }
            });
        }
        return dataSetElements;
    }

    async loadPatient(patientId: number): Promise<Patient> {
        try {
            return await this._patientApiService
                .get(patientId)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadPatientStays(patientId: number, params?: any): Promise<any> {
        try {
            params = params || {
                include: 'contents,contents.codificationDataSetElementStatus,healthGhm,ctHealthGhsGhm,children,children.medicalUnit,children.contents.codificationDataSetElementStatus,' +
                    'children.healthStayAdditional,children.healthStayAdditional.healthGme,children.healthStayAdditional.ctHealthGmtGme,' +
                    'children.contents.dataSetElementDiagnostic.diagnostic,children.contents.dataSetElementDiagnostic.diagnostic.diagnosticLevel,' +
                    'children.contents.dataSetElementDiagnostic.codificationLabel.color,' +
                    'linkDocumentAllCount,linkDocumentEssentialAllCount,establishment',
                append: 'is_ready,has_document_overlap_risk'
            };
            params.sort = `-${this._DATE_SORT_TYPE}`;
            return await this._patientApiService
                .getPatientStays(patientId, params)
                .pipe(map(res => params.include ? this._buildDataSetElements(res) : res))
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    getPatientGender(patient: Patient): string {
        if (patient) {
            if (patient.gender === 1) {
                return this._translateService.instant('HEALTH.PATIENT.MAN');
            } else if (patient.gender === 2) {
                return this._translateService.instant('HEALTH.PATIENT.WOMAN');
            }
        }
        return '';
    }

    getPatientAge(patient: Patient): string {
        if (patient?.birthDate) {
            const age = moment().diff(moment(patient.birthDate), 'days');
            return this.formatPatientAge(age);
        }
        return '';
    }

    formatPatientAge(age: number): string {
        if (age >= 365) {
            age = Math.floor(age / 365);
            const suffix = age > 1 ? this._translateService.instant('STUFF.YEARS') : this._translateService.instant('STUFF.YEAR');
            return `${age} ${suffix}`;
        } else {
            const suffix = age > 1 ? this._translateService.instant('STUFF.DAYS') : this._translateService.instant('STUFF.DAY');
            return `${age || 0} ${suffix}`;
        }
    }
    formatPatientMovement(entry: string): string[] {
        return entry.split('|');
    }
}
