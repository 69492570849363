import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionActMissingSlugComponent} from './search-engine-condition-act-missing-slug.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionActMissingSlugCSARRService extends SearchEngineCondition {
    useCase: string;

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.EXCLUDING_ACTS_CSARR'),
            'actMissingSlugCSARR',
            SearchEngineConditionActMissingSlugComponent,
            true);
        this.useCase = this._translateService.currentLang === 'fr' ? 'csarr' : 'unk';
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            actMissingSlug: [],
            actType: this.useCase
        };
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.actMissingSlug = this.apiParams.args.slugs;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.type = 'actPresence'; // Override because there are 2 services (CCAM and CSARR)
        this.apiParams.args = {
            slugs: [...this.params.actMissingSlug],
            type: this.params.actType,
            operator: 'NAND'
        };
    }

    isValidApiParams(args: any): boolean {
        return !!(args?.slugs?.length && args?.type === this.useCase);
    }
}
