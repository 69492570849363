import {Component, Input} from '@angular/core';
import {StayDetailCodificationDiagnosticService} from '../../../modules/stay/detail/codification/diagnostic/stay-detail-codification-diagnostic.service';
import {StayDetailService} from '../../../modules/stay/detail/stay-detail.service';

@Component({
    selector: 'ct-ghm-tag',
    templateUrl: './ghm-tag.component.html',
    styleUrls: ['./ghm-tag.component.scss']
})
export class GhmTagComponent {
    @Input() healthGhm: any;
    @Input() canDisplaySupIcon: boolean;
    @Input() canDisplayNoDataText = true;
    @Input() healthGhmDescriptionKey = 'name';
    @Input() tooltipPlacement: string;
    @Input() htmlAttributeName: string;
    @Input() htmlAttributeValue: string;

    constructor(private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService,
                private _stayDetailService: StayDetailService) { }

    getDiagnosticLabelClass() {
        if (this.healthGhm) {
            return `tag-${this._stayDetailCodificationDiagnosticService
                .getDiagnosticLabelColorByLevel(this._stayDetailService
                    .convertGhmToLevel(this.healthGhm.slug))}`;
        }
        return '';
    }
}
