<div [ngStyle]="{'max-height': maxContainerHeight, 'height': containerHeight}">
    <ng-container *ngIf="src">
        <ng-container>
            <object class="full-width"
                    type="application/pdf"
                    [ngStyle]="{'height': objectHeight}"
                    [data]="url | safeUrl:true"></object>
        </ng-container>
    </ng-container>

    <div class="align-center-center no-data full-height"
         *ngIf="!src">
        {{'ERROR.NO_DOCUMENT' | translate}}
    </div>
</div>
