import {TranslateService} from '@ngx-translate/core';
import {SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionCodifyComponent} from './search-engine-condition-codify.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionCodifyService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['codify'],
            _translateService.instant('FILTER_SEARCH.LIST.SEARCH.CODIFY_STATUS'),
            'codify',
            SearchEngineConditionCodifyComponent,
            false);
        this.init();
    }

    init(uriParams?: any): void {
        this.uriParams = {codify: ''};
        this.params = {codify: ''};
        this.setUriParams(uriParams);
    }

    convertToUriParams(): void {
        if (this.params.codify === true) {
            this.uriParams.codify = '1';
        } else if (this.params.codify === false) {
            this.uriParams.codify = '0';
        } else {
            this.uriParams.codify = '';
        }
    }

    convertToParams(): void {
        if (this.uriParams.codify === '1') {
            this.params.codify = true;
        } else if (this.uriParams.codify === '0') {
            this.params.codify = false;
        } else {
            this.params.codify = '';
        }
    }
}
