import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {SearchEngineConditionDateComparisonComponent} from '../../shared/search-engine-condition-date-comparison/search-engine-condition-date-comparison.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionEndDateService extends SearchEngineCondition {
    private _defaultDateRange: string;
    private _useCase: string;

    get useCase(): string {
        return this._useCase;
    }

    set useCase(value: string) {
        this._useCase = value;
    }

    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService) {
        super(['endDate', 'customEndDateSubtractAmount', 'customEndDateSubtractUnit'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.EXIT_DATE'),
            'endDate',
            SearchEngineConditionDateComparisonComponent,
            true);
        this._useCase = 'codification';
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            startDate: null,
            endDate: null,
            customSubtractAmount: null,
            customSubtractUnit: 'day'
        };
        this.uriParams = {
            endDate: '',
            customEndDateSubtractAmount: '',
            customEndDateSubtractUnit: 'day'
        };
        if (this._useCase === 'qualityControl') {
            const exitDateRange = this._configurationService.getConfigurationContent('front', 'qualityControl.exitDateRange') || 'currentMonth';
            this.params.startDate = DateHelperService.getDateRangeByType(exitDateRange).startDate;
            this.params.endDate = DateHelperService.getDateRangeByType(exitDateRange).endDate;
        } else if (this._useCase === 'dashboard') {
            this._setDefaultDateRange();
            this.params.startDate = DateHelperService.getDateRangeByType(this._defaultDateRange).startDate;
            this.params.endDate = DateHelperService.getDateRangeByType(this._defaultDateRange).endDate;
        }
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    private _setDefaultDateRange(): void {
        this._defaultDateRange = this._configurationService.getConfigurationContent('front', 'dashboard.defaultDateRange');
        if (!this._defaultDateRange) {
            const clientType = this._configurationService.getConfigurationContent('front', 'clientType') || 'public';
            this._defaultDateRange = clientType === 'public' ? 'currentYear' : 'currentMonth';
        }
    }

    private _setParamsForDashboard(params: any): void {
        // For dashboard, we don't want empty params to be sent
        // so, we set default value
        if (this._useCase === 'dashboard') {
            if (!params.startDate &&
                !params.endDate) {
                params.startDate = DateHelperService.getDateRangeByType(this._defaultDateRange).startDate;
                params.endDate = DateHelperService.getDateRangeByType(this._defaultDateRange).endDate;
            } else if (!params.startDate) {
                params.startDate = DateHelperService.getDateRangeByType(this._defaultDateRange).startDate;
            } else if (!params.endDate) {
                params.endDate = DateHelperService.getDateRangeByType(this._defaultDateRange).endDate;
            }
        }
    }

    private _updateCustomUriParams(): void {
        this.uriParams.customEndDateSubtractAmount = this.params.customSubtractAmount ? this.params.customSubtractAmount.toString() : '';
        this.uriParams.customEndDateSubtractUnit = this.params.customSubtractUnit;
    }

    private _updateCustomParams(): void {
        this.params.customSubtractAmount = Number.parseFloat(this.uriParams.customEndDateSubtractAmount);
        this.params.customSubtractUnit = this.uriParams.customEndDateSubtractUnit;
    }

    convertToUriParams(): void {
        this._setParamsForDashboard(this.params);
        this.updateUriParamsComparisonDate(this.uriParams, 'endDate', this.params);
        this._updateCustomUriParams();
    }

    convertToParams(): void {
        if (this.uriParams.endDate) {
            this.updateParamsComparisonDate(this.uriParams, 'endDate', this.params);
            this._updateCustomParams();
        }
    }

    convertToParamsFromApiParams(): void {
        this.updateParamsFromApiParamsComparisonDate(this.apiParams, this.params);
        this._updateCustomParams();
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        const startDate: string = DateHelperService.toMysql(this.params.startDate);
        const endDate: string = DateHelperService.toMysql(this.params.endDate);
        this.apiParams.args = {...this.getComparisonArgsObject(startDate, endDate)};
        this._updateCustomUriParams();
    }

    isValid(fields?: any): boolean {
        if (!this.isAdvanced) {
            return true;
        }
        return (!this.isUndefined(fields['endDate']) &&
            !this.isEmpty(fields['endDate']));
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
