import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StayDetailActPredictionListComponent } from './stay-detail-act-prediction-list.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {StayDetailDiagnosticPredictionElementModule} from '../../diagnostic-prediction/element/stay-detail-diagnostic-prediction-element.module';
import {StayDetailActPredictionElementModule} from '../element/stay-detail-act-prediction-element.module';



@NgModule({
    declarations: [
        StayDetailActPredictionListComponent
    ],
    exports: [
        StayDetailActPredictionListComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        MatDividerModule,
        MatProgressBarModule,
        StayDetailDiagnosticPredictionElementModule,
        StayDetailActPredictionElementModule
    ]
})
export class StayDetailActPredictionListModule { }
