import {Injectable} from '@angular/core';
import {FilterSearch} from '../filter-search.model';

@Injectable({
    providedIn: 'root'
})
export class FilterSearchEditDialogService {
    constructor() {}

    sortFiltersSearch(a: FilterSearch, b: FilterSearch) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        } else {
            return 0;
        }

    }
}
