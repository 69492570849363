import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DiagnosisChronicApiService {
    private _BASE_URL = 'diagnosis-chronic';

    constructor(private _http: HttpService) { }

    getAll(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
