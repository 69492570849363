import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FilterSearchEditDialogComponent} from './filter-search-edit-dialog.component';
import {PipesModule} from '../../../../shared/pipes/pipes.module';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {FilterSearchEditValidityFrameModule} from '../edit-validity-frame/filter-search-edit-validity-frame.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {WorklistUserElementModule} from '../../../../shared/components/worklist-user-element/worklist-user-element.module';


@NgModule({
    declarations: [
        FilterSearchEditDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        PipesModule,
        NgxMatSelectSearchModule,
        MatProgressBarModule,
        MatRadioModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        FilterSearchEditValidityFrameModule,
        MatCheckboxModule,
        WorklistUserElementModule,
    ]
})
export class FilterSearchEditDialogModule {
}
