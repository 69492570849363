<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="edit-dialog-content">
    <h1 mat-dialog-title>{{'TOOLTIP.EDIT_PATIENT_IPP' | translate}}</h1>

    <form (ngSubmit)="validate()">
        <mat-dialog-content>
            <mat-form-field class="width-150">
                <input matInput
                       required
                       type="text"
                       name="IPP"
                       [placeholder]="'ACRONYM.IPP' | translate"
                       [(ngModel)]="IPP">
            </mat-form-field>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button
                    mat-dialog-close
                    type="button"
                    class="color-blue-on-white">{{'BUTTON.CANCEL' | translate | uppercase}}</button>

            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="isValidationDisabled()">{{'BUTTON.VALIDATE' | translate | uppercase}}</button>
        </mat-dialog-actions>
    </form>
</div>
