import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ct-need-access-rights-page',
    templateUrl: './need-access-rights-page.component.html',
    styleUrls: ['./need-access-rights-page.component.scss']
})
export class NeedAccessRightsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
