import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DiagnosticNoteDisplayDialogComponent} from './display-dialog/diagnostic-note-display-dialog.component';
import {DiagnosticNoteApiService} from '../../../core/api-services/diagnostic-note/diagnostic-note.api.service';
import {DiagnosticNoteEditDialogComponent} from './edit-dialog/diagnostic-note-edit-dialog.component';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {DiagnosticNote} from './diagnostic-note.model';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticNoteService {
    constructor(private _translateService: TranslateService,
                private _diagnosticNoteApiService: DiagnosticNoteApiService,
                private _matDialog: MatDialog,
                private _broadcastService: BroadcastService) {}

    getSimplifiedScoreText(simplifiedScore: number): string {
        switch (simplifiedScore) {
            case 0:
                return this._translateService.instant('DIAGNOSTIC.CONFIDENCE_NONE');
            case 1:
                return this._translateService.instant('DIAGNOSTIC.CONFIDENCE_LOW');
            case 2:
                return this._translateService.instant('DIAGNOSTIC.CONFIDENCE_AVERAGE');
            case 3:
                return this._translateService.instant('DIAGNOSTIC.CONFIDENCE_HIGH');
            default:
                return '';
        }
    }

    openDiagnosticNoteDisplayDialog(diagnosticId: number) {
        this._matDialog.open(DiagnosticNoteDisplayDialogComponent, {
            data: {
                service: this,
                diagnosticId
            },
            autoFocus: false,
            panelClass: 'diagnostic-note-display-dialog'
        });
    }

    openDiagnosticNoteEditDialog(diagnosticNote?: DiagnosticNote) {
        const dialogRef: MatDialogRef<DiagnosticNoteEditDialogComponent> =
            this._matDialog.open(DiagnosticNoteEditDialogComponent, {
                data: {
                    service: this,
                    diagnosticNote
                },
                autoFocus: false,
                panelClass: 'diagnostic-note-edit-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(res => {
                if (res &&
                    res.diagnosticNote) {
                    if (res.isUpdate) {
                        this._broadcastService.send('diagnosticNote::update', {diagnosticNote: res.diagnosticNote});
                    } else {
                        this._broadcastService.send('diagnosticNote::creation', {diagnosticNote: res.diagnosticNote});
                    }
                }
            });
    }

    getDiagnosticNoteFileSrc(diagnosticNote: DiagnosticNote) {
        return diagnosticNote && diagnosticNote.filename ? `analyzer_notes/${diagnosticNote.filename}` : '';
    }

    private _addFilters(params: any) {
        if (params.name) {
            params['filter[name]'] = params.name;
            delete params.name;
        }
        if (params.diagnosticSlugParent) {
            params['filter[diagnostics.slug]'] = params.diagnosticSlugParent;
            delete params.diagnosticSlugParent;
        }
        if (params.diagnosticSlugs) {
            params['filter[diagnostics.slug]'] = params.diagnosticSlugs;
            delete params.diagnosticSlugs;
        }
        if (params.diagnosticId) {
            params['filter[diagnostics.id]'] = params.diagnosticId;
            delete params.diagnosticId;
        }
    }

    async loadAllDiagnosticNotes(params?: any) {
        try {
            this._addFilters(params);
            return await this._diagnosticNoteApiService
                .getAll(params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadDiagnosticNote(diagnosticNoteId:  number, params?: any) {
        try {
            return await this._diagnosticNoteApiService
                .get(diagnosticNoteId, params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async createDiagnosticNote(body: FormData) {
        try {
            return await this._diagnosticNoteApiService
                .create(body)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateDiagnosticNote(diagnosticNoteId:  number, body: FormData) {
        try {
            if (body &&
                body instanceof FormData) {
                // Because PHP doesn't accept PATCH with FormData
                body.append('_method', 'PATCH');
            }
            return await this._diagnosticNoteApiService
                .update(diagnosticNoteId, body)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async deleteDiagnosticNote(diagnosticNoteId: number) {
        try {
            return await this._diagnosticNoteApiService
                .delete(diagnosticNoteId)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
