<mat-card class="no-padding">
    <mat-progress-bar mode="indeterminate"
                      [ngClass]="{'visibility-hidden': !isLoading}"></mat-progress-bar>

    <mat-card-content>
        <ct-tab-list [broadcastMessage]="'dataSetElementStayJustification::switchTab'"
                     [(activeTabId)]="stayDetailJustificationService.activeTabId">
            <ct-tab-list-item *ngFor="let tab of stayDetailJustificationService.tabs"
                              [label]="tab.name"
                              [id]="tab.id">
                <div class="tab-content">
                    <form *ngIf="canDisplaySearchInput && isActiveTabFirstOne()"
                          (ngSubmit)="search(tab)">
                        <div class="field-container">
                            <mat-form-field>
                                <input matInput
                                       type="text"
                                       name="query"
                                       [(ngModel)]="query"
                                       [placeholder]="'DATA_SET.CODIFICATION_ELEMENT.JUSTIFICATION.SEARCH_INSIDE_DOCS' | translate">
                            </mat-form-field>

                            <button mat-icon-button
                                    type="button"
                                    [matTooltip]="'TOOLTIP.RESET_SEARCH' | translate"
                                    [disabled]="isLoading || !query"
                                    (click)="resetSearch()">
                                <mat-icon>delete</mat-icon>
                            </button>

                            <button mat-raised-button
                                    type="submit"
                                    color="primary"
                                    [disabled]="isLoading || !query">{{'BUTTON.SEARCH' | translate | uppercase}}</button>
                        </div>
                    </form>

                    <div class="no-data text-center"
                         [ngClass]="{'padding-top-10': isActiveTabFirstOne()}"
                         *ngIf="!canDisplaySearchInput && isLoading && tab.highlight && !tab.highlight.length">
                        {{'INFO.SEARCH_IN_PROGRESS' | translate}}
                    </div>

                    <mat-divider *ngIf="canDisplayDivider(tab)"></mat-divider>

                    <ct-document-highlight-preview *ngIf="!tab.hasNoResult"
                                                   [element]="tab"
                                                   [forStayDetailJustification]="true"
                                                   [isInnerHitsGroupingAlreadyDone]="true"></ct-document-highlight-preview>

                    <div class="no-data text-center"
                         [ngClass]="{'padding-top-10': isActiveTabFirstOne()}"
                         *ngIf="(!tab.init || !canDisplaySearchInput) && tab.hasNoResult">
                        {{'DATA_SET.CODIFICATION_ELEMENT.JUSTIFICATION.NO_RESULT' | translate}}
                    </div>
                </div>
            </ct-tab-list-item>
        </ct-tab-list>
    </mat-card-content>
</mat-card>
