import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticSearchService {
    constructor(private _translateService: TranslateService) {}

    getTooltipText(diagnostic: any, isDiagnosticSeverityLevelActivated: boolean) {
        const stringToReturn = `${diagnostic.name || diagnostic.description}`;
        return `${stringToReturn}${isDiagnosticSeverityLevelActivated ? ` (${this._translateService.instant('STUFF.LEVEL')}. ${diagnostic.level})` : ''}`;
    }

    /**
     * If the diagnostic is a parent then there is a *
     * @param diagnostic
     * @param isDiagnosticSeverityLevelActivated
     */
    getDisplayText(diagnostic: any, isDiagnosticSeverityLevelActivated: boolean): string {
        let stringToReturn = `${diagnostic.slug}`;
        if (!diagnostic.parentId || diagnostic.parentId === diagnostic.id) {
            stringToReturn = stringToReturn + '*';
        }
        return `${stringToReturn} ${diagnostic.description || diagnostic.name || ''}${isDiagnosticSeverityLevelActivated ? ` (${this._translateService.instant('STUFF.LEVEL')}. ${diagnostic.level})` : ''}`;
    }
}
