import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MedicationService} from '../medication.service';
import {Medication} from '../medication.model';

@Component({
    selector: 'ct-medication-detail-dialog',
    templateUrl: './medication-detail-dialog.component.html',
    styleUrls: ['./medication-detail-dialog.component.scss']
})
export class MedicationDetailDialogComponent implements OnInit {
    private _medicationService: MedicationService;
    private _medicationId: number;

    medication: Medication;
    isLoading: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.isLoading = true;
        this._medicationService = this.data.service;
        this._medicationId = this.data.medicationId;
        this.loadMedication();
    }

    async loadMedication() {
        try {
            this.medication = await this._medicationService
                .loadMedication(this._medicationId);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

}
