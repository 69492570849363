<div>
    <mat-form-field class="width-100">
        <mat-select [placeholder]="'HEALTH.PATIENT.GENDER' | translate"
                    [panelClass]="['mat-' + colorService.getColorAccordingToCurrentState()]"
                    [(ngModel)]="condition.service.params.gender">
            <mat-option value="1">
                {{'HEALTH.PATIENT.MAN' | translate}}
            </mat-option>

            <mat-option value="2">
                {{'HEALTH.PATIENT.WOMAN' | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
