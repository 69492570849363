import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'ct-password-validator',
    templateUrl: './password-validator.component.html',
    styleUrls: ['./password-validator.component.scss']
})
export class PasswordValidatorComponent implements OnInit {
    @Input() field: string;
    @Output() status: EventEmitter<boolean> = new EventEmitter();

    active = false;
    data: any;

    private _validator = {
        length: 12,
        special: ':-+,<>()_\'=!@#$%^&*?.;\/'
    };

    constructor() {
    }

    ngOnInit() {
    }

    hasLength() {
        const condition = this.field && this.field.length >= this._validator.length;
        const isValid = condition && this.updateValidate();
        this.status.emit(isValid);
        return condition;
    }

    hasNumeric() {
        const pattern = /.*[0-9].*/g;
        return this.field && this.field.length ? pattern.test(this.field) : false;
    }

    hasLower() {
        const pattern = /.*[a-z].*/g;
        return this.field && this.field.length ? pattern.test(this.field) : false;
    }

    hasUpper() {
        const pattern = /.*[A-Z].*/g;
        return this.field && this.field.length ? pattern.test(this.field) : false;
    }

    hasSpecial() {
        const pattern = /.*[-,:<>()_=!@#$%^&*?.;\/*].*/g;
        return this.field && this.field.length ? pattern.test(this.field) : false;
    }

    updateValidate() {
        return this.hasNumeric() && this.hasLower() && this.hasUpper() && this.hasSpecial();
    }

}
