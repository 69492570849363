<mat-dialog-content>
    <ng-container *ngIf="!isLoading">
        <button mat-icon-button
                mat-dialog-close
                class="close-icon">
            <mat-icon class="close-icon">close</mat-icon>
        </button>

        <div class="padding-24">
            <div class="margin-bottom-16">
                <h2 class="margin-bottom-20">{{'VERSION_DISPLAY_DIALOG.VERSION' | translate}} {{tags.version || ''}}</h2>
                <ng-container>
                    <div class="row">
                        <div class="col-auto no-padding-left">
                            <div *ngFor="let service of tags.services" class="font-weight-700">{{service.name}}</div>
                        </div>
                        <div class="col">
                            <div *ngFor="let service of tags.services" >{{service.version}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div>
                <h2 class="font-weight-bold margin-bottom-20">{{'VERSION_DISPLAY_DIALOG.MODULES' | translate}}</h2>
                <div class="row">
                    <div class="col no-padding">
                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.dashboard">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.dashboard">clear</mat-icon>
                            <span class="module">{{'TOOLBAR.DASHBOARD' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon class="text-color md-green vertical-align-middle">done</mat-icon>
                            <span class="module">{{'TOOLBAR.STAY_LIST' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.filterSearch">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.filterSearch">clear</mat-icon>
                            <span class="module">{{'TOOLBAR.FILTER_SEARCH_STAY_LIST' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.qualityControl">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.qualityControl">clear</mat-icon>
                            <span class="module">{{'TOOLBAR.QUALITY_CONTROL' | translate}}</span>
                        </div>
                    </div>

                    <div class="col no-padding">
                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.stayReady">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.stayReady">clear</mat-icon>
                            <span class="module">{{'STAY.READY' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.autoCoding">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.autoCoding">clear</mat-icon>
                            <span class="module">{{'VERSION_DISPLAY_DIALOG.AUTO_CODING' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.autoDiag">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.autoDiag">clear</mat-icon>
                            <span class="module">{{'TOOLBAR.AUTO_DIAGNOSIS' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.predictionAnalysis">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.predictionAnalysis">clear</mat-icon>
                            <span class="module">{{'TOOLBAR.PREDICTION_ANALYSIS' | translate}}</span>
                        </div>
                    </div>

                    <div class="col no-padding">
                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.patient">done</mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.patient">clear</mat-icon>
                            <span class="module">{{'TOOLBAR.PATIENT_LIST' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon class="text-color md-green"
                                      *ngIf="modules.filterSearchPatient">done
                            </mat-icon>
                            <mat-icon class="text-color md-red"
                                      *ngIf="!modules.filterSearchPatient">clear
                            </mat-icon>
                            <span class="module">{{'TOOLBAR.FILTER_SEARCH_PATIENT_LIST' | translate}}</span>
                        </div>

                        <div>
                            <mat-icon *ngIf="modules.chronicDiagnostic"
                                      class="text-color md-green">done
                            </mat-icon>
                            <mat-icon *ngIf="!modules.chronicDiagnostic"
                                      class="text-color md-red">clear
                            </mat-icon>
                            <span class="module">{{'TOOLBAR.CHRONIC_DIAGNOSTIC_LIST' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="align-center-center"
         *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate"
                              [diameter]="150"></mat-progress-spinner>
    </div>
</mat-dialog-content>
