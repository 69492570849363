import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionCollectionComponent} from './search-engine-condition-collection.component';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';

@Injectable()
export class SearchEngineConditionCollectionService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService,
                private _authenticationService: AuthenticationService, ) {
        super(['collection'],
            _translateService.instant('FILTER_SEARCH.LIST.SEARCH.TYPE'),
            'collection',
            SearchEngineConditionCollectionComponent,
            false);
        this.init();
    }

    init(uriParams?: any) {
        const isCommonActivated = this._configurationService.getConfigurationContent('front', 'filterSearch.search.isCommonActivated') || false;
        const isCothActivated = this._configurationService.getConfigurationContent('front', 'filterSearch.search.isCothActivated') || false;
        const cothDefaultValue = isCothActivated &&
            this._configurationService.getConfigurationContent('front', 'filterSearch.search.cothDefaultValue');
        const isAutoSupervised = (this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlNearAutomationModule') &&
            this._authenticationService.hasRole('admin')) || false;
        this.params = {
            own: true,
            shared: true,
            common: isCommonActivated,
            default: cothDefaultValue,
            automatable: this._authenticationService.hasRole('admin'),
            autoSupervised: isAutoSupervised
        };
        this.uriParams = {collection: 'own,shared'};
        if (cothDefaultValue) {
            this.uriParams.collection = this.uriParams.collection += ',default';
        }

        if (isCommonActivated) {
            this.uriParams.collection = this.uriParams.collection += ',common';
        }
        if (this._authenticationService.hasRole('admin')) {
            this.uriParams.collection = this.uriParams.collection += ',automatable';
        }
        if (isAutoSupervised) {
            this.uriParams.collection = this.uriParams.collection += ',autoSupervised';
        }

        this.setUriParams(uriParams);
    }

    convertToUriParams() {
        this.uriParams.collection = '';
        this.uriParams.collection += this.params.own === true ? 'own,' : '';
        this.uriParams.collection += this.params.shared === true ? 'shared,' : '';
        this.uriParams.collection += this.params.common === true ? 'common,' : '';
        this.uriParams.collection += this.params.default === true ? 'default,' : '';
        this.uriParams.collection += this.params.automatable === true ? 'automatable,' : '';
        this.uriParams.collection += this.params.autoSupervised === true ? 'autoSupervised,' : '';

        if (this.uriParams.collection.lastIndexOf(',') === (this.uriParams.collection.length - 1)) {
            this.uriParams.collection = this.uriParams.collection
                .replace(/\,(?=[^,]*$)/, '');
        }
    }

    convertToParams() {
        this.params.own = this.uriParams.collection.includes('own');
        this.params.shared = this.uriParams.collection.includes('shared');
        this.params.common = this.uriParams.collection.includes('common');
        this.params.default = this.uriParams.collection.includes('default');
        this.params.automatable = this.uriParams.collection.includes('automatable');
        this.params.autoSupervised = this.uriParams.collection.includes('autoSupervised');
    }

    isValid(fields?: any): boolean {
        return true;
    }
}
