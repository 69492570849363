<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="edit-dialog-content">
    <h1 mat-dialog-title *ngIf="!diagnosticNote">{{'DIAGNOSTIC.NOTE.EDIT_DIALOG.TITLE_CREATE' | translate}}</h1>
    <h1 mat-dialog-title *ngIf="diagnosticNote">{{'DIAGNOSTIC.NOTE.EDIT_DIALOG.TITLE_EDIT' | translate}}</h1>

    <form>
        <mat-dialog-content>
            <mat-form-field class="full-width">
                <input matInput
                       required
                       type="text"
                       name="'name'"
                       [(ngModel)]="diagnosticNoteData.name"
                       [placeholder]="'DIAGNOSTIC.NOTE.EDIT_DIALOG.NAME' | translate">
            </mat-form-field>

            <ct-diagnostic-search [returnObject]="true"
                                [(param)]="diagnosticNoteData.diagnostics"></ct-diagnostic-search>

            <mat-form-field class="full-width">
                    <textarea matInput
                              cdkTextareaAutosize
                              type="text"
                              name="'description'"
                              [cdkAutosizeMinRows]="3"
                              [(ngModel)]="diagnosticNoteData.text"
                              [placeholder]="'DIAGNOSTIC.NOTE.EDIT_DIALOG.DESCRIPTION' | translate">
                    </textarea>
            </mat-form-field>

            <ct-upload-file-button class="display-block margin-bottom-20"
                                   (selectedFile)="getFileToUpload($event)"></ct-upload-file-button>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button
                    mat-dialog-close
                    type="button"
                    class="color-blue-on-white">{{'BUTTON.CANCEL' | translate | uppercase}}</button>

            <button mat-raised-button
                    id="validateButton"
                    color="primary"
                    type="submit"
                    [disabled]="isLoading || !diagnosticNoteData.name"
                    (click)="validate()">{{'BUTTON.VALIDATE' | translate | uppercase}}</button>
        </mat-dialog-actions>
    </form>
</div>
