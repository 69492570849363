import { User } from '../../../modules/user/user.model';
import { DataSetElement } from '../../../modules/stay/shared/data-set-element.model';
import { ConfigurationService } from 'src/app/modules/configuration/configuration.service';
import { StayListElementActionAddDialogUsersComponent } from '../stay-list-element/action/add-dialog-users/stay-list-element-action-add-dialog-users.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../modules/user/user.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'stay-user-button',
    templateUrl: './stay-user-button.component.html',
    styleUrls: [
        './stay-user-button.component.scss'
    ]
})

export class StayUserButtonComponent implements OnInit {
    @Input() isClickable = false;
    @Input() dataSetElement: DataSetElement;

    canDisplayLinkStayUser = false;
    userInitial: string;
    userFullName: string;
    user ?: User;


    constructor(private _userService: UserService,
        private _matDialog: MatDialog,
        private _configurationService: ConfigurationService
        ) {}

    ngOnInit() {
        this.canDisplayLinkStayUser = true;
        this.user = this.dataSetElement?.parent ?
            this.dataSetElement?.parent.dataSetContent?.user :
            (
                this.dataSetElement?.dataSetContent?.user ?
                    this.dataSetElement?.dataSetContent?.user : null
            );

        this._formatStayUser();
    }

    private _formatStayUser() {
        this.userInitial = this._userService.getInitials(this.user);
        this.userFullName = this.user?.fullName;
    }

    assignStayUser() {
        if (this.isClickable) {
            const dialogRef: MatDialogRef<StayListElementActionAddDialogUsersComponent> =
                this._matDialog.open(StayListElementActionAddDialogUsersComponent, {
                    data : {
                    dataSetContentId: this.dataSetElement.dataSetContent.id,
                    user: this.user,
                    autoFocus: false
                }});

            dialogRef
                .afterClosed()
                .subscribe(async res => {
                    this.user = res?.user;
                    this._formatStayUser();
                });
        }
    }
}
