import {Component, Inject, OnInit} from '@angular/core';
import {DiagnosticNoteService} from '../diagnostic-note.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DiagnosticNote} from '../diagnostic-note.model';

@Component({
    selector: 'ct-diagnostic-note-display-dialog',
    templateUrl: './diagnostic-note-display-dialog.component.html',
    styleUrls: ['./diagnostic-note-display-dialog.component.scss']
})
export class DiagnosticNoteDisplayDialogComponent implements OnInit {
    private _diagnosticNoteService: DiagnosticNoteService;
    private _diagnosticId: number;

    diagnosticNotes: DiagnosticNote[] = [];
    diagnosticNotesDisplayDocumentData: any = {};
    isLoading: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this._diagnosticNoteService = this.data.service;
        this._diagnosticId = this.data.diagnosticId;
        this._loadAllDiagnosticNotes();
    }

    private async _loadAllDiagnosticNotes() {
        try {
            this.isLoading = true;
            const res = await this._diagnosticNoteService
                .loadAllDiagnosticNotes({diagnosticId: this._diagnosticId});
            this.diagnosticNotes = res && res.data ? res.data : [];
            this._initDiagnosticNotesDisplayDocumentData();
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    private _initDiagnosticNotesDisplayDocumentData(): void {
        if (this.diagnosticNotes) {
            this.diagnosticNotes.forEach(diagnosticNote => {
                if (diagnosticNote) {
                    this.diagnosticNotesDisplayDocumentData[diagnosticNote.id] = {
                        src: this._getFileSrc(diagnosticNote),
                        filename: diagnosticNote.filename
                    };
                }
            });
        }
    }

    private _getFileSrc(diagnosticNote: DiagnosticNote): string {
        return this._diagnosticNoteService.getDiagnosticNoteFileSrc(diagnosticNote);
    }
}
