<div class="flex-column-full-height">
    <mat-toolbar class="dialog-toolbar">
        <h2>{{'DIAGNOSTIC.NOTE.CODING_INFO' | translate}}</h2>
        <button mat-icon-button
                mat-dialog-close>
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <mat-progress-bar mode="indeterminate"
                      *ngIf="isLoading"></mat-progress-bar>

    <ng-container *ngIf="!isLoading">
        <mat-tab-group *ngIf="diagnosticNotes.length">
            <ng-container *ngFor="let note of diagnosticNotes">
                <mat-tab *ngIf="note.filename || note.text"
                         [label]="note.name">
                    <div class="row">
                        <div class="col no-padding">
                            <div class="padding-16"
                                 *ngIf="note.text">
                                {{note.text}}
                            </div>
                            <ct-file-display *ngIf="note.filename"
                                             [data]="diagnosticNotesDisplayDocumentData[note.id]"
                                             [containerHeight]="note.text ? '757px': '808px'"
                                             [objectHeight]="'100%'"></ct-file-display>
                        </div>
                    </div>
                </mat-tab>
            </ng-container>
        </mat-tab-group>

        <div *ngIf="diagnosticNotes.length === 0"
             class="flex-grow-1">
            <div class="align-center-center full-height">
                <span class="no-data font-size-20">{{'ERROR.NO_DIAGNOSTIC_NOTE' | translate}}</span>
            </div>
        </div>
    </ng-container>
</div>
