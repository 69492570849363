<div [ngStyle]="{'padding-top': '8px'}">
    <span>{{'STAY.REVALUATION' | translate}}</span>
    <mat-radio-group name="'codificationLabel'"
                     [ngModel]="codificationLabel"
                     (ngModelChange)="updateValuationCodificationLabel($event)">
        <mat-radio-button *ngFor="let button of radioButtons"
                          [value]="button.value"
                          color="primary">
            {{button.label | translate}}
        </mat-radio-button>
    </mat-radio-group>

    <div class="display-inline-block margin-bottom-10">
        <span class="margin-right-10">{{'DATA_SET.CODIFICATION.SEARCH.CONFIDENCE_MIN' | translate}}</span>
        <mat-slider
            class="example-margin"
            color="primary"
            [disabled]="!codificationLabel"
            [max]="maxSliderValue"
            [min]="1"
            [step]="1"
            [tickInterval]="1"
            [(ngModel)]="sliderValue"
            (ngModelChange)="updateValuationScore()">
        </mat-slider>
        <span class="margin-left-10">{{'DATA_SET.CODIFICATION.SEARCH.CONFIDENCE_MAX' | translate}}</span>
    </div>
</div>
