import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionStayLengthComponent} from './search-engine-condition-stay-length.component';

@Injectable()
export class SearchEngineConditionStayLengthService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super(['stayLength'],
            _translateService.instant('STAY.TYPE'),
            'stayLength',
            SearchEngineConditionStayLengthComponent,
            false);
        this.init();
    }

    init(uriParams?: any): void {
        this.params = {stayLength: 'all'};
        this.uriParams = {stayLength: 'all', duration: ''};
        this.setUriParams(uriParams);
    }

    convertToUriParams(): void {
        this.uriParams.stayLength = this.params.stayLength;
        switch (this.params.stayLength) {
            case 'all':
                this.uriParams.duration = '';
                break;
            case '0':
                this.uriParams.duration = '0;<=';
                break;
            case 'short':
                this.uriParams.duration = '1;>=<;2';
                break;
            case 'long':
                this.uriParams.duration = '3;>=';
                break;
            default:
                this.uriParams.duration = '';
        }
    }

    isValid(fields?: any): boolean {
        return true;
    }
}
