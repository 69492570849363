<div class="flex-column-full-height">
    <mat-toolbar class="dialog-toolbar">
        <h2 class="title">{{title || src}}</h2>
        <button mat-icon-button
                mat-dialog-close>
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <ct-file-display class="flex-grow-1"
                     [data]="displayDocumentData"
                     [containerHeight]="'856px'"
                     [objectHeight]="'100%'"></ct-file-display>
</div>
