import {ComponentRef, Injectable} from '@angular/core';

export enum SearchEssentialDocumentStatus {
    ALL_ESSENTIAL_DOCUMENT,
    ONE_ESSENTIAL_DOCUMENT,
    NO_ESSENTIAL_DOCUMENT,

}

@Injectable({
    providedIn: 'root'
})

export class SearchEngineHelperService {

    constructor() {}

    searchEssentialDocumentStatus = SearchEssentialDocumentStatus;

    addDivider(availableSearchConditions: any[], id: number): any[] {
        if (availableSearchConditions) {
            // We need because of the case we come back to the page
            // otherwise it would add each divider again
            const isDividerAlreadyAdded = !!availableSearchConditions
                .find(service => service.slug === 'divider' && service.id === id);
            if (!isDividerAlreadyAdded) {
                return [...availableSearchConditions, {
                    fields: [],
                    slug: 'divider',
                    id
                }];
            }
            return availableSearchConditions;
        }
        return [];
    }

    getSearchCondition(searchConditions: any[], searchConditionSlug: string): any {
        const searchCondition = searchConditions.find(condition =>
            condition.service.slug === searchConditionSlug);
        return searchCondition ? searchCondition : null;
    }

    getAvailableSearchCondition(availableSearchConditions: any[], conditionSlug: string): any {
        const conditionToReturn = availableSearchConditions
            .find(condition => condition.slug === conditionSlug);
        return conditionToReturn ? conditionToReturn : null;
    }

    setSearchConditionProperties(data: any, conditionService: any): void {
        if (data) {
            // When we switch between stay lists this._availableSearchConditions is not empty
            // so, we have to update the services properties if it changes
            if (data.displayName &&
                data.displayName !== '') {
                conditionService.displayName = data.displayName;
            }
            if (data.isAdvanced === true ||
                data.isAdvanced === false) {
                conditionService.isAdvanced = data.isAdvanced;
            }
            if (data.useCase) {
                conditionService.useCase = data.useCase;
            }
            if (data.localStorageKey) {
                conditionService.localStorageKey = data.localStorageKey;
            }
            // For shared component between condition services
            conditionService.isMCOUseCase = data.isMCOUseCase ?? false;
            conditionService.isSSRUseCase = data.isSSRUseCase ?? false;
            conditionService.canDisplayButtons = data.canDisplayButtons ?? false;
            conditionService.separator = data.separator ?? null;
            if (conditionService.slug === 'diagnosisMissing' ||
                (typeof data.validateWithCheckbox !== 'undefined' &&
                    data.validateWithCheckbox !== null)) {
                conditionService.validateWithCheckbox = data.validateWithCheckbox;
            }
        }
    }

    /**
     * Update availableSearchConditions array with conditionService
     * and update its data if necessary
     * @param availableSearchConditions
     * @param conditionService
     * @param data is set in the search services
     */
    addAvailableSearchCondition(availableSearchConditions: any[],
                                conditionService: any,
                                data?: any): any[] {
        if (conditionService) {
            const isServiceAlreadyAdded = !!availableSearchConditions
                .find(service => service.slug === conditionService.slug);
            if (!isServiceAlreadyAdded) {
                this.setSearchConditionProperties(data, conditionService);
                return [...availableSearchConditions, conditionService];
            }
        }
        return availableSearchConditions;
    }

    setComponentInstance(dynamicComponentRef: ComponentRef<any>, condition: any): void {
        if (dynamicComponentRef &&
            condition?.service) {
            dynamicComponentRef.instance.condition = condition;
            Object.keys(condition.service).forEach(key => {
                dynamicComponentRef.instance[key] = condition.service[key];
            });
        }
    }
}
