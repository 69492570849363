import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-act-missing-slug',
    templateUrl: './search-engine-condition-act-missing-slug.component.html',
    styleUrls: ['./search-engine-condition-act-missing-slug.component.scss']
})
export class SearchEngineConditionActMissingSlugComponent implements OnInit {
    condition: any;
    useCase: string;

    constructor() { }

    ngOnInit() {
    }
}
