import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionStringValueComponent} from '../../shared/search-engine-condition-string-value/search-engine-condition-string-value.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDoctorFullNameService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'doctorFullName';
    placeholder = 'DATA_SET.CODIFICATION_ELEMENT.DOCTOR';

    constructor(private _translateService: TranslateService) {
        super(['doctorFullName'],
            _translateService.instant('DATA_SET.CODIFICATION_ELEMENT.DOCTOR'),
            'doctorFullName',
            SearchEngineConditionStringValueComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {doctorFullName: null};
        this.uriParams = {doctorFullName: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.doctorFullName) {
            this.apiParams.args = {value: this.params.doctorFullName};
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.doctorFullName = this.apiParams.args.value;
    }

    isValid(fields?: any): boolean {
        return !this.isUndefined(fields['doctorFullName']) &&
            !this.isEmpty(fields['doctorFullName']);
    }

    isValidApiParams(args: any): boolean {
        return !!args?.value;
    }
}
