import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HealthDocumentApiService {
    private _BASE_URL = 'health-document';

    constructor(private _http: HttpService) {}

    get(healthDocumentId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${healthDocumentId}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    getAnonymization(healthDocumentId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${healthDocumentId}/anonymized`, {params: urlRequestParams});
    }

    anonymize(healthDocumentId: number, body, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/${healthDocumentId}/anonymize`, body, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    approve(healthDocumentId: number, body: any, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/${healthDocumentId}/approve`, body, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    summarize(healthDocumentId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${healthDocumentId}/summarize`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
