import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {BroadcastService} from '../../../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {TranslationHelperService} from '../../../../../../core/services/translation.helper.service';

@Component({
    selector: 'ct-stay-detail-diagnostic-prediction-list-menu',
    templateUrl: './stay-detail-diagnostic-prediction-list-menu.component.html',
    styleUrls: ['./stay-detail-diagnostic-prediction-list-menu.component.scss']
})
export class StayDetailDiagnosticPredictionListMenuComponent implements OnInit {
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

    @Input() codificationLabelIds: number[];
    @Input() options: {
        orderBy: {value, active},
        groupByParent: {value, active},
        display: {value, active}
    };

    isGroupingActivated: boolean;
    isDiagnosticSeverityLevelActivated: boolean;

    constructor(private _broadcastService: BroadcastService,
                private _translationHelperService: TranslationHelperService,
                private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this._initConfig();
        this._initAvailableFeatures();
    }

    private _initConfig() {
        this.isGroupingActivated = this._configurationService.getConfigurationContent('front', 'health.isGroupingActivated');
    }

    private _initAvailableFeatures() {
        this.isDiagnosticSeverityLevelActivated = this._translationHelperService.isFeatureAvailable('diagnosticSeverityLevel');
    }

    private _broadcast(switchGroupByParent: boolean = false): void {
        this._broadcastService.send('diagnosticPredictionListMenu::changeOption', {switchGroupByParent});
    }

    openMenu() {
        this.menuTrigger.openMenu();
    }

    canDisplayMenu() {
        return this.options.orderBy.active ||
            this.options.groupByParent.active ||
            this.options.display.active;
    }

    switchDisplay() {
        this.options.display.value = !this.options.display.value;
        this.options.display.active = !this.options.display.active;
        this._broadcast();
    }

    switchGroupByParent() {
        this.options.groupByParent.value = !this.options.groupByParent.value;
        this._broadcast(this.options.groupByParent.value);
    }

    switchOrderBy(value: string[]) {
        this.options.orderBy.value.length = 0;
        if (value.includes('level')) {
            this.options.orderBy.value.push('-diagnostic.diagnosticLevel.level');
        }
        if (value.includes('slug')) {
            this.options.orderBy.value.push('diagnostic.slug');
        }
        if (value.includes('ghm')) {
            this.options.orderBy.value.push('healthGhsGhm.healthGhmSlug');
        }
        if (value.includes('score')) {
            // simplifiedScore as first is important
            this.options.orderBy.value.push('-simplifiedScore');
            this.options.orderBy.value.push('-score');
        }
    }

    /**
     * Should display orderBy item or not
     * Don't use includes because the order is important
     * @param value
     */
    isOrderByItemActive(value: string[]) {
        if (value.length === 1) {
            if (value[0].includes('score')) {
                return this.options.orderBy.value[1] === value[0];
            }
            return this.options.orderBy.value[0] === value[0];
        } else if (value.length === 2) {
            if (value[1].includes('score')) {
                return this.options.orderBy.value[0] === value[0] &&
                    this.options.orderBy.value[2] === value[1];
            }
            return this.options.orderBy.value[0] === value[0] &&
                this.options.orderBy.value[1] === value[1];
        }
        return false;
    }
}
