<mat-form-field class="width-125">
    <mat-select #multipleSelect
                multiple
                [panelClass]="'condition-select-health-movement'"
                [placeholder]="placeholder | translate"
                [(ngModel)]="condition.service.params[paramKey]"
                [disabled]="healthMovements.length === 0">
        <mat-checkbox class="toggle-all"
                      [color]="'primary'"
                      [indeterminate]="isIndeterminate()"
                      [checked]="isChecked()"
                      (click)="toggleAll($event)">
            <span *ngIf="!isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.CHECK_ALL' | translate}} </span>
            <span *ngIf="isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.UNCHECK_ALL' | translate}} </span>
        </mat-checkbox>

        <div class="mode"
             *ngFor="let mode of getHealthMovementModes()">
            <mat-checkbox [color]="'primary'"
                          [indeterminate]="isIndeterminateMode(mode)"
                          [checked]="isCheckedMode(mode)"
                          (click)="toggleAll($event, mode)">
                <span *ngIf="useCase === 'entrance'">{{'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_ENTRANCE' | translate}} {{mode}}</span>
                <span *ngIf="useCase === 'exit'">{{'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_EXIT' | translate}} {{mode}}</span>
            </mat-checkbox>

            <mat-option *ngFor="let movement of healthMovementsGroupByMode[mode]"
                        [value]="movement.id">
                {{getPrefix(movement)}}{{movement.name}}
            </mat-option>
        </div>
    </mat-select>
    <mat-progress-bar *ngIf="isLoading"
                      mode="indeterminate"
                      class="autocomplete-progress-bar"></mat-progress-bar>
</mat-form-field>
