import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-ip-dms',
    templateUrl: './search-engine-condition-ip-dms.component.html',
    styleUrls: ['./search-engine-condition-ip-dms.component.scss']
})
export class SearchEngineConditionIpDmsComponent implements OnInit {
    condition: any;

    constructor() { }

    ngOnInit(): void {
    }

}
