import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    constructor(private _httpService: HttpService) {}

    /**
     * We get the has from version.json and if it has changed
     * then we force reload the window to empty cache
     * @param url
     */
    private _checkVersion(url) {
        // timestamp these requests to invalidate caches
        this._httpService.getFile(url + '?t=' + new Date().getTime())
            .toPromise()
            .then((response: any) => {
                    const hash = response.hash;
                    const hashChanged = this._hasHashChanged(response.hash);
                    if (hashChanged) {
                        localStorage.setItem('buildHash', hash);
                        location.reload(true);
                    }
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    private _hasHashChanged(newHash: string) {
        const currentBuildVersion = localStorage.getItem('buildHash');
        return currentBuildVersion !== newHash;
    }

    checkBuildVersion() {
        const url = './version.json';
        this._checkVersion(url);
    }
}
