import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {FilterSearchService} from '../../../filter-search/shared/filter-search.service';
import {SearchEngineConditionDateComparisonComponent} from '../../shared/search-engine-condition-date-comparison/search-engine-condition-date-comparison.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionValidityFrameService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService,
                private _filterSearchService: FilterSearchService) {
        super(['validityFrame'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.FRAME_VALIDITY'),
            'validityFrame',
            SearchEngineConditionDateComparisonComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            startDate: null,
            endDate: null
        };
        this.uriParams = {
            validityFrame: ''
        };
        const {startDate, endDate} = this._filterSearchService.getDefaultValidityFrame();
        this.params.startDate = startDate;
        this.params.endDate = endDate;
        this.uriParams.validityFrame = `${DateHelperService.toMysql(startDate)};>=<;${DateHelperService.toMysql(endDate)}`;
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.updateUriParamsComparisonDate(this.uriParams, 'validityFrame', this.params);
    }

    convertToParams(): void {
        this.updateParamsComparisonDate(this.uriParams, 'validityFrame', this.params);
    }

    convertToParamsFromApiParams(): void {
      this.updateParamsFromApiParamsComparisonDate(this.apiParams, this.params);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        const startDate: string = DateHelperService.toMysql(this.params.startDate);
        const endDate: string = DateHelperService.toMysql(this.params.endDate);
        this.apiParams.args = {...this.getComparisonArgsObject(startDate, endDate)};
    }

    isValid(fields?: any): boolean {
        return (!this.isUndefined(fields['validityFrame']) &&
            !this.isEmpty(fields['validityFrame']));
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
