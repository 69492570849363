import {Component, OnInit} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';

@Component({
    selector: 'ct-search-engine-condition-gender',
    templateUrl: './search-engine-condition-gender.component.html',
    styleUrls: ['./search-engine-condition-gender.component.scss']
})
export class SearchEngineConditionGenderComponent implements OnInit {
    condition: any;

    constructor(public colorService: ColorService) { }

    ngOnInit() {
    }
}
