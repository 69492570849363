import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '@uirouter/core';
import {Subscription} from 'rxjs';
import {DataSetElement} from '../../shared/data-set-element.model';
import {DataSetCodification} from '../../shared/data-set-codification.model';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {AppLogService} from '../../../../core/app-log/app-log.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {StayDetailService} from '../stay-detail.service';
import {CodificationLabelsModel} from '../../shared/codification-labels.model';

@Component({
    selector: 'ct-stay-detail-diagnostic-prediction',
    templateUrl: './stay-detail-diagnostic-prediction.component.html',
    styleUrls: ['./stay-detail-diagnostic-prediction.component.scss']
})
export class StayDetailDiagnosticPredictionComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    @Input() dataSetElement: DataSetElement;
    @Input() dataSetElementParent: DataSetElement;
    @Input() dataSetCodification: DataSetCodification;
    @Input() codificationLabels: CodificationLabelsModel;
    @Input() diagnostics: any[];
    @Input() predictiveDiagnostics: any[];
    @Input() predictiveActs: any[];
    @Input() isNearAutomationQualityControl: boolean;
    @Input() isTypeRehabilitation: boolean;
    @Input() displayOnlySimplifiedScore: boolean;
    @Input() canDisplayTooltip: boolean;

    @Output() firstPredictiveDiagnosticLoaded: EventEmitter<any> = new EventEmitter<any>();

    tabs: any[] = [];

    canDisplayStaySeverity = false;
    isLoading: boolean;
    activeTabId: number;

    constructor(public $state: StateService,
                private _stayDetailService: StayDetailService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _configurationService: ConfigurationService,
                private _logService: AppLogService,
                private _broadcastService: BroadcastService) {
    }

    ngOnInit() {
        this._initAvailableFeatures();
        this._initTabs();
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailCodificationNearAutomation::cancel':
                        if (res.data &&
                            !res.data.isNearAutomationQualityControlList) {
                            this.isNearAutomationQualityControl = false;
                            this._initTabs();
                        }
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private _initTabs() {
        this.tabs = [
            {
                id: 1,
                name: this._translateService.instant('ACRONYM.DP'),
                codificationLabelIds: [this.codificationLabels.DP, this.codificationLabels.DP_ROOT],
                orderBy: ['-simplifiedScore', '-score'],
                display: !this.isTypeRehabilitation && this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.display')
            },
            {
                id: 50,
                name: this._translateService.instant('ACRONYM.DA'),
                codificationLabelIds: [this.codificationLabels.DA, this.codificationLabels.DA_ROOT],
                orderBy: ['-simplifiedScore', '-score'],
                display: this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.display')
            },
            {
                id: 9,
                name: this._translateService.instant('ACRONYM.MP'),
                codificationLabelIds: [this.codificationLabels.MP],
                orderBy: ['-simplifiedScore', '-score'],
                display: this.isTypeRehabilitation && this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.morbidManifestationDiagnostic.display')
            },
            {
                id: 10,
                name: this._translateService.instant('ACRONYM.AE'),
                codificationLabelIds: [this.codificationLabels.AE],
                orderBy: ['-simplifiedScore', '-score'],
                display: this.isTypeRehabilitation && this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.etiologicalAffectionDiagnostic.display')
            },
            {
                id: 11,
                name: this._translateService.instant('ACRONYM.DR'),
                codificationLabelIds: [this.codificationLabels.DR],
                orderBy: ['-simplifiedScore', '-score'],
                display: !this.isTypeRehabilitation && this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.relatedDiagnostic.display')
            },
            {
                // todo: check act codification label id
                id: 51,
                name: this._translateService.instant('HEALTH.ACTS'),
                codificationLabelIds: [this.codificationLabels.CCAM, this.codificationLabels.CSARR],
                orderBy: ['-simplifiedScore', '-score'],
                display: this.predictiveActs?.length || this.isNearAutomationQualityControl
            }
        ];
        const DPTab = this.tabs.find(tab => tab.id === 1);
        const DATab = this.tabs.find(tab => tab.id === 50);
        const DRTab = this.tabs.find(tab => tab.id === 11);

        if (!this.isTypeRehabilitation) {
            if ((this.$state.params.AIValuation === 'DA' ||
                (!DPTab.display && !DRTab.display)) &&
                DATab.display) {
                this.activeTabId = 50;
            } else if (DPTab.display) {
                this.activeTabId = 1;
            } else if (DRTab.display) {
                this.activeTabId = 11;
            }
        } else {
            this.activeTabId = 9;
        }
    }

    private _initAvailableFeatures() {
        this.canDisplayStaySeverity = !this.isNearAutomationQualityControl &&
            this._translationHelperService.isFeatureAvailable('staySeverity') &&
            this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.severity.display') &&
            this._stayDetailService.isStayMoreThan3NightsOrEras(this.dataSetElementParent);
    }

    orderById(tabs: any[]) {
        return tabs.sort((a, b) => a.id > b.id ? 1 : a.id === b.id ? 0 : -1);
    }

    updateActiveTabId(value: number) {
        this.activeTabId = value;
        switch (value) {
            case 1:
                this._logService.logInfo('Looking at DP predictions');
                break;
            case 11:
                this._logService.logInfo('Looking at DR predictions');
                break;
            case 50:
                this._logService.logInfo('Looking at DA predictions');
                break;
            case 51:
                this._logService.logInfo('Looking at ACT predictions');
                break;
            case 100:
                this._logService.logInfo('Looking at Severity');
                break;
        }
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
