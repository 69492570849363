import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';

export enum DocumentTypeValueSource {
    INFERRED = 'inferred',
    STATED = 'stated'
}

export interface DocumentTypeValue {
    documentTypeId: number;
    documentTypeSlug: string;
    source: string;
}

@Component({
    selector: 'ct-document-type-search',
    templateUrl: './document-type-search.component.html',
    styleUrls: ['./document-type-search.component.scss']
})
export class DocumentTypeSearchComponent implements OnInit {
    @ViewChild('documentTypeInput', { static: true }) documentTypeInput: ElementRef<HTMLInputElement>;

    private _param: DocumentTypeValue[];

    @Input() documentTypes: any[] = [];
    @Input() set param(newParam: DocumentTypeValue[]) {
        if (newParam && this._param !== newParam) {
            this._param = newParam;
            this._initSelectedDocumentTypes();
        }
    }

    @Output() paramChange: EventEmitter<DocumentTypeValue[]> = new EventEmitter<DocumentTypeValue[]>();

    selectedDocumentTypes: any[] = [];
    searchedDocumentTypes$: Observable<any[]>;
    formControl: FormControl = new FormControl();

    constructor(private _translateService: TranslateService) { }

    ngOnInit(): void {
        this.searchedDocumentTypes$ =
            this.formControl.valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._getFilteredDocumentTypes(value)),
                    map(value => _.orderBy(value, ['isInferredDocumentType', documentType => documentType.name.toLowerCase()], ['asc', 'asc']))); // Display inferred doc types first
        this._initSelectedDocumentTypes();
    }

    private _getFilteredDocumentTypes(query: string): any[] {
        if (this.documentTypes) {
            const documentTypes = this.documentTypes
                .map(documentType => ({
                    ...documentType,
                    name: documentType.name || documentType.slug || ''
                }));
            if (query?.length > 0) {
                return documentTypes.filter(documentType => documentType.name.toLowerCase().includes(query.toLowerCase()));
            }
            return documentTypes;
        }
        return [];
    }

    private _initSelectedDocumentTypes(): void {
        if (this.documentTypes &&
            this._param) {
            this.selectedDocumentTypes = this.documentTypes.filter(documentType => {
                const documentTypeFromParam = this._param.find(el => el.documentTypeId === documentType.id &&
                    el.source === this._getDocumentTypeValueSource(documentType.isInferredDocumentType));
                return !!documentTypeFromParam;
            });
        }
    }

    private _getDocumentTypeValueSource(isInferredDocumentType: boolean): DocumentTypeValueSource {
        return isInferredDocumentType ? DocumentTypeValueSource.INFERRED : DocumentTypeValueSource.STATED;
    }

    private _mapToDocumentTypeValues(): DocumentTypeValue[] {
        if (this.selectedDocumentTypes) {
            return this.selectedDocumentTypes.map(el => ({
                documentTypeId: el.id,
                documentTypeSlug: el.slug,
                source: this._getDocumentTypeValueSource(el.isInferredDocumentType)
            }));
        }
        return [];
    }

    /**
     * Add the document type to the chips list (not if already added)
     * @param event
     */
    selectDocumentType(event: any): void {
        if (event) {
            if (!this.selectedDocumentTypes) {
                this.selectedDocumentTypes = [];
            }
            const searchedDocumentType = event.option.value || {};
            const documentType = this.selectedDocumentTypes.find(el => el.id === searchedDocumentType.id);
            if (!documentType) {
                if (searchedDocumentType !== {}) {
                    this.selectedDocumentTypes.push(searchedDocumentType);
                }
                // We update the service params
                this.paramChange.emit(this._mapToDocumentTypeValues());
            }
            this.documentTypeInput.nativeElement.value = '';
            this.formControl.setValue(null);
        }
    }

    removeChip(documentTypeToRemove: any): void {
        if (this.selectedDocumentTypes &&
            documentTypeToRemove) {
            this.selectedDocumentTypes = this.selectedDocumentTypes.filter(el => el.id !== documentTypeToRemove.id);
            // We update the service params
            this.paramChange.emit(this._mapToDocumentTypeValues());
        }
    }

    getTooltip(isInferredDocumentType: boolean): string {
        return isInferredDocumentType ? this._translateService.instant('TOOLTIP.INFERRED_DOCUMENT_TYPE') : '';
    }

    trackByFn(index: number, item: any): number | null {
        return item ? item.id : null;
    }
}
