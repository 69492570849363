import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {DiagnosticService} from '../../../modules/diagnostic/diagnostic.service';
import {TranslationHelperService} from '../../../core/services/translation.helper.service';
import {DiagnosticSearchService} from './diagnostic-search.service';

@Component({
    selector: 'ct-diagnostic-search',
    templateUrl: './diagnostic-search.component.html',
    styleUrls: ['./diagnostic-search.component.scss']
})
export class DiagnosticSearchComponent implements OnInit {
    private _paramCopy: any[];

    // param is an array of slugs
    @Input() param: any[];
    @Input() selectable: boolean;
    @Input() placeholder: string;
    // True if we want paramChange to emit diagnostic object
    @Input() returnObject: boolean;
    @Output() paramChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selection: EventEmitter<any> = new EventEmitter<any>();
    @Output() enterKeyPressed: EventEmitter<boolean> = new EventEmitter<boolean>();

    selectedDiagnostics: any[] = [];
    searchedDiagnostics: Observable<any>;
    formControl = new FormControl();
    isLoading: boolean;
    isDiagnosticSeverityLevelActivated: boolean;

    @ViewChild('diagnosticInput', { static: true }) diagnosticInput: ElementRef<HTMLInputElement>;

    constructor(public diagnosticSearchService: DiagnosticSearchService,
                private _diagnosticService: DiagnosticService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _broadcastService: BroadcastService) { }

    ngOnInit() {
        this.isDiagnosticSeverityLevelActivated = this._translationHelperService.isFeatureAvailable('diagnosticSeverityLevel');
        if (!this.placeholder) {
            this.placeholder = this._translateService.instant('HEALTH.DIAGNOSTICS');
        }
        this._init();
        this.searchedDiagnostics =
            this.formControl.valueChanges
                .pipe(startWith(''),
                    debounceTime(200),
                    switchMap(value => {
                        this.isLoading = true;
                        if (value && value.length > 0) {
                            return this._search$(value);
                        } else {
                            this.isLoading = false;
                            return EMPTY;
                        }
                    }));
        this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'diagnosticNoteListSearch::resetDiagnosticSlug':
                    case 'filterSearchListDisplay::resetDiagnosticSlug':
                        this.selectedDiagnostics = [];
                        break;
                    case 'diagnosticSearch::updateParam':
                        this._init();
                        break;
                    default:
                }
            });
    }

    private _init() {
        // To initialize the input when we load with uriParams
        if (this.param) {
            this._paramCopy = _.cloneDeep(this.param);
            this.selectedDiagnostics = this.param.map(slug => ({slug}));
            this._updateServiceParams();
        }
        this._initSelectedDiagnosticsTooltip();
    }

    private _initSelectedDiagnosticsTooltip() {
        if (this.selectedDiagnostics.length > 0) {
            const promises = [];
            const parentSlugs = [];
            const selectedDiagnosticsSlug = _.cloneDeep(this.selectedDiagnostics).map(el => el.slug);
            this.selectedDiagnostics.forEach(obj => {
                // Because in case we have a parent we would have a duplication of the children
                const currentParentSlug = obj.slug.split('.')[0];
                let query = '';
                // If the current parent slug is not a children of a slug
                if (!selectedDiagnosticsSlug.includes(currentParentSlug)) {
                    query = obj.slug;
                    // If the current parent slug has not already been pushed
                } else if (!parentSlugs.includes(currentParentSlug)) {
                    query = currentParentSlug;
                    parentSlugs.push(currentParentSlug);
                }
                if (query) {
                    const promise = this._search$(query).toPromise();
                    promises.push(promise);
                }
            });
            Promise.all(promises)
                .then(res => {
                    this._updateSelectedDiagnostics(res);
                });
        }
    }

    private _updateSelectedDiagnostics(res: any[][]) {
        if (res) {
            let diagnostics = [];
            // res is an array of arrays
            res.forEach(array => diagnostics = diagnostics.concat(array));
            // If this.param exists then it means we need to display only the diagnostics that are
            // present in this.param
            if (this._paramCopy) {
                diagnostics = diagnostics.filter(el => this._paramCopy.includes(el.slug));
            }
            this.selectedDiagnostics.length = 0;
            [].push.apply(this.selectedDiagnostics, diagnostics);
        }
    }

    private _updateServiceParams() {
        if (!this.returnObject) {
            // We update the service params
            this.paramChange
                .emit(this.selectedDiagnostics.map(sa => sa.slug));
        } else {
            this.paramChange
                .emit(this.selectedDiagnostics);
        }
    }

    /**
     * Add the diagnostic to the chips list (not if already added)
     * and add the children if the selected diagnostic is a parent
     * @param event
     */
    selectDiagnostic(event: any) {
        if (event) {
            const selectedDiagnostic = event.option.value;
            const diagnostic = this.selectedDiagnostics.find(a => a.slug === selectedDiagnostic.slug);
            if (!diagnostic) {
                // If it's a parent we need to load it's children and add them to the chip list
                if (selectedDiagnostic.id === selectedDiagnostic.parentId || !selectedDiagnostic.parentId) {
                    let withSlugParam = true;
                    if (selectedDiagnostic.id === -1) {
                        withSlugParam = false;
                    }
                    this._diagnosticService
                        .loadDiagnosticsWithES$(selectedDiagnostic.slug, withSlugParam)
                        .toPromise()
                        .then(data => {
                            this.selectedDiagnostics = this.selectedDiagnostics.concat(data.data);
                            this._updateServiceParams();
                        });
                } else {
                    // Else we just push the diagnostic
                    this.selectedDiagnostics.push(selectedDiagnostic);
                    this._updateServiceParams();
                }
            }

            this.diagnosticInput.nativeElement.value = '';
            this.formControl.setValue(null);

            if (this.selectable) {
                this.selection.emit({isRemoval: false, value: this.selectedDiagnostics});
            }
        }
    }

    private _search$(query: string): Observable<any> {
        return this._diagnosticService
            .loadDiagnosticsWithES$(query)
            .pipe(map(res => {
                this.isLoading = false;
                const data = res.data;
                // Add fake one for get all result
                data.unshift({ id : -1, codifiable: true,
                                    description: '',
                                    level: 0,
                                    name: '',
                                    parentId: null,
                                    searchAggregate: query,
                                    slug: query});
                return data;
            }));
    }

    removeChip(diagnosticToRemove: any) {
        if (diagnosticToRemove) {
            this.selectedDiagnostics = this.selectedDiagnostics.filter(diagnostic => diagnostic.slug !== diagnosticToRemove.slug);
            this._updateServiceParams();
            this.selection.emit({isRemoval: true, value: diagnosticToRemove});
        }
    }
}
