import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StayDetailCommentComponent } from './stay-detail-comment.component';
import {TranslateModule} from '@ngx-translate/core';
import {FeatureToggleDirectiveModule} from '../../../../shared/directives/feature-toggle/feature-toggle.directive.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        StayDetailCommentComponent
    ],
    exports: [
        StayDetailCommentComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FeatureToggleDirectiveModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        ReactiveFormsModule
    ]
})
export class StayDetailCommentModule { }
