import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PeriodSelectComponent} from './period-select.component';
import {FieldDisplayModule} from '../../../../shared/components/field-display/field-display.module';
import {TabListModule} from '../../../../shared/components/tab-list/tab-list.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        PeriodSelectComponent
    ],
    imports: [
        CommonModule,
        FieldDisplayModule,
        TabListModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatTooltipModule,
        FormsModule,
    ],
    exports: [
        PeriodSelectComponent
    ],
})
export class PeriodSelectModule {}
