import {Component, Inject, OnInit} from '@angular/core';
import {AlertService} from '../../../alert/alert.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FilterSearch} from '../filter-search.model';
import {User} from '../../../user/user.model';
import {UserService} from '../../../user/user.service';
import {Alert} from '../../../alert/alert.model';

@Component({
    selector: 'ct-filter-search-subscribe-dialog',
    templateUrl: './filter-search-subscribe-dialog.component.html',
    styleUrls: ['./filter-search-subscribe-dialog.component.scss']
})
export class FilterSearchSubscribeDialogComponent implements OnInit {
    private _currentUser: any = {};
    private _allUsersEmails: string[] = [];
    private _alertName: string;

    filterSearch: FilterSearch;
    isUnsubscription: boolean;
    isCurrentUserSubscribed: boolean;
    isEdition: boolean;
    isLoading: boolean;
    titleText = '';
    sendEmailToText = '';
    emails: string[] = [];
    notValidEmailsIndexes: number[] = [];
    emailsNotValid = false;
    usersDb: User[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<FilterSearchSubscribeDialogComponent>,
                private _translateService: TranslateService,
                private _userService: UserService,
                private _alertService: AlertService) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.isUnsubscription = this.data.isUnsubscription;
        this.isCurrentUserSubscribed = this.data.isCurrentUserSubscribed;
        this.isEdition = this.data.isEdition;
        this.filterSearch = this.data.filterSearch;
        this._alertName = this.filterSearch ? `cohort_new_patient_${this.filterSearch.id}` : '';
        this._currentUser = JSON.parse(localStorage.getItem('user')) || {};
        this._initTitleText();
        this._initSendEmailText();
        Promise.all([
            this._loadUsers()
        ]).then(() => {
            this.isLoading = false;
        });
        if (this.isEdition) {
            this._initEmailInputsForEdition();
        }
    }
    private _initTitleText(): void {
        if (!this.isEdition) {
            if (this.filterSearch?.name) {
                const key = !this.isUnsubscription ? 'FILTER_SEARCH.SUBSCRIBE_DIALOG.SUBSCRIBE_CONFIRMATION_TEXT' : 'FILTER_SEARCH.SUBSCRIBE_DIALOG.UNSUBSCRIBE_CONFIRMATION_TEXT';
                this.titleText = this._translateService.instant(key, {filterSearchName: this.filterSearch.name});
            } else {
                this.titleText = '';
            }
        } else {
            this.titleText = this._translateService.instant('FILTER_SEARCH.SUBSCRIBE_DIALOG.EDIT_ALERT_SUBSCRIBERS');
        }
    }

    private _initSendEmailText(): void {
        if (this.isCurrentUserSubscribed &&
            this._currentUser?.email) {
            const sendEmailToTextKey = !this.isEdition ? 'FILTER_SEARCH.SUBSCRIBE_DIALOG.SEND_EMAIL_TO' : 'FILTER_SEARCH.SUBSCRIBE_DIALOG.SEND_EMAIL_TO_EDITION';
            this.sendEmailToText = this._translateService.instant(sendEmailToTextKey, {mailAddress: this._currentUser.email});
        } else {
            this.sendEmailToText = '';
        }
    }

    private _initEmailInputsForEdition(): void {
        if (this.filterSearch?.alertSubscribers) {
            const currentUser = JSON.parse(localStorage.getItem('user')) || {};
            this.emails = this.filterSearch.alertSubscribers
                .filter(user => currentUser.id !== user.id)
                .map(el => el.email);
        }
    }

    private async _loadUsers(): Promise<void> {
        try {
            this.usersDb = await this._userService.loadUsers({
                include: 'alerts'
            });
            this._allUsersEmails = this.usersDb?.map(el => el.email);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _getUsersEmailForSubscription(): string[] {
        const usersEmail = [];
        if (this.emails) {
            if (this.isCurrentUserSubscribed &&
                this._currentUser?.email) {
                // The user can update the subscribers even if he is not subscribed himself
                // so, we don't send its email if he is not
                usersEmail.push(this._currentUser.email);
            }
            this.emails.forEach(email => {
                if (email !== '') {
                    usersEmail.push(email);
                }
            });
        }
        return usersEmail;
    }

    private async _subscribeToAlert(unsubscribe?: boolean): Promise<void> {
        try {
            if (this.filterSearch) {
                this.isLoading = true;
                if (!unsubscribe) {
                    await this._alertService.subscribeToAlert(this._alertName, true, this._getUsersEmailForSubscription());
                } else {
                    await this._alertService.unsubscribeFromAlert(null, this._alertName);
                }
                this.isLoading = false;
                this.dialogRef.close(true);
            }
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    async assignUser(user: User): Promise<void> {
        try {
            this.isLoading = true;
            const alert =  await this._alertService.updateAlertSubscribers(this._alertName, {'userId': user.id});
            this._upsertUserSubscription(user, alert);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    private _upsertUserSubscription(user: User, alert: Alert) {
        const userIndex = this.usersDb.findIndex(userDb => userDb.id === user.id);

        if (userIndex !== -1) {
            if (!this.isUserSubscribed(user)) {
                this.usersDb[userIndex].alerts.push(alert);
            } else  {
                this.usersDb[userIndex].alerts = this.usersDb[userIndex].alerts.filter(alertUser => alertUser.id !== alert.id);
            }
        }
    }

    isUserSubscribed(user: User) {
        return user?.alerts?.some(alert => {
            const alertId = alert.name.split('_').pop();
            return alertId === this.filterSearch.id.toString();
        });
    }

    validate(): void {
        this._subscribeToAlert(this.isUnsubscription);
    }
}
