import {Injectable} from '@angular/core';
import {HttpService} from '../../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DataSetElementHealthDocumentApiService {
    private _BASE_URL = 'data-set-element';

    constructor(private _http: HttpService) {}

    getAll(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/health-document`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    get(dataSetElementId: number, healthDocumentId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/health-document/${healthDocumentId}`, {params: urlRequestParams});
    }

    attach(dataSetElementId: number, healthDocumentId: number, body: any) {
        return this._http
            .post(`${this._BASE_URL}/${dataSetElementId}/health-document/${healthDocumentId}/attach`, body)
            .pipe(map(res => res.data));
    }

    detach(dataSetElementId: number, healthDocumentId: number, body: any) {
        return this._http
            .delete(`${this._BASE_URL}/${dataSetElementId}/health-document/${healthDocumentId}/detach`, body)
            .pipe(map(res => res.data));
    }
}
