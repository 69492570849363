import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionQueryComponent} from './search-engine-condition-query.component';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

export interface ApiValue {
    qMust: string;
    qNot: string;
    qOpt: string;
    documentTypeIds: number[];
    ignoreNegation: boolean;
}

@Injectable()
export class SearchEngineConditionQueryService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.QUERY'),
            'documentContent',
            SearchEngineConditionQueryComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            query: [{
                q: '',
                qMust: '',
                qNot: '',
                qOpt: '',
                qDocumentTypeId: [],
                ignoreNegated: true
            }]
        };
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.query = this._getParamsFromValues();
    }

    private _getParamsFromValues(): any[] {
        return this.apiParams.args.values?.map(value => ({
            qMust: value.qMust,
            qNot: value.qNot,
            qOpt: value.qOpt,
            qDocumentTypeId: value.documentTypeIds !== null ? value.documentTypeIds : [],
            ignoreNegated: value.ignoreNegation
        })) || [];
    }

    private _getValues(): ApiValue[] {
        return this.params.query?.map(query => ({
                qMust: query.qMust,
                qNot: query.qNot,
                qOpt: query.qOpt,
                documentTypeIds: query.qDocumentTypeId,
                ignoreNegation: query.ignoreNegated
            })) || [];
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this._getValues()};
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
