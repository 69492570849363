<div class="display-flex align-center">
    <span class="margin-right-10"><b>{{'DATA_SET.CODIFICATION.SEARCH.DOCUMENT_PRESENT' | translate}}</b></span>
    <ct-search-including-excluding-operator class="margin-right-10"
                                            [(param)]="condition.service.params.documentTypePresentOperator"></ct-search-including-excluding-operator>
    <ct-search-engine-condition-document-type-select class="display-inline-block margin-right-10"
                                                     [condition]="condition"
                                                     [paramKey]="'documentTypePresentValues'"
                                                     [toggleAllMapCallback]="toggleAllMapCallback"
                                                     [documentTypes]="mixedDocumentTypes"
                                                     [isLoading]="isLoading"></ct-search-engine-condition-document-type-select>
    <ct-document-type-search class="flex-grow-1"
                             *ngIf="!isLoading && mixedDocumentTypes"
                             [(param)]="condition.service.params.documentTypePresentValues"
                             [documentTypes]="mixedDocumentTypes"></ct-document-type-search>
</div>

<div class="display-flex align-center">
    <span class="margin-right-10"><b>{{'DATA_SET.CODIFICATION.SEARCH.DOCUMENT_MISSING' | translate}}</b></span>
    <ct-search-including-excluding-operator class="margin-right-10"
                                            [(param)]="condition.service.params.documentTypeMissingOperator"
                                            [isExcluding]="true"></ct-search-including-excluding-operator>
    <ct-search-engine-condition-document-type-select class="display-inline-block margin-right-10"
                                                     [condition]="condition"
                                                     [paramKey]="'documentTypeMissingValues'"
                                                     [toggleAllMapCallback]="toggleAllMapCallback"
                                                     [documentTypes]="mixedDocumentTypes"
                                                     [isLoading]="isLoading"></ct-search-engine-condition-document-type-select>
    <ct-document-type-search class="flex-grow-1"
                             *ngIf="!isLoading && mixedDocumentTypes"
                             [(param)]="condition.service.params.documentTypeMissingValues"
                             [documentTypes]="mixedDocumentTypes"></ct-document-type-search>
</div>
