import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionCodificationTypeComponent} from './search-engine-condition-codification-type.component';
import {Injectable} from '@angular/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {CodificationLabelEnum} from "../../../stay/shared/codification-label-enum";

@Injectable()
export class SearchEngineConditionCodificationTypeService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.CODIFICATION_TYPE'),
            'aiCodedStatus',
            SearchEngineConditionCodificationTypeComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {aiCodedStatus: []};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.aiCodedStatus = this.apiParams.args.values;
    }

    private _addAiCodedStatusValue(): void {
        this.apiParams.args.values.push({
            type: this.slug,
            args: {values: this.params.aiCodedStatus}
        });
    }

    private _fillValues(): void {
        const DP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP);
        if (this.params.aiCodedStatus.includes('-1')) {
            this.apiParams.args.values.push({
                type: 'codificationLabelPresence',
                args: {codificationLabel: [DP], operator: 'NOR'}
            });
        } else if (this.params.aiCodedStatus.includes('0')) {
            this.apiParams.args.values.push({
                type: 'codificationLabelPresence',
                args: {codificationLabel: [DP], operator: 'OR'}
            });
            // If we have in addition to "stay coded without AI", "stay coded by AI"
            // AND "stay coded by near automation" then we don't send aiCodedStatus
            if (this.params.aiCodedStatus.length !== 3 ||
                !this.params.aiCodedStatus.includes('1') ||
                !this.params.aiCodedStatus.includes('2')) {
                this._addAiCodedStatusValue();
            }
        } else {
            this._addAiCodedStatusValue();
        }
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: []};
        this._fillValues();
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
