<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="add-feedback-dialog-content">
    <form [formGroup]="feedbackForm">
        <h1 mat-dialog-title>{{'FEEDBACK.ADD_DIALOG.TITLE' |
            translate}}</h1>

        <mat-dialog-content>
            <mat-form-field class="full-width">
                <input matInput
                       required
                       type="text"
                       name="'object'"
                       formControlName="object"
                       [placeholder]="'FEEDBACK.ADD_DIALOG.OBJECT' | translate">
            </mat-form-field>

            <mat-form-field class="full-width">
                    <textarea matInput
                              required
                              cdkTextareaAutosize
                              type="text"
                              name="'message'"
                              formControlName="message"
                              [cdkAutosizeMinRows]="6"
                              [placeholder]="'FEEDBACK.ADD_DIALOG.CONTENT' | translate">
                    </textarea>
            </mat-form-field>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button
                    mat-dialog-close
                    type="button"
                    class="color-blue-on-white">{{'BUTTON.CANCEL' | translate | uppercase}}
            </button>

            <button mat-raised-button
                    type="submit"
                    color="primary"
                    [disabled]="!feedbackForm.valid || isLoading"
                    (click)="validate()">{{'BUTTON.VALIDATE' | translate | uppercase}}
            </button>
        </mat-dialog-actions>
    </form>
</div>
