import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailDiagnosticPredictionComponent} from './stay-detail-diagnostic-prediction.component';
import {StayDetailDiagnosticPredictionListComponent} from './list/stay-detail-diagnostic-prediction-list.component';
import {StayDetailDiagnosticPredictionListMenuComponent} from './list/menu/stay-detail-diagnostic-prediction-list-menu.component';
import {StayDetailSeverityModule} from '../severity/stay-detail-severity.module';
import {TabListModule} from '../../../../shared/components/tab-list/tab-list.module';
import {TooltipModule} from '../../../../shared/components/tooltip/tooltip.module';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {StayDetailDiagnosticPredictionHeaderModule} from './header/stay-detail-diagnostic-prediction-header.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {StayDetailDiagnosticPredictionElementModule} from './element/stay-detail-diagnostic-prediction-element.module';
import {StayDetailActPredictionElementModule} from '../act-prediction/element/stay-detail-act-prediction-element.module';
import {StayDetailActPredictionListModule} from '../act-prediction/list/stay-detail-act-prediction-list.module';
@NgModule({
    declarations: [
        StayDetailDiagnosticPredictionComponent,
        StayDetailDiagnosticPredictionListComponent,
        StayDetailDiagnosticPredictionListMenuComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        TabListModule,
        StayDetailSeverityModule,
        StayDetailDiagnosticPredictionHeaderModule,
        StayDetailDiagnosticPredictionElementModule,
        TooltipModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatMenuModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        NgbTooltipModule,
        StayDetailActPredictionElementModule,
        StayDetailActPredictionListModule,
    ],
    exports: [
        StayDetailDiagnosticPredictionComponent,
        StayDetailDiagnosticPredictionListComponent,
        StayDetailDiagnosticPredictionListMenuComponent,
        StayDetailDiagnosticPredictionHeaderModule
    ],
})
export class StayDetailDiagnosticPredictionModule {}
