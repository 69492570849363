import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EstablishmentApiService {
    private _BASE_URL = 'establishment';

    constructor(private _http: HttpService) {
    }

    getAll(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    addUserEstblishments(userId: number, body: number[]) {
        return this._http
            .post(`user/${userId}/establishment/add`, {establishmentIds: body})
            .pipe(map(res => res.data));
    }

    deleteUserEstblishments(userId: number, body: number[]) {
        return this._http
            .delete(`user/${userId}/establishment`, {establishmentIds: body})
            .pipe(map(res => res.data));
    }
}
