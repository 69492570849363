import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {DiagnosticNoteDisplayDialogComponent} from './diagnostic-note-display-dialog.component';
import {FileDisplayModule} from '../../../../shared/components/file-display/file-display.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        DiagnosticNoteDisplayDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FileDisplayModule,
        MatTabsModule,
        MatToolbarModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule
    ]
})
export class DiagnosticNoteDisplayDialogModule {}
