<mat-form-field class="width-190">
    <mat-select #multipleSelect
                multiple
                [placeholder]="placeholder | translate"
                [(ngModel)]="condition?.service?.params[paramKey]"
                [disabled]="data?.length === 0">
        <mat-option *ngFor="let el of data"
                    [value]="el.id">
            {{ el.level + " "}} {{el.text | translate}}
        </mat-option>
    </mat-select>
</mat-form-field>
