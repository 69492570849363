import {Component, Input, OnInit} from '@angular/core';
import {MedicationService} from './medication.service';

@Component({
    selector: 'ct-medication',
    templateUrl: './medication.component.html',
    styleUrls: ['./medication.component.scss']
})
export class MedicationComponent implements OnInit {
    @Input() id: string;

    constructor(private _medicationService: MedicationService) { }

    ngOnInit() {
    }

    openMedicationDetailDialog(event) {
        event.stopPropagation();
        this._medicationService.openMedicationDetailDialog(Number.parseFloat(this.id));
    }

}
