import {Component, Input, OnInit} from '@angular/core';
import {UserService} from 'src/app/modules/user/user.service';
@Component({
    selector: 'ct-search-engine-condition-user-assign',
    templateUrl: './search-engine-condition-user-assign.component.html',
    styleUrls: ['./search-engine-condition-user-assign.component.scss']
})
export class SearchEngineConditionUserAssignComponent implements OnInit {
    @Input() condition: any;

    userList: any[];
    isLoading: boolean;
    toggleAllMapCallback = (element: any) => element?.id;

    constructor(private userService: UserService) { }

   async ngOnInit() {
        await this._loadAllUsers();
   }

    private _mapToGenericData(users: any[]): any[] {
        if (users) {
            return users.map(user => ({
                ...user,
                text: user.firstName + ' ' + user.lastName,
                value: this.toggleAllMapCallback(user)
            }));
        }
        return [];
    }

    private async _loadAllUsers(): Promise<void> {
        try {
            this.isLoading = true;
            const res = await this.userService.loadUsers();
            this.userList = this._mapToGenericData(res);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    compareWithCallback(objectFromOption: any, objectFromParam: any): boolean {
        return objectFromOption?.id === objectFromParam?.id;
    }
}
