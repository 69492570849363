import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiCodedIndicatorComponent} from './ai-coded-indicator.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        AiCodedIndicatorComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        AiCodedIndicatorComponent
    ]
})
export class AiCodedIndicatorModule {}
