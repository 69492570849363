import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailJustificationComponent} from './stay-detail-justification.component';
import {FormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {PipesModule} from '../../../../shared/pipes/pipes.module';
import {TabListModule} from '../../../../shared/components/tab-list/tab-list.module';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {DocumentHighlightPreviewStructuredModule} from '../../../../shared/components/document-highlight-preview/structured/document-highlight-preview-structured.module';
import {DocumentHighlightPreviewModule} from '../../../../shared/components/document-highlight-preview/document-highlight-preview.module';

@NgModule({
    declarations: [
        StayDetailJustificationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),
        DocumentHighlightPreviewStructuredModule,
        PipesModule,
        TabListModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatFormFieldModule,
        MatCardModule,
        MatDividerModule,
        MatProgressBarModule,
        MatInputModule,
        DocumentHighlightPreviewModule
    ],
    exports: [
        StayDetailJustificationComponent
    ],
})
export class StayDetailJustificationModule {}
