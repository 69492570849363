import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'ct-stay-detail-diagnostic-prediction-header',
    templateUrl: './stay-detail-diagnostic-prediction-header.component.html',
    styleUrls: ['./stay-detail-diagnostic-prediction-header.component.scss']
})
export class StayDetailDiagnosticPredictionHeaderComponent implements OnInit {
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

    @Input() dataSetElement: DataSetElement;

    canCodify: boolean;
    isLoading: boolean;

    constructor(private _dataSetElementService: DataSetElementService,
                private _broadcastService: BroadcastService) { }

    ngOnInit(): void {
        const currentUser = JSON.parse(localStorage.getItem('user')) || null;
        if (currentUser && currentUser.roles) {
            const roles = currentUser.roles.filter(role => 'admin' === role.name);
            this.canCodify = roles.length > 0;
        }
    }

    openMenu(event: any): void {
        if (event) {
            event.stopPropagation();
        }
        this.menuTrigger.openMenu();
    }

    async codifyDataSetElement(): Promise<void> {
        try {
            this.isLoading = true;
            const newPredictiveDiagnostics = await this._dataSetElementService
                .codifyDataSetElement(this.dataSetElement.id);
            // To update prediction diagnostics
            this.dataSetElement.dataSetElementDiagnosticPredictive.length = 0;
            [].push.apply(this.dataSetElement.dataSetElementDiagnosticPredictive, newPredictiveDiagnostics);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    async groupingDataSetElement(): Promise<void> {
        try {
            this.isLoading = true;
            await this._dataSetElementService.calculateGrouping(this.dataSetElement.id, false);
            await this._dataSetElementService.calculateGrouping(this.dataSetElement.id, true);
            this._broadcastService.send('stay::grouping', {success: true, reload: true});
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }
}
