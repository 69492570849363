import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionStringValueComponent} from '../../shared/search-engine-condition-string-value/search-engine-condition-string-value.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionNameService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'name';
    placeholder = 'FILTER_SEARCH.LIST.SEARCH.NAME';

    constructor(private _translateService: TranslateService) {
        super(['name', 'nameDashboard', 'nameVisualization'],
            _translateService.instant('FILTER_SEARCH.LIST.SEARCH.NAME'),
            'name',
            SearchEngineConditionStringValueComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {name: '', nameDashboard: '', nameVisualization: ''};
        this.uriParams = {name: '', nameDashboard: '', nameVisualization: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.name = this.apiParams.args.value;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.isAdvanced) {
            this.apiParams.args = {value: this.params.name};
        }
    }

    isValid(fields: any): boolean {
        if (!this.isAdvanced) {
            return true;
        }
        return !this.isUndefined(fields['name']) &&
            !this.isEmpty(fields['name']);
    }

    isValidApiParams(args: any): boolean {
        if (!this.isAdvanced) {
            return true;
        }
        return !!args?.value;
    }
}
