import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';
import {Configuration} from '../../../modules/configuration/configuration.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationApiService {
    private _BASE_URL = 'configuration';

    constructor(private _http: HttpService) { }

    getAll(): Observable<Configuration[]> {
        return this._http
            .get(this._BASE_URL)
            .pipe(map(res => res.data));
    }

    get(configurationId: number, urlRequestParams?: any): Observable<Configuration> {
        return this._http
            .get(`${this._BASE_URL}/${configurationId}`, {params: urlRequestParams});
    }

    getHasMailServerConfiguration() {
        return this._http
            .get(`${this._BASE_URL}/mail-server`);
    }

    getLanguageConfiguration() {
        return this._http
            .get(`${this._BASE_URL}/language`);
    }

    update(configurationId: number, body: any, urlRequestParams?: any) {
        return this._http
            .patch(`${this._BASE_URL}/${configurationId}`, body, {params: urlRequestParams});
    }

    delete(configurationId: number) {
        return this._http
            .delete(`${this._BASE_URL}/${configurationId}`);
    }

    resetAll(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/resetAll`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    reset(configurationId: number) {
        return this._http
            .get(`${this._BASE_URL}/reset/${configurationId}`);
    }
}
