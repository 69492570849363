import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {TabListComponent} from '../tab-list.component';

@Component({
    selector: 'ct-tab-list-item',
    templateUrl: './tab-list-item.component.html',
    styleUrls: ['./tab-list-item.component.scss']
})
export class TabListItemComponent implements OnInit, OnChanges {
    @Input() id: number;
    @Input() label: string;
    @Input() canDisplayBadge = false;
    @Input() badgeValue: number;
    @Input() tooltipTemplate: TemplateRef<any>;

    constructor(public tabList: TabListComponent) { }

    ngOnInit() {
        if (!this.id) {
            this.id = Math.floor(Math.random() * 1000) + 1;
        }

        const tabToAdd = {
            id: this.id,
            label: this.label.toString(),
            canDisplayBadge: this.canDisplayBadge,
            badgeValue: this.badgeValue,
            tooltipTemplate: this.tooltipTemplate
        };
        this.tabList.addTab(tabToAdd);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes &&
            !changes.id &&
            changes.badgeValue &&
            changes.badgeValue.currentValue !== -1) {
            const newTab = {
                badgeValue: changes.badgeValue.currentValue
            };
            this.tabList.updateTabData(this.id, null, newTab);
        }
    }
}
