<input #diagnosticInput
       matInput
       class="autocomplete-input"
       [placeholder]="diagnosticsToDisplay?.length === 0 ? ('STUFF.NONE_MALE' | translate) : addPlaceholder"
       [formControl]="formControl"
       [matAutocomplete]="auto"
       [matChipInputFor]="matChipList"
       [disabled]="isDisabled">

<span ctCustomHtmlAttribute
      [htmlAttributeName]="'cypress-id'"
      [htmlAttributeValue]="cypressIdValue"
      *ngIf="diagnosticsToDisplay?.length === 0"></span>

<mat-progress-bar mode="indeterminate"
                  class="autocomplete-progress-bar"
                  *ngIf="isAutocompleteLoading"></mat-progress-bar>

<mat-autocomplete #auto="matAutocomplete"
                  [panelWidth]="'auto'"
                  [ngClass]="{'visibility-hidden': diagnosticsToDisplay?.length}"
                  [class]="'codification-diagnostic-add-autocomplete'"
                  (optionSelected)="selectDiagnostic($event)">
    <mat-option *ngFor="let searchedDiagnostic of searchedDiagnostics | async; index as i"
                [id]="i.toString()"
                [ngClass]="{'excluded': searchedDiagnostic.excluded}"
                [value]="searchedDiagnostic"
                [ngbTooltip]="getTooltipText(searchedDiagnostic, i)"
                [container]="'body'"
                tooltipClass="ngb-tooltip"
                placement="top">
        {{diagnosticSearchService.getDisplayText(searchedDiagnostic, isDiagnosticSeverityLevelActivated)}}
    </mat-option>
</mat-autocomplete>
