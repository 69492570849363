<div class="row">
    <div class="col no-padding">
        <!--Contains button and menu-->
        <ct-stay-detail-diagnostic-prediction-list-menu
            *ngIf="canDisplayPredictionListMenu"
            [ngStyle]="{'top': predictionListMenuStyle.top, 'right': predictionListMenuStyle.right}"
            [codificationLabelIds]="codificationLabelIds"
            [options]="menuOptions"></ct-stay-detail-diagnostic-prediction-list-menu>

        <div class="row">
            <div class="col no-padding">
                <div class="predictions-block"
                     *ngIf="predictiveDiagnosticsToDisplay && predictiveDiagnosticsToDisplay.length > 0"
                     [ngStyle]="{'max-height': predictionListMaxHeight}">
                    <div class="row"
                         *ngFor="let predictiveDiagnostic of orderPredictiveDiagnosticsToDisplay(predictiveDiagnosticsToDisplay); index as i; first as isFirst; last as isLast">
                        <div class="col no-padding hover-prediction">
                            <mat-progress-bar
                                *ngIf="predictiveDiagnostic.diagnostic.isLoading"
                                mode="indeterminate"
                            ></mat-progress-bar>

                            <div [ngClass]="{'parent': canDisplayGroupParentStyle()}"
                                 (click)="expand(predictiveDiagnostic, i)">
                                <div class="min-width-25"
                                     *ngIf="canDisplayGroupParentStyle() && predictiveDiagnosticsToDisplayHaveChildren">
                                    <mat-icon class="expand-icon"
                                              *ngIf="predictiveDiagnostic.children && predictiveDiagnostic.children.length">
                                        {{expandChildren[i] ? 'expand_less' : 'expand_more'}}</mat-icon>
                                </div>
                                <div class="flex-grow-1">
                                    <ct-stay-detail-diagnostic-prediction-element
                                        [forceDisplay]="menuOptions.forceDisplay"
                                        [groupByParent]="menuOptions.groupByParent"
                                        [isMixedDisplayActivated]="isMixedDisplayActivated"
                                        [dataSetElement]="dataSetElement"
                                        [predictiveDiagnostic]="predictiveDiagnostic"
                                        [diagnostics]="diagnostics"
                                        [codificationLabels]="codificationLabels"
                                        [codificationLabelIds]="codificationLabelIds"
                                        [isTypeRehabilitation]="isTypeRehabilitation"
                                        [displayOnlySimplifiedScore]="displayOnlySimplifiedScore"
                                        [canDisplayTooltip]="canDisplayTooltip"
                                        [filterSearch]="filtersSearch"
                                        [isFirst]="isFirst"
                                        (removePredictiveDiagnostic)="removePredictiveDiagnostic($event)"></ct-stay-detail-diagnostic-prediction-element>
                                </div>
                            </div>

                            <div class="expand-container padding-left-right"
                                 *ngIf="expandChildren[i]">
                                <div *ngFor="let childDiagnostic of orderPredictiveDiagnosticsToDisplay(predictiveDiagnostic.children)">
                                    <mat-progress-bar mode="indeterminate"
                                                      *ngIf="childDiagnostic.diagnostic.isLoading"></mat-progress-bar>

                                    <ct-stay-detail-diagnostic-prediction-element
                                        [dataSetElement]="dataSetElement"
                                        [predictiveDiagnostic]="childDiagnostic"
                                        [diagnostics]="diagnostics"
                                        [codificationLabels]="codificationLabels"
                                        [codificationLabelIds]="codificationLabelIds"
                                        [forceDisplay]="menuOptions.forceDisplay"
                                        [groupByParent]="menuOptions.groupByParent"
                                        [isTypeRehabilitation]="isTypeRehabilitation"
                                        [isMixedDisplayActivated]="isMixedDisplayActivated"
                                        [displayOnlySimplifiedScore]="displayOnlySimplifiedScore"
                                        [canDisplayTooltip]="canDisplayTooltip"
                                        [filterSearch]="filtersSearch"></ct-stay-detail-diagnostic-prediction-element>
                                </div>
                            </div>
                            <mat-divider *ngIf="!isLast"></mat-divider>
                        </div>
                    </div>
                </div>

                <div class="row more-predictions-block margin-top-10"
                     *ngIf="!isMixedDisplayActivated && !isNearAutomationQualityControl && hasMorePredictions() && predictiveDiagnosticsToDisplay && predictiveDiagnosticsToDisplay.length > 0">
                    <div class="col no-padding text-center">
                        <button mat-mini-fab
                                color="primary"
                                (click)="updateForceDisplay()">
                            <mat-icon *ngIf="!menuOptions.forceDisplay.value">expand_more</mat-icon>
                            <mat-icon *ngIf="menuOptions.forceDisplay.value">expand_less</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="predictiveDiagnosticsToDisplay && predictiveDiagnosticsToDisplay.length === 0">
            <!--No data-->
            <div class="row">
                <div class="col no-padding">
                    <div class="padding-top-16 text-disabled text-center">
                        <span *ngIf="!isFilterSearchPredictionList">{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.NO_DATA' | translate}}</span>

                        <ng-container *ngIf="isFilterSearchPredictionList && filtersSearch?.length">
                        <span *ngIf="(isFilterSearchUseCase && isFilterSearchSelected) || (!isFilterSearchUseCase && (hasDiagnosisMissingSlug || hasActPresenceSlug))">
                            {{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.NO_DATA_FILTER_SEARCH_TAB' | translate}}</span>
                            <span *ngIf="(isFilterSearchUseCase && !isFilterSearchSelected) || (!isFilterSearchUseCase && !hasDiagnosisMissingSlug && !hasActPresenceSlug)">
                            {{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.MUST_SELECT_A_FILTER_SEARCH' | translate}}</span>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row more-predictions-block"
                 *ngIf="!isMixedDisplayActivated && !isNearAutomationQualityControl && hasMorePredictions()">
                <div class="col no-padding text-center">
                    <button mat-mini-fab
                            color="primary"
                            (click)="updateForceDisplay()">
                        <mat-icon *ngIf="!menuOptions.forceDisplay.value">expand_more</mat-icon>
                        <mat-icon *ngIf="menuOptions.forceDisplay.value">expand_less</mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
