import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StayDetailDialogComponent} from './stay-detail-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailModule} from '../../../modules/stay/detail/stay-detail.module';

@NgModule({
    declarations: [
        StayDetailDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        StayDetailModule,
        MatDialogModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        StayDetailDialogComponent
    ]
})
export class StayDetailDialogModule {}
