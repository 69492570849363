import {TranslateService} from '@ngx-translate/core';
import {SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionPerPageService extends SearchEngineCondition {
    localStorageKey: string;

    constructor(private _translateService: TranslateService) {
        super(['perPage'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.RESULT_NUMBER'),
            'perPage',
            null,
            false);
        this.init();
    }

    init(uriParams?: any) {
        this.params = {perPage: '10'};
        this.uriParams = {perPage: '10'};
        this._setDefaultPerPage();
        this.setUriParams(uriParams);
    }

    private _setDefaultPerPage(): void {
        if (this.localStorageKey) {
            const savedValue: string = localStorage.getItem(this.localStorageKey);
            if (savedValue) {
                this.params.perPage = savedValue;
                this.uriParams.perPage = savedValue;
            }
        }
    }

    isValid(): boolean {
        return true;
    }
}
