import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {TranslateModule} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {authenticationStates} from './authentication.state';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {PasswordValidatorModule} from '../../shared/components/password-validator/password-validator.module';
import {AuthenticationComponent} from './authentication.component';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NeedAccessRightsPageComponent } from './need-access-rights-page/need-access-rights-page.component';
import {HeaderModule} from '../../shared/components/header/header.module';

@NgModule({
    declarations: [
        AuthenticationComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        NeedAccessRightsPageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UIRouterModule.forChild({states: authenticationStates}),
        TranslateModule.forChild(),
        HeaderModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        PasswordValidatorModule
    ]
})
export class AuthenticationModule {
}
