import {Component, Inject, OnInit} from '@angular/core';
import {VersionDisplayDialogService} from './version-display-dialog.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfigurationService} from '../configuration/configuration.service';

@Component({
    selector: 'ct-version-display-dialog',
    templateUrl: './version-display-dialog.component.html',
    styleUrls: ['./version-display-dialog.component.scss']
})
export class VersionDisplayDialogComponent implements OnInit {
    private _versionDisplayDialogService: VersionDisplayDialogService;

    isLoading: boolean;
    tags: any = {};
    modules: any = {
        stays: true
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this._versionDisplayDialogService = this.data.service;
        this._getConfig();
        this._loadTagsFile();
    }

    private async _loadTagsFile() {
        try {
            this.isLoading = true;
            this.tags = await  this._versionDisplayDialogService.loadTagsFile();
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
        }
    }

    private _getConfig() {
        this.modules.filterSearch = this._configurationService.getConfigurationContent('front', 'menu.canDisplayFilterSearchModule');
        this.modules.stayReady = this._configurationService.getConfigurationContent('front', 'dataSetElement.isReady');
        this.modules.qualityControl = this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlModule');
        this.modules.dashboard = this._configurationService.getConfigurationContent('front', 'menu.canDisplayDashboardModule');
        this.modules.predictionAnalysis = this._configurationService.getConfigurationContent('front', 'menu.canDisplayPredictionAnalysisModule');
        this.modules.autoDiag = this._configurationService.getConfigurationContent('front', 'menu.canDisplayAutoDiagnosisModule');
        this.modules.autoCoding = this._configurationService.getConfigurationContent('front', 'diagnostic.isAutoEncodingActive');
        this.modules.patient = this._configurationService.getConfigurationContent('front', 'menu.canDisplayPatientModule');
        this.modules.filterSearchPatient = this._configurationService.getConfigurationContent('front', 'menu.canDisplayPatientFilterSearchModule');
        this.modules.chronicDiagnostic = this._configurationService.getConfigurationContent('front', 'menu.canDisplayChronicDiagnosticModule');
    }
}
