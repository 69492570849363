import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../../../../../configuration/configuration.service';

@Component({
    selector: 'ct-stay-detail-document-content-structured',
    templateUrl: './stay-detail-document-content-structured.component.html',
    styleUrls: ['./stay-detail-document-content-structured.component.scss']
})
export class StayDetailDocumentContentStructuredComponent implements OnInit {
    private _searchQuery: string;
    private _rows = [];
    private _localColsToDisplay: any;
    private _highlightData = {
        documentTypeId: 1,
        valueColumnSlug: '',
        highThresholdColumnSlug: '',
        lowThresholdColumnSlug: '',
    };

    @ViewChild('table') table;

    @Input() document: any;
    @Input() set searchQuery(newSearchQuery: string) {
        if (newSearchQuery !== this._searchQuery) {
            this._searchQuery = newSearchQuery;
            this.filterRows();
        }
    }

    rowsToDisplay = [];
    columnsToDisplay = [];
    columns = [];
    colsToActivate: any;
    limit = 10;
    tableMessages = {
        emptyMessage: `<span>${this._translateService.instant('ERROR.NO_DATA')}</span>`
    };

    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService) { }

    ngOnInit() {
        this._initConfig();
        if (this.document) {
            this._localColsToDisplay = JSON.parse(localStorage.getItem('structuredColumnsToDisplay')) || {};
            this.colsToActivate = {
                documentTypeId: this.document.documentTypeId
            };
            if (this.document.columns) {
                this.columnsToDisplay = this.document
                    .columns
                    .map(column => {
                        return {
                            ...column,
                            prop: column.slug
                        };
                    });
            }
            if (this.document.structuredData &&
                this.document.structuredData.lines) {
                this.rowsToDisplay = this.document.structuredData.lines;
            }
            this._rows = [...this.rowsToDisplay];
            this.columns = [...this.columnsToDisplay];
            this._initColumnsToActivate();

            // If we reopen the document and there is a search query
            // then filter
            if (this._searchQuery) {
                this.filterRows();
            }
        }
    }

    private _initConfig() {
        this._highlightData.documentTypeId = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.documentTypeId');
        this._highlightData.valueColumnSlug = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.valueColumnSlug');
        this._highlightData.highThresholdColumnSlug = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.highThresholdColumnSlug');
        this._highlightData.lowThresholdColumnSlug = this._configurationService.getConfigurationContent('front', 'health.document.structured.highlight.lowThresholdColumnSlug');
    }

    private _initColumnsToActivate() {
        if (this._localColsToDisplay[this.document.documentTypeId]) {
            this.colsToActivate = this._localColsToDisplay[this.document.documentTypeId];
            this.updateColumnsToDisplay(false);
        } else {
            this.columns.forEach(col => {
                this.colsToActivate[col.slug] = true;
            });
        }
    }

    updateColumnsToDisplay(updateLocalStorage: boolean) {
        this.columnsToDisplay = this.columns
            .filter(col => this.colsToActivate[col.slug]);
        if (updateLocalStorage) {
            this._localColsToDisplay[this.document.documentTypeId] = this.colsToActivate;
            localStorage.setItem('structuredColumnsToDisplay', JSON.stringify(this._localColsToDisplay));
        }
    }

    canDisplayPaginator() {
        return (this.rowsToDisplay.length / this.limit) > 1;
    }

    filterRows() {
        if (this._searchQuery === '') {
            this.rowsToDisplay = this._rows;
        } else {
            this.rowsToDisplay = this._rows
                .filter(row => Object.keys(row).some(key => {
                    if (row[key]) {
                        return row[key]
                            .toString()
                            .toLowerCase()
                            .includes(this._searchQuery.toLowerCase());
                    } else {
                        return false;
                    }
                }));
        }
    }

    // Has to be an arrow function otherwise this is not the class context
    getRowClass = (row: any) => {
        if (this._highlightData && this.document) {
            if (this._highlightData.documentTypeId === this.document.documentTypeId) {
                const value = row[this._highlightData.valueColumnSlug];
                const displayHighlight = value < row[this._highlightData.lowThresholdColumnSlug] || value > row[this._highlightData.highThresholdColumnSlug];
                return {
                    'highlight-red': displayHighlight
                };
            }
            return '';
        }
        return '';
    }
}
