<mat-expansion-panel [expanded]="isExpanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h3 ctCustomHtmlAttribute
                [htmlAttributeName]="'cypress-id'"
                [htmlAttributeValue]="titleCypressId"
                class="h3">{{title || ''}}</h3>

            <ng-content select="ct-expansion-panel-title-actions"></ng-content>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-content select="ct-expansion-panel-body"></ng-content>
</mat-expansion-panel>
