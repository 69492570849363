import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {WorklistUserElementComponent} from './worklist-user-element.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        WorklistUserElementComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule
    ],
    exports: [
        WorklistUserElementComponent
    ]
})
export class WorklistUserElementModule {}
