import {Component, Input} from '@angular/core';

@Component({
    selector: 'ct-status-light',
    templateUrl: './status-light.component.html',
    styleUrls: ['./status-light.component.scss']
})
export class StatusLightComponent {
    @Input() tooltip: string;
    @Input() tooltipStatusTrue: string;
    @Input() tooltipStatusFalse: string;
    @Input() color: string;
    @Input() status: boolean;
    @Input() reverse: boolean;

    constructor() { }

    getColorClass() {
        if (this.color) {
            if (this.status) {
                return 'md-icon-' + this.color;
            }
            return '';
        } else {
            let value = ['red', 'green'];
            let color = '';
            if (this.reverse) {
                value = ['green', 'red'];
            }
            if (this.status === false || this.status === null) {
                this.tooltip = this.tooltipStatusFalse || this.tooltip;
                color = 'md-icon-' + value[0];
            } else if (this.status === true) {
                this.tooltip = this.tooltipStatusTrue || this.tooltip;
                color = 'md-icon-' + value[1];
            }
            return color;
        }
    }

}
