import {Component, Input, OnInit} from '@angular/core';
import {DocumentTypeService} from '../../../document-type/document-type.service';
import * as _ from 'lodash-es';
import {DocumentTypeValue, DocumentTypeValueSource} from '../../../../shared/components/document-type-search/document-type-search.component';

export interface MixedDocumentTypes {
    documentTypes: any;
    inferredDocumentTypes: any;
}

@Component({
    selector: 'ct-search-engine-condition-document-type',
    templateUrl: './search-engine-condition-document-type.component.html',
    styleUrls: ['./search-engine-condition-document-type.component.scss']
})
export class SearchEngineConditionDocumentTypeComponent implements OnInit {
    @Input() condition: any;

    mixedDocumentTypes: any[] = [];
    isLoading: boolean;
    toggleAllMapCallback: (any) => DocumentTypeValue = (element: any) => ({
        documentTypeId: element?.id,
        documentTypeSlug: element?.slug,
        source: element?.isInferredDocumentType ? DocumentTypeValueSource.INFERRED : DocumentTypeValueSource.STATED
    })

    constructor(private _documentTypeService: DocumentTypeService) { }

    ngOnInit(): void {
        this._loadAllMixedDocumentTypes();
    }

    private _mapToGenericData(mixedDocumentTypes: MixedDocumentTypes): any[] {
        if (mixedDocumentTypes?.documentTypes &&
            mixedDocumentTypes?.inferredDocumentTypes) {
            const newMixedDocumentTypes = mixedDocumentTypes.documentTypes
                .concat(mixedDocumentTypes.inferredDocumentTypes)
                .map(documentType => ({
                    ...documentType,
                    text: documentType.name || documentType.slug || '',
                    value: this.toggleAllMapCallback(documentType)
                }));
            return _.orderBy(newMixedDocumentTypes, ['isInferredDocumentType', documentType => documentType.text.toLowerCase()], ['asc', 'asc']);
        }
        return [];
    }

    private async _loadAllMixedDocumentTypes(): Promise<void> {
        try {
            this.isLoading = true;
            const mixedDocumentTypes: MixedDocumentTypes = await this._documentTypeService.loadAllMixedDocumentTypes();
            this.mixedDocumentTypes = this._mapToGenericData(mixedDocumentTypes);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }
}
