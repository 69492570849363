import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'ct-stay-detail-collapse-button',
    templateUrl: './stay-detail-collapse-button.component.html',
    styleUrls: ['./stay-detail-collapse-button.component.scss']
})
export class StayDetailCollapseButtonComponent implements OnInit {
    @Input() leftSideDisplayState: string;

    @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit(): void {
    }

}
