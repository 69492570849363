<ct-header></ct-header>

<div *ngIf="isActive"
     class="col-md-4 ct-margin-auto">
    <mat-card class="reset-form-card">
        <form [formGroup]="resetPasswordForm">
            <mat-card-content>
                <mat-form-field *ngIf="type === 3"
                                class="layout-fill"
                                [ngClass]="{'form-field-invalid': error}">
                    <label class="layout-fill">
                        <input [placeholder]="'INPUT.OLD_PASSWORD' | translate"
                               formControlName="oldPassword"
                               matInput
                               required
                               type="password">
                    </label>
                    <mat-error *ngFor="let validationError of validationErrors.password">
                        <ng-container
                            *ngIf="formControls['oldPassword'].hasError(validationError.type)">
                            {{validationError.message}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="layout-fill"
                                [ngClass]="{'form-field-invalid': error}">
                    <label class="layout-fill">
                        <input [placeholder]="'INPUT.NEW_PASSWORD' | translate"
                               formControlName="password"
                               matInput
                               required
                               type="password">
                    </label>
                    <mat-error *ngFor="let validationError of validationErrors.password">
                        <ng-container
                            *ngIf="formControls['password'].hasError(validationError.type)">
                            {{validationError.message}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>

                <ct-password-validator
                    (status)="isPasswordValid($event)"
                    [field]="resetPasswordForm.get('password').value"
                    class="margin-top-10 margin-bottom-10">
                </ct-password-validator>

                <mat-form-field class="layout-fill"
                                [ngClass]="{'form-field-invalid': error}">
                    <label class="layout-fill">
                        <input [placeholder]="'INPUT.NEW_PASSWORD_CONFIRM' | translate"
                               formControlName="passwordConfirmation"
                               matInput
                               required
                               type="password">
                    </label>
                    <mat-error
                        *ngFor="let validationError of validationErrors.passwordConfirmation">
                        <ng-container
                            *ngIf="formControls['passwordConfirmation'].hasError(validationError.type)">
                            {{validationError.message}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </mat-card-content>

            <mat-card-actions>
                <button mat-raised-button
                        type="submit"
                        (click)="submit()"
                        color="primary">
                    {{('BUTTON.UPDATE' | translate) | uppercase}}
                </button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
