<div class="display-flex">
    <ct-search-engine-condition-select-with-dynamic-data class="display-inline-block margin-right-10"
                                                         [condition]="condition"
                                                         [paramKey]="'medicalUnitId'"
                                                         [toggleAllMapCallback]="toggleAllMapCallback"
                                                         [compareWithCallback]="compareWithCallback"
                                                         [data]="medicalUnits"
                                                         [placeholder]="'DATA_SET.CODIFICATION.SEARCH.MEDICAL_UNIT'"
                                                         [isLoading]="isLoading"></ct-search-engine-condition-select-with-dynamic-data>

    <ct-medical-unit-search class="flex-grow-1"
                            *ngIf="!isLoading && medicalUnits"
                            [(param)]="condition.service.params.medicalUnitId"
                            [medicalUnits]="medicalUnits"></ct-medical-unit-search>
</div>
