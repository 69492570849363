import {Injectable} from '@angular/core';
import {DashboardActivityApiService} from '../../core/api-services/dashboard/activity/dashboard-activity.api.service';
import {TranslateService} from '@ngx-translate/core';
import {DateHelperService} from '../../core/services/date.helper.service';
import {jsPDF} from 'jspdf';
import {JsPdfService} from '../../core/services/js-pdf.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    // Duration in seconds
    private readonly _TIME_SPENT_EVENT_DURATION = 10;

    constructor(private _translateService: TranslateService,
                private _dateHelperService: DateHelperService,
                private _jsPdfService: JsPdfService,
                private _dashboardActivityApiService: DashboardActivityApiService) {}

    private _shouldSendEvent(user: any) {
        if (user && user.email) {
            const email = user.email.split('@')[1];
            return email ? !(email.includes('collective-thinking')) : false;
        }
        return false;
    }

    /**
     * Build event to send to API according to eventName
     * @param eventName Name of event (stay_direct_access, stay_rule_check, stay_update_status, stay_rule_status, stay_codification)
     * @param eventData Data that correspond to the event (stayId, endDate ...)
     * @private
     */
    private _buildEvent(eventName: string,
                        eventData: any): any {
        const event: any = {
            type: eventName,
            // only data for stay_direct_access
            data: {
                stayId: eventData.stayId,
                endDate: eventData.endDate,
                type: eventData.type
            }
        };
        switch (eventName) {
            case 'stay_update_status':
                event.data = {
                    ...event.data,
                    oldStatus: eventData.oldStatus,
                    newStatus: eventData.newStatus
                };
                break;
            case 'stay_near_auto_codification':
            case 'stay_codification':
                event.data = {
                    ...event.data,
                    code: eventData.code,
                    action: eventData.action,
                    codificationLabelId: eventData.codificationLabelId,
                    value: eventData.value >= 0 ? eventData.value : null
                };
                break;
            case 'stay_rule_check':
                event.data = {
                    ...event.data,
                    ruleName: eventData.filterSearchName,
                    ruleId: eventData.filterSearchId
                };
                break;
            case 'time_spent':
                event.data = {
                    ...event.data,
                    module: eventData.module,
                    context: eventData.context,
                    status: eventData.status,
                    duration: this._TIME_SPENT_EVENT_DURATION
                };
                break;
            case 'stay_rule_status':
                event.data = {
                    ...event.data,
                    ruleName: eventData.filterSearchName,
                    ruleId: eventData.filterSearchId,
                    ruleStatus: eventData.filterSearchStatus,
                };
                break;
            default:
        }
        return event;
    }

    calculateAverageTimeSpent(timeSpentMetrics: any): any {
        const timeSpent: any = {value: 0};
        if (timeSpentMetrics &&
            timeSpentMetrics.averageTime &&
            timeSpentMetrics.averageTime.value) {
            const averageTime = timeSpentMetrics.averageTime.value;
            if (averageTime < 60) { // < 1 minute
                timeSpent.value = Math.round(averageTime);
                timeSpent.unit = this._translateService.instant('TIME.SECONDS_LOWERCASE');
            } else if (averageTime < 60 * 60 ) { // >= 1 minute and < 1 hour
                timeSpent.value = Math.round(averageTime / 60);
                timeSpent.unit = this._translateService.instant(timeSpent.value !== 1 ? 'TIME.MINUTES_LOWERCASE' : 'TIME.MINUTE_LOWERCASE');
            } else if (averageTime < 60 * 60 * 24) { // >= 1 hour and < 1 day
                timeSpent.value = Math.round(averageTime / (60 * 60));
                timeSpent.unit = this._translateService.instant(timeSpent.value !== 1 ? 'TIME.HOURS_LOWERCASE' : 'TIME.HOUR_LOWERCASE');
            } else if (averageTime === 60 * 60 * 24) { // === 1 day
                timeSpent.value = 1;
                timeSpent.unit = this._translateService.instant('TIME.DAY_LOWERCASE');
            } else {
                timeSpent.value = Math.round(averageTime / (60 * 60 * 24));
                timeSpent.unit = this._translateService.instant('TIME.DAYS_LOWERCASE');
            }
        }
        return timeSpent;
    }

    /**
     * Send event to API
     * @param eventName Name of event (stay_direct_access, stay_rule_check, stay_update_status, stay_codification)
     * @param eventData Data that correspond to the event (stayId, endDate ...)
     */
    async sendEvent(eventName: string,
                    eventData: any): Promise<void> {
        const currentUser = JSON.parse(localStorage.getItem('user')) || {};
        if (this._shouldSendEvent(currentUser)) {
            try {
                const event = this._buildEvent(eventName, eventData);
                await this._dashboardActivityApiService
                    .postEvent(event)
                    .toPromise();
            } catch (e) {
                console.error(e);
                throw e;
            }
        }
    }

    private _getPeriod(endDate: string): {startDate: string, endDate: string} {
        const dates = {startDate: '', endDate: ''};
        if (endDate) {
            const split = endDate.split(';');
            if (split.length === 3) {
                dates.startDate = this._dateHelperService.mysqlToFrench(split[0]);
                dates.endDate = this._dateHelperService.mysqlToFrench(split[2]);
            } else if (split.length === 2) {
                const dateToUpdate = split[1] === '>=' ? 'startDate' : split[1] === '<=' ? 'endDate' : '';
                if (dateToUpdate) {
                    dates[dateToUpdate] = this._dateHelperService.mysqlToFrench(split[0]);
                }
            }
        }
        return dates;
    }

    private async _getPdfFile(period: {startDate: string, endDate: string}, numberOfPages: number = 2): Promise<jsPDF> {
        try {
            const pdfFile = new jsPDF('landscape', 'mm', 'a4');
            // Add CT logo
            const logoPath = './assets/images/logo.png';
            const logoWidth = 150;
            const pdfFileWidth: number = pdfFile.internal.pageSize.getWidth();
            const pdfFileHeight: number = pdfFile.internal.pageSize.getHeight();
            // Center
            pdfFile.addImage(logoPath, (pdfFileWidth / 2) - (logoWidth / 2), 1, logoWidth, 30);
            // Add selected period
            pdfFile.setFontSize(15);
            const text = `${this._translateService.instant('DASHBOARD.DISPLAY_PERIOD')} : ${period.startDate} - ${period.endDate}`;
            pdfFile.text(text, (pdfFileWidth / 2), 40, {align: 'center'});
            // Add charts
            await this._jsPdfService.addCanvasImageToPage(pdfFile, '#downloadableDiv1', 60, pdfFileHeight - 60);
            pdfFile.addPage('a4', 'landscape');
            await this._jsPdfService.addCanvasImageToPage(pdfFile, '#downloadableDiv2', 1, pdfFileHeight);
            if (numberOfPages > 2) {
                pdfFile.addPage('a4', 'landscape');
                let height = pdfFileHeight;
                let y = 1;
                if (numberOfPages === 2.5) {
                    // If numberOfPages === 2.5 it means we have only one line of this page, so
                    // we don't want the image to be full height
                    height = height - (height * 0.30);
                    // Center
                    y = (pdfFileHeight / 2) - (height / 2);
                }
                await this._jsPdfService.addCanvasImageToPage(pdfFile, '#downloadableDiv3', y, height);
                return pdfFile;
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _getFileName(period: string): string {
        const stringToReturn: string = this._translateService.instant('DASHBOARD.EXPORT_FILENAME');
        if (period) {
            // slashes are converted to _ in navigator
            return stringToReturn.concat(period);
        }
        return stringToReturn;
    }

    private _savePdf(pdfFile: jsPDF, period: {startDate: string, endDate: string}): void {
        if (pdfFile &&
            period) {
            const fileName: string = this._getFileName(`${period.startDate}_${period.endDate}`);
            pdfFile.save(`${fileName}.pdf`);
        }
    }

    async downloadPageAsPdf(endDate: string, numberOfPages: number = 2): Promise<void> {
        try {
            const period: {startDate: string, endDate: string} = this._getPeriod(endDate);
            const pdfFile: jsPDF = await this._getPdfFile(period, numberOfPages);
            this._savePdf(pdfFile, period);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
