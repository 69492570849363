import {Injectable} from '@angular/core';
import {Document} from '../../modules/document-type/document-list/document.model';

@Injectable({
    providedIn: 'root'
})
export class FileSrcService {

    getDocumentSrc(document: Document) {
        if (document && document.id) {
            return `api/v2/health-document/${document.id}/file`;
        }
        return '';
    }
}
