import {Injectable} from '@angular/core';
import {LogFields, Logger} from './logger';
import {BroadcastService} from '../services/broadcast.service';

@Injectable({
    providedIn: 'root'
})
export class AppLogService {
    private _logger: Logger;
    private _env: string;
    private _clientName: string;

    constructor(private _broadcastService: BroadcastService) {
        this._init(true);
        this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'configuration::update':
                        this._init(false);
                        break;
                    default:
                }
            });
    }

    private _init(firstOne: boolean) {
        // No use of ConfigurationService because otherwise there is a circular dependency
        const configurations = JSON.parse(localStorage.getItem('configurations')) || [];
        const logStashConfig = configurations.find(config => config.type === 'front' && config.name === 'logStashEndpoint') || {};
        if (firstOne) {
            this._logger = new Logger(logStashConfig.content);
        } else {
            this._logger.logEndpoint = logStashConfig.content;
        }
        const envConfig = configurations.find(config => config.type === 'front' && config.name === 'env') || {};
        this._env = envConfig.content;
        const clientNameConfig = configurations.find(config => config.type === 'front' && config.name === 'clientName') || {};
        this._clientName = clientNameConfig.content;
    }

    private _getLogFields(): LogFields {
        const currentUser = JSON.parse(localStorage.getItem('user')) || {};
        const url = location.href;
        return {
            environment: this._env,
            userId: currentUser.id,
            clientName: this._clientName,
            url
        };
    }

    logInfo(message: string, revaluation?: number) {
        const logFields = this._getLogFields();
        if (revaluation !== null &&
            typeof revaluation !== 'undefined') {
            logFields.revaluation = revaluation;
        }
        this._logger.log('Information', message, logFields);
    }

    logWarning(message: string) {
        const logFields = this._getLogFields();
        this._logger.log('Warning', message, logFields);
    }

    logError(errorMsg: string, elapsedTime?: number, requestPath?: string) {
        const logFields = this._getLogFields();
        if (elapsedTime) {
            logFields.elapsedTime = elapsedTime;
        }
        if (requestPath) {
            logFields.requestPath = requestPath;
        }
        this._logger.log('Error', errorMsg, logFields);
    }
}
