<div class="layout-fill align-center-center login-content">
    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title class="login-card-title">
                <span class="mat-headline">{{'AUTHENTICATION.TITLE' | translate}}</span>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p class="error">{{'ERROR.FIRST_LDAP_CONNECTION' | translate}}</p>
        </mat-card-content>
    </mat-card>
</div>
