import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {ConfigurationService} from '../../configuration/configuration.service';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'ct-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    validationErrors: any = {
        email: [
            {
                type: 'required',
                message: this._translateService.instant('ERROR.REQUIRED_FIELD')
            },
            {
                type: 'email',
                message: this._translateService.instant('ERROR.WRONG_EMAIL')
            }
        ],
        password: [
            {
                type: 'required',
                message: this._translateService.instant('ERROR.REQUIRED_FIELD')
            }
        ]
    };

    active = false;
    loading = false;
    isUserDenied = false;
    error = false;
    hasMailServer = false;
    errorMessage = '';
    loginForm: FormGroup;
    isEmailInputFocused = false;
    isPasswordInputFocused = false;
    user: any = {};

    constructor(public $state: StateService,
                public formBuilder: FormBuilder,
                private _translateService: TranslateService,
                private _authenticationService: AuthenticationService,
                private _configurationService: ConfigurationService) {}

    async ngOnInit() {
        try {
            this._initForm();
            await this._loadHasMailServerConfig();
            if (this._authenticationService.user.isAuthenticated) {
                await this._autoLogin();
            } else {
                this.active = true;
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    get formControls() {
        return this.loginForm.controls;
    }

    private _initForm() {
        this.loginForm = this.formBuilder
            .group({
                email: [null, [
                    Validators.required,
                    Validators.email,
                    (control: AbstractControl) => {
                        if (!control.value) {
                            return null;
                        }
                        this.user.email = control.value;
                    }]
                ],
                password: [null, [Validators.required,
                    (control: AbstractControl) => {
                        if (!control.value) {
                            return null;
                        }
                        this.user.password = control.value;
                    }]]
            });
    }

    private async _autoLogin() {
        try {
            const status: any = await this._authenticationService
                .isAuthenticated();
            if (status) {
                this._authenticationService.redirectAccordingToRole();
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async _loadHasMailServerConfig() {
        try {
            const hasMailServer = await this._configurationService
                .loadSingleConfiguration('hasMailServer');
            this.hasMailServer = hasMailServer ? hasMailServer.status : false;
        } catch (e) {
            this.hasMailServer = false;
            throw e;
        }
    }

    private _handleLoginError(errorObj: any) {
        switch (errorObj.status) {
            case 429:
                const denyObj = {
                    email: this.user.email,
                    expire: moment().add(10, 'minutes').toString()
                };
                localStorage
                    .setItem(`denyAccessLoginUser_${this.user.email}`,
                        JSON.stringify(denyObj));
                this.isUserDenied = true;
                this.errorMessage = this._translateService
                    .instant('ERROR.TOO_MANY_CONNECTION_ATTEMPTS_10');
                break;
            case 404:
            case 401:
                this.errorMessage = this._translateService
                    .instant('ERROR.WRONG_USER_AND_PASSWORD');
                break;
            default:
                this.errorMessage = this._translateService
                    .instant('ERROR.TRY_AGAIN');
        }
    }

    async login() {
        try {
            this.loading = true;
            await this._authenticationService.login(this.user);
            this.error = false;
            this.isUserDenied = false;
        } catch (errorObj) {
            this.loading = false;
            this.error = true;
            this._handleLoginError(errorObj);
        }
    }

    onEmailInputBlur() {
        this.isEmailInputFocused = !this.isEmailInputFocused;
        const denyAccessLoginUser = `denyAccessLoginUser_${this.user.email}`;
        const userTmpForbidden = JSON.parse(localStorage.getItem(denyAccessLoginUser));
        if (!userTmpForbidden) {
            this.isUserDenied = false;
        } else {
            const now = moment().toString();
            this.isUserDenied = now < userTmpForbidden.expire.toString();
        }
    }

    goToForgotPassword() {
        this.$state.go('authentication.forgot-password');
    }
}
