import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailCodificationNearAutomationComponent} from './stay-detail-codification-near-automation.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailCodificationNearAutomationConfirmDialogModule} from './confirm-dialog/stay-detail-codification-near-automation-confirm-dialog.module';

@NgModule({
    declarations: [
        StayDetailCodificationNearAutomationComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        StayDetailCodificationNearAutomationConfirmDialogModule,
        NgbTooltipModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        StayDetailCodificationNearAutomationComponent
    ],
})
export class StayDetailCodificationNearAutomationModule {}
