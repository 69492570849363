<div class="ct-header background-color"
     [ngClass]="getClasses()">
    <div class="banner-title">
        <div class="mat-headline div-fill max-width-600"
             *ngIf="title && icon">
           <mat-icon>{{icon}}</mat-icon>
            <span>{{title}}</span>
        </div>

        <ng-content select="ct-header-content"></ng-content>
    </div>
</div>