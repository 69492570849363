import {TranslateService} from '@ngx-translate/core';
import {SearchEngineCondition} from '../search-engine.condition';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionScaleService extends SearchEngineCondition {
    private _defaultScale: string;
    private _useCase: string;

    get useCase(): string {
        return this._useCase;
    }

    set useCase(value: string) {
        this._useCase = value;
    }

    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService) {
        super(['scale'],
            _translateService.instant('STUFF.SCALE'),
            'scale',
            null,
            false);
        this.init();
    }

    init(uriParams?: any): void {
        if (this._useCase === 'dashboard') {
            this._setDefaultScale();
        }
        this.params = {scale: this._defaultScale};
        this.uriParams = {scale: this._defaultScale};
        this.setUriParams(uriParams);
    }

    private _setDefaultScale(): void {
        this._defaultScale = this._configurationService.getConfigurationContent('front', 'dashboard.defaultScale');
        if (!this._defaultScale) {
            const clientType = this._configurationService.getConfigurationContent('front', 'clientType') || 'public';
            this._defaultScale = clientType === 'public' ? 'month' : 'day';
        }
    }

    isValid(fields?: any): boolean {
        return true;
    }
}
