import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldDisplayComponent} from './field-display.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomHtmlAttributeDirectiveModule} from '../../directives/custom-html-attribute/custom-html-attribute.directive.module';

@NgModule({
    declarations: [
        FieldDisplayComponent
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule.forChild(),
        CustomHtmlAttributeDirectiveModule
    ],
    exports: [
        FieldDisplayComponent
    ]
})
export class FieldDisplayModule {}
