import {Component, OnInit} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';

@Component({
    selector: 'ct-search-engine-condition-diagnostic-slug',
    templateUrl: './search-engine-condition-diagnostic-slug.component.html',
    styleUrls: ['./search-engine-condition-diagnostic-slug.component.scss']
})
export class SearchEngineConditionDiagnosticSlugComponent implements OnInit {
    private _codificationLabels = {
        DP: '',
        DR: '',
        DA: '',
        MP: '',
        AE: '',
    };

    condition: any;
    canUseCodificationLabels = {
        DP: true,
        DR: false,
        DA: true,
        MP: true,
        AE: true
    };
    isMCOUseCase: boolean;
    isSSRUseCase: boolean;
    canDisplayDR: boolean;

    constructor(public colorService: ColorService,
                private _translationHelperService: TranslationHelperService,
                private _configurationService: ConfigurationService) {}

    ngOnInit() {
        this._initAvailableFeatures();
        this._initCodificationLabels();
    }

    private _initAvailableFeatures() {
        this.isSSRUseCase = this._configurationService.appTypeIs('SSR');
        this.isMCOUseCase = this._configurationService.appTypeIs('MCO');
        if (this._configurationService.appTypeIsMCOAndSSR()) {
            this.isSSRUseCase = true;
            this.isMCOUseCase = true;
        }
        this.canDisplayDR = this._translationHelperService.isFeatureAvailable('DR');
    }

    private _initCodificationLabelsMCO(): void {
        this._codificationLabels.DP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP) || 'DP';
        this._codificationLabels.DR = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DR) || 'DR';
        if (this.condition.service.params.diagnosticCodificationLabel) {
            this.canUseCodificationLabels.DP = this.condition.service.params.diagnosticCodificationLabel.includes(this._codificationLabels.DP);
            this.canUseCodificationLabels.DR = this.condition.service.params.diagnosticCodificationLabel.includes(this._codificationLabels.DR);
        }
    }

    private _initCodificationLabelsSSR(): void {
        this._codificationLabels.MP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.MP) || 'MP';
        this._codificationLabels.AE = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.AE) || 'AE';
        if (this.condition.service.params.diagnosticCodificationLabel) {
            this.canUseCodificationLabels.MP = this.condition.service.params.diagnosticCodificationLabel.includes(this._codificationLabels.MP);
            this.canUseCodificationLabels.AE = this.condition.service.params.diagnosticCodificationLabel.includes(this._codificationLabels.AE);
        }
    }

    private _initCodificationLabels(): void {
        if (this.isMCOUseCase) {
            this._initCodificationLabelsMCO();
        }
        if (this.isSSRUseCase) {
            this._initCodificationLabelsSSR();
        }
        this._codificationLabels.DA = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DA) || 'DA';
        this.canUseCodificationLabels.DA = this.condition.service.params.diagnosticCodificationLabel ?
            this.condition.service.params.diagnosticCodificationLabel.includes(this._codificationLabels.DA) : true;
    }

    updateDiagnosticCodificationLabelParam(useCase: string) {
        if (this.condition.service.params.diagnosticCodificationLabel) {
            const canUseCodificationLabel = this.canUseCodificationLabels[useCase];
            const codificationLabel = this._codificationLabels[useCase];
            if (canUseCodificationLabel) {
                this.condition.service.params.diagnosticCodificationLabel.push(codificationLabel);
            } else {
                const index = this.condition.service.params.diagnosticCodificationLabel.indexOf(codificationLabel);
                if (index > -1) {
                    this.condition.service.params.diagnosticCodificationLabel.splice(index, 1);
                }
            }
        }
    }

    canDisplayConditionSelect() {
        return this.condition.service.params.diagnosticCodificationLabel ?
            this.condition.service.params.diagnosticCodificationLabel.includes(this._codificationLabels.DA) : false;
    }
}
