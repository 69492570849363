import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DocumentTypeApiService {
    private _BASE_URL = 'document-type';

    constructor(private _http: HttpService) { }

    getAll(urlRequestParams?: any) {
        return this._http
            .get(this._BASE_URL, {params: urlRequestParams});
    }

    get(documentTypeId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${documentTypeId}`, {params: urlRequestParams});
    }

    getStructuredDataColumn(documentTypeId: number) {
        return this._http
            .get(`${this._BASE_URL}/${documentTypeId}/structured-data-column`)
            .pipe(map(res => res.data));
    }

    update(documentTypeId: number, data: any) {
        return this._http
            .patch(`${this._BASE_URL}/${documentTypeId}`, data);
    }
}
