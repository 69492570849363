import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {StayDetailFilterSearchActListComponent} from './stay-detail-filter-search-act-list.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        StayDetailFilterSearchActListComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        NgbTooltipModule,
        MatIconModule,
        MatDividerModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
    exports: [
        StayDetailFilterSearchActListComponent
    ],
})
export class StayDetailFilterSearchActListModule {}
