import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ct-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {
    @Input() title: string;
    @Input() titleCypressId: string;
    @Input() panelKey: string;

    @Input() isExpanded = true;

    constructor() { }

    ngOnInit(): void {
    }
}
