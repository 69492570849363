<mat-form-field class="width-110">
    <mat-select multiple
                [placeholder]="'FILTER_SEARCH.FILTER_SEARCH_TYPE' | translate"
                [panelClass]="['mat-' + colorService.getColorAccordingToCurrentState()]"
                [(ngModel)]="condition.service.params.tagName">
        <mat-option *ngFor="let type of filterSearchTypes"
                    [value]="type.value">
            {{type.text | translate}}
        </mat-option>
    </mat-select>
</mat-form-field>
