import {AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TooltipDirective} from '../../directives/tooltip/tooltip.directive';

@Component({
    selector: 'ct-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, AfterViewInit {
    @ViewChild('tooltipContainer', {read: ElementRef}) tooltipContainer: ElementRef;
    @ViewChild('templateContainer', { read: ViewContainerRef, static: true }) templateContainerRef: ViewContainerRef;

    right: string;
    overrideRightCssProperty: boolean;
    isLoaded: boolean;
    isHovering: boolean;
    componentRef: ComponentRef<TooltipComponent>;
    tooltipDirective: TooltipDirective;

    constructor(private _cdRef: ChangeDetectorRef) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // Timeout because otherwise clientWidth equals 0
        setTimeout(() => {
            if (this.overrideRightCssProperty) {
                this.right = `${(- this.tooltipContainer.nativeElement.clientWidth + Number.parseFloat(this.right))}px`;
            }
            this.isLoaded = true;
        }, 10);
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.isHovering = true;
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.isHovering = false;
        if (this.componentRef) {
            this.tooltipDirective.destroy();
        }
    }
}
