import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionPredictionSourceComponent} from './search-engine-condition-prediction-source.component';

@Injectable({
    providedIn: 'root'
})
export class SearchEngineConditionPredictionSourceService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['predictionSource'],
            _translateService.instant('STAY.PREDICTION'),
            'predictionSource',
            SearchEngineConditionPredictionSourceComponent,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = { predictionSource: uriParams?.predictionSource ? uriParams.predictionSource : 'rule' };
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = this.params.predictionSource;
    }
}
