import {Component, Input, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../../../configuration/configuration.service';

@Component({
    selector: 'ct-stay-detail-document-content-text',
    templateUrl: './stay-detail-document-content-text.component.html',
    styleUrls: ['./stay-detail-document-content-text.component.scss']
})
export class StayDetailDocumentContentTextComponent implements OnInit {
    @Input() text: any;

    documentTextFontSize: string;

    constructor(private _configurationService: ConfigurationService) {
        this._initConfig();
    }

    private _initConfig() {
        this.documentTextFontSize = this._configurationService.getConfigurationContent('front', 'health.document.synthesisFontSize') || '12px';
    }

    ngOnInit() {
    }
}
