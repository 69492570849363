import {Injectable} from '@angular/core';
import {ResetPasswordApiService} from '../api-services/reset-password.api.service';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {
    constructor(private _resetPasswordApiService: ResetPasswordApiService) {
    }

    async checkToken(token: string) {
        try {
            return await this._resetPasswordApiService
                .checkToken(token)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async resetPassword(token: string, type: number, body: any) {
        try {
            if (type === 1) {
                return await this._resetPasswordApiService.initPassword(
                    token,
                    body
                ).toPromise();
            }
            if (type === 2) {
                return await this._resetPasswordApiService.forgotPassword(
                    token,
                    body
                ).toPromise();
            }
            if (type === 3) {
                return await this._resetPasswordApiService.expiredPassword(
                    token,
                    body
                ).toPromise();
            }
        } catch (e) {
            throw e;
        }
    }
}
