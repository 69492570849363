import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-date-value',
    templateUrl: './search-engine-condition-date-value.component.html',
    styleUrls: ['./search-engine-condition-date-value.component.scss']
})
export class SearchEngineConditionDateValueComponent implements OnInit {
    condition: any;
    paramKey: string;
    placeholder = 'STUFF.VALUE';

    constructor() { }

    ngOnInit(): void {
    }

}
