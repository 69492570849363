<div class="row"
     *ngIf="!isLoading && !isPatientStayHistoryLoading && !isGroupingSimulationLoading">
    <div class="col no-padding">
        <div class="row">
            <div class="col no-padding">
                <div class="matching-chronic-diagnostics-block">
                    <div class="row"
                         *ngFor="let diagnosticToDisplay of orderDiagnosticsToDisplay(); index as i; last as isLast">
                        <div class="col no-padding">
                            <mat-progress-bar mode="indeterminate"
                                              *ngIf="diagnosticToDisplay.diagnostic.isLoading"></mat-progress-bar>

                            <div>
                                <ct-stay-detail-diagnostic-prediction-element
                                    [hasSimplifiedScore]="false"
                                    [dataSetElement]="dataSetElement"
                                    [diagnostics]="diagnostics"
                                    [predictiveDiagnostic]="diagnosticToDisplay"
                                    [codificationLabels]="codificationLabels"
                                    [codificationLabelIds]="[codificationLabels.DP, codificationLabels.DR, codificationLabels.DA, codificationLabels.MP, codificationLabels.AE]"
                                    [isTypeRehabilitation]="isTypeRehabilitation"></ct-stay-detail-diagnostic-prediction-element>
                            </div>
                            <mat-divider *ngIf="!isLast"></mat-divider>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--No data-->
        <div class="row">
            <div class="col no-padding">
                <div class="text-disabled text-center"
                     [ngClass]="{'margin-bottom-5': diagnosticHistory?.length}">
                    <span *ngIf="!canDisplayAllDiagnostics && diagnosticHistory?.length && matchingChronicDiagnostics?.length === 0">{{'CHRONIC_DIAGNOSTICS.NO_MATCHING_CHRONIC_DIAGNOSTICS' | translate}}</span>
                    <span *ngIf="diagnosticHistory?.length === 0">{{'CHRONIC_DIAGNOSTICS.NO_DATA' | translate}}</span>
                </div>
            </div>
        </div>

        <div class="row all-diagnostics-block"
             *ngIf="diagnosticHistory?.length > matchingChronicDiagnostics?.length">
            <div class="col no-padding text-center">
                <button mat-mini-fab
                        color="primary"
                        (click)="canDisplayAllDiagnostics = !canDisplayAllDiagnostics">
                    <mat-icon *ngIf="!canDisplayAllDiagnostics">expand_more</mat-icon>
                    <mat-icon *ngIf="canDisplayAllDiagnostics">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="align-center-center margin-top-10">
    <mat-progress-spinner
        mode="indeterminate"
        *ngIf="isLoading || isPatientStayHistoryLoading || isGroupingSimulationLoading"
        [diameter]="100"></mat-progress-spinner>
</div>
