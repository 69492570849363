<div>
    <mat-form-field>
        <mat-select #multipleSelect
                    multiple
                    cypress-id="search-engine-condition-ghm-severity-select"
                    [placeholder]="placeHolder | translate"
                    [panelClass]="['condition-select', 'mat-' + colorService.getColorAccordingToCurrentState()]"
                    [(ngModel)]="condition.service.params[paramKey]">
            <mat-checkbox [color]="colorService.getColorAccordingToCurrentState()"
                          [indeterminate]="isIndeterminate()"
                          [checked]="isChecked()"
                          (click)="toggleAll($event)">
                <span *ngIf="!isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.CHECK_ALL' | translate}} </span>
                <span *ngIf="isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.UNCHECK_ALL' | translate}} </span>
            </mat-checkbox>

            <mat-option *ngFor="let severity of severities"
                        [value]="severity.severity">
                {{severity.severity}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
