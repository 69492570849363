import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, ComparisonArgs, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionNumberComparisonComponent} from '../../shared/search-engine-condition-number-comparison/search-engine-condition-number-comparison.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDurationService extends SearchEngineCondition {
    // Because of shared component
    paramMinKey = 'durationMin';
    paramMaxKey = 'durationMax';
    placeholder = 'DATA_SET.CODIFICATION.SEARCH.DURATION';

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.DURATION'),
            'duration',
            SearchEngineConditionNumberComparisonComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {durationMin: '' , durationMax: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        if (this.apiParams.args.operator === '<=') {
            this.params.durationMax = this.apiParams.args.minValue ?? null;
        } else if (this.apiParams.args.operator === '>=') {
            this.params.durationMin = this.apiParams.args.minValue ?? null;
        } else if (this.apiParams.args.operator === '>=<') {
            this.params.durationMin = this.apiParams.args.minValue ?? null;
            this.params.durationMax = this.apiParams.args.maxValue ?? null;
        }
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.getComparisonArgsObject(this.params?.durationMin, this.params?.durationMax)};
    }
    convertToUriParams(): void {
        super.convertToUriParams();
        this.updateUriParamsComparisonNumber(this.uriParams, 'duration', this.params, 'durationMin', 'durationMax');
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
