import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {DataSetElementService} from '../../../modules/stay/shared/data-set-element.service';

@Component({
    selector: 'ct-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    @ViewChild('cancelButton', {read: ElementRef}) cancelButtonElement: ElementRef;
    @ViewChild('validateButton', {read: ElementRef}) validateButtonElement: ElementRef;

    private _isCancelButtonFocused: boolean;
    private _isValidateButtonFocused: boolean;

    title: string;
    validateButtonText: string;
    cancelButtonText: string;
    validateButtonCustomMinWidth: string;
    canClose = true;
    status: string;

    stayStatus = [
        {
            value: '0',
            text: 'STAY.STATUS_UNCHECKED_PLURAL'
        },
        {
            value: '1',
            text: 'STAY.STATUS_PRICING_IMPACT'
        },
        {
            value: '2',
            text: 'STAY.STATUS_VERIFIED'
        },
        {
            value: '3',
            text: 'STAY.STATUS_WAITING'
        },
        {
            value: '4',
            text: 'STAY.STATUS_PRIMO_CODAGE'
        },
        {
            value: '5',
            text: 'STAY.STATUS_NO_PRICING_IMPACT'
        }
    ];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _hotKeysService: HotkeysService,
                public dataSetElementService: DataSetElementService,
                public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
        this.title = this.data.title;
        this.validateButtonText = this.data.validateButtonText;
        this.cancelButtonText = this.data.cancelButtonText;
        this.validateButtonCustomMinWidth = this.data.validateButtonCustomMinWidth;
        this.status = this.data.status;
        if (typeof this.data.canClose !== 'undefined') {
            this.canClose = this.data.canClose;
        }
        this._setHotkeys();
    }

    private _setHotkeys() {
        this._hotKeysService
            .add(new Hotkey('left', (): boolean => {
                this.cancelButtonElement.nativeElement.focus();
                this._isCancelButtonFocused = true;
                this._isValidateButtonFocused = false;
                    return false; // Prevent bubbling
            }));
        this._hotKeysService
            .add(new Hotkey('right', (): boolean => {
                this.validateButtonElement.nativeElement.focus();
                this._isCancelButtonFocused = false;
                this._isValidateButtonFocused = true;
                    return false; // Prevent bubbling
            }));
        this._hotKeysService
            .add(new Hotkey('enter', (): boolean => {
                const buttonElement = this._isValidateButtonFocused ? this.validateButtonElement : this.cancelButtonElement;
                buttonElement.nativeElement.click();
                return false; // Prevent bubbling
            }));
    }

    confirm(value: string) {
        this.dialogRef.close(value);
    }
}
