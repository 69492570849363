<div class="align-col">
    <ct-tag [text]="'FILTER_SEARCH.PRIVATES' | translate"
            [icon]="'person'"
            [obj]="condition.service.params"
            [key]="'own'"
            [color]="getColor()"
            (tagUpdated)="tagUpdate()"></ct-tag>

    <ct-tag [text]="'FILTER_SEARCH.PUBLIC' | translate"
            [icon]="'group'"
            [obj]="condition.service.params"
            [key]="'shared'"
            [color]="getColor()"
            (tagUpdated)="tagUpdate()"></ct-tag>
    <ct-tag *ngIf="authenticationService.hasRole('admin')"
            [text]="'FILTER_SEARCH.AUTOMATABLE' | translate"
            [icon]="'autorenew'"
            [obj]="condition.service.params"
            [key]="'automatable'"
            [color]="getColor()"
            (tagUpdated)="tagUpdate()"></ct-tag>
</div>

<div class="align-col">
    <ct-tag [text]="'STUFF.GROUP' | translate"
            [icon]="'business'"
            [obj]="condition.service.params"
            [key]="'common'"
            [color]="getColor()"
            (tagUpdated)="tagUpdate()"></ct-tag>

    <ct-tag [text]="'Collective Thinking'"
            [displayCTLogo]="true"
            [obj]="condition.service.params"
            [key]="'default'"
            [color]="getColor()"
            (tagUpdated)="tagUpdate()"></ct-tag>
    <ct-tag *ngIf="isAutoSupervised"
            [text]="'FILTER_SEARCH.AUTO_SUPERVISED' | translate"
            [icon]="'supervised_user_circle'"
            [obj]="condition.service.params"
            [key]="'autoSupervised'"
            [color]="getColor()"
            (tagUpdated)="tagUpdate()"></ct-tag>
</div>

