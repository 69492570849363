<mat-form-field>
    <mat-select #multipleSelect
                multiple
                [panelClass]="'condition-select'"
                [placeholder]="placeholder | translate"
                [(ngModel)]="condition?.service?.params[paramKey]"
                [compareWith]="compareWithCallback"
                [disabled]="data?.length === 0">
        <mat-checkbox [color]="'primary'"
                      [indeterminate]="isIndeterminate()"
                      [checked]="isChecked()"
                      (click)="toggleAll($event)">
            <span *ngIf="!isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.CHECK_ALL' | translate}} </span>
            <span *ngIf="isChecked()"> {{'DATA_SET.CODIFICATION.SEARCH.UNCHECK_ALL' | translate}} </span>
        </mat-checkbox>

        <mat-option *ngFor="let el of data"
                    [value]="el.value">
            {{el.text}}
        </mat-option>
    </mat-select>
    <mat-progress-bar *ngIf="isLoading"
                      mode="indeterminate"
                      class="autocomplete-progress-bar"></mat-progress-bar>
</mat-form-field>
