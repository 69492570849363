<input #actInput
       matInput
       class="autocomplete-input"
       [placeholder]="canDisplayNonePlaceholder ? ('STUFF.NONE_MALE' | translate) : 'DATA_SET.CODIFICATION_ELEMENT.ACT.ADD_ACTS' | translate"
       [formControl]="formControl"
       [matAutocomplete]="auto"
       [matChipInputFor]="matChipList">

<mat-progress-bar mode="indeterminate"
                  class="autocomplete-progress-bar"
                  *ngIf="isAutocompleteLoading"></mat-progress-bar>

<mat-autocomplete #auto="matAutocomplete"
                  [panelWidth]="autoCompletePanelWidth"
                  [class]="'codification-act-add-autocomplete'"
                  (optionSelected)="selectAct($event)">
    <mat-option *ngFor="let searchedAct of searchedActs | async; index as i"
                [value]="searchedAct"
                [ngbTooltip]="getTooltipText(searchedAct, i)"
                [container]="'body'"
                tooltipClass="ngb-tooltip"
                placement="left">
        {{searchedAct.slug}} {{searchedAct.name}}
    </mat-option>
</mat-autocomplete>
