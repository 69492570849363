import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {Subscription} from 'rxjs';
import {SnackBarService} from '../../../../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StayDetailEditActExecutionDateDialogComponent} from '../../codification/act/edit-act-date-dialog/stay-detail-edit-act-execution-date-dialog.component';

@Component({
    selector: 'ct-stay-detail-filter-search-act-list',
    templateUrl: './stay-detail-filter-search-act-list.component.html',
    styleUrls: ['./stay-detail-filter-search-act-list.component.scss']
})
export class StayDetailFilterSearchActListComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];
    private _acts: any[] = [];

    @Input() dataSetElement: any;
    @Input() isLoading: any;
    @Input() set acts(newActs: any[]) {
        if (newActs) {
            this._acts = newActs;
            this._updateActsIsCodedProperty();
        }
    }

    get acts() {
        return this._acts;
    }

    constructor(private _dataSetElementService: DataSetElementService,
                private _snackBarService: SnackBarService,
                private _translateService: TranslateService,
                private _broadcastService: BroadcastService,
                private _matDialog: MatDialog) { }

    ngOnInit() {
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailCodificationAct::updateLinkActs':
                        this._updateActsIsCodedProperty();
                        break;
                }
            });
        this._subscriptions.push(sub);
    }

    private _updateActsIsCodedProperty(): void {
        if (this.acts) {
            const linkActs = this.dataSetElement.dataSetContent.linkActNotGrouped;
            if (linkActs) {
                this.acts.forEach(act => {
                    act.isCoded = linkActs.find(linkAct => linkAct.id === act.id);
                });
            }
        }
    }

    openExecuteDateDisplayDialog(linkAct: any) {
        const dialogRef: MatDialogRef<StayDetailEditActExecutionDateDialogComponent> =
            this._matDialog.open(StayDetailEditActExecutionDateDialogComponent, {
                data: {
                    linkAct
                },
                autoFocus: false,
                panelClass: 'data-set-element-dates-edit-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(async res => {
                if (res) {
                    this.addAct(res.new);
                }
            });
    }

    async addAct(act: any) {
        try {
            act.isLoading = true;
            const addedActs = await this._dataSetElementService
                .addActs(this.dataSetElement.id, {data: [{actId: act.id, execution_date: act.execution_date}], type: act.type});
            if (addedActs) {
                this._snackBarService.success(this._translateService.instant('SUCCESS.ADD_ACT'));
                this._broadcastService.send('act::addActs', {acts: [addedActs[0]]});
            }
            act.isLoading = false;
        } catch (e) {
            console.error(e);
        }
    }

    ngOnDestroy() {
        if (this._subscriptions?.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
