import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';
import {DiagnosticNote} from '../../../modules/diagnostic/note/diagnostic-note.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticNoteApiService {
    private _BASE_URL = 'diagnostic-note';

    constructor(private _http: HttpService) { }

    getAll(urlRequestParams?: any): Observable<any> {
        return this._http
            .get(this._BASE_URL, {params: urlRequestParams})
            .pipe(map(res => (
                {
                    ...res,
                    meta: {
                        total: res.meta?.total || 0,
                        lastPage: res.meta?.last_page || 1,
                        currentPage: res.meta?.current_page || 1
                    }
                }
            )));
    }

    get(diagnosticNoteId: number, urlRequestParams?: any): Observable<DiagnosticNote> {
        return this._http
            .get(`${this._BASE_URL}/${diagnosticNoteId}`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    create(body: any) {
        return this._http
            .post(`${this._BASE_URL}`, body)
            .pipe(map(res => res.data));
    }

    update(diagnosticNoteId: number, body: any) {
        return this._http
            .post(`${this._BASE_URL}/${diagnosticNoteId}`, body)
            .pipe(map(res => res.data));
    }

    delete(diagnosticNoteId: number) {
        return this._http
            .delete(`${this._BASE_URL}/${diagnosticNoteId}`)
            .pipe(map(res => res.data));
    }
}
