<div class="acts-block"
     *ngIf="!isLoading.value">
    <ng-container *ngFor="let act of acts; last as isLast">
        <mat-progress-bar mode="indeterminate"
                          [ngClass]="{'visibility-hidden': !act.isLoading}"></mat-progress-bar>

        <div class="act"
             [ngClass]="{'act-coded': act.isCoded}">
            <div class="align-center">
                <span class="act-slug">{{act.slug}}</span>

                <div class="display-inline-block act-name"
                     [ngbTooltip]="act.name">
                    {{act.name}}
                </div>
                <button mat-mini-fab
                        color="primary"
                        class="add-act-button"
                        (click)="openExecuteDateDisplayDialog(act)"><mat-icon>add</mat-icon></button>
            </div>
        </div>

        <mat-divider *ngIf="!isLast"></mat-divider>
    </ng-container>
</div>

<div class="align-center-center margin-top-10"
     *ngIf="isLoading.value">
    <mat-progress-spinner
        mode="indeterminate"
        [diameter]="70"></mat-progress-spinner>
</div>
