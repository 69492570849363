import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../../http/http.service';
import {Observable} from 'rxjs';
import {CommentInterface} from '../../../../modules/stay/detail/comment/stay-detail-comment.service';

@Injectable({
    providedIn: 'root'
})
export class CommentApiService {
    private _BASE_URL = 'data-set-element';

    constructor(private _http: HttpService) { }

    create(dataSetContentId: number, comment: CommentInterface): Observable<CommentInterface> {
        return this._http
            .post(`${this._BASE_URL}/${dataSetContentId}/comment`, comment)
            .pipe(map(res => res.data));
    }
    update(dataSetContentId: number, comment: CommentInterface): Observable<CommentInterface> {
        return this._http
            .patch(`${this._BASE_URL}/${dataSetContentId}/comment`, comment)
            .pipe(map(res => res.data));
    }
}
