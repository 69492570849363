import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionIsErasService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super(['isEras'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.STAY_STATUS'),
            'isEras',
            null,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {isEras: false};
        this.uriParams = {isEras: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.uriParams.isEras = this.params.isEras ? 'true' : '';
    }

    convertToParams(): void {
        this.params.isEras = this.uriParams.isEras === 'true';
    }

    convertToParamsFromApiParams(): void {
        this.params.isEras = this.apiParams.args.value;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {value: this.params.isEras};
    }
}
