import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {TranslationHelperService} from '../../../../../core/services/translation.helper.service';

@Component({
    selector: 'ct-stay-detail-codification-act',
    templateUrl: './stay-detail-codification-act.component.html',
    styleUrls: ['./stay-detail-codification-act.component.scss']
})
export class StayDetailCodificationActComponent implements OnInit {
    @Input() dataSetElement: DataSetElement;
    @Input() autoCompletePanelWidth = '90%';
    @Input() validateButton = true;
    @Input() disableEdition = false;
    @Input() isSSRUseCase = false;
    @Input() isBasicAct = false;

    @Input() triggerValidation: boolean;

    actTypeNotForSSR: string;
    @Output() validationDone: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _translationHelperService: TranslationHelperService) { }

    ngOnInit() {
        this._initActTypeNotForSSR();
    }

    private _initActTypeNotForSSR() {
        this.actTypeNotForSSR =  this._translationHelperService.isFeatureAvailable('CSARRActs') ? 'ccam' : 'unk';
    }
}
