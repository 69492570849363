<mat-progress-bar mode="indeterminate"
                  *ngIf="isLoading"></mat-progress-bar>

<div class="edit-dialog-content">
    <h1 mat-dialog-title>{{'TOOLTIP.EDIT_RUM_DATES' | translate}}</h1>

    <form [formGroup]="datesForm"
          (ngSubmit)="validate()">
        <mat-dialog-content>
            <div>
                <mat-form-field class="max-width-110">
                    <input matInput
                           required
                           name="startDate"
                           formControlName="startDate"
                           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.AFTER' | translate"
                           [matDatepicker]="startDateDatePicker"
                           [max]="formControls['endDate'].value">
                    <mat-datepicker-toggle matSuffix
                                           [for]="startDateDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDateDatePicker
                                    startView="year"></mat-datepicker>
                </mat-form-field>

                <span class="margin-left-10 margin-right-10">{{'STUFF.AT_LOWERCASE' | translate}}</span>

                <mat-form-field class="width-70">
                    <input matInput
                           required
                           type="time"
                           name="startDateTime"
                           formControlName="startDateTime">
                </mat-form-field>

                <mat-error class="margin-bottom-10"
                           *ngIf="datesForm.errors?.outRangeDate">
                    {{'ERROR.START_DATE_SHOULD_BE_BEFORE_TO_END_DATE' | translate}}
                </mat-error>
            </div>

            <div>
                <mat-form-field class="max-width-110">
                    <input matInput
                           required
                           name="endDate"
                           formControlName="endDate"
                           [placeholder]="'DATA_SET.CODIFICATION.SEARCH.BEFORE' | translate"
                           [matDatepicker]="endDateDatePicker"
                           [min]="formControls['startDate'].value">
                    <mat-datepicker-toggle matSuffix
                                           [for]="endDateDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDateDatePicker
                                    startView="year"></mat-datepicker>
                </mat-form-field>

                <span class="margin-left-10 margin-right-10">{{'STUFF.AT_LOWERCASE' | translate}}</span>

                <mat-form-field class="width-70">
                    <input matInput
                           required
                           type="time"
                           name="endDateTime"
                           formControlName="endDateTime">
                </mat-form-field>

                <mat-error class="margin-bottom-10"
                           *ngIf="datesForm.errors?.outRangeDate">
                    {{'ERROR.END_DATE_SHOULD_BE_AFTER_TO_START_DATE' | translate}}
                </mat-error>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button
                    mat-dialog-close
                    type="button"
                    class="color-blue-on-white">{{'BUTTON.CANCEL' | translate | uppercase}}</button>

            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="isLoading || isValidationDisabled()">{{'BUTTON.VALIDATE' | translate | uppercase}}</button>
        </mat-dialog-actions>
    </form>
</div>
