import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailCodificationHeaderComponent} from './stay-detail-codification-header.component';
import {WarningIndicatorModule} from '../../../../../shared/components/warning-indicator/warning-indicator.module';

@NgModule({
    declarations: [
        StayDetailCodificationHeaderComponent
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule.forChild(),
        WarningIndicatorModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule
    ],
    exports: [
        StayDetailCodificationHeaderComponent
    ],
})
export class StayDetailCodificationHeaderModule {}
