import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionHospitalizationTypeComponent} from './search-engine-condition-hospitalization-type.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionHospitalizationTypeService extends SearchEngineCondition {
    static data: {id: number, text: string}[] = [
        {id: 1, text: 'DATA_SET.CODIFICATION.SEARCH.HOSPITALIZATION_TYPE.1'},
        {id: 2, text: 'DATA_SET.CODIFICATION.SEARCH.HOSPITALIZATION_TYPE.2'},
        {id: 3, text: 'DATA_SET.CODIFICATION.SEARCH.HOSPITALIZATION_TYPE.3'},
        {id: 4, text: 'DATA_SET.CODIFICATION.SEARCH.HOSPITALIZATION_TYPE.4'}
    ];

    constructor(private _translateService: TranslateService) {
        super(['hospitalizationType'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.HOSPITALIZATION_TYPE.HOSPITALIZATION_TYPE'),
            'hospitalizationType',
            SearchEngineConditionHospitalizationTypeComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {hospitalizationType: []};
        this.uriParams = {hospitalizationType: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.hospitalizationType) {
            this.uriParams.hospitalizationType = this.params.hospitalizationType.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.hospitalizationType) {
            this.params.hospitalizationType = this.uriParams.hospitalizationType.split(';')?.map(id => Number.parseFloat(id));
        }
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.hospitalizationType};
    }

    convertToParamsFromApiParams(): void {
        this.params.hospitalizationType = this.apiParams.args.values;
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields['hospitalizationType']) &&
            !this.isEmpty(fields['hospitalizationType']));
    }

    isValidApiParams(args: any): boolean {
        return !!args?.values?.length;
    }
}
