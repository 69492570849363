import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PasswordValidatorComponent} from './password-validator.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        PasswordValidatorComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule.forChild()
    ],
    exports: [
        PasswordValidatorComponent
    ]
})
export class PasswordValidatorModule {
}
