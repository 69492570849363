import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ExpansionPanelComponent} from './expansion-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {CustomHtmlAttributeDirectiveModule} from '../../directives/custom-html-attribute/custom-html-attribute.directive.module';

@NgModule({
    declarations: [
        ExpansionPanelComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatExpansionModule,
        CustomHtmlAttributeDirectiveModule
    ],
    exports: [
        ExpansionPanelComponent
    ]
})
export class ExpansionPanelModule {}
