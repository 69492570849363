import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailCollapseButtonComponent} from './stay-detail-collapse-button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        StayDetailCollapseButtonComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        StayDetailCollapseButtonComponent
    ],
})
export class StayDetailCollapseButtonModule {}
