import {Component} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';

@Component({
    selector: 'ct-search-engine-condition-birth-date',
    templateUrl: './search-engine-condition-birth-date.component.html',
    styleUrls: ['./search-engine-condition-birth-date.component.scss']
})
export class SearchEngineConditionBirthDateComponent {
    condition: any;

    constructor(public colorService: ColorService) { }

    dateChange(event: any, period: string) {
        if (event.value) {
            if (period === 'start') {
                this.condition.service.params.startDate = new Date(event.value);
            } else if (period === 'end') {
                this.condition.service.params.endDate = new Date(event.value);
            }
        }
    }
}
