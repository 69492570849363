<mat-form-field class="full-width">
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let selectedDocumentType of selectedDocumentTypes; trackBy: trackByFn"
                  [ngClass]="{'background-color-grey': selectedDocumentType.isInferredDocumentType}"
                  [value]="selectedDocumentType.code"
                  [matTooltip]="getTooltip(selectedDocumentType.isInferredDocumentType)"
                  [removable]="true"
                  (removed)="removeChip(selectedDocumentType)">
            {{selectedDocumentType.name || selectedDocumentType.slug}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input #documentTypeInput
               [placeholder]="'DATA_SET.CODIFICATION.SEARCH.DOCUMENT_TYPES' | translate"
               [formControl]="formControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList">
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="selectDocumentType($event)">
        <mat-option *ngFor="let searchedDocumentType of searchedDocumentTypes$ | async; index as i"
                    [value]="searchedDocumentType">
            {{searchedDocumentType.name || searchedDocumentType.slug}}
            <b *ngIf="searchedDocumentType.isInferredDocumentType">({{'TOOLTIP.INFERRED_DOCUMENT_TYPE' | translate}})</b>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
