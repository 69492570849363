import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {StayDetailDiagnosticPredictionHeaderComponent} from './stay-detail-diagnostic-prediction-header.component';

@NgModule({
    declarations: [
        StayDetailDiagnosticPredictionHeaderComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    exports: [
        StayDetailDiagnosticPredictionHeaderComponent
    ],
})
export class StayDetailDiagnosticPredictionHeaderModule {}
