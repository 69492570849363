import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailCodificationComponent} from './stay-detail-codification.component';
import {TabListModule} from '../../../../shared/components/tab-list/tab-list.module';
import {FieldDisplayModule} from '../../../../shared/components/field-display/field-display.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from '@angular/material/button';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StayDetailCodificationDiagnosticModule} from './diagnostic/stay-detail-codification-diagnostic.module';
import {StayDetailCodificationActModule} from './act/stay-detail-codification-act.module';
import {StayDetailCodificationNearAutomationModule} from './near-automation/stay-detail-codification-near-automation.module';
import {StayDetailCodificationHeaderModule} from './header/stay-detail-codification-header.module';
import {StayDetailDataSetElementDatesEditDialogModule} from '../shared/stay-detail-data-set-element-dates-edit-dialog/stay-detail-data-set-element-dates-edit-dialog.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        StayDetailCodificationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),
        StayDetailCodificationDiagnosticModule,
        StayDetailCodificationNearAutomationModule,
        StayDetailCodificationActModule,
        StayDetailCodificationHeaderModule,
        StayDetailDataSetElementDatesEditDialogModule,
        NgbTooltipModule,
        TabListModule,
        FieldDisplayModule,
        DragDropModule,
        MatDividerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatChipsModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        StayDetailCodificationComponent,
        StayDetailCodificationHeaderModule
    ],
})
export class StayDetailCodificationModule {}
