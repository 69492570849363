import {Injectable} from '@angular/core';
import {HttpService} from '../../../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DataSetElementCodificationActApiService {
    private _BASE_URL = 'data-set-element';

    constructor(private _http: HttpService) {}

    addActs(dataSetElementId: number, codificationId: any, body: any, urlRequestParams?: any) {
        return this._http
            .post(`${this._BASE_URL}/${dataSetElementId}/codification/${codificationId}/act`, body, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    deleteAct(dataSetElementId: number, codificationId: any, actId: number, urlRequestParams?: any) {
        return this._http
            .delete(`${this._BASE_URL}/${dataSetElementId}/codification/${codificationId}/act/${actId}`, null , {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
