<ng-container *ngIf="!isTypeRehabilitation">
    <div class="row margin-bottom-5">
        <div class="col no-padding">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'HEALTH.PATIENT.AGE_GESTATIONAL' | translate"
                              [value]="dataSetElement?.dataSetContent?.gestationalAge"></ct-field-display>
        </div>

        <div class="col no-padding">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'HEALTH.PATIENT.NEW_BORN_WEIGHT_BIS' | translate"
                              [value]="dataSetElement?.dataSetContent?.newBornWeight"></ct-field-display>
        </div>
    </div>
</ng-container>

<div class="row margin-bottom-5"
     *ngIf="!isTypeRehabilitation">
    <div class="col no-padding">
        <ct-field-display class="display-block"
                          [isKeyBold]="true"
                          [key]="'DATA_SET.CODIFICATION.SEARCH.LAST_MENSTRUATION_DATE' | translate"
                          [value]="dateHelperService.mysqlToFrench(dataSetElement?.dataSetContent?.lastMenstruationAt)"></ct-field-display>
    </div>
</div>

<ng-container *ngIf="isTypeRehabilitation && hasHealthStayAdditional()">
    <div class="row margin-bottom-5">
        <div class="col no-padding">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'DATA_SET.CODIFICATION.SEARCH.HOSPITALIZATION_TYPE.HOSPITALIZATION_TYPE' | translate"
                              [value]="getHospitalizationTypeText(dataSetElement.dataSetContent.healthStayAdditional.hospitalizationType)"></ct-field-display>
        </div>
    </div>

    <div class="row margin-bottom-5">
        <div class="col no-padding">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'DATA_SET.CODIFICATION.SEARCH.PRESENCE_DAYS' | translate"
                              [value]="getPresenceDaysText(dataSetElement.dataSetContent.healthStayAdditional.presenceDays)"
                              [isValueDisabled]="getPresenceDaysText(dataSetElement.dataSetContent.healthStayAdditional.presenceDays) === ('STUFF.NONE_MALE' | translate)"></ct-field-display>
        </div>
    </div>

    <div class="row margin-bottom-5">
        <div class="col no-padding">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'DATA_SET.CODIFICATION.SEARCH.SURGERY_DATE' | translate"
                              [value]="dateHelperService.mysqlToFrench(dataSetElement.dataSetContent.healthStayAdditional.surgeryDate)"></ct-field-display>
        </div>
    </div>
</ng-container>

<div class="row margin-bottom-5">
    <div class="col no-padding">
        <ct-field-display class="display-block"
                          [isKeyBold]="true"
                          [key]="'DATA_SET.CODIFICATION_ELEMENT.DOCTOR' | translate"
                          [value]="getDoctorName(dataSetElement?.dataSetContent?.healthDoctor)"></ct-field-display>
    </div>
</div>

<!-- IGS2 -->
<div class="row margin-bottom-5"
     *ngIf="dataSetElement?.dataSetContent?.healthIgs2Id">
    <div class="col no-padding">
        <ct-field-display class="display-block"
                          [isKeyBold]="true"
                          [key]="'ACRONYM.IGS2' | translate"
                          [value]="dataSetElement?.dataSetContent?.healthIgs2Id"></ct-field-display>
    </div>
</div>
