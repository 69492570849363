import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionNumberComparisonComponent} from '../../shared/search-engine-condition-number-comparison/search-engine-condition-number-comparison.component';

@Injectable()
export class SearchEngineConditionNewBornWeightService extends SearchEngineCondition {
    // Because of shared component
    paramMinKey = 'weightMin';
    paramMaxKey = 'weightMax';
    placeholder = 'HEALTH.PATIENT.NEW_BORN_WEIGHT';
    unit = 'g';

    constructor(private _translateService: TranslateService) {
        super(['newBornWeight'],
            _translateService.instant('HEALTH.PATIENT.NEW_BORN_WEIGHT'),
            'newBornWeight',
            SearchEngineConditionNumberComparisonComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {weightMin: null, weightMax: null};
        this.uriParams = {newBornWeight: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.updateUriParamsComparisonNumber(this.uriParams, 'newBornWeight', this.params, 'weightMin', 'weightMax');
    }

    convertToParams(): void {
        this.updateParamsComparisonNumber(this.uriParams, 'newBornWeight', this.params, 'weightMin', 'weightMax');
    }

    convertToParamsFromApiParams(): void {
        this.params.weightMin = this.apiParams.args.minValue;
        this.params.weightMax = this.apiParams.args.maxValue ?? null;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.getComparisonArgsObject(this.params.weightMin, this.params.weightMax)};
    }

    isValid(fields?: any): boolean {
        return this.isValidComparisonNumbers(fields, 'newBornWeight');
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
