import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionBirthDateComponent} from './search-engine-condition-birth-date.component';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionBirthDateService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super([],
            _translateService.instant('HEALTH.PATIENT.BIRTH_DATE'),
            'birthDate',
            SearchEngineConditionBirthDateComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {startDate: null, endDate: null};
        this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.updateParamsFromApiParamsComparisonDate(this.apiParams, this.params);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        const startDate: string = DateHelperService.toMysql(this.params.startDate);
        const endDate: string = DateHelperService.toMysql(this.params.endDate);
        this.apiParams.args = {...this.getComparisonArgsObject(startDate, endDate)};
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }

    updateParamsFromApiParamsComparisonDate(apiParams: any, params: any): void {
        if (apiParams.args.operator === '<=') {
            params.endDate = new Date(DateHelperService.mysqlToInput(apiParams.args.minValue)) ?? null;
        } else if (apiParams.args.operator === '>=') {
            params.startDate = new Date(DateHelperService.mysqlToInput(apiParams.args.minValue)) ?? null;
        } else if (apiParams.args.operator === '>=<') {
            params.startDate = new Date(DateHelperService.mysqlToInput(apiParams.args.minValue)) ?? null;
            params.endDate = new Date(DateHelperService.mysqlToInput(apiParams.args.maxValue)) ?? null;
        }
    }
}
