import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Directive({
    selector: '[ctFeatureToggle]'
})
export class FeatureToggleDirective implements OnInit {
    @Input() ctFeatureToggle: string;

    constructor(private _componentRef: TemplateRef<any>,
                private _viewContainerRef: ViewContainerRef) {
    }

    ngOnInit(): void {
        if (this._isEnabled()) {
            this._viewContainerRef.createEmbeddedView(this._componentRef);
        } else {
            this._viewContainerRef.clear();
        }
    }

    private _isEnabled() {
        const features = environment.features;
        if (features['*']) {
            return true;
        }
        return features[this.ctFeatureToggle];
    }
}
