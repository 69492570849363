import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionEstablishmentComponent} from './search-engine-condition-establishment.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionEstablishmentService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super(['establishment'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.FINESS_ID'),
            'establishment',
            SearchEngineConditionEstablishmentComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {establishment: []};
        this.uriParams = {establishment: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.establishment) {
            this.uriParams.establishment = this.params.establishment.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.establishment !== '') {
            this.params.establishment = this.uriParams.establishment.split(';');
        } else {
            this.params.establishment = [];
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.establishment = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.establishment};
    }
}
