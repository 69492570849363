import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionNumberComparisonComponent} from '../../shared/search-engine-condition-number-comparison/search-engine-condition-number-comparison.component';

@Injectable()
export class SearchEngineConditionGestationalAgeService extends SearchEngineCondition {
    // Because of shared component
    paramMinKey = 'ageMin';
    paramMaxKey = 'ageMax';
    placeholder = 'HEALTH.PATIENT.AGE_GESTATIONAL';

    constructor(private _translateService: TranslateService) {
        super(['gestationalAge'],
            _translateService.instant('HEALTH.PATIENT.AGE_GESTATIONAL'),
            'gestationalAge',
            SearchEngineConditionNumberComparisonComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {ageMin: null, ageMax: null};
        this.uriParams = {gestationalAge: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        this.updateUriParamsComparisonNumber(this.uriParams, 'gestationalAge', this.params, 'ageMin', 'ageMax');
    }

    convertToParams(): void {
        this.updateParamsComparisonNumber(this.uriParams, 'gestationalAge', this.params, 'ageMin', 'ageMax');
    }

    convertToParamsFromApiParams(): void {
        this.params.ageMin = this.apiParams.args.minValue;
        this.params.ageMax = this.apiParams.args.maxValue ?? null;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.getComparisonArgsObject(this.params.ageMin, this.params.ageMax)};
    }

    isValid(fields?: any): boolean {
        return this.isValidComparisonNumbers(fields, 'gestationalAge');
    }

    isValidApiParams(args: any): boolean {
        return !!(args && !this.equalsFalse(args.minValue) && args.operator);
    }
}
