<div class="display-inline-block">
    <mat-form-field class="max-width-110"
                    [color]="colorService.getColorAccordingToCurrentState()">
        <input matInput
               [placeholder]="'DATA_SET.CODIFICATION.SEARCH.AFTER' | translate"
               [matDatepicker]="startDate"
               [max]="condition.service.params.endDate"
               [(ngModel)]="condition.service.params.startDate"
               (dateChange)="onDateChange($event, 'start')">
        <mat-datepicker-toggle matSuffix
                               [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate
                        startView="year"></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="max-width-110 margin-left-16 margin-right-16"
                    [color]="colorService.getColorAccordingToCurrentState()">
        <input matInput
               [placeholder]="'DATA_SET.CODIFICATION.SEARCH.BEFORE' | translate"
               [matDatepicker]="endDate"
               [min]="condition.service.params.startDate"
               [(ngModel)]="condition.service.params.endDate"
               (dateChange)="onDateChange($event, 'end')">
        <mat-datepicker-toggle matSuffix
                               [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate
                        startView="year"></mat-datepicker>
    </mat-form-field>

    <ng-container *ngIf="canDisplayButtons">
        <ng-container *ngIf="clientType === 'private'">
            <div class="display-inline-block font-weight-bold cursor-pointer tag tag-blue tag-raised"
                 (click)="search('yesterday')">{{'TIME.YESTERDAY' | translate}}</div>
            <div class="display-inline-block font-weight-bold cursor-pointer tag tag-blue tag-raised"
                 (click)="search('last7Days')">{{'TIME.LAST_7_DAYS' | translate}}</div>
        </ng-container>

        <ng-container *ngIf="clientType === 'public'">
            <div class="display-inline-block font-weight-bold cursor-pointer tag tag-blue tag-raised"
                 (click)="search('lastMonth')">{{'TIME.LAST_MONTH' | translate}}</div>
            <div class="display-inline-block font-weight-bold cursor-pointer tag tag-blue tag-raised"
                 (click)="search('currentYear')">{{'TIME.CURRENT_YEAR' | translate}}</div>
            <div class="display-inline-block font-weight-bold cursor-pointer tag tag-blue tag-raised"
                 *ngIf="canDisplayLamdaButton()"
                 (click)="search('lamda')">{{'TIME.LAMDA' | translate}}</div>
        </ng-container>

        <div class="display-inline-block font-weight-bold cursor-pointer tag tag-blue tag-raised"
             (click)="onCustomDateButtonClick()">{{getDialogButtonTranslation()}}</div>

        <div class="display-inline-block font-weight-bold cursor-pointer tag tag-blue tag-raised dialog-icon"
             *ngIf="condition.service.params.customSubtractAmount"
             [matTooltip]="'TOOLTIP.EDIT_CUSTOM_DATE' | translate"
             (click)="openDialog()">
            <mat-icon>edit</mat-icon>
        </div>
    </ng-container>
</div>
