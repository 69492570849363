import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {SnackBarService} from '../../../../../core/services/snack-bar.service';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {AppLogService} from '../../../../../core/app-log/app-log.service';
import {DashboardService} from '../../../../dashboard/dashboard.service';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {CodificationLabelEnum} from '../../../shared/codification-label-enum';

@Injectable({
    providedIn: 'root'
})
export class StayDetailCodificationDiagnosticService {
    constructor(
        private _dataSetElementService: DataSetElementService,
        private _snackbarService: SnackBarService,
        private _broadcastService: BroadcastService,
        private _translateService: TranslateService,
        private _logService: AppLogService,
        private _dashboardService: DashboardService,
    ) {
    }

    private _sendLogToLogStash(codificationLabelSlug: CodificationLabelEnum, isPrediction: boolean, revaluation: number, isUpdate: boolean) {
        let message = `Codage ${codificationLabelSlug}`;
        if (isPrediction) {
            message = `Codage prediction ${codificationLabelSlug}`;
        } else if (isUpdate) {
            message = `Modification codage ${codificationLabelSlug}`;
        }
        this._logService.logInfo(message, revaluation);
    }

    public async addDiagnostic(
        dataSetElement: DataSetElement,
        dataSetCodificationId: number,
        data: any,
        canDisplaySuccessMessage = true
    ) {
        try {
            const postData = {
                diagnosticId: data.diagnostic.id,
                codificationLabelId: data.codificationLabelId,
                nearAuto: !!data.isNearAutomation
            };
            const res = await this._dataSetElementService
                .addDiagnostic(dataSetElement, dataSetCodificationId, postData);
            data.diagnostic.isLoading = false;
            if (data.isUpdate) {
                // Update
                if (typeof res === 'object' &&
                    res.downgradedDiagnostic === true) {
                    // With declassification
                    const params = {
                        diagnosticSlug: res.diagnostic.slug,
                        diagnosticName: res.diagnostic.name,
                        codificationLabelId: data.codificationLabelId,
                        codificationLabelSlug: res.codificationLabel.slug
                    };
                    if (canDisplaySuccessMessage) {
                        const message = `${this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.DIAGNOSTIC.DIAGNOSTIC_DECLASSIFIED', params)}`;
                        this._snackbarService.success(message);
                    }
                } else if (canDisplaySuccessMessage) {
                    // Without declassification
                    this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
                }
            } else if (!data.isNearAutomation && canDisplaySuccessMessage) {
                // Creation
                const successMessage = this._translateService.instant('SUCCESS.ADD_DIAGNOSTIC');

                this._snackbarService.success(successMessage);
            } else if (typeof res === 'object' && res.reason) {
                // Webpims error
                this._snackbarService.error(res.reason + ' ' + res.message);
            }
            this._sendEventForDashboard('create', dataSetElement, data, data.isNearAutomation);
            this._sendLogToLogStash(data.codificationLabelSlug, data.isPrediction, data.referencialPrice, data.isUpdate);

            if (canDisplaySuccessMessage) {
                this._broadcastService.send('diagnostic::addDiagnostic', {
                    success: true,
                    reload: true,
                    diagnosticId: data.diagnostic.id,
                    dataSetElementId: dataSetElement.id,
                    codificationLabelId: data.codificationLabelId
                });

                this._broadcastService.send('diagnostic::refreshDisplay');
            }
            if (!data.isPrediction &&
                !data.isCreation &&
                data.isLoading) {
                data.isLoading.value = false;
            }
        } catch (e) {
            data.diagnostic.isLoading = false;
            this._broadcastService.send('diagnostic::addDiagnostic', {
                success: false,
                dataSetElementId: dataSetElement.id,
                codificationLabelId: data.codificationLabelId,
            });
            if (!data.isPrediction &&
                !data.isCreation &&
                data.isLoading) {
                data.isLoading.value = false;
            }
            throw e;
        }
    }

    private _sendEventForDashboard(action: string, dataSetElement: DataSetElement, data: any, isNearAutomation = false) {
        const eventName = !isNearAutomation ? 'stay_codification' : 'stay_near_auto_codification';
        const eventData: any = {
            stayId: dataSetElement.dataSetContent.stayId,
            endDate: dataSetElement.dataSetContent.endDate,
            type: dataSetElement.dataSetContent.type,
            code: data.diagnostic.slug,
            codificationLabelId: data.codificationLabelId,
            codificationLabelSlug: data.codificationLabelSlug,
            value: data.referencialPrice,
            action
        };
        this._dashboardService.sendEvent(eventName, eventData);
    }

    private async _removeDiagnostic(dataSetElement: DataSetElement,
                                    dataSetCodificationId: number,
                                    data: any) {
        try {
            await this._dataSetElementService
                .removeDiagnostic(dataSetElement, dataSetCodificationId, data.diagnostic.id);
            this._sendEventForDashboard('delete', dataSetElement, data);
            this._snackbarService.success(this._translateService.instant('SUCCESS.DELETION'));
            this._broadcastService.send('diagnostic::removeDiagnostic', {
                dataSetElementId: dataSetElement.id,
                codificationLabelId: data.codificationLabelId,
                success: true, reload: true
            });

            if (data.isLoading) {
                data.isLoading.value = false;
            }
        } catch (e) {
            this._broadcastService.send('diagnostic::removeDiagnostic', {
                dataSetElementId: dataSetElement.id,
                codificationLabelId: data.codificationLabelId,
                success: false
            });

            if (data.isLoading) {
                data.isLoading.value = false;
            }

            throw e;
        }
    }

    /**
     * Add/remove diagnostic
     *
     * @param dataSetElement
     * @param dataSetCodificationId
     * @param data
     * @param canDisplaySuccessMessage
     *
     * @private
     */
    async updateDiagnosticCodification(
        dataSetElement: DataSetElement,
        dataSetCodificationId: number,
        data: any,
        canDisplaySuccessMessage = true,
    ) {
        if (!data) {
            return;
        }

        data.diagnostic.isLoading = true;

        try {
            // If label then it's a creation else it's a remove
            if (!data.isRemoval) {
                // If it's a switch of diagnostic codification label
                if (!data.isPrediction && !data.isCreation && data.isLoading) {
                    data.isLoading.value = true;
                }

                await this.addDiagnostic(dataSetElement, dataSetCodificationId, data, canDisplaySuccessMessage);
            } else {
                if (data.isLoading) {
                    data.isLoading.value = true;
                }

                await this._removeDiagnostic(dataSetElement, dataSetCodificationId, data);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async editDiagnostic(dataSetElement: DataSetElement,
                         dataSetCodificationId: number,
                         data: { diagnosticToAdd, diagnosticToRemove, codificationLabelSlug, codificationLabelId, referencialPrice }) {
        try {
            if (data &&
                data.diagnosticToRemove &&
                data.diagnosticToAdd) {
                data.diagnosticToAdd.isLoading = true;
                await this._dataSetElementService.removeDiagnostic(dataSetElement,
                    dataSetCodificationId,
                    data.diagnosticToRemove.id,
                    false);
                const postData = {
                    diagnosticId: data.diagnosticToAdd.id,
                    codificationLabelId: data.codificationLabelId
                };
                await this._dataSetElementService.addDiagnostic(dataSetElement, dataSetCodificationId, postData);
                data.diagnosticToAdd.isLoading = false;
                this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
                this._sendLogToLogStash(data.codificationLabelSlug, false, data.referencialPrice, false);
                this._broadcastService.send('diagnostic::editDiagnostic', {success: true, reload: true, diagnosticToRemove: data.diagnosticToRemove});
            }
        } catch (e) {
            data.diagnosticToAdd.isLoading = false;
            this._broadcastService.send('diagnostic::editDiagnostic', {success: false});
            console.error(e);
            throw e;
        }
    }

    getDiagnosticLabelColor(diagnostic: any, byLevel: boolean, canDisplayDiagnosticSeverityLevel: boolean = true) {
        let color = 'black';
        if (byLevel || (diagnostic?.codificationLabel)) {
            if (byLevel || (diagnostic.codificationLabel.slug === CodificationLabelEnum.DA)) {
                if (canDisplayDiagnosticSeverityLevel) {
                    color = diagnostic.diagnostic?.diagnosticLevel ?
                        this.getDiagnosticLabelColorByLevel(diagnostic.diagnostic.diagnosticLevel.level) : 'grey';
                } else {
                    // Default color when we don't display the severity level
                    color = 'green';
                }
            } else if (diagnostic.codificationLabel.slug === CodificationLabelEnum.DP) {
                color = 'blue';
            } else if (diagnostic.codificationLabel.slug === CodificationLabelEnum.DR || diagnostic.codificationLabel.slug === CodificationLabelEnum.MP) {
                color = 'purple';
            } else if (diagnostic.codificationLabel.slug === CodificationLabelEnum.AE) {
                color = 'indigo';
            }
        }
        return color;
    }

    getDiagnosticLabelColorByLevel(level: number) {
        let color: string;
        switch (level) {
            case 0 :
            case 1 :
                color = 'grey';
                break;
            case 2:
                color = 'green';
                break;
            case 3:
                color = 'orange';
                break;
            case 4:
                color = 'red';
                break;
            default:
                color = 'blue';
                break;
        }
        return color;
    }

    /**
     * Compares diagnostics and excludingDiagnosticIds and remove from diagnostics the excluding ones
     * If force === false only sets exclude property to true otherwise delete it
     * @param diagnostics
     * @param excludingDiagnosticIds
     * @param force
     */
    removeDAByExclusion(diagnostics: any[], excludingDiagnosticIds: number[], force: boolean = false) {
        if (excludingDiagnosticIds) {
            if (force) {
                return diagnostics
                    .filter(diagnostic => {
                        const matchingExcludingDiagnosticId = excludingDiagnosticIds
                            .find(id => id === diagnostic.diagnostic.id);
                        return !(diagnostic.codificationLabel.slug === CodificationLabelEnum.DA && matchingExcludingDiagnosticId);
                    });
            } else {
                return diagnostics
                    .map(diagnostic => {
                        const matchingExcludingDiagnosticId = excludingDiagnosticIds
                            .find(id => id === diagnostic.diagnostic.id);
                        if (diagnostic.codificationLabel.slug === CodificationLabelEnum.DA && matchingExcludingDiagnosticId) {
                            diagnostic.exclude = true;
                            if (diagnostic.new) {
                                this._snackbarService.success(this._translateService.instant('TOOLTIP.EXCLUDED_CODE'));
                                diagnostic.new = false;
                            }
                            return diagnostic;
                        } else {
                            diagnostic.exclude = false;
                            return diagnostic;
                        }
                    });
            }
        }
        return diagnostics;
    }

    /**
     * For logStash
     * @private
     */
    getRevaluationValue(diagnostic: any, predictiveDiagnostics: any[], dataSetElement: DataSetElement) {
        if (diagnostic &&
            predictiveDiagnostics &&
            dataSetElement?.dataSetContent) {
            const predictiveDiagnostic = predictiveDiagnostics.find(el => el.diagnosticId === diagnostic.id);
            return predictiveDiagnostic ? predictiveDiagnostic.pricing - dataSetElement.dataSetContent.pricing : null;
        }
        return null;
    }
}
