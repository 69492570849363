import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionStringValueComponent} from '../../shared/search-engine-condition-string-value/search-engine-condition-string-value.component';
import {Injectable} from '@angular/core';

@Injectable()

export class SearchEngineConditionPatientIppService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'patientIpp';
    placeholder = 'ACRONYM.PATIENTIPP';

    constructor(private _translateService: TranslateService) {
        super(['patientIpp'],
            _translateService.instant('ACRONYM.PATIENTIPP'),
            'patientIpp',
            SearchEngineConditionStringValueComponent,
            false);
        this.init();
    }

    init(apiParams?: ApiCriteria): void {
        this.params = {patientIpp: null};
        this.setApiParams(apiParams);
    }

    private _storeIppList(obj: any): void {
        let ippList = null;
        if (obj.patientIpp) {
                ippList = obj.patientIpp;
        }
        ippList && !!ippList[0] ? sessionStorage.setItem('patientSearchIppList', JSON.stringify(ippList)) : sessionStorage.setItem('patientSearchIppList', '');
    }

    convertToUriParams(): void {
        this.uriParams.patientIpp = this.params.patientIpp;
        this._storeIppList(this.params);
    }

    convertToParams(): void {
        this.params.patientIpp = this.uriParams.patientIpp;
        this._storeIppList(this.uriParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.patientIpp = this.apiParams.args.value;
        this._storeIppList(this.params);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.patientIpp) {
            this.apiParams.args = {value: this.params.patientIpp};
            this._storeIppList(this.params);
        }
    }
}
