import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';
import {Diagnostic} from '../../../modules/diagnostic/diagnostic.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticApiService {
    private _BASE_URL = 'diagnostic';

    constructor(private _http: HttpService) { }

    getAll(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}`, {params: urlRequestParams})
            .pipe(map(res => (
                {
                    ...res,
                    meta: {
                        total: res.meta?.total || 0,
                        lastPage: res.meta?.last_page || 1,
                        currentPage: res.meta?.current_page || 1
                    }
                }
            )));
    }

    getAllWithES(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/search`, {params: urlRequestParams})
            .pipe(map(res => (
                {
                    ...res,
                    data: res.data.map(el => el.data)
                }
            )));
    }

    getGolds(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/with-gold`, {params: urlRequestParams})
            .pipe(map(res => (
                {
                    ...res,
                    meta: {
                        total: res.meta?.total || 0,
                        lastPage: res.meta?.last_page || 1,
                        currentPage: res.meta?.current_page || 1
                    }
                }
            )));
    }

    getPotentialGolds(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/potential-gold`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    update(diagnosticId: number, body: any, urlRequestParams?: any): Observable<Diagnostic> {
        return this._http
            .patch(`${this._BASE_URL}/${diagnosticId}`, body, urlRequestParams);
    }
}
