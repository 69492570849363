import {Component, Input, OnInit} from '@angular/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {StayDetailCodificationService} from '../stay-detail-codification.service';
import {DateHelperService} from '../../../../../core/services/date.helper.service';
import {DataSetElementService} from '../../../shared/data-set-element.service';

@Component({
    selector: 'ct-stay-detail-codification-header',
    templateUrl: './stay-detail-codification-header.component.html',
    styleUrls: ['./stay-detail-codification-header.component.scss']
})
export class StayDetailCodificationHeaderComponent implements OnInit {
    private _canDisplayImpreciseStayDateIndicatorFromConfig: boolean;

    @Input() dataSetElement: DataSetElement;

    constructor(private _configurationService: ConfigurationService,
                private _dateHelperService: DateHelperService,
                private _dataSetElementService: DataSetElementService,
                private _stayDetailCodificationService: StayDetailCodificationService) { }

    ngOnInit(): void {
        this._canDisplayImpreciseStayDateIndicatorFromConfig = this._configurationService.getConfigurationContent('front', 'date.canDisplayImpreciseStayDateIndicator');
    }

    canDisplayImpreciseStayDateIndicator() {
        return this._canDisplayImpreciseStayDateIndicatorFromConfig && this._dataSetElementService.hasImpreciseDate(this.dataSetElement);
    }

    openEditionDialog(event: any) {
        if (event) {
            event.stopPropagation();
        }
        this._stayDetailCodificationService.openDataSetElementDatesEditDialog(this.dataSetElement);
    }

}
