import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DocumentTypeDocumentListDisplayDialogComponent} from '../dialog/document-type-document-list-display-dialog.component';

@Component({
    selector: 'ct-document-type-document-list-display-button',
    templateUrl: './document-type-document-list-display-button.component.html',
    styleUrls: ['./document-type-document-list-display-button.component.scss']
})
export class DocumentTypeDocumentListDisplayButtonComponent implements OnInit {
    @Input() documents: any;
    @Input() inferredDocumentTypeName: string;
    @Input() documentTypeName: string;

    constructor(private _matDialog: MatDialog) { }

    ngOnInit(): void {
    }

    openDialog(event: any): void {
        if (event) {
            event.stopPropagation();
        }
        this._matDialog.open(DocumentTypeDocumentListDisplayDialogComponent, {
            data: {
                documents: this.documents,
                inferredDocumentTypeName: this.inferredDocumentTypeName,
                documentTypeName: this.documentTypeName
            },
            autoFocus: false,
            panelClass: 'document-type-document-list-display-dialog'
        });
    }
}
