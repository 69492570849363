import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export interface BroadcastData {
    message: string;
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class BroadcastService {
    private readonly _broadcastSubject$: Subject<BroadcastData>;

    get broadcastData(): Observable<BroadcastData> {
        return this._broadcastSubject$.asObservable();
    }

    constructor() {
        this._broadcastSubject$ = new Subject<BroadcastData>();
    }

    send(message: string, data?: any) {
        const broadcastData: BroadcastData = {
            message,
            data
        };
        this._broadcastSubject$.next(broadcastData);
    }
}
