import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-dependency',
    templateUrl: './search-engine-condition-dependency.component.html'
})
export class SearchEngineConditionDependencyComponent implements OnInit {
    condition: any;
    paramKey: string;
    placeholder: string;
    data: any[];

    constructor() { }

    ngOnInit(): void {
    }

}
