import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from '@angular/material/button';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StayDetailCodificationDiagnosticComponent} from './stay-detail-codification-diagnostic.component';
import {FieldDisplayModule} from '../../../../../shared/components/field-display/field-display.module';
import {StayDetailCodificationDiagnosticAddDialogComponent} from './add-dialog/stay-detail-codification-diagnostic-add-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {StayDetailCodificationDiagnosticAddAutocompleteComponent} from './add-autocomplete/stay-detail-codification-diagnostic-add-autocomplete.component';
import {CustomHtmlAttributeDirectiveModule} from '../../../../../shared/directives/custom-html-attribute/custom-html-attribute.directive.module';

@NgModule({
    declarations: [
        StayDetailCodificationDiagnosticComponent,
        StayDetailCodificationDiagnosticAddDialogComponent,
        StayDetailCodificationDiagnosticAddAutocompleteComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        NgbTooltipModule,
        FieldDisplayModule,
        DragDropModule,
        MatDividerModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatRadioModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatChipsModule,
        CustomHtmlAttributeDirectiveModule
    ],
    exports: [
        StayDetailCodificationDiagnosticComponent
    ],
})
export class StayDetailCodificationDiagnosticModule {}
