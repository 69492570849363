
<div *ngIf="canDisplayLinkStayUser && dataSetElement.children" class="d-inline-block">
    <button class="user"
            [matTooltip]="this.userFullName"
            [ngClass]="user ? 'assigned' : 'default'"
            [disabled]="!this.isClickable"
            (click)="assignStayUser()"
    >
        {{this.userInitial}}
    </button>
</div>
