import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {StateService} from '@uirouter/core';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionCodificationStatusService extends SearchEngineCondition {

    constructor(public $state: StateService,
                private _translateService: TranslateService,
                private _configurationService: ConfigurationService) {
        super(['codificationStatus'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.STAY_STATUS'),
            'codificationStatus',
            null,
            false);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria, overrideDefaultValue = true): void {
        const codificationStatusDefaultValue = [];
        if (['quality-control', 'quality-control-imprecise-diagnostic'].includes(this.$state.current.name)) {
            codificationStatusDefaultValue.push('0');
        } else if (this.$state.params.fromFilterSearchList !== 'true') {
            const codificationStatusDefaultLabel = this._configurationService.getConfigurationContent('front', 'advancedSearch.stayStatusDefaultValue');

            const codificationStatusDefaults = {
                unchecked: '0',
                pricing: '1',
                verified: '2',
                waiting: '3',
                'primo-codage': '4',
                'no-pricing': '5',
            };

            codificationStatusDefaultValue.push(
                codificationStatusDefaults[codificationStatusDefaultLabel] || '-1'
            );

            if (!this.$state.params.codificationStatus &&
                this.$state.params.fromStayList === 'true' &&
                overrideDefaultValue) {
                codificationStatusDefaultValue.push('-1');
            }
        }
        if (!codificationStatusDefaultValue.length) {
            codificationStatusDefaultValue.push('-1');
        }
        this.params = {codificationStatus: codificationStatusDefaultValue};
        this.uriParams = {codificationStatus: codificationStatusDefaultValue};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams() {
        if (this.params.codificationStatus) {
            this.uriParams.codificationStatus = this.params.codificationStatus.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.codificationStatus !== '') {
            this.params.codificationStatus = typeof this.uriParams.codificationStatus === 'string' ? this.uriParams.codificationStatus.split(';') : this.uriParams.codificationStatus;
        } else {
            this.params.codificationStatus = [];
        }
    }

    convertToParamsFromApiParams(): void {
        if ( this.apiParams ) {
            this.params.codificationStatus = this.apiParams.args.values;
        }
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.codificationStatus};
    }

    getApiParams(): ApiCriteria {
        const apiParams = super.getApiParams();
        return apiParams?.args?.values?.includes('-1') ? null : this._apiParams;
    }
}
