import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionMedicalUnitComponent} from './search-engine-condition-medical-unit.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionMedicalUnitService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService) {
        super(['medicalUnitId'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.MEDICAL_UNIT'),
            'medicalUnitId',
            SearchEngineConditionMedicalUnitComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {medicalUnitId: []};
        this.uriParams = {medicalUnitId: null};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params.medicalUnitId) {
            this.uriParams.medicalUnitId = this.params.medicalUnitId.join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams.medicalUnitId) {
            this.params.medicalUnitId = this.uriParams.medicalUnitId.split(';')?.map(id => Number.parseFloat(id));
        }
    }

    convertToParamsFromApiParams(): void {
        this.params.medicalUnitId = this.apiParams.args.values;
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {values: this.params.medicalUnitId};
    }

    isValid(fields?: any): boolean {
        if (!this.isAdvanced) {
            return true;
        }
        return !this.isEmpty(fields['medicalUnitId']) && !this.isUndefined(fields['medicalUnitId']);
    }

    isValidApiParams(args: any): boolean {
        if (!this.isAdvanced) {
            return true;
        }
        return !!args?.values?.length;
    }
}
