<mat-form-field class="padding-right-20 operator-value">
    <mat-select placeholder="{{ label | translate}}"
                [ngModel]="data"
                (ngModelChange)="dataChange.emit($event)">
        <mat-option *ngFor="let operator of operators"
                    [value]="operator">
            {{operator}}
        </mat-option>
    </mat-select>
</mat-form-field>
