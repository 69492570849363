import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class FilterSearchDiagnosticApiService {
    private _BASE_URL = 'filter-search';

    constructor(private _http: HttpService) {}

    refreshScore(filterSearchId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${filterSearchId}/diagnostic/refresh-score`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
