export class StringHelperService {
    static splitOutsideEnclosure(str: string, delimiter: string, enclosure: string): string[] {
        const result = [];
        let quote = false; // true means we're inside a quoted field
        // iterate over each character, keep track of current field index (i) and previous character (pc)
        let i = 0;
        let previousChar = '';
        for (let index = 0; index < str.length; index++) {
            const cc = str[index];
            result[i] = result[i] || ''; // create a new array value (starts with empty string) if necessary
            // If it's a delimiter, and we're not in a quoted field, move on to the next field
            if (cc === delimiter && !quote) {
                ++i;
                continue;
            }
            // If it's just one enclosure not escaped, begin/end quoted field
            if (cc === enclosure && previousChar !== '\\') {
                quote = !quote;
            }
            // Otherwise, append the current character to the current field
            result[i] += cc;
            // Keep previous character to detect escaped enclosure
            previousChar = cc;
        }
        return result;
    }

    /**
     * Returns input string with first letter of each word capitalized
     * @param str
     */
    static capitalizeFirstLetterOfEachWord(str: string): string {
        if (str) {
            return str.toLowerCase()
                .split(' ')
                .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
        }
        return '';
    }
}
