import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {StayDetailDiagnosticPredictionElementComponent} from './stay-detail-diagnostic-prediction-element.component';
import {SimplifiedScoreModule} from '../../../../../shared/components/simplified-score/simplified-score.module';
import {TooltipDirectiveModule} from '../../../../../shared/directives/tooltip/tooltip.directive.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {StayDetailDiagnosticPredictionElementTooltipComponent} from './tooltip/stay-detail-diagnostic-prediction-element-tooltip.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {WarningIndicatorModule} from '../../../../../shared/components/warning-indicator/warning-indicator.module';
import {MatCardModule} from '@angular/material/card';

@NgModule({
    declarations: [
        StayDetailDiagnosticPredictionElementComponent,
        StayDetailDiagnosticPredictionElementTooltipComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        WarningIndicatorModule,
        TooltipDirectiveModule,
        SimplifiedScoreModule,
        NgbTooltipModule,
        MatCardModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule
    ],
    exports: [
        StayDetailDiagnosticPredictionElementComponent
    ],
})
export class StayDetailDiagnosticPredictionElementModule {}
