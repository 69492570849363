<div>
    <ct-stay-detail-codification-act-edition [dataSetElement]="dataSetElement"
                                             [autoCompletePanelWidth]="autoCompletePanelWidth"
                                             [disableEdition]="disableEdition"
                                             [validateButton]="validateButton"
                                             [isSSRUseCase]="isSSRUseCase"
                                             [fieldTranslationKey]="!isSSRUseCase ? 'HEALTH.ACTS' : 'HEALTH.ACTS_CCAM'"
                                             [triggerValidation]="triggerValidation"
                                             [actType]="actTypeNotForSSR"
                                             [isBasicAct]="isBasicAct"
                                             (validationDone)="validationDone.emit(true)"></ct-stay-detail-codification-act-edition>
</div>

<div *ngIf="isSSRUseCase"
     class="margin-top-10">
    <ct-stay-detail-codification-act-edition [dataSetElement]="dataSetElement"
                                             [autoCompletePanelWidth]="autoCompletePanelWidth"
                                             [disableEdition]="disableEdition"
                                             [validateButton]="validateButton"
                                             [isSSRUseCase]="isSSRUseCase"
                                             [fieldTranslationKey]="'HEALTH.ACTS_CSARR'"
                                             [triggerValidation]="triggerValidation"
                                             [actType]="'csarr'"
                                             [isBasicAct]="isBasicAct"
                                             (validationDone)="validationDone.emit(true)"></ct-stay-detail-codification-act-edition>
</div>
