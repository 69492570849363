<div class="align-center">
    <ct-status-light class="margin-left-5"
                     *ngIf="canDisplayStayRisk"
                     [tooltip]="getRiskTooltip()"
                     [reverse]="true"
                     [status]="dataSetElement.dataSetContent?.hasDocumentOverlapRisk"></ct-status-light>

    <div class="align-center margin-left-16">
        <h2 class="h2 display-inline-block no-margin external-text"
            *ngIf="isTypeExternal">{{'STUFF.EXTERNAL' | translate}}</h2>

        <ng-container *ngIf="!isTypeExternal">
            <!--Change all RUM status-->
            <div class="stay-status background-color"
                 [ngClass]="[dataSetElementService.getStatusColor(dataSetElement?.codificationDataSetElementStatus?.status)]"
                 [matTooltip]="dataSetElementService.getStatusDescription(dataSetElement?.codificationDataSetElementStatus?.status)"
                 [matMenuTriggerFor]="changeStatusMenu"
                 (click)="openMenu($event)">
                <span>{{dataSetElementService.getStatusText(dataSetElement)}}</span>
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #changeStatusMenu="matMenu">
                <ng-template matMenuContent>
                    <button mat-menu-item
                            [matTooltip]="dataSetElementService.getStatusDescription(0)"
                            (click)="dataSetElementService.changeDataSetElementStatus(dataSetElement, 0)">
                        <span>{{'STAY.STATUS_UNCHECKED' | translate}}</span>

                    </button>

                    <button mat-menu-item
                            [matTooltip]="dataSetElementService.getStatusDescription(3)"
                            (click)="dataSetElementService.changeDataSetElementStatus(dataSetElement, 3)">
                        <span>{{'STAY.STATUS_WAITING' | translate}}</span>
                    </button>

                    <button mat-menu-item
                            [matTooltip]="dataSetElementService.getStatusDescription(2)"
                            (click)="dataSetElementService.changeDataSetElementStatus(dataSetElement, 2)">
                        <span>{{'STAY.STATUS_VERIFIED' | translate}}</span>
                    </button>

                </ng-template>
            </mat-menu>
        </ng-container>

        <ct-ai-coded-indicator *ngIf="!isTypeExternal"
                               [aiCodedStatus]="dataSetElement.codificationDataSetElementStatus?.aiCodedStatus"></ct-ai-coded-indicator>

        <div *ngIf="dataSetElement.dataSetContent.eras"
             class="raac background-color"
             [ngClass]="{'md-blue-900': dataSetElement.dataSetContent.eras, 'md-red-500': !dataSetElement.dataSetContent.eras}"
             [ngbTooltip]="'DATA_SET.CODIFICATION.SEARCH.ERAS' | translate"
             tooltipClass="ngb-tooltip">
            RAAC
        </div>

        <div class="spinner">
            <mat-progress-spinner
                *ngIf="dataSetElement.codificationDataSetElementStatus?.statusLoading"
                [diameter]="15"
                mode="indeterminate">
            </mat-progress-spinner>
        </div>
    </div>
</div>
