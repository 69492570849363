import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class MedicationApiService {
    private _BASE_URL = 'medicament';

    constructor(private _http: HttpService) { }

    get(medicationId: number, urlRequestParams?: any) {
        urlRequestParams = {
            ...urlRequestParams,
            'medicament-id': medicationId
        };
        return this._http
            .get(`${this._BASE_URL}`, {params: urlRequestParams});
    }
}
