import {Component, Input, OnInit} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';

@Component({
    selector: 'ct-search-engine-condition-filter-search-type',
    templateUrl: './search-engine-condition-filter-search-type.component.html',
    styleUrls: ['./search-engine-condition-filter-search-type.component.scss']
})
export class SearchEngineConditionFilterSearchTypeComponent implements OnInit {
    @Input() condition: any;

    filterSearchTypes = [
        {
            value: 'diagnosis',
            text: 'HEALTH.DIAGNOSTICS'
        },
        {
            value: 'act',
            text: 'HEALTH.ACTS'
        },
        {
            // Cela ajoute une checkbox "Liste de travail" dans la liste déroulante "Type de règle" dans le filtre de recherche
            value: 'worklist',
            text: 'FILTER_SEARCH.LIST.WORKLIST'
        },
        {
            value: 'none',
            text: 'STUFF.OTHER_PLURAL'
        }];

    constructor(public colorService: ColorService) { }

    ngOnInit() {
    }

}
