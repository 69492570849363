import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationApiService {

    constructor(private _http: HttpService) {}

    login(body: any) {
        return this._http
            .post(`login`, body)
            .pipe(map(obj => {
                return {
                    ...obj.data,
                    firstName: obj.data.first_name,
                    lastName: obj.data.last_name,
                    passwordChangedAt: obj.data.password_changed_at
                };
            }));
    }

    logout() {
        return this._http
            .get(`logout`);
    }

    isAuthenticated() {
        return this._http
            .get(`is-authenticated`);
    }
}
