import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ct-search-engine-condition-presence-days',
    templateUrl: './search-engine-condition-presence-days.component.html',
    styleUrls: ['./search-engine-condition-presence-days.component.scss']
})
export class SearchEngineConditionPresenceDaysComponent implements OnInit {
    condition: any;
    days = [
        {index: 0, value: false, text: 'TIME.DAY.MONDAY'},
        {index: 1, value: false, text: 'TIME.DAY.TUESDAY'},
        {index: 2, value: false, text: 'TIME.DAY.WEDNESDAY'},
        {index: 3, value: false, text: 'TIME.DAY.THURSDAY'},
        {index: 4, value: false, text: 'TIME.DAY.FRIDAY'},
        {index: 5, value: false, text: 'TIME.DAY.SATURDAY'},
        {index: 6, value: false, text: 'TIME.DAY.SUNDAY'}
    ];

    constructor() { }

    ngOnInit(): void {
        this._initDaysValue();
    }

    private _initDaysValue(): void {
        if (this.condition?.service?.params?.presenceDays) {
            Object.entries(this.condition.service.params.presenceDays)
                .forEach(([key, value]: [string, boolean]) => {
                    const day = this.days.find(el => el.index.toString() === key);
                    if (day) {
                        day.value = value;
                    }
                });
        }
    }

    onChange(isChecked: boolean, index: number): void {
        if (this.condition?.service?.params?.presenceDays) {
            this.condition.service.params.presenceDays[index] = isChecked;
        }
    }
}
