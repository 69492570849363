import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {DataSetElement} from '../../../../shared/data-set-element.model';
import {StayDetailService} from '../../../stay-detail.service';
import {StayDetailCodificationDiagnosticService} from '../../diagnostic/stay-detail-codification-diagnostic.service';

@Component({
    selector: 'ct-stay-detail-codification-near-automation-confirm-dialog',
    templateUrl: './stay-detail-codification-near-automation-confirm-dialog.component.html',
    styleUrls: ['./stay-detail-codification-near-automation-confirm-dialog.component.scss']
})
export class StayDetailCodificationNearAutomationConfirmDialogComponent implements OnInit {
    @ViewChild('cancelButton', {read: ElementRef}) cancelButtonElement: ElementRef;
    @ViewChild('validateButton', {read: ElementRef}) validateButtonElement: ElementRef;

    private _isCancelButtonFocused: boolean;
    private _isValidateButtonFocused: boolean;

    title: string;
    dataSetElement: DataSetElement;
    isLastStay = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _hotKeysService: HotkeysService,
                public dialogRef: MatDialogRef<StayDetailCodificationNearAutomationConfirmDialogComponent>,
                private _stayDetailService: StayDetailService,
                private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService) {
        this.dataSetElement = this.data.dataSetElement;
        this._setHotkeys();
    }

    ngOnInit() {
        this._init();
    }

    private _setHotkeys() {
        this._hotKeysService
            .add(new Hotkey('left', (): boolean => {
                this.cancelButtonElement.nativeElement.focus();
                this._isCancelButtonFocused = true;
                this._isValidateButtonFocused = false;
                return false; // Prevent bubbling
            }));
        this._hotKeysService
            .add(new Hotkey('right', (): boolean => {
                this.validateButtonElement.nativeElement.focus();
                this._isCancelButtonFocused = false;
                this._isValidateButtonFocused = true;
                return false; // Prevent bubbling
            }));
        this._hotKeysService
            .add(new Hotkey('enter', (): boolean => {
                const buttonElement = this._isValidateButtonFocused ? this.validateButtonElement : this.cancelButtonElement;
                buttonElement.nativeElement.click();
                return false; // Prevent bubbling
            }));
    }

    private _init() {
        const dataSetElementListingInfo = JSON.parse(sessionStorage.getItem('dataSetElementListingInfo'));
        if (this.dataSetElement &&
            this.dataSetElement.parent &&
            dataSetElementListingInfo &&
            dataSetElementListingInfo.data) {
            const currentStoredDataSetElement = dataSetElementListingInfo.data
                .find(element => element.dataSetElementId === this.dataSetElement.parent.id);
            if (currentStoredDataSetElement) {
                this.isLastStay = (dataSetElementListingInfo.currentPage === dataSetElementListingInfo.lastPage) &&
                    !currentStoredDataSetElement.nextDataSetElementFirstChildId;
            }
        }
    }

    confirm(value: any) {
        this.dialogRef.close(value);
    }
}
