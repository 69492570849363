import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DocumentTypeDocumentListDisplayButtonComponent} from './document-type-document-list-display-button.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        DocumentTypeDocumentListDisplayButtonComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        DocumentTypeDocumentListDisplayButtonComponent
    ]
})
export class DocumentTypeDocumentListDisplayButtonModule {}
